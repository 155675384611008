import http, { getApiURL } from '../httpService'

export function getCompanyHolisticPayroll() {
  const url = getApiURL({
    pathname: '/financial_wellness/dashboard',
  })

  return http.getV2(url)
}

export function createDisabilityClaimsData() {
  const url = getApiURL({
    pathname: '/company_claims_details',
  })

  return http.postV2(url)
}

export function getDisabilityClaims(claimsDetailId) {
  const url = getApiURL({
    pathname: `/company_claims_details/${claimsDetailId}`,
  })

  return http.getV2(url)
}

export function getClaimDetail(claimsDetailId, claimNoveltyId, withDocument) {
  const url = getApiURL({
    pathname: `/company_claims_details/${claimsDetailId}/novelty_claims/${claimNoveltyId}`,
    searchParams: { with_attach_document: withDocument },
  })

  return http.getV2(url)
}

export function createDisabilityClaimsDetail(claimsDetailId) {
  const url = getApiURL({
    pathname: `/company_claims_details/${claimsDetailId}/novelty_claims`,
  })

  return http.postV2(url)
}

export function updateDisabilityClaimsData({
  claimsDetailId,
  claimNoveltyId,
  correctedClaimData,
}) {
  const url = getApiURL({
    pathname: `/company_claims_details/${claimsDetailId}/novelty_claims/${claimNoveltyId}`,
  })

  return http.putV2(url, correctedClaimData)
}

export function getDisabilityClaimsDetail({
  searchParams = {},
  claimsDetailId,
}) {
  const url = getApiURL({
    pathname: `/company_claims_details/${claimsDetailId}/novelty_claims`,
    searchParams,
  })

  return http.getV2(url)
}

export default {
  getCompanyHolisticPayroll,
  createDisabilityClaimsData,
  getDisabilityClaims,
  getClaimDetail,
  createDisabilityClaimsDetail,
  updateDisabilityClaimsData,
  getDisabilityClaimsDetail,
}
