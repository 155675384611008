import { alpha } from '@mui/material'

import error from 'assets/images/three-dimensional-icons/error.png'
import tick from 'assets/images/three-dimensional-icons/tick.png'

const getChipStatusStyles = (themeInstance) => {
  const status = {
    Firmado: 'Firmado',
    Pendiente: 'Pendiente',
  }

  const chipBackgroundColor = {
    Firmado: alpha(themeInstance.palette.primary.light, 0.2),
    Pendiente: alpha(themeInstance.palette.complementary2.light, 0.2),
  }

  const color = {
    Firmado: 'primary.dark',
    Pendiente: 'complementary2.dark',
  }

  const icon = {
    Firmado: tick,
    Pendiente: error,
  }

  const alt = {
    Firmado: 'Persona que ya firmó el documento',
    Pendiente: 'Persona que no ha firmado el documento',
  }

  const iconBackgroundColor = {
    Firmado: themeInstance.palette.primary.light,
    Pendiente: themeInstance.palette.white.dark,
  }

  return {
    status,
    chipBackgroundColor,
    color,
    icon,
    alt,
    iconBackgroundColor,
  }
}

export default getChipStatusStyles
