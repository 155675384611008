import { Form } from 'formik'

import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'

import AddButton from 'components/UI/Button/AddButton'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'

import MobileCell from '../common/CardGrid/MobileCell'

const RowContainer = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Box>
  )
}

const AddNewExtraHour = ({
  isActive,
  handleShow,
  handleClose,
  columnsWidth,
}) => {
  const isTabletUp = useMediaQuery((theme) => theme.breakpoints.up('tablet'))

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        padding: theme.spacing(1, 2, 2, 2),
        [theme.breakpoints.up('tablet')]: {
          height: '3rem',
          padding: theme.spacing(0, 2),
          marginTop: theme.spacing(2),
        },
        [theme.breakpoints.up('laptop')]: {
          padding: theme.spacing(0, 4),
        },
      })}
    >
      {isActive ? (
        <Form style={{ width: '100%' }}>
          {isTabletUp ? (
            <Box
              sx={(theme) => ({
                [theme.breakpoints.up('tablet')]: {
                  display: 'grid',
                  gridTemplateColumns: columnsWidth,
                },
              })}
            >
              <FormField
                name="name"
                placeholder="Nombre"
                autoComplete="off"
                margin="none"
                required
                sx={{
                  maxWidth: '11rem',
                  height: '2rem',
                }}
              />
              <FormField
                type="number"
                name="constant_value"
                autoComplete="off"
                margin="none"
                placeholder="0"
                required
                sx={{
                  maxWidth: '11rem',
                  height: '2rem',
                }}
              />
              <FormField
                type="number"
                name="quantity"
                optional
                autoComplete="off"
                margin="none"
                placeholder="0"
                sx={{
                  maxWidth: '6rem',
                  height: '2rem',
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip
                  title="Cancelar"
                  placement="bottom"
                  arrow
                  disableInteractive
                >
                  <IconButton
                    aria-label="delete"
                    onClick={handleClose}
                    color="complementary1"
                  >
                    <Icon name="x" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ) : (
            <Box>
              <RowContainer>
                <MobileCell alignY="center">
                  <Typography variant="lead1">Concepto</Typography>
                </MobileCell>
                <MobileCell alignX="right" alignY="center">
                  <FormField
                    name="name"
                    placeholder="Nombre"
                    autoComplete="off"
                    margin="none"
                    required
                    sx={{
                      maxWidth: '11rem',
                      height: '2rem',
                    }}
                  />
                </MobileCell>
              </RowContainer>
              <RowContainer>
                <MobileCell alignY="center">
                  <Typography variant="lead1">
                    Valor sobre hora ordinaria
                  </Typography>
                </MobileCell>
                <MobileCell alignX="right" alignY="center">
                  <FormField
                    type="number"
                    name="constant_value"
                    autoComplete="off"
                    margin="none"
                    placeholder="0"
                    required
                    sx={{
                      maxWidth: '11rem',
                      height: '2rem',
                    }}
                  />
                </MobileCell>
              </RowContainer>
              <RowContainer>
                <MobileCell alignY="center">
                  <Typography variant="lead1"># de horas</Typography>
                </MobileCell>
                <MobileCell alignX="right" alignY="center">
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      gap: theme.spacing(1),
                      alignItems: 'center',
                    })}
                  >
                    <FormField
                      type="number"
                      name="quantity"
                      optional
                      autoComplete="off"
                      margin="none"
                      placeholder="0"
                      sx={{
                        maxWidth: '6rem',
                        height: '2rem',
                      }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Tooltip
                        title="Cancelar"
                        placement="bottom"
                        arrow
                        disableInteractive
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={handleClose}
                          color="complementary1"
                        >
                          <Icon name="x" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </MobileCell>
              </RowContainer>
            </Box>
          )}
        </Form>
      ) : (
        <AddButton onClick={handleShow}>
          Agregar una nueva hora o recargo
        </AddButton>
      )}
    </Box>
  )
}

export default AddNewExtraHour
