import { Box } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import ContactExpertCard from 'components/Worker/WorkersOnboarding/SSAffiliation/InProcessModal/atoms/ContactExpertCard'
import InProcessModalHeader from 'components/Worker/WorkersOnboarding/SSAffiliation/InProcessModal/atoms/ModalHeader'

import { getCompanyId } from 'utils/company'
import useCompanyHolisticPayrollService from 'utils/hooks/HolisticPayroll/CompayService'

import ClaimDataCard from './atoms/ClaimDataCard'
import ClaimStatusStepper from './atoms/ClaimStatusStepper'
import DisabilityDateCard from './atoms/DisabilityDateCard'
import DisabilityDocument from './atoms/DisabilityDocument'
import MoneyToClaimCard from './atoms/MoneyToClaimCard'

export default function ClaimStatusModalContent({
  handleClose,
  claimsDetailId,
  claimNoveltyId,
}) {
  const companyId = getCompanyId()
  const { companyHolisticPayrollQuery: claimStatusDetailQuery } =
    useCompanyHolisticPayrollService({
      serviceParams: {
        queryKey: ['getClaimStatusDetail', companyId],
        claimsDetailId,
        claimNoveltyId,
      },
    })
  const claimStatusDetailQueryData = claimStatusDetailQuery?.data
  const claimStatus = claimStatusDetailQueryData?.status

  const isClaimCompleted = claimStatus === 'claimed'

  return claimStatusDetailQuery.isLoading ? (
    <LoadingBox />
  ) : (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(4.5),
      })}
    >
      <InProcessModalHeader
        handleClose={handleClose}
        claimStatus={claimStatus}
        isProcessCompleted={isClaimCompleted}
        processType="claim"
      />
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: spacing(3),
        })}
      >
        <ClaimDataCard worker={claimStatusDetailQueryData?.worker_data} />
        <MoneyToClaimCard statusDetailData={claimStatusDetailQueryData} />
        <DisabilityDateCard statusDetailData={claimStatusDetailQueryData} />
      </Box>
      <DisabilityDocument statusDetailData={claimStatusDetailQueryData} />
      <ClaimStatusStepper statusDetailData={claimStatusDetailQueryData} />
      <ContactExpertCard handleClose={handleClose} />
    </Box>
  )
}
