import cardValidator from 'card-validator'
import { useFormikContext } from 'formik'
import { useState } from 'react'

import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'

import amex from 'assets/images/views/subscription/amex.svg'
import dinersClub from 'assets/images/views/subscription/dinersclub.svg'
import mastercard from 'assets/images/views/subscription/mastercard.svg'
import visa from 'assets/images/views/subscription/visa.svg'

export const creditCardLogos = {
  'american-express': amex,
  'diners-club': dinersClub,
  mastercard,
  visa,
}

const CreditCardNumberField = ({ showLogo = true, name }) => {
  const { values, setValues } = useFormikContext()
  const [cardType, setCardType] = useState()

  const handleValueChange = ({ value }) => {
    const ccType = cardValidator.number(value).card?.type

    setCardType(ccType)

    setValues({
      ...values,
      credit_card_form: { ...values.credit_card_form, card_number: value },
    })
  }

  return (
    <FormField
      name={name || 'card_number'}
      label="Número de la tarjeta"
      variant="number"
      isNumericString
      format="#### #### #### ####"
      onValueChange={handleValueChange}
      inputProps={{ autoComplete: 'cc-number' }}
      optional={false}
      endAdornment={
        showLogo ? (
          cardType && creditCardLogos[cardType] ? (
            <img
              height={25}
              width={50}
              src={creditCardLogos[cardType]}
              alt={`${cardType} logo`}
            />
          ) : (
            <Icon name="credit-card" />
          )
        ) : null
      }
    />
  )
}

export default CreditCardNumberField
