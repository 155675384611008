import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { Alert, Box, IconButton, Typography, useTheme } from '@mui/material'

import LinkButton from 'components/UI/Button/LinkButton'
import CircularProgressCounter from 'components/UI/CircularProgressCounter'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { yupLocaleES } from 'utils/form'

import { PASSWORD_RECOVERY, UNLOCK_ACCOUNT } from 'config/routes'

yup.setLocale(yupLocaleES)

export const initialValues = {
  email: '',
  password: '',
  otp_code: '',
}

export function getValidationSchema(otpAuthStep) {
  return otpAuthStep === 1
    ? yup.object({
        email: yup.string().email().required(),
        password: yup
          .string()
          .trim()
          .min(8, 'La contraseña es muy corta')
          .max(128, 'La contraseña es demasiado larga')
          .required(),
      })
    : yup.object({
        otp_code: yup
          .string()
          .trim()
          .length(6, 'Debe tener 6 números.')
          .required(),
      })
}

function ResendEmailUnlockAccountCard() {
  const [disabledLink, setDisabledLink] = useState(true)

  const onComplete = () => {
    setDisabledLink(false)
  }

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3),
      })}
    >
      <CircularProgressCounter
        startCounting
        duration={120}
        onComplete={onComplete}
      />
      <LinkButton
        component={RouterLink}
        to={UNLOCK_ACCOUNT}
        disabled={disabledLink}
      >
        Reenviar correo
      </LinkButton>
    </Box>
  )
}

const getMessage = ({ code, requestMessage, otpAuthStep }) => {
  const messages = {
    '0001': {
      severityAlert: 'error',
      description:
        otpAuthStep === 1 ? (
          <Typography fontSize="0.8rem">
            El correo electrónico o contraseña son incorrectos. Recuerda que
            siempre puedes recuperar tu contraseña en caso de que no la
            recuerdes.{' '}
            <Link to={PASSWORD_RECOVERY} fontWeight={600} color="accent4.main">
              Olvidé mi contraseña.
            </Link>
          </Typography>
        ) : (
          <Typography fontSize="0.8rem">
            El código que ingresaste es incorrecto o ya expiró.
          </Typography>
        ),
    },
    '0002': {
      severityAlert: 'error',
      description: (
        <Typography fontSize="0.8rem">
          El usuario con el que estás ingresando está inactivo. Contacta con el
          administrador de la cuenta para activar el usuario.
        </Typography>
      ),
    },
    '0003': {
      severityAlert: 'warning',
      description: (
        <Typography
          fontSize="0.8rem"
          sx={(theme) => ({
            paddingRight: theme.spacing(5.275),
          })}
        >
          {requestMessage}
        </Typography>
      ),
    },
    '0101': {
      severityAlert: 'error',
      description: <Typography fontSize="0.8rem">{requestMessage}</Typography>,
    },
  }

  return (
    messages[code] || {
      severityAlert: 'info',
      description: (
        <>
          <Typography fontSize="0.8rem">{requestMessage}</Typography>
        </>
      ),
    }
  )
}

function AlertUnlockAccount({ description, onClose }) {
  const themeInstance = useTheme()
  const warningColor = themeInstance.palette.warning.main

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        display: 'flex',
        border: `1px solid ${warningColor}`,
        borderRadius: '0.5rem',
        overflow: 'hidden',
        margin: theme.spacing(2, 0),
      })}
    >
      <Box
        sx={{
          width: '0.625rem',
          backgroundColor: warningColor,
        }}
      />
      <Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(1.875, 2.625, 1.875, 1),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            })}
          >
            <Icon name="info" sx={{ color: warningColor }} fontSize="large" />
            <Typography variant="h4">!Upsss¡ Algo ha sucedido</Typography>
          </Box>
          <IconButton
            sx={{
              backgroundColor: 'transparent',
              '&:focus,:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={onClose}
          >
            <Icon name="circle-x" />
          </IconButton>
        </Box>
        <Box
          sx={(theme) => ({
            paddingLeft: theme.spacing(7),
          })}
        >
          {description}
          <ResendEmailUnlockAccountCard />
        </Box>
      </Box>
    </Box>
  )
}

export function AlertMessage({ message = {}, otpAuthStep, handleCloseAlert }) {
  const { code, message: requestMessage } = message

  const { severityAlert, description } = getMessage({
    code,
    requestMessage,
    otpAuthStep,
  })

  return code === '0003' ? (
    <AlertUnlockAccount description={description} onClose={handleCloseAlert} />
  ) : (
    <Alert severity={severityAlert} onClose={handleCloseAlert}>
      {description}
    </Alert>
  )
}
