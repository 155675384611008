import { useState } from 'react'

export function useProvisionsAdjustmentModal() {
  const [state, setState] = useState({
    open: false,
    electronicPayrollId: '',
    provisions: null,
  })

  const openProvisionsAdjustmentModal = ({
    electronicPayrollId,
    provisions,
  }) => {
    setState({
      electronicPayrollId,
      provisions,
      open: true,
    })
  }

  const closeProvisionsAdjustmentModal = () => {
    setState({
      open: false,
      electronicPayrollId: '',
      provisions: null,
    })
  }

  return {
    provisionsAdjustmentModalState: state,
    openProvisionsAdjustmentModal,
    closeProvisionsAdjustmentModal,
  }
}
