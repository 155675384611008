import { Formik } from 'formik'
import { useQueryClient } from 'react-query'

import {
  Box,
  Grid,
  IconButton,
  Link,
  Button as MButton,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'

import { getUserEmail, getUserId } from 'utils/auth'
import useUsersService from 'utils/hooks/settings/usersService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

const IntegrationToken = () => {
  const queryClient = useQueryClient()
  const userEmail = getUserEmail()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const confirm = useConfirm()
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const queryKey = ['userToken', getUserId()]
  const { usersQuery, usersMutation } = useUsersService({
    serviceParams: {
      queryKey,
    },
  })

  const handleCopyToClipboard = async () => {
    try {
      if (!document.hidden) {
        await navigator.clipboard.writeText(usersQuery.data)
        showSuccessMessage(
          'Se ha copiado el token al portapapeles correctamente'
        )
      }
    } catch (error) {
      handleError(error)
    }
  }

  const confirmUpdateToken = () => {
    usersMutation.mutate(
      {
        mutationMethod: 'PATCH',
        mutationKey: 'updateUserToken',
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey)
          showSuccessMessage('El token se ha renovado correctamente')
        },
      }
    )
  }

  const handleUpdateToken = () => {
    confirm({
      title: 'Renovar API token',
      description:
        'Al renovar el token las aplicaciones conectadas con las credenciales anteriores perderán acceso a tu información. ¿Estás seguro que deseas continuar?',
      okText: 'Renovar',
      onOk: confirmUpdateToken,
    })
  }

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(10),
      })}
    >
      <Typography
        variant="h5"
        sx={(theme) => ({
          marginBottom: theme.spacing(4),
        })}
      >
        Integración por API
      </Typography>
      <Typography
        sx={(theme) => ({
          marginBottom: theme.spacing(1),
        })}
      >
        Con la integración por API puedes conectar otros sistemas con Aleluya
        para mantener actualizada tu información. Consulta toda la documentación{' '}
        <Link
          href="https://nominapp.readme.io/reference/información-general"
          target="_blank"
          fontWeight={600}
          color="accent4.main"
        >
          aquí
        </Link>
        .
      </Typography>
      <Typography
        sx={(theme) => ({
          marginBottom: theme.spacing(2),
        })}
      >
        <b>Recuerda:</b> cada usuario tiene diferentes credenciales y todas las
        acciones realizadas, quedarán registradas a nombre del usuario que
        utilices.
      </Typography>
      <Paper
        sx={(theme) => ({
          padding: theme.spacing(3),
          [theme.breakpoints.up('sm')]: {
            maxWidth: '34.5rem',
            width: '100%',
            padding: theme.spacing(3),
          },
        })}
      >
        <Formik
          enableReinitialize
          initialValues={{
            email: userEmail,
            api_token: usersQuery.data,
          }}
        >
          {() => {
            return (
              <>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(3),
                  })}
                >
                  <FormField
                    type="email"
                    name="email"
                    label="Usuario"
                    readOnly
                  />
                  <FormField
                    type="text"
                    name="api_token"
                    label="Token"
                    readOnly
                    stretchRightButton
                    endAdornment={
                      <>
                        {xsDown ? (
                          <IconButton
                            onClick={handleCopyToClipboard}
                            size="large"
                          >
                            <Icon name="copy" />
                          </IconButton>
                        ) : (
                          <MButton
                            sx={(theme) => ({
                              borderRadius: theme.spacing(1.25),
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                              width: '8rem',
                            })}
                            startIcon={<Icon name="copy" />}
                            onClick={handleCopyToClipboard}
                          >
                            Copiar
                          </MButton>
                        )}
                      </>
                    }
                  />
                </Box>
                <Grid container justifyContent="center">
                  <Button
                    variant="outlined"
                    sx={(theme) => ({
                      display: 'inline-flex',
                      alignSelf: 'center',
                      marginTop: theme.spacing(2),
                    })}
                    onClick={handleUpdateToken}
                    loading={usersMutation.isLoading}
                  >
                    Renovar token
                  </Button>
                </Grid>
              </>
            )
          }}
        </Formik>
      </Paper>
    </Box>
  )
}

export default IntegrationToken
