import { Box, Typography } from '@mui/material'

import LoadingBox from 'components/UI/Loading/LoadingBox'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { getCompanyId } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import useCompanyHolisticPayrollService from 'utils/hooks/HolisticPayroll/CompayService'

export default function RejectedClaimModalContent({
  claimsDetailId,
  claimNoveltyId,
}) {
  const companyId = getCompanyId()
  const {
    companyHolisticPayrollQuery: {
      data: { created_at: createdAt, rejected_at: rejectedAt, comment } = {},
      isLoading: claimStatusQueryIsLoading,
    } = {},
  } = useCompanyHolisticPayrollService({
    serviceParams: {
      queryKey: ['getClaimStatusDetail', companyId],
      claimsDetailId,
      claimNoveltyId,
    },
  })

  return claimStatusQueryIsLoading ? (
    <LoadingBox />
  ) : (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(3),
      })}
    >
      <Typography variant="h4" color="accent4.main">
        Fecha de creación: {formatDisplayDateString(createdAt)}
      </Typography>
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(1),
        })}
      >
        <Typography color="black.dark">
          Tu solicitud de reclamación fue rechazada por nuestro equipo.
        </Typography>
        <Typography color="black.dark">
          <Typography component="span" color="black.dark" fontWeight={600}>
            Razón de rechazo:
          </Typography>{' '}
          {comment}
        </Typography>
        <Typography color="black.dark">
          Si tienes dudas, escríbenos a{' '}
          <OrganizationEmail variant="claims" color="accent4.main" />.
        </Typography>
      </Box>
      <Typography color="black.dark">
        <b>Fecha de rechazo: {formatDisplayDateString(rejectedAt)}</b>
      </Typography>
    </Box>
  )
}
