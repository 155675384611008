import { useFormikContext } from 'formik'
import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'
import {
  generateBasicNumberFormatter,
  generateBasicNumberParser,
} from 'utils/format'
import {
  fourToFifteenAlphanumerics,
  fourToFifteenDigits,
  leadingZerosOrNotDigits,
  nineDigits,
  notAlphanumerics,
  sevenToThirteenAlphanumerics,
  sevenToThirteenDigits,
} from 'utils/regex'

import FormField from '../FormField/Index'

yup.setLocale(yupLocaleES)

function getValidationSchema(
  isCompanyId,
  documentType,
  isCurrentPaymentMethod
) {
  const range = isCompanyId ? '6 a 13' : '4 a 15'

  let regex
  let errorMessage

  if (documentType === 'ni') {
    regex = nineDigits
    errorMessage = 'Debe tener 9 dígitos'
  } else if (documentType === 'pa') {
    regex = isCompanyId
      ? sevenToThirteenAlphanumerics
      : fourToFifteenAlphanumerics
    errorMessage = `Debe tener de ${range} carácteres`
  } else {
    regex = isCompanyId ? sevenToThirteenDigits : fourToFifteenDigits
    errorMessage = `Debe tener de ${range} dígitos`
  }

  return isCurrentPaymentMethod
    ? yup.string().nullable()
    : yup.string().nullable().trim().matches(regex, errorMessage).required()
}

const IdentificationNumberField = ({
  isCompanyId = false,
  name,
  documentTypeKey,
  disableValidate,
  optional = false,
  label,
  nestedValidationKey,
  ...props
}) => {
  const { values } = useFormikContext()

  const {
    [documentTypeKey]: documentTypeFromProps,
    is_current_payment_method: isCurrentPaymentMethod,
  } = values

  const documentType = nestedValidationKey
    ? values[nestedValidationKey]?.document_type
    : values.document_type

  const localDocumentType = documentTypeFromProps || documentType

  const validate = async (value) => {
    let errorMessage

    try {
      await getValidationSchema(
        isCompanyId,
        localDocumentType,
        isCurrentPaymentMethod
      ).validate(value)
    } catch (error) {
      const [errorItem] = error.errors
      errorMessage = errorItem
    }

    return errorMessage
  }

  return (
    <FormField
      name={name || 'id_number'}
      label={label || 'Número de documento'}
      variant="custom-format"
      optional={optional}
      formatter={generateBasicNumberFormatter({
        thousandSeparator: null,
        decimalSeparator: null,
      })}
      parser={generateBasicNumberParser({
        regexToRemove:
          localDocumentType === 'pa'
            ? notAlphanumerics
            : leadingZerosOrNotDigits,
        convertToNumber: false,
        max: isCompanyId ? 13 : 15,
      })}
      validate={!disableValidate ? validate : undefined}
      {...props}
    />
  )
}

export default IdentificationNumberField
