import { useState } from 'react'

export function useNoveltyTypeModal() {
  const [state, setState] = useState({
    open: false,
    bonusIncomes: 0,
    electronicPayrollId: '',
    provisions: null,
  })

  const openNoveltyTypeModal = ({
    bonusIncomes,
    electronicPayrollId,
    provisions,
  }) => {
    setState((previousState) => ({
      ...previousState,
      bonusIncomes,
      electronicPayrollId,
      provisions,
      open: true,
    }))
  }

  const closeNoveltyTypeModal = () => {
    setState({
      open: false,
      bonusIncomes: 0,
      electronicPayrollId: '',
      provisions: null,
    })
  }

  return {
    noveltyTypeModalState: state,
    openNoveltyTypeModal,
    closeNoveltyTypeModal,
  }
}
