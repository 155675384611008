import { Box, Button, Card, Typography, alpha } from '@mui/material'

import thumbDown from 'assets/images/three-dimensional-icons/thumb-down.png'

const ErrorInfoCard = ({ quantity, handleOpenErrorsModal }) => {
  const moreThanOne = quantity > 1

  return (
    <Card
      sx={(theme) => ({
        height: '3.5rem',
        display: 'grid',
        gridTemplateColumns: '3.5rem auto',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down('laptop')]: { height: 'auto' },
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: alpha(theme.palette.error.light, 0.4),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        })}
      >
        <Box
          component="img"
          loading="lazy"
          src={thumbDown}
          alt="Tienes errores en tu archivo"
          width={40}
          height={40}
        />
      </Box>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 1),
          display: 'grid',
          gridTemplateColumns: '3fr 1.5fr',
          justifyContent: 'center',
          marginLeft: theme.spacing(2),
          alignItems: 'center',
          alignContent: 'space-around',
          [theme.breakpoints.down('tablet')]: {
            gridTemplateColumns: '1fr',
            padding: theme.spacing(2),
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="lead2">
            ¡OJO! {moreThanOne ? 'Hay varios errores' : 'Hay un error'} en tu
            archivo
          </Typography>
          <Typography variant="body2">
            Tienes{' '}
            <b>
              {quantity} {moreThanOne ? 'errores' : 'un error'}{' '}
            </b>{' '}
            en la carga de estas novedades
          </Typography>
        </Box>
        <Button
          onClick={handleOpenErrorsModal}
          sx={(theme) => ({
            width: '10.5rem',
            [theme.breakpoints.down('tablet')]: {
              marginTop: theme.spacing(2),
              width: 'fit-content',
            },
          })}
        >
          Corregir {moreThanOne ? 'errores' : 'error'}
        </Button>
      </Box>
    </Card>
  )
}

export default ErrorInfoCard
