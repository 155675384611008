import useLocalStorage from '@rehooks/local-storage'
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Button } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Avatar from 'components/UI/Avatar'
import DropdownButton from 'components/UI/Button/Dropdown'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'

import { getBase64FromFile } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'
import { profileImageRequirements } from 'utils/worker'

const ProfilePicture = ({ worker }) => {
  const [workerPictureUrl, setWorkerPictureUrl] = useState(worker.picture)

  const confirm = useConfirm()
  const { isWorker } = useUser()
  const queryClient = useQueryClient()
  const { workerMutation } = useWorkerService({
    queryOptions: {
      enabled: false,
    },
  })
  const { showSuccessMessage, showErrorMessage } = useNotifications()

  const setPicture = useLocalStorage('user_picture')[1]
  const { supported_formats, max_size, min_size } = profileImageRequirements

  const updatePicture = async (file = null) => {
    workerMutation.mutate(
      {
        mutationMethod: 'PATCH',
        mutationKey: isWorker ? 'workerSelfUpdate' : null,
        worker: {
          picture:
            file instanceof File
              ? {
                  image: await getBase64FromFile(file),
                  name: file.name,
                }
              : {},
        },
        workerId: worker.id,
      },
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries(['getWorkerById', worker.id])
          queryClient.invalidateQueries(['getWorkerProfile', worker.id])
          showSuccessMessage(
            `La imagen de perfil ha sido ${file ? 'actualizada' : 'eliminada'}.`
          )
          if (isWorker) setPicture(data.picture)
        },
      }
    )
  }

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'profilePictureMenu',
  })

  const closePopup = () => popupState.close()

  const fileInputRef = useRef(null)
  const stopEvent = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onFileAdded = (event) => {
    stopEvent(event)

    const file = event.target.files[0]

    if (file instanceof File) {
      if (!supported_formats.list.includes(file.type)) {
        showErrorMessage(supported_formats.message)
        return
      }

      if (file.size === min_size.value) {
        showErrorMessage(min_size.message)
        return
      }

      if (file.size > max_size.value) {
        showErrorMessage(max_size.message)
        return
      }
      setWorkerPictureUrl(URL.createObjectURL(file))
      updatePicture(file)
    }
  }

  const handleDeleteFile = () => {
    confirm({
      type: 'warning',
      title: '¿Estás seguro de que quieres eliminar esta foto?',
      description: 'Esta acción no se puede deshacer.',
      okText: 'Eliminar',
      cancelText: 'Cancelar',
      onOk: () => {
        setWorkerPictureUrl(null)
        updatePicture()
      },
    })
  }

  const openFileDialog = () => {
    fileInputRef.current.click()
  }

  const getDropdownOptions = () => {
    const options = [
      {
        id: 'update_picture',
        icon: <Icon name="refresh-cw" />,
        iconPosition: 'left',
        name: `${workerPictureUrl ? 'Actualizar' : 'Cargar'} foto de perfil`,
        onClick: () => {
          openFileDialog()
          closePopup()
        },
      },
    ]

    if (workerPictureUrl) {
      options.push({
        id: 'delete_picture',
        name: 'Borrar foto de perfil',
        icon: <Icon name="trash-2" />,
        iconPosition: 'left',
        onClick: () => {
          handleDeleteFile()
          closePopup()
        },
      })
    }

    return options
  }

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        component="input"
        type="file"
        ref={fileInputRef}
        accept={['image/jpeg', 'image/png']}
        onChange={onFileAdded}
        data-cy="picture-input"
        sx={{
          display: 'none',
        }}
      />
      <DropdownButton
        popupState={popupState}
        menuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        Icon={
          <Button
            sx={(theme) => ({
              minWidth: '2.1rem',
              height: '2.1rem',
              width: '2.1rem',
              position: 'absolute',
              top: 5,
              right: 5,
              zIndex: 1,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '50%',
              padding: theme.spacing(0.5),
            })}
            {...bindTrigger(popupState)}
          >
            <Icon
              name="pencil-line"
              sx={(theme) => ({
                color: theme.palette.black.main,
                fontSize: '1.6rem',
              })}
            />
          </Button>
        }
        options={getDropdownOptions()}
      />
      <Avatar
        src={workerPictureUrl}
        avatarId={6}
        sx={{
          height: '9rem',
          width: '9rem',
          minHeight: '9rem',
          minWidth: '9rem',
        }}
      />
    </Box>
  )
}

export default ProfilePicture
