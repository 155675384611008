import useModals from 'components/App/ModalsManager/useModals'

import ClaimStatusModalContent from './ClaimStatusModalContent'
import ClaimSubmissionSuccessModalContent from './ClaimSubmissionSuccessModalContent'
import RejectedClaimModalContent from './RejectedClaimModal'
import UploadDisabilitySupportModalContent from './UploadDisabilitySupportModalContent'

export default function useDisabilityClaimsModals() {
  const modals = useModals()

  const handleCloseModal = () => {
    modals.closeAll()
  }

  function openClaimSubmissionSuccessModal() {
    modals.openModal({
      id: 'claimSubmissionSuccessModal',
      content: (
        <ClaimSubmissionSuccessModalContent handleClose={handleCloseModal} />
      ),
      modalProps: {
        onCancel: handleCloseModal,
        paperSx: ({ breakpoints }) => ({
          maxWidth: '49rem',
          position: 'relative',
          [breakpoints.up('md')]: {
            width: '49rem',
          },
        }),
        contentWrapperSx: {
          overflow: 'hidden',
          maxHeight: { mobile: '100%', tablet: '24rem' },
        },
        hideFooter: true,
      },
    })
  }

  function openUploadDisabilitySupportModal(
    claimsDetailId,
    claimNoveltyId,
    workerName
  ) {
    modals.openModal({
      id: 'uploadDisabilitySupportModal',
      content: (
        <UploadDisabilitySupportModalContent
          handleClose={handleCloseModal}
          claimsDetailId={claimsDetailId}
          claimNoveltyId={claimNoveltyId}
          workerName={workerName}
        />
      ),
      modalProps: {
        onCancel: handleCloseModal,
        header: '¡Ojo! Revisa tu solicitud de reclamación',
        hideFooter: true,
        paperSx: {
          maxWidth: '45rem',
        },
      },
    })
  }

  function openRejectedClaimModal(claimsDetailId, claimNoveltyId) {
    modals.openModal({
      id: 'rejectedClaimModal',
      content: (
        <RejectedClaimModalContent
          handleClose={handleCloseModal}
          claimsDetailId={claimsDetailId}
          claimNoveltyId={claimNoveltyId}
        />
      ),
      modalProps: {
        onCancel: handleCloseModal,
        header: '¡Ojo! Revisa tu solicitud de reclamación',
        hideCancelButton: true,
        okText: 'Cerrar',
        onOk: handleCloseModal,
        paperSx: {
          maxWidth: '45rem',
        },
      },
    })
  }

  function openClaimStatusModal(claimsDetailId, claimNoveltyId) {
    modals.openModal({
      id: 'claimStatusModal',
      content: (
        <ClaimStatusModalContent
          handleClose={handleCloseModal}
          claimsDetailId={claimsDetailId}
          claimNoveltyId={claimNoveltyId}
        />
      ),
      modalProps: {
        hideCancelButton: true,
        okText: 'Regresar',
        onOk: handleCloseModal,
        paperSx: {
          maxWidth: '58rem',
        },
        contentWrapperSx: { paddingTop: '0 !important' },
      },
    })
  }

  return {
    openClaimSubmissionSuccessModal,
    openUploadDisabilitySupportModal,
    openRejectedClaimModal,
    openClaimStatusModal,
  }
}
