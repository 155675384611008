import { useState } from 'react'

import { Box, IconButton, Tooltip, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

const UserName = ({ value }) => {
  const [clipboard, setClipboard] = useState(false)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(value)
    setClipboard(true)
    setTimeout(() => {
      setClipboard(false)
    }, 1000)
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        variant="body2"
      >
        {value}
      </Typography>

      <Tooltip title="Acabas de copiar el usuario" open={clipboard} arrow>
        <IconButton onClick={handleCopyToClipboard}>
          <Icon name="copy" />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default UserName
