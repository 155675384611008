import Icon from 'components/UI/Icon'

import { isOrganizer } from 'utils/auth'
import { formatDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import { wageCategories } from 'utils/worker'

export const columnsData = [
  {
    Header: '#',
    accessor: 'number',
    customWidth: '100px',
  },
  {
    Header: 'Salario base',
    accessor: 'base_salary',
    customMinWidth: '150px',
    Cell: ({ row }) => formatCurrency(row.original.base_salary),
  },
  {
    Header: 'Tipo',
    accessor: 'category',
    Cell: ({ row }) =>
      wageCategories[row.original.category]
        ? wageCategories[row.original.category]
        : row.original.category,
  },
  {
    Header: 'Fecha Inicial',
    accessor: 'initial_day',
    Cell: ({ row }) => formatDisplayDate(row.original.initial_day),
  },
  {
    Header: 'Fecha Final',
    accessor: 'end_day',
    Cell: ({ row }) => formatDisplayDate(row.original.end_day) || 'No aplica',
  },
]

export const getActions = ({
  organizerDeleteWage,
  confirm,
  showWageModal,
  handleEditWage,
  handleDeleteWage,
}) => {
  return [
    {
      id: 'new_salary',
      tooltip: 'Nuevo salario',
      isFreeAction: true,
      onClick: () => showWageModal({}),
    },
    (rowData) => ({
      id: 'edit_salary',
      icon: <Icon name="pencil-line" />,
      tooltip: 'Editar',
      onClick: () => handleEditWage(rowData),
      hidden: !rowData.editable,
    }),
    (rowData) => ({
      id: 'delete_salary',
      icon: <Icon name="trash-2" />,
      tooltip: 'Eliminar',
      onClick: () => handleDeleteWage(rowData),
      hidden: !rowData.deleteable,
    }),
    {
      id: 'organizer_delete_salary',
      icon: <Icon name="circle-x" />,
      tooltip: 'Eliminar salario',
      hidden: !isOrganizer(),
      onClick: (rowData) => {
        confirm({
          type: 'warning',
          title: 'Eliminar salario',
          description:
            'Eliminar este salario es permanente y no se podrá deshacer. ¿Estás seguro?',
          okText: 'Eliminar',
          confirmCheckbox: true,
          onOk: () => organizerDeleteWage(rowData.id),
        })
      },
    },
  ]
}
