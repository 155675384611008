import { useFormikContext } from 'formik'

import { Box } from '@mui/material'

import IdentificationNumberField from 'components/UI/Formik/CommonFields/IdentificationNumberField'
import FormField from 'components/UI/Formik/FormField/Index'

import { calculateVerificationDigit } from 'utils/company'
import { generateBasicNumberParser } from 'utils/format'

const CompanyIdentificationNumberField = ({
  name,
  disabled = false,
  label = 'Número de documento',
  nestedValidationKey,
  ...props
}) => {
  const { values, setValues } = useFormikContext()

  return values.document_type === 'ni' ||
    values[nestedValidationKey]?.document_type === 'ni' ? (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        alignItems: 'start',
        gridGap: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          gridTemplateColumns: '1fr 4.4rem',
        },
      })}
    >
      <IdentificationNumberField
        name={name}
        onChange={({ target: { value } }) => {
          const parser = generateBasicNumberParser({
            max: 9,
            convertToNumber: false,
          })

          const parsed = parser(value)

          if (
            values.id_number !== parsed &&
            values[nestedValidationKey]?.id_number !== parsed
          ) {
            setValues(
              !nestedValidationKey
                ? {
                    ...values,
                    id_number: parsed,
                    verification_digit: calculateVerificationDigit(parsed),
                  }
                : {
                    ...values,
                    [nestedValidationKey]: {
                      ...values[nestedValidationKey],
                      id_number: parsed,
                      verification_digit: calculateVerificationDigit(parsed),
                    },
                  }
            )
          }
        }}
        isCompanyId
        disabled={disabled}
        label={label}
        optional={false}
        nestedValidationKey={nestedValidationKey}
        {...props}
      />
      <FormField
        name={
          nestedValidationKey
            ? `${nestedValidationKey}.verification_digit`
            : 'verification_digit'
        }
        label="DV"
        placeholder="DV"
        disabled
      />
    </Box>
  ) : (
    <IdentificationNumberField
      name={name}
      isCompanyId
      disabled={disabled}
      label={label}
      nestedValidationKey={nestedValidationKey}
      {...props}
    />
  )
}

export default CompanyIdentificationNumberField
