import * as yup from 'yup'

import { yupLocaleES } from 'utils/form'

yup.setLocale(yupLocaleES)

export const stepNumber = {
  role: 0,
  data: 1,
  select_action: 2,
}

export const stepsData = [
  {
    step: 0,
    id: 'role',
    title: 'Selecciona el rol que mejor te describa',
    subtitle: 'Te estaremos guiando en la configuración de tu cuenta.',
    pathname: '/onboarding/role',
  },
  {
    step: 1,
    id: 'data',
    title: '¡Ya casi! Personalicemos Aleluya para ti',
    subtitle:
      'Ingresa tus datos y los de tu empresa para configurar tu cuenta y comenzar tus 30 días gratis. Sin tarjeta de crédito.',
    pathname: '/onboarding/personalize',
  },
  {
    step: 2,
    id: 'select_action',
    title: '',
    pathname: '/onboarding/congratulations',
  },
]
export const schemaValidation = {
  0: yup.object({
    user_role: yup.string(),
    user_role_other_info: yup.string().when('user_role', {
      is: (userRole) => userRole === 'other',
      then: yup.string().required(),
    }),
  }),
  1: yup.object({
    user_name: yup.string().nullable().required(),
    name: yup
      .string()
      .trim()
      .min(3, 'El nombre debe tener mínimo 3 caracteres')
      .max(50, 'El nombre debe tener máximo 50 caracteres')
      .required(),
    document_type: yup.string().nullable().required(),
    id_number: yup.string().nullable().required(),
    verification_digit: yup.string().nullable(),
    workers_number: yup
      .string()
      .nullable()
      .when('user_role', {
        is: (userRole) => !['employee', 'student'].includes(userRole),
        then: yup.string().nullable().required(),
      }),
    payroll_frequency: yup.string().nullable().required(),
  }),
}
