import { useContext } from 'react'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import { NoveltiesHistoryContext } from './NoveltiesHistory'

const DownloadNoveltiesAction = () => {
  const { hasAnyNovelty, handleClickNoveltyReport } = useContext(
    NoveltiesHistoryContext
  )

  return (
    <Button
      startIcon={<Icon name="download" />}
      onClick={handleClickNoveltyReport}
      disabled={!hasAnyNovelty}
      endIcon={<PremiumFeatureIcon />}
    >
      Descargar novedades
    </Button>
  )
}

export default DownloadNoveltiesAction
