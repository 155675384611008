import { useDateRangePicker } from '@react-aria/datepicker'
import { useDateRangePickerState } from '@react-stately/datepicker'
import { useRef, useState } from 'react'

import {
  Box,
  Dialog,
  Popover,
  dialogClasses,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import DateField from './DateField'
import RangeCalendar from './RangeCalendar'
import RangePickerV2 from './indexV2'

const DateRangePicker = ({
  startDateId,
  startDateDataCy,
  endDateId,
  endDateDataCy,
  disabled,
  onChange,
  value,
  error,
  shouldCloseOnSelect = false,
  version,
  ...props
}) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const state = useDateRangePickerState({
    shouldCloseOnSelect,
    isDisabled: disabled || false,
    onChange,
    value,
    ...props,
  })
  const ref = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    groupProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(
    { ...props, 'aria-label': 'Date range input' },
    state,
    ref
  )

  const onClickInput = () => {
    if (disabled) return

    setAnchorEl(ref.current)
    buttonProps.onPress()
  }

  if (version === 2) {
    return (
      <RangePickerV2
        startDateId={startDateId}
        startDateDataCy={startDateDataCy}
        endDateId={endDateId}
        endDateDataCy={endDateDataCy}
        disabled={disabled}
        onChange={onChange}
        value={value}
        error={error}
        {...props}
      />
    )
  }

  return (
    <>
      <Box
        ref={ref}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          flexDirection: 'column',
          '& > svg': {
            transform: 'rotate(90deg)',
          },
          [theme.breakpoints.up('tablet')]: {
            flexDirection: 'row',
            '& > svg': {
              transform: 'none',
            },
          },
        })}
        {...groupProps}
      >
        <DateField
          {...startFieldProps}
          onClick={onClickInput}
          placeholder="Día inicial"
          id={startDateId}
          disabled={disabled}
          error={error}
          dataCy={startDateDataCy}
        />
        <Icon name="move-right" />
        <DateField
          {...endFieldProps}
          id={endDateId}
          onClick={onClickInput}
          placeholder="Día final"
          disabled={disabled}
          error={error}
          dataCy={endDateDataCy}
        />
      </Box>
      {isDesktop ? (
        <Popover
          {...dialogProps}
          open={state.isOpen}
          onClose={() => state.setOpen(false)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <RangeCalendar
            {...calendarProps}
            onCloseCalendar={() => state.setOpen(false)}
            isDesktop={isDesktop}
          />
        </Popover>
      ) : (
        <Dialog
          keepMounted
          open={state.isOpen}
          onClose={() => state.setOpen(false)}
          sx={{
            [`& .${dialogClasses.paper}`]: {
              margin: 'auto',
            },
          }}
        >
          <RangeCalendar
            {...calendarProps}
            onCloseCalendar={() => state.setOpen(false)}
            isDesktop={isDesktop}
          />
        </Dialog>
      )}
    </>
  )
}

export default DateRangePicker
