import { useFormikContext } from 'formik'

import { Box, Typography } from '@mui/material'

import CellphoneField from 'components/UI/Formik/CommonFields/CellphoneField'
import CompanyIdentificationNumberField from 'components/UI/Formik/CommonFields/CompanyIdentificationNumberField'
import DocumentTypeField from 'components/UI/Formik/CommonFields/DocumentTypeField'
import FormField from 'components/UI/Formik/FormField/Index'

import { documentTypesOptions } from 'utils/company'

export const BillingInfoFields = () => {
  const { values } = useFormikContext()
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        width: '100%',
        rowGap: theme.spacing(2.5),
        gridTemplateColumns: '1fr',
      })}
    >
      <Typography>Confirma acá tus datos</Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: theme.spacing(2),
          [theme.breakpoints.up('desktop')]: {
            gridTemplateColumns: '1fr 1fr',
            ...(values.company_form.document_type === 'ni' && {
              gridTemplateColumns: '1.5fr 3fr',
            }),
          },
        })}
      >
        <DocumentTypeField
          name="company_form.document_type"
          options={documentTypesOptions}
          optional={false}
        />
        <CompanyIdentificationNumberField
          name="company_form.id_number"
          nestedValidationKey="company_form"
        />
      </Box>
      <FormField
        name="company_form.name"
        label="Nombre o razón social"
        placeholder="Nombre o razón social"
        optional={false}
      />
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(2),
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        })}
      >
        <FormField
          name="company_form.email"
          type="emai"
          label="Correo"
          optional={false}
          placeholder="ejemplo@aleluya.com"
        />
        <CellphoneField name="company_form.phone" />
      </Box>
    </Box>
  )
}
