import { Link } from 'react-router-dom'

import { Box } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getDisabilityClaimsIndex } from 'services/organizer/holisticPayrollServices'

import * as routes from 'config/routes'

import { claimsIndexColumnsData } from './helpers'

export default function OrganizerDisabilityClaimsIndex() {
  const { clearCompany } = useUser()

  const fetchCompanyClaims = (pageIndex, search) => {
    return {
      queryKey: ['organizerIndexDisabilityClaims', pageIndex, search],
      queryFunction: () =>
        getDisabilityClaimsIndex({
          searchParams: {
            page: pageIndex + 1,
            search,
            per_page: 20,
          },
        }),
      queryOptions: {
        onSuccess: () => {
          clearCompany()
        },
      },
    }
  }

  return (
    <Page
      header="Reclamación de incapacidades"
      documentTitle="Reclamación de incapacidades"
      grid
    >
      <Box sx={{ gridColumn: '1 / -1' }}>
        <Table
          data={fetchCompanyClaims}
          columns={claimsIndexColumnsData}
          actions={[
            (rowData) => ({
              id: 'details',
              tooltip: 'Ver detalle',
              icon: <Icon name="eye" />,
              buttonProps: {
                component: Link,
                to: routes.ORGANIZER_DISABILITY_CLAIMS_SHOW(rowData.id),
                state: { claimDetailId: rowData.id },
                size: 'medium',
                color: 'complementary1',
                onClick: undefined,
              },
            }),
          ]}
          options={{
            version: { toolbar: 'v2' },
            alignActionsCell: 'center',
            alignActionsHeader: 'center',
          }}
        />
      </Box>
    </Page>
  )
}
