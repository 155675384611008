import { SvgIcon } from '@mui/material'

import sprite from 'assets/sprite.svg'

const Icon = ({ name, sx, fontSize = 'inherit', title } = {}) => {
  return (
    <SvgIcon fontSize={fontSize} inheritViewBox sx={sx}>
      {title ? <title>{title}</title> : null}
      <use href={`${sprite}#${name}`} />
    </SvgIcon>
  )
}

export default Icon
