import { useInbox } from '@trycourier/react-hooks'
import { m } from 'framer-motion'
import { useEffect, useState } from 'react'

import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Popper,
  Typography,
  badgeClasses,
  tabClasses,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import LazyAnimation from 'components/UI/LazyAnimation'
import Link from 'components/UI/MaterialUI/Link'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'

import IconButton from '../IconButton'
import Messages from './Messages'

const bellVariants = {
  active: {
    rotate: [0, 10, -10, 10, -10, 10, -10, 0],
    transition: {
      duration: 1.5,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatDelay: 3,
    },
  },
  inactive: { rotate: 0 },
}

const clapperVariants = {
  active: {
    translateX: [0, -4, 4, -4, 4, -4, 4, 0],
    transition: {
      duration: 1.5,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatDelay: 3,
    },
  },
  inactive: { translateX: 0 },
}

const NotificationIcon = ({ showBadge }) => {
  return (
    <Badge
      invisible={!showBadge}
      variant="dot"
      color="primary"
      sx={(theme) => ({
        [`& .${badgeClasses.badge}`]: {
          right: 8,
          top: 8,
          height: '0.8rem',
          width: '0.8rem',
          borderRadius: '1rem',
          border: `2px solid ${theme.palette.white.main}}`,
        },
      })}
    >
      <LazyAnimation>
        <m.svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            transformOrigin: 'top',
          }}
          animate={showBadge ? 'active' : 'inactive'}
          variants={bellVariants}
        >
          <m.path d="M10.268 21a2 2 0 0 0 3.464 0" variants={clapperVariants} />
          <m.path d="M3.262 15.326A1 1 0 0 0 4 17h16a1 1 0 0 0 .74-1.673C19.41 13.956 18 12.499 18 8A6 6 0 0 0 6 8c0 4.499-1.411 5.956-2.738 7.326" />
        </m.svg>
      </LazyAnimation>
    </Badge>
  )
}

const Popover = () => {
  const { markAllAsRead, messages, fetchMessages } = useInbox()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const [activeTab, setActiveTab] = useState(0)

  let unreadMessageCount = 0
  messages?.forEach((message) => {
    if (!message.read) {
      unreadMessageCount += 1
    }
  })

  const filteredMessages =
    activeTab === 1 ? messages?.filter((message) => !message.read) : messages

  useEffect(() => {
    fetchMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
  }

  const isOpen = open && Boolean(anchorEl)
  const id = isOpen ? 'notifications-popper' : undefined

  const onKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleChangeTab = (_, newTab) => {
    setActiveTab(newTab)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box>
        <IconButton
          onKeyDown={onKeyDown}
          aria-describedby={id}
          variant="outlined"
          onClick={handleClick}
          icon={<NotificationIcon showBadge={unreadMessageCount > 0} />}
        />
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement={isDesktop ? 'bottom-end' : 'bottom-start'}
          sx={(theme) => ({
            zIndex: theme.zIndex.appBar + 100,
            overflow: 'hidden',
            borderRadius: '1rem',
            boxShadow: theme.shadows[5],
            top: '0.5rem !important',
            [theme.breakpoints.up('tablet')]: {
              maxHeight: '44.5rem',
              margin: '0 !important',
            },
          })}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '28rem',
                  width: '100%',
                  maxHeight: '44rem',
                  height: '100%',
                  overflow: 'hidden',
                  backgroundColor: theme.palette.white.main,
                  [theme.breakpoints.up('tablet')]: {
                    maxHeight: '44.5rem',
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    padding: theme.spacing(2),
                    gap: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.white.dark}`,
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: theme.spacing(6.75),
                    })}
                  >
                    <Typography variant="h5">Notificaciones</Typography>
                    <Button
                      variant="text"
                      size="small"
                      endIcon={<Icon name="circle-check-big" />}
                      onClick={() => markAllAsRead(false)}
                      sx={(theme) => ({
                        [theme.breakpoints.down('tablet')]: {
                          textAlign: 'left',
                        },
                      })}
                    >
                      Marcar todo como leído
                    </Button>
                  </Box>
                  <RoundedTabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    variant="fullWidth"
                    scrollButtons={false}
                    tabsConfig={[
                      {
                        label: 'Todas',
                      },
                      {
                        label: 'No leídas',
                      },
                    ]}
                    sx={{
                      width: '12rem',
                      minHeight: 'auto',
                      [`& .${tabClasses.root}`]: {
                        padding: 0,
                        height: '1.8rem',
                        minHeight: 'auto',
                        minWidth: 'auto',
                      },
                    }}
                  />
                </Box>
                <Messages messages={filteredMessages} />
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '3rem',
                    borderTop: `1px solid ${theme.palette.white.dark}`,
                  })}
                >
                  <Link
                    to="/notifications"
                    sx={(theme) => ({
                      color: theme.palette.black.main,
                      ...theme.typography.lead2,
                    })}
                    onClick={() => setOpen(false)}
                  >
                    Ver todas las notificaciones
                  </Link>
                </Box>
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export default Popover
