import { useEffect } from 'react'

import Icon from 'components/UI/Icon'

import { initHeadway } from 'utils/integrations/scripts/headway'

import IconButton from './IconButton'

const HeadwayButton = () => {
  useEffect(() => {
    initHeadway()
  }, [])

  return <IconButton id="headway" icon={<Icon name="megaphone" />} />
}

export default HeadwayButton
