import { Formik } from 'formik'
import { createContext, useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { Box } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Page from 'components/UI/Page/Page'

import {
  isFreeCompany,
  isPremiumExpiredByCancellationCompany,
} from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { removeSuffix } from 'utils/general'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import { DASHBOARD, SUBSCRIPTION_STATUS } from 'config/routes'

import usePayment from '../Atoms/usePayment'
import usePsePaymentCheck from '../Atoms/usePsePaymentCheck'
import SubscriptionSummary from './Summary'
import { getInitialValues, getValidationSchema } from './helpers'

export const SubscriptionViewContext = createContext()
SubscriptionViewContext.displayName = 'SubscriptionViewContext'

const SubscriptionPayment = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)
  const isPsePayment = queryParams.get('pse_payment') === 'true'
  const { isAPartnerChild, company } = useUser()
  const { subscription } = useSubscription()
  const companyId = getCompanyId()

  const { openPseTransactionResponseModal, pseTransactionStatus } =
    usePsePaymentCheck()

  const { modality: modalitySwitch, selectedPlan: planSelected } =
    location?.state || {}

  const isAFreeCompany = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )

  const subscriptionExpiredByCancellation =
    isPremiumExpiredByCancellationCompany(subscription)

  const { subscriptionQuery: { data: { plans: plansQueryData } = {} } = {} } =
    useSubscriptionService({
      serviceParams: {
        queryKey: ['getPlans', companyId],
      },
      queryOptions: {
        enabled: subscriptionExpiredByCancellation,
      },
    })

  const currentPlan = plansQueryData?.find(
    (plan) =>
      removeSuffix(plan.coded_name, -8) ===
      removeSuffix(subscription.plan.coded_name, -8)
  )

  const [modality, setModality] = useState(
    modalitySwitch || subscription?.type || 'month'
  )
  const [selectedPlan, setSelectedPlan] = useState(
    planSelected || currentPlan || {}
  )
  const locationState = Boolean(location.state)

  const onSelectPlan = (newPlan) => setSelectedPlan(newPlan)

  const [activePayment, setActivePayment] = useState('credit_card')

  const [expandedAccordion, setExpandedAccordion] = useState(activePayment)
  const [selectedPaymentMethod, setselectedPaymentMethod] = useState('')

  const isPremiumExpiredSubscription =
    subscription?.status === 'premium_expired' ||
    subscriptionExpiredByCancellation

  const { onSubmit, isLoading } = usePayment({
    plan: selectedPlan,
    subscriptionTypeValidators: { isPremiumExpiredSubscription },
    isFromSubscriptionPaymentView: true,
  })

  const handleSubmit = (values) =>
    onSubmit({
      values,
      selectedPaymentMethod,
    })

  const subscriptionViewContextValue = useMemo(() => {
    return {
      company,
      modality,
      selectedPlan,
      activePayment,
      expandedAccordion,
      subscription: subscription,
      loadingPayment: isLoading,
      selectedPaymentMethod,
      onSelectPlan,
      setSelectedPlan,
      setModality,
      setActivePayment,
      setExpandedAccordion,
      setselectedPaymentMethod,
    }
  }, [
    company,
    modality,
    selectedPlan,
    activePayment,
    expandedAccordion,
    subscription,
    isLoading,
    selectedPaymentMethod,
  ])

  useEffect(() => {
    setSelectedPlan(planSelected || currentPlan)
  }, [planSelected, currentPlan])

  useEffect(() => {
    if (isPsePayment && pseTransactionStatus === 'DECLINED') {
      openPseTransactionResponseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPsePayment, pseTransactionStatus])

  const baseNavigationRoute =
    isAPartnerChild || isAFreeCompany || pseTransactionStatus === 'PENDING'
      ? DASHBOARD
      : SUBSCRIPTION_STATUS()

  const finalNavegationRoute = isPsePayment
    ? `${baseNavigationRoute}?pse_payment=true`
    : baseNavigationRoute

  return (locationState || subscriptionExpiredByCancellation) &&
    !['APPROVED', 'PENDING'].includes(pseTransactionStatus) ? (
    <SubscriptionViewContext.Provider value={subscriptionViewContextValue}>
      <Page
        documentTitle="Método de pago"
        rootSx={{
          ':after': { content: 'none' },
        }}
        contentSx={{ margin: '0 !important', height: '100%' }}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={getInitialValues({
            subscription,
            selectedPlanId: selectedPlan?.id,
            company,
          })}
          validationSchema={getValidationSchema()}
        >
          <Box
            sx={{
              height: '100%',
            }}
          >
            <SubscriptionSummary />
          </Box>
        </Formik>
      </Page>
    </SubscriptionViewContext.Provider>
  ) : (
    <Navigate to={finalNavegationRoute} />
  )
}

export default SubscriptionPayment
