import { Box, Typography } from '@mui/material'

const NoveltyCard = ({ noveltyCard }) => {
  const { id, icon, title, amount, alt } = noveltyCard

  const getCardWord = () => {
    if (id === 'workers') return amount === 1 ? 'persona' : 'personas'
    return amount === 1 ? 'novedad' : 'novedades'
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '2fr 7fr',
        height: '6rem',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        boxShadow: theme.shadows[3],
        [theme.breakpoints.down('tablet')]: {
          gridTemplateColumns: '1fr 5fr',
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          backgroundColor: theme.palette.accent4.light,
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <Box
          component="img"
          loading="lazy"
          src={icon}
          alt={alt}
          width={64}
          height={64}
        />
      </Box>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(1),
        })}
      >
        <Typography variant="lead1">{title}</Typography>
        <Typography variant="h2">
          {amount} {getCardWord()}
        </Typography>
      </Box>
    </Box>
  )
}

export default NoveltyCard
