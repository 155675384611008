import { IconButton, Tooltip } from '@mui/material'

import Icon from '../Icon'

const DeleteItemButton = ({
  deleteable = true,
  onClick,
  size,
  className,
  style,
  ...rest
}) => {
  const handleOnClick = () => {
    if (deleteable && onClick) {
      onClick()
    }
  }

  return (
    <Tooltip
      title={deleteable ? 'Eliminar' : 'No se puede eliminar'}
      className={className}
      disableInteractive
      arrow
    >
      {/* this <span> removes MUI warning */}
      <span>
        <IconButton
          aria-label="delete"
          onClick={handleOnClick}
          size={size}
          disabled={!deleteable}
          style={style}
          {...rest}
        >
          <Icon name="trash-2" />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default DeleteItemButton
