import { useDateRangePicker } from '@react-aria/datepicker'
import { useDateRangePickerState } from '@react-stately/datepicker'
import { createContext, useContext, useRef, useState } from 'react'

import {
  Box,
  Dialog,
  IconButton,
  Popover,
  dialogClasses,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import DateFieldV2 from './DateFieldV2'
import RangeCalendar from './RangeCalendarV2'

export const DateRangePickerContext = createContext({
  isDesktop: true,
  tooltipTitle: undefined,
  tooltipDescription: undefined,
  locale: 'es-ES',
  maxValue: {},
  minValue: {},
})
DateRangePickerContext.displayName = 'DateRangePickerContext'

export const useDateRangePickerContext = () => {
  const context = useContext(DateRangePickerContext)

  if (!context)
    throw Error(
      'useDateRangePickerContext needs to be used inside DateRangePickerContext'
    )

  return context
}

const DateRangePicker = ({
  disabled,
  onChange,
  value,
  shouldCloseOnSelect = true,
  tooltipTitle,
  tooltipDescription,
  maxValue,
  minValue,
  sx,
  ...props
}) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const state = useDateRangePickerState({
    shouldCloseOnSelect,
    isDisabled: disabled || false,
    onChange,
    value,
    maxValue,
    minValue,
    ...props,
  })
  const ref = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    groupProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(
    {
      ...props,
      'aria-label': 'Date range input',
      isDisabled: disabled || false,
      maxValue,
      minValue,
    },
    state,
    ref
  )
  const { onPress, ...restButtonProps } = buttonProps

  const handleOpenPopup = () => {
    if (disabled) return

    setAnchorEl(ref.current)
    onPress()
  }

  const contextValue = {
    isDesktop,
    tooltipTitle,
    tooltipDescription,
    locale: 'es-ES',
    maxValue,
    minValue,
  }

  return (
    <DateRangePickerContext.Provider value={contextValue}>
      <Box
        ref={ref}
        sx={[
          (theme) => ({
            display: 'flex',
            gap: theme.spacing(1),
            borderRadius: '0.5rem',
            height: '2.5rem',
            width: '100%',
            lineHeight: '1.4375em',
            padding: theme.spacing(0, 1),
            position: 'relative',
            alignItems: 'center',
            [theme.breakpoints.up('tablet')]: {
              padding: theme.spacing(0, 2),
              gap: theme.spacing(2),
            },
            '&:hover': {
              '& > fieldset': {
                border: `2px solid ${theme.palette.primary.main}`,
                ...(state.validationState === 'invalid' && {
                  border: `2px solid ${theme.palette.error.main}`,
                }),
              },
            },
            ...(disabled && {
              backgroundColor: theme.palette.black.light,
              '& > fieldset, :hover > fieldset': {
                border: `1px solid ${theme.palette.black.dark}`,
              },
              '& > div > span, div > div > span': {
                color: theme.palette.black.main,
                pointerEvents: 'none',
              },
            }),
          }),
          sx,
        ]}
      >
        <Box
          {...groupProps}
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: {
              mobile: theme.spacing(1),
            },
            width: '100%',
          })}
        >
          <DateFieldV2 {...startFieldProps} />
          <Box
            component="span"
            aria-hidden="true"
            sx={(theme) => ({
              color: theme.palette.black.light,
            })}
          >
            –
          </Box>
          <DateFieldV2 {...endFieldProps} />
        </Box>
        <Box
          aria-hidden
          component="fieldset"
          sx={(theme) => ({
            position: 'absolute',
            bottom: 0,
            right: 0,
            top: 0,
            left: 0,
            margin: 0,
            pointerEvents: 'none',
            borderRadius: 'inherit',
            overflow: 'hidden',
            minWidth: '0%',
            border: `1px solid ${theme.palette.black.light}`,
            padding: theme.spacing(0, 1),
            ...(state.validationState === 'invalid' && {
              border: `1px solid ${theme.palette.error.main}`,
            }),
          })}
        />
        <IconButton
          {...restButtonProps}
          onClick={handleOpenPopup}
          disabled={disabled}
          sx={(theme) => ({
            borderRadius: 0,
            backgroundColor: 'transparent',
            color: theme.palette.black.light,
            padding: 0,
            width: 'auto',
            height: 'auto',
            '&::before': {
              content: 'none',
            },
            '&:hover, :focus': {
              backgroundColor: 'transparent',
              color: theme.palette.black.main,
            },
            ...(disabled && {
              color: theme.palette.black.main,
              '&.Mui-disabled': {
                color: theme.palette.black.main,
              },
            }),
          })}
        >
          <Icon name="calendar-days" />
        </IconButton>
      </Box>
      {isDesktop ? (
        <Popover
          {...dialogProps}
          open={state.isOpen}
          onClose={() => state.setOpen(false)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <RangeCalendar
            {...calendarProps}
            onCloseCalendar={() => state.setOpen(false)}
            isDesktop={isDesktop}
          />
        </Popover>
      ) : (
        <Dialog
          open={state.isOpen}
          onClose={() => state.setOpen(false)}
          sx={{
            [`& .${dialogClasses.paper}`]: {
              margin: 'auto',
            },
          }}
        >
          <RangeCalendar
            {...calendarProps}
            onCloseCalendar={() => state.setOpen(false)}
            isDesktop={isDesktop}
          />
        </Dialog>
      )}
    </DateRangePickerContext.Provider>
  )
}

export default DateRangePicker
