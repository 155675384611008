import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useParams } from 'react-router-dom'

import { Button, Menu, MenuItem } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Icon from 'components/UI/Icon'

import useElectronicPayrollPdfService from 'utils/hooks/ElectronicPayroll/pdfService'

const PdfDownloadMenu = () => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'pdfDownloadMenu',
  })

  const { enqueueDownload } = useDownloadManager()
  const { openPremiumFeatureModal, showPremiumFeatures } = usePremiumFeature()
  const { downloadPdfMutation } = useElectronicPayrollPdfService()
  const { periodId } = useParams()

  const handleDownloadFile = (fileType) => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }

    popupState.close()

    enqueueDownload({
      name: 'comprobantes de nómina electrónica',
      fileCode: 'electronic_payroll_payslips',
      firebasePath: `electronic_payroll/periods/${periodId}/payslips`,
      namePlural: true,
      service: () =>
        downloadPdfMutation.mutateAsync({
          mutationMethod: 'GET',
          mutationKey: 'getAllElectronicPayrollPdfs',
          periodId,
          fileType,
          async: true,
        }),
    })
  }

  return (
    <>
      <Button
        variant="outlined"
        endIcon={
          showPremiumFeatures ? (
            <Icon name="chevron-down" />
          ) : (
            <PremiumFeatureIcon />
          )
        }
        onClick={showPremiumFeatures ? null : openPremiumFeatureModal}
        {...(showPremiumFeatures ? bindTrigger(popupState) : null)}
      >
        Descargar archivos
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleDownloadFile('pdf')}>
          Representación gráfica Agrupada
        </MenuItem>
        <MenuItem onClick={() => handleDownloadFile('zip')}>
          Representación gráfica Individual
        </MenuItem>
      </Menu>
    </>
  )
}

export default PdfDownloadMenu
