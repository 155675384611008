import { Box } from '@mui/material'

import avatar1 from 'assets/images/avatars/avatar-1.png'
import avatar2 from 'assets/images/avatars/avatar-2.png'
import avatar3 from 'assets/images/avatars/avatar-3.png'
import avatar4 from 'assets/images/avatars/avatar-4.png'
import avatar5 from 'assets/images/avatars/avatar-5.png'
import avatar6 from 'assets/images/avatars/avatar-6.png'
import avatar7 from 'assets/images/avatars/avatar-7.png'
import avatar8 from 'assets/images/avatars/avatar-8.png'

const avatars = {
  1: avatar1,
  2: avatar2,
  3: avatar3,
  4: avatar4,
  5: avatar5,
  6: avatar6,
  7: avatar7,
  8: avatar8,
}

const Avatar = ({ avatarId = 2, src, sx }) => {
  if (src && typeof src === 'string') {
    return (
      <Box
        component="img"
        sx={[
          {
            width: '2rem',
            height: '2rem',
            minWidth: '2rem',
            minHeight: '2rem',
            borderRadius: '50%',
            transform: 'translateZ(0)',
            backfaceVisibility: 'hidden',
            msTransform: 'translateZ(0)',
            WebkitTransform: 'translateZ(0)',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          },
          sx,
        ]}
        src={src}
      />
    )
  }

  return (
    <Box
      component="img"
      loading="lazy"
      src={avatars[avatarId]}
      alt="Avatar por defecto"
      sx={[
        {
          width: '2rem',
          height: '2rem',
          borderRadius: '50%',
          maxWidth: 'unset',
        },
        sx,
      ]}
    />
  )
}

export default Avatar
