import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  cancelElectronicPayroll,
  createElectronicPreviousPayroll,
  getElectronicPayrollItems,
  getElectronicPayrollPeriodById,
  periodPayrollSummary,
  sendElectronicPayroll,
  updateBonusIncomes,
  updateElectronicPayrollProvisions,
} from 'services/payroll/electronicPayrollPeriodService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const { periodId, queryKey, electronicPayrollStatuses, electronicPayrollId } =
    serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'electronicPayrollPeriod')
    return getElectronicPayrollPeriodById(periodId, electronicPayrollStatuses)

  if (currentQueryKey === 'electronicPayrollItems')
    return getElectronicPayrollItems(periodId)

  if (currentQueryKey === 'getElectronicPayrollSummary')
    return periodPayrollSummary({ electronicPayrollId })

  return null
}

const getMutationFunction = (mutationData) => {
  const {
    mutationMethod,
    periodId,
    electronicPayrolls,
    electronicPayrollItems,
    electronicPayrollId,
    mutationKey,
    provisionAdjustment,
  } = mutationData

  if (mutationMethod === 'PUT' && electronicPayrollItems)
    return updateBonusIncomes(periodId, electronicPayrollItems)

  if (mutationMethod === 'PUT')
    return sendElectronicPayroll(periodId, electronicPayrolls)

  if (mutationMethod === 'POST') return createElectronicPreviousPayroll()

  if (
    mutationMethod === 'PATCH' &&
    mutationKey === 'updateElectronicPayrollProvisions'
  ) {
    return updateElectronicPayrollProvisions(
      electronicPayrollId,
      provisionAdjustment
    )
  }

  if (mutationMethod === 'PATCH')
    return cancelElectronicPayroll(electronicPayrollId)

  return null
}

const useElectronicPayrollPeriodService = ({
  queryOptions = {},
  serviceParams = { queryKey: 'electronicPayrollPeriod' },
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restElectronicPayroll } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const electronicPayrollMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    electronicPayrollPeriodQuery: { data, ...restElectronicPayroll },
    electronicPayrollMutation,
  }
}

export default useElectronicPayrollPeriodService
