import { Link } from 'react-router-dom'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material'

import Icon from 'components/UI/Icon'

const CardEdit = ({ card, sx }) => {
  const { title, description, btnText, externalLink, to, dataCy, state } = card

  return (
    <Card
      sx={[
        {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
        },
        sx,
      ]}
    >
      <CardHeader title={<Typography variant="h5">{title}</Typography>} />
      <CardContent
        sx={(theme) => ({
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          flexGrow: 1,
        })}
      >
        <Typography>{description}</Typography>
      </CardContent>
      <CardActions
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-end',
          padding: theme.spacing(1, 2),
        })}
      >
        <Button
          color="primary"
          variant="text"
          data-cy={dataCy}
          component={externalLink ? 'a' : Link}
          to={externalLink ? undefined : to}
          state={externalLink ? undefined : state}
          href={externalLink ? to : undefined}
          target={externalLink ? '_blank' : undefined}
          sx={{
            minWidth: 'max-content',
          }}
          endIcon={<Icon name="move-right" />}
        >
          {btnText}
        </Button>
      </CardActions>
    </Card>
  )
}

export default CardEdit
