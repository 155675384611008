import { parseDate } from '@internationalized/date'
import { useDatePicker } from '@react-aria/datepicker'
import { useDatePickerState } from '@react-stately/datepicker'
import { captureMessage } from '@sentry/react'
import { isAfter, isValid, parseISO } from 'date-fns'
import { createContext, useContext, useRef, useState } from 'react'

import {
  Dialog,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import Calendar from './Calendar'
import DateField from './DateField'

export const DatePickerContext = createContext({
  isDesktop: true,
})
DatePickerContext.displayName = 'DatePickerContext'

export const useDatePickerContext = () => {
  const context = useContext(DatePickerContext)

  if (!context)
    throw Error(
      'useDatePickerContext needs to be used inside DatePickerContext'
    )

  return context
}

const DatePickerField = ({ id, name, value, ...props }) => {
  const { setValue, setTouched, disabled, touched, showError, onChange } = props
  let { minDate = null, maxDate = null } = props
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const currentValue = value ? parseDate(value) : null

  if (minDate && maxDate && isAfter(parseISO(minDate), parseISO(maxDate))) {
    captureMessage(
      `[UI/DatePicker]Error: minDate: ${minDate} cannot be greater than maxDate: ${maxDate}`
    )
    minDate = null
    maxDate = null
  }

  const currentMinValue = minDate ? parseDate(minDate) : null
  const currentMaxValue = maxDate ? parseDate(maxDate) : null

  const handleOnChange = (selectedValue) => {
    const nextValue = selectedValue?.toString?.()

    if (!onChange) {
      if (isValid(parseISO(nextValue))) {
        setValue(nextValue)

        if (!touched) {
          setTouched(true)
        }
      }
    } else {
      onChange(nextValue)
    }
  }

  const state = useDatePickerState({
    isDisabled: disabled || false,
    onChange: handleOnChange,
    maxValue: currentMaxValue,
    minValue: currentMinValue,
    value: currentValue,
  })
  const ref = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const { fieldProps, buttonProps, dialogProps, calendarProps } = useDatePicker(
    {
      'aria-label': 'Date field',
      isDisabled: disabled || false,
      maxValue: currentMaxValue,
      minValue: currentMinValue,
      onChange: handleOnChange,
      value: currentValue,
    },
    state,
    ref
  )
  const { onPress, ...restButtonProps } = buttonProps
  const isInvalid = showError || state.validationState === 'invalid'

  const handleOpenPopup = () => {
    if (disabled) return

    setAnchorEl(ref.current)
    onPress()
  }

  const contextValue = {
    isDesktop,
    fieldProps,
  }

  return (
    <DatePickerContext.Provider value={contextValue}>
      <div ref={ref}>
        <TextField
          disabled={disabled}
          name={name}
          id={id}
          error={isInvalid}
          InputProps={{
            inputComponent: DateField,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  {...restButtonProps}
                  id={`${name}_calendar_button`}
                  data-cy={`${name}_calendar_button`}
                  onClick={handleOpenPopup}
                  disabled={disabled}
                  sx={(theme) => ({
                    borderRadius: 0,
                    backgroundColor: 'transparent',
                    color: theme.palette.black.light,
                    padding: 0,
                    width: 'auto',
                    height: 'auto',
                    '&::before': {
                      content: 'none',
                    },
                    '&:hover, :focus': {
                      backgroundColor: 'transparent',
                      color: theme.palette.black.main,
                    },
                    ...(disabled && {
                      color: theme.palette.black.main,
                      '&.Mui-disabled': {
                        color: theme.palette.black.main,
                      },
                    }),
                  })}
                >
                  <Icon name="calendar-days" />
                </IconButton>
              </InputAdornment>
            ),
            sx: (theme) => ({
              '& > div[role="presentation"] > span': {
                color: `${theme.palette.black.light} !important`,
                '&:focus': {
                  backgroundColor: theme.palette.primary.main,
                  color: `${theme.palette.black.dark} !important`,
                },
                ...(currentValue && {
                  color: `${theme.palette.black.dark} !important`,
                }),
              },
            }),
          }}
        />
      </div>
      {isDesktop ? (
        <Popover
          {...dialogProps}
          open={state.isOpen}
          onClose={() => state.setOpen(false)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Calendar
            {...calendarProps}
            onCloseCalendar={() => state.setOpen(false)}
            isDesktop={isDesktop}
          />
        </Popover>
      ) : (
        <Dialog
          open={state.isOpen}
          onClose={() => state.setOpen(false)}
          PaperProps={{
            elevation: 0,
            sx: {
              margin: 'auto',
            },
          }}
        >
          <Calendar
            {...calendarProps}
            onCloseCalendar={() => state.setOpen(false)}
            isDesktop={isDesktop}
          />
        </Dialog>
      )}
    </DatePickerContext.Provider>
  )
}

export default DatePickerField
