import { Box, Paper, Typography, useMediaQuery } from '@mui/material'

import Adornment from 'components/UI/Adornment'
import Icon from 'components/UI/Icon'
import useWorker from 'components/Worker/useWorker'

import { formatNumberWithDecimal } from 'utils/format'

const HolidaysContent = () => {
  const { worker } = useWorker({ useCache: true })
  const disabled = worker.terminated || worker.contract_category !== 'employee'
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'))
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const accumulatedHolidays = worker.accumulated_holidays
    ? formatNumberWithDecimal(worker.accumulated_holidays, 3)
    : 0

  return (
    <Paper
      sx={(theme) => ({
        display: 'flex',
        minHeight: '9.5rem',
        padding: theme.spacing(2, 3),
        flexDirection: 'column',
        backgroundColor: theme.palette.complementary1.light,
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
        [theme.breakpoints.up('tablet')]: {
          padding: theme.spacing(2, 4, 2, 4),
        },
        [theme.breakpoints.between('tablet', 'laptop')]: {
          padding: theme.spacing(2),
        },
        [theme.breakpoints.between('laptop', 'lg')]: {
          padding: theme.spacing(2),
        },
      })}
    >
      <Adornment
        sx={{
          top: -35,
          right: -20,
          transform: 'rotate(10deg)',
        }}
        width="100"
        height="100"
        variant="explosion"
        color="complementary1.dark"
      />
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.5),
          marginBottom: theme.spacing(1),
        })}
      >
        <Icon name="footprints" fontSize="large" />
        <Typography
          variant="body1"
          sx={{
            zIndex: 2,
          }}
        >
          Vacaciones acumuladas
        </Typography>
      </Box>
      <Typography
        variant={isDesktop || isMobile ? 'h1' : 'h2'}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {disabled ? '--' : `${accumulatedHolidays} días`}
      </Typography>
    </Paper>
  )
}

export default HolidaysContent
