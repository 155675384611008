import { Box, Button, Paper, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import Modal from 'components/UI/Modal/Modal'

import { formatCurrency } from 'utils/format'
import { isObjectEmpty } from 'utils/general'

import dollar from 'assets/images/three-dimensional-icons/dollar.png'
import moneyBag from 'assets/images/three-dimensional-icons/money-bag.png'

function Trigger({ isEditMode, onButtonClick, ...props }) {
  return (
    <Button
      size="small"
      endIcon={
        <Icon
          name={isEditMode ? 'arrow-right' : 'edit-pencil'}
          basic
          sx={{ fontSize: '1.5rem !important' }}
        />
      }
      variant="outlined"
      onClick={onButtonClick}
      {...props}
    >
      {isEditMode ? 'Editar' : 'Ingresar'}
    </Button>
  )
}

function ItemCard({
  currencyValue,
  title,
  srcIcon,
  onButtonClick,
  hidden = false,
}) {
  if (hidden) {
    return null
  }

  const isEditMode = currencyValue > 0

  return (
    <Paper
      sx={(theme) => ({
        height: '11rem',
        minWidth: '12.5rem',
        borderRadius: theme.spacing(3),
        justifySelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1.5, 1.5, 0, 1.5),
        gap: theme.spacing(2),
      })}
    >
      <Box
        sx={(theme) => ({
          height: '6rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: theme.spacing(1.5),
          padding: theme.spacing(1),
          backgroundColor: isEditMode
            ? theme.palette.primary.light
            : theme.palette.complementary1.light,
        })}
      >
        <Typography variant="lead2">{title}</Typography>

        {isEditMode ? (
          <Typography
            sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
            variant="h4"
          >
            {formatCurrency(currencyValue)}
          </Typography>
        ) : (
          <Box
            component="img"
            loading="lazy"
            src={srcIcon}
            alt=""
            width={64}
            height={64}
          />
        )}
      </Box>
      <Trigger isEditMode={isEditMode} onButtonClick={onButtonClick} />
    </Paper>
  )
}

export function NoveltyTypeModal({
  state,
  handleClose,
  openOtherIncomesModal,
  openProvisionsAdjustmentModal,
}) {
  const { bonusIncomes, open, electronicPayrollId, provisions } = state

  const provisionsTotalValue = Object.values(provisions).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      header="Novedades en nómina electrónica"
      footer={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button
            onClick={handleClose}
            startIcon={
              <Icon name="arrow-right" sx={{ transform: 'rotate(180deg)' }} />
            }
            size="large"
          >
            Regresar
          </Button>
        </Box>
      }
      dialogProps={{
        fullWidth: true,
        maxWidth: 'tablet',
      }}
    >
      <Typography color="black.dark">
        Agrega aquí las novedades adicionales sobre el pago de nómina de este
        periodo.
      </Typography>

      <Box
        sx={(theme) => ({
          paddingTop: theme.spacing(5),
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 12.5rem)',
          gap: theme.spacing(3),
        })}
      >
        <ItemCard
          title="Otros ingresos"
          srcIcon={moneyBag}
          currencyValue={bonusIncomes}
          onButtonClick={() => {
            openOtherIncomesModal({ electronicPayrollId })
            handleClose()
          }}
        />
        <ItemCard
          title="Ajuste provisiones"
          srcIcon={dollar}
          currencyValue={provisionsTotalValue}
          hidden={isObjectEmpty(provisions)}
          onButtonClick={() => {
            openProvisionsAdjustmentModal({ electronicPayrollId, provisions })
            handleClose()
          }}
        />
      </Box>
    </Modal>
  )
}
