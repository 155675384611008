import { Chip, Typography, alpha } from '@mui/material'

import useEndFreeModals from 'components/App/Premium/useEndFreeModals'
import {
  electronicPayrollStatusOptions,
  useCancelable,
  useDownloadFilesAction,
  useDownloadPdf,
} from 'components/Period/common/helpers'
import FilterButton from 'components/UI/Button/FilterButton'
import LinkButton from 'components/UI/Button/LinkButton'
import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import Tooltip from 'components/UI/Tooltip'

import { formatDateRange } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useNotifications from 'utils/hooks/useNotifications'

const ElectronicPayrollStatus = ({
  rowData,
  openSingleEmittedRejectedModal,
}) => {
  const handleClickRejected = () => {
    openSingleEmittedRejectedModal(rowData.error_messages)
  }

  const statusOptions = {
    issued: {
      title: 'Emitida',
      color: 'primary',
      tooltip: null,
    },
    rejected: {
      title: 'Rechazada',
      color: 'error',
      tooltip: {
        title: 'NE Rechazada',
        description: (
          <LinkButton onClick={handleClickRejected}>
            Mira aquí el detalle
          </LinkButton>
        ),
      },
    },
    processing: {
      title: 'En proceso',
      color: 'complementary2',
      tooltip: null,
    },
    pending: {
      title: 'Por emitir',
      color: 'accent4',
      tooltip: null,
    },
    modified: {
      title: 'Modificada',
      color: 'accent4',
      tooltip: null,
    },
    canceled: {
      title: 'Anulada',
      color: 'error',
      tooltip: null,
    },
    replaced: {
      title: 'Reemplazada',
      color: 'accent3',
      tooltip: null,
    },
  }

  return (
    <Tooltip
      title={statusOptions[rowData.status].tooltip?.title}
      description={statusOptions[rowData.status].tooltip?.description}
    >
      <Chip
        label={
          <Typography
            variant="body2"
            fontWeight={600}
            sx={(theme) => ({
              color: theme.palette[statusOptions[rowData.status].color].dark,
            })}
          >
            {statusOptions[rowData.status].title}
          </Typography>
        }
        sx={(theme) => ({
          backgroundColor: alpha(
            theme.palette[statusOptions[rowData.status].color].main,
            0.2
          ),
          width: '100%',
        })}
      />
    </Tooltip>
  )
}

export const getColumns = (openSingleEmittedRejectedModal) => {
  return [
    {
      Header: 'Fecha',
      accessor: 'end_day',
      Cell: ({ row }) =>
        formatDateRange(row.original.initial_day, row.original.end_day),
    },
    {
      Header: 'Ingresos',
      accessor: 'incomes',
      Cell: ({ row }) => formatCurrency(row.original.incomes),
    },
    {
      Header: 'Deducciones',
      accessor: 'deductions',
      Cell: ({ row }) => formatCurrency(row.original.deductions),
    },
    {
      Header: 'Total emitido',
      accessor: 'payment',
      Cell: ({ row }) => formatCurrency(row.original.payment),
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row }) => (
        <ElectronicPayrollStatus
          rowData={row.original}
          openSingleEmittedRejectedModal={openSingleEmittedRejectedModal}
        />
      ),
    },
  ]
}

const useToIssuedAction = ({ maintenance }) => {
  const { isNotValidCompany, openEndFreeElectronicPayrollModal } =
    useEndFreeModals()

  return {
    disabled: ({ options }) => !options.to_issue || maintenance,
    handleClick: () =>
      isNotValidCompany ? () => openEndFreeElectronicPayrollModal() : null,
  }
}

export const useActionsTable = ({
  periodId,
  maintenance,
  activeStatus,
  setActiveStatus,
  isLoading,
}) => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const { showSuccessMessage, showErrorMessage } = useNotifications()

  const downloadFilesAction = useDownloadFilesAction()
  const toIssued = useToIssuedAction({
    maintenance,
  })
  const downloadPdfAction = useDownloadPdf({
    periodId,
    showLoadingModal,
    hideLoadingModal,
  })
  const cancelableAction = useCancelable({
    showLoadingModal,
    hideLoadingModal,
    showSuccessMessage,
    showErrorMessage,
    invalidateQueries: 'getElectronicPayrollHistoryByWorker',
  })

  return [
    (rowData) => ({
      id: 'to_issued',
      tooltip: 'Emitir',
      icon: <Icon name="send" />,
      disabled: isLoading || !rowData.options.to_issue || maintenance,
      onClick: toIssued.handleClick(),
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'download_files',
      tooltip: 'Descargar XML',
      icon: <Icon name="download" />,
      disabled: !rowData.options.download_files,
      onClick: () => downloadFilesAction.handleClick(rowData),
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'download_pdf',
      tooltip: 'Descargar PDF',
      icon: <Icon name="file-pen-line" />,
      onClick: () => downloadPdfAction.handleClick(rowData),
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'cancelable',
      tooltip: rowData.status === 'issued' ? 'Anular' : 'Eliminar',
      icon: <Icon name="trash-2" />,
      disabled: !rowData.options.cancelable || maintenance,
      onClick: () => cancelableAction.handleClick(rowData),
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    {
      id: 'toolbarFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          options={electronicPayrollStatusOptions}
          defaultLabel="Todas"
          value={activeStatus}
          onChange={setActiveStatus}
        />
      ),
    },
  ]
}
