import { Box, Button } from '@mui/material'

import { useNoveltyType } from 'components/Period/Payroll/helpers'
import Icon from 'components/UI/Icon'
import Modal from 'components/UI/Modal/Modal'

import Content from './Content'

const NoveltyTypeModal = ({ state, handleClose }) => {
  const { payrollState } = useNoveltyType()

  return (
    <Modal
      open={state.open}
      onClose={handleClose}
      onCancel={handleClose}
      header={`¿Qué tipo de novedad deseas reportar a ${payrollState.workerName}?`}
      footer={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Button
            onClick={handleClose}
            startIcon={<Icon name="move-left" />}
            size="large"
          >
            Regresar
          </Button>
        </Box>
      }
      dialogProps={{
        fullWidth: true,
        maxWidth: 'lg',
      }}
    >
      <Content />
    </Modal>
  )
}

export default NoveltyTypeModal
