import { addMonths, format } from 'date-fns'
import esLocale from 'date-fns/locale/es/index.js'
import { createContext, useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'

import { getCompanyId } from 'utils/company'
import usePeriodService from 'utils/hooks/payroll/periodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import * as routes from 'config/routes'

export const PeriodContext = createContext({})
PeriodContext.displayName = 'PeriodContext'

export const usePeriod = () => {
  const context = useContext(PeriodContext)

  if (context === undefined) {
    throw new Error('usePeriod must be used within a PeriodContext provider')
  }

  return context
}

export const NoveltyTypeContext = createContext({})
PeriodContext.displayName = 'NoveltyTypeContext'

export const useNoveltyType = () => {
  const context = useContext(NoveltyTypeContext)

  if (context === undefined) {
    throw new Error(
      'useNoveltyType must be used within a NoveltyTypeContext provider'
    )
  }

  return context
}

export const PeriodAPIContext = createContext({})
PeriodContext.displayName = 'PeriodAPIContext'

export const usePeriodAPI = () => {
  const context = useContext(PeriodAPIContext)

  if (context === undefined) {
    throw new Error(
      'usePeriodAPI must be used within a PeriodAPIContext provider'
    )
  }

  return context
}

export const getPaidSocialBenefits = (socialBenefits) => {
  return socialBenefits?.filter((sb) => sb.action === 'update')
}

export const concatTitlePaidSocialBenefits = (titles) => {
  return (
    titles?.length > 0 &&
    titles?.reduce(
      (res, currentTitle, idx) =>
        res + (idx < titles.length - 1 ? ', ' : ' y ') + currentTitle
    )
  )
}

export const getSocialBenefitsCategoriesAlert = (socialBenefits) => {
  return socialBenefits
    .filter(
      (sb) =>
        sb.action === 'update' || (sb.action === 'create' && !sb.show_reminder)
    )
    .map((sb) => sb.category)
}

export const isSbAlertActionsShown = (
  socialBenefits,
  initialDay = new Date()
) => {
  const date = new Date(initialDay)
  const month = date.getMonth()
  const sbPaidCategories = getSocialBenefitsCategoriesAlert(socialBenefits)

  if (!sbPaidCategories.includes('severance_interests') && month === 0)
    return true
  if (!sbPaidCategories.includes('severance') && month === 1) return true
  if (
    !sbPaidCategories.includes('service_bonus') &&
    (month === 5 || month === 11)
  )
    return true
  return false
}

export const getSocialBenefitLink = (
  socialBenefits,
  periodId,
  initialDay = new Date(),
  isPreviousPeriod = false
) => {
  const month = new Date(initialDay).getMonth()
  let socialBenefitId = null

  if (month === 0)
    socialBenefitId = socialBenefits.filter(
      (sb) => sb.category === 'severance_interests'
    )[0]?.social_benefits_period_id
  else if (month === 1)
    socialBenefitId = socialBenefits.filter(
      (sb) => sb.category === 'severance'
    )[0]?.social_benefits_period_id
  else if (month === 5)
    socialBenefitId = socialBenefits.filter(
      (sb) => sb.category === 'service_bonus'
    )[0]?.social_benefits_period_id
  else if (month === 11)
    socialBenefitId = socialBenefits.filter(
      (sb) => sb.category === 'service_bonus'
    )[0]?.social_benefits_period_id

  return isPreviousPeriod
    ? routes.PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS(
        periodId,
        socialBenefitId
      )
    : routes.PERIOD_SOCIAL_BENEFIT_PERIODS(periodId, socialBenefitId)
}

export const getPeriodTotalsConfiguration = (periodInitialDay) => {
  const nextMonthToPeriodInitialDay = format(
    addMonths(periodInitialDay, 1),
    'MMMM yyyy',
    {
      locale: esLocale,
    }
  )

  return {
    workers_payment: {
      title: 'Pago total nómina',
      backgroundColor: 'primary',
      icon: <Icon name="hand-coins" sx={{ fontSize: '1.5rem' }} />,
      tooltipTitle: 'Pago total nómina',
      tooltipDescription: 'Es la suma del pago neto de todas las personas.',
    },
    company_cost: {
      title: 'Costo total empresa',
      backgroundColor: 'accent2',
      icon: <Icon name="file-pen-line" sx={{ fontSize: '1.5rem' }} />,
      tooltipTitle: 'Costo total empresa',
      tooltipDescription:
        'Es la suma de: Total Ingresos + Total Pagos SS Empresa + Total Pago Parafiscales	+ Total Provisiones.',
    },
    social_security_payment: {
      title: 'Seguridad social',
      backgroundColor: 'complementary2.light',
      icon: <Icon name="shield-plus" sx={{ fontSize: '1.5rem' }} />,
      tooltipTitle: 'Seguridad social',
      tooltipDescription: (
        <Box>
          <Typography sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
            <Typography component="span" variant="lead1">
              Para pagar en el mes:{' '}
            </Typography>
            {nextMonthToPeriodInitialDay}
          </Typography>
          Es la suma de: Pensión persona + Salud persona + F. Solidaridad
          Pensional + Pensión Empresa + Salud Empresa + Riesgos + Sena + ICBF +
          Caja Compensación.
        </Box>
      ),
    },
    social_benefits_provision: {
      title: 'Provisiones',
      backgroundColor: 'complementary1.light',
      icon: <Icon name="piggy-bank" sx={{ fontSize: '1.5rem' }} />,
      tooltipTitle: 'Provisiones',
      tooltipDescription:
        'Es la suma de: Cesantías + Intereses a las Cesantías	+ Prima + Vacaciones.',
    },
  }
}

export const useReturnPayPayroll = (callback) => {
  const { period, payPreviousPayroll } = useContext(PeriodContext)
  const navigate = useNavigate()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const periodId = period.id
  const companyId = getCompanyId()
  const { periodMutation } = usePeriodService({
    queryOptions: {
      enabled: false,
    },
  })

  const periodQueryKey = [
    payPreviousPayroll ? 'periodById' : 'currentPeriod',
    companyId,
    ...(payPreviousPayroll ? [periodId] : []),
  ]

  return async () => {
    try {
      if (payPreviousPayroll) {
        navigate(routes.PERIOD_PAY_PREVIOUS_PAYROLL(periodId), {
          replace: true,
        })
      } else {
        showLoadingModal()
        await periodMutation.mutateAsync(
          {
            mutationMethod: 'PATCH',
            period: {
              payrolls_paid: false,
            },
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(periodQueryKey, {
                exact: false,
              })
              navigate(routes.PERIOD_PAY_PAYROLL(), { replace: true })
              hideLoadingModal()
            },
            onError: () => {
              hideLoadingModal()
            },
          }
        )
      }
    } catch (error) {
      handleError(error)
      hideLoadingModal()
    }

    if (callback) {
      callback()
    }
  }
}

export const getSeveranceDescription = (socialBenefits) => {
  const hasSeveranceLiquidated = socialBenefits?.some(
    ({ action, category }) => {
      return category === 'severance' && action === 'update'
    }
  )

  if (!hasSeveranceLiquidated) {
    return null
  }

  return (
    <Typography
      sx={(theme) => ({ marginBottom: theme.spacing(2) })}
      color="black.dark"
    >
      <Box component="span" color="primary.main" fontWeight={700}>
        Recuerda!
      </Box>{' '}
      Las cesantías no son incluidas en la colillas ya que no se pagan
      directamente a la persona.
    </Typography>
  )
}
