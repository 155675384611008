import { Form, Formik } from 'formik'
import { useState } from 'react'

import { Box } from '@mui/material'

import PaymentModalFields from 'components/Subscription/Index/PaymentMethod/PaymentModalFields'
import { getCurrentPaymentMethods } from 'components/Subscription/Index/PaymentMethod/helpers'
import {
  getInitialValues,
  getPaymentFields,
  getValidationSchema,
} from 'components/Subscription/SubscriptionFormFields/helpers'
import Button from 'components/UI/Button/Button'

import usePayment from '../usePayment'
import useSubscription from '../useSubscription'

const PaymentModal = ({
  handleClose,
  currentPlan,
  extraWorkersEdit,
  handlePreviousModal,
  subscriptionChange = false,
  newSubscriptionType,
  isFromExtraWorkersModal = false,
  subscriptionTypeValidators,
  isEditingPaymentMethod = false,
  currentPlanPayment = false,
  isFromUpdateMethodInfo,
  openUpdatePaymentMethodInfoModal,
  isFromImmediatePayment,
}) => {
  const { subscription } = useSubscription()

  const { subscription_payment_methods_info: currentPaymentMethodsInfo } =
    subscription || {}

  const currentPaymentMethods = getCurrentPaymentMethods(
    currentPaymentMethodsInfo
  )

  const [activePayment, setActivePayment] = useState(() => {
    if (isEditingPaymentMethod && subscription?.payment_category === 'pse') {
      return 'credit_card'
    }
    return subscription?.payment_category || 'credit_card'
  })

  const [isCurrentMethodPayment, setIsCurrentMethodPayment] = useState(
    currentPaymentMethods?.includes(activePayment)
  )

  const { tabsData, onSubmit, formRef, isLoading, handleSubmitForm } =
    usePayment({
      onCancel: handleClose,
      plan: currentPlan,
      extraWorkers: extraWorkersEdit,
      subscriptionChange,
      newSubscriptionType,
      isFromExtraWorkersModal,
      subscriptionTypeValidators,
      isEditingPaymentMethod,
      currentPlanPayment,
      isFromUpdateMethodInfo,
      openUpdatePaymentMethodInfoModal,
    })

  const handleSubmit = (values) =>
    onSubmit({
      values,
      selectedPaymentMethod: activePayment,
      isCurrentMethodPayment,
    })

  return (
    <Formik
      innerRef={formRef}
      onSubmit={handleSubmit}
      initialValues={getInitialValues(isCurrentMethodPayment)}
      validationSchema={getValidationSchema(
        activePayment,
        isCurrentMethodPayment
      )}
      validateOnChange={false}
      enableReinitialize
    >
      <Form>
        <PaymentModalFields
          tabsData={tabsData}
          activePayment={activePayment}
          paymentFields={getPaymentFields(
            isEditingPaymentMethod,
            newSubscriptionType
          )}
          setActivePayment={setActivePayment}
          setIsCurrentMethodPayment={setIsCurrentMethodPayment}
          isEditingPaymentMethod={isEditingPaymentMethod}
          currentPlanPayment={currentPlanPayment}
          extraWorkersEdit={extraWorkersEdit}
          isFromExtraWorkersModal={isFromExtraWorkersModal}
          isFromUpdateMethodInfo={isFromUpdateMethodInfo}
          isFromImmediatePayment={isFromImmediatePayment}
        />
        <Box
          sx={(theme) => ({
            display: 'flex',
            gap: theme.spacing(2),
            marginTop: theme.spacing(6),
            [theme.breakpoints.down('tablet')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Button
            onClick={handleSubmitForm}
            loading={isLoading}
            disabled={isEditingPaymentMethod && isCurrentMethodPayment}
          >
            {isEditingPaymentMethod
              ? `${currentPaymentMethodsInfo?.length ? 'Actualizar' : 'Agregar'} método de pago`
              : 'Procesar pago'}
          </Button>
          <Button
            variant="outlined"
            onClick={
              extraWorkersEdit || subscriptionChange
                ? handlePreviousModal
                : handleClose
            }
          >
            {extraWorkersEdit || subscriptionChange ? 'Atras' : 'Cancelar'}
          </Button>
        </Box>
      </Form>
    </Formik>
  )
}

export default PaymentModal
