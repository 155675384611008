import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'

import { Menu, MenuItem } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import { workerCertificates } from 'utils/worker'

import IncomeCertificateModal from '../CertificateModal/Income'
import LaborCertificateModal from '../CertificateModal/Labor'
import useIncomeModal from '../CertificateModal/useIncomeModal'
import useLaborModal from '../CertificateModal/useLaborModal'

const CertificatesMenu = ({ worker, workerOptions, isWorkerRole }) => {
  const { certificate_options: certificateOptions } = workerOptions || {}
  const {
    workers_income_certificate_access: incomeCertificateAccess,
    workers_labor_certificate_access: laborCertificateAccess,
  } = worker || {}

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workerCertificatesMenu',
  })

  const { laborModalState, closeLaborModal, openLaborModal } = useLaborModal()
  const { incomeModalState, closeIncomeModal, openIncomeModal } =
    useIncomeModal()

  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  const popupBindTrigger = bindTrigger(popupState)
  const openPopover = (event) => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    popupBindTrigger.onClick(event)
  }

  const showModal = (modalType) => {
    if (modalType === 'labor_certificate') openLaborModal(worker)
    if (modalType === 'income_certificate') openIncomeModal(worker)
    popupState.close()
  }

  const allowedCertificateOptions = certificateOptions.filter(
    (option) =>
      (option === 'income_certificate' && incomeCertificateAccess) ||
      (option === 'labor_certificate' && laborCertificateAccess) ||
      !isWorkerRole
  )

  return (
    <>
      <Button
        endIcon={
          !showPremiumFeatures ? (
            <PremiumFeatureIcon />
          ) : (
            <Icon name="chevron-down" />
          )
        }
        {...popupBindTrigger}
        onClick={openPopover}
        variant="outlined"
        size="large"
        sx={{ width: { mobile: 1, tablet: 'auto' } }}
      >
        Certificados
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {allowedCertificateOptions.map((option) => {
          return (
            <MenuItem key={option} onClick={() => showModal(option)}>
              {workerCertificates[option] ?? ''}
            </MenuItem>
          )
        })}
      </Menu>
      {/* Modals */}
      {laborModalState.open ? (
        <LaborCertificateModal
          state={laborModalState}
          handleClose={closeLaborModal}
        />
      ) : null}
      {incomeModalState.open ? (
        <IncomeCertificateModal
          state={incomeModalState}
          handleClose={closeIncomeModal}
        />
      ) : null}
    </>
  )
}

export default CertificatesMenu
