import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Link, Typography } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import useUsersService from 'utils/hooks/settings/usersService'
import useNotifications from 'utils/hooks/useNotifications'
import { trackEvent } from 'utils/integration'
import integrationEvent from 'utils/integrations/events/eventsNames'

import {
  createUser,
  getAllUsers,
  updateUserState,
} from 'services/settings/userService'

import { columnsData, useTableActions } from './helpers'
import validationSchema from './validationSchema'

const SettingsUsers = () => {
  const queryClient = useQueryClient()
  const confirm = useConfirm()
  const { showSuccessMessage } = useNotifications()

  const [userState, setUserState] = useState('')
  const [userRole, setUserRole] = useState('')

  const companyId = getCompanyId()

  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  const usersDataCache = queryClient.getQueryData(['users'], { exact: false })
  const refetchUsers = () =>
    queryClient.invalidateQueries(['users'], { exact: false })
  const hasOneUsers = usersDataCache?.data
    ? usersDataCache?.data.length >= 1
    : refetchUsers()

  const { usersMutation } = useUsersService({
    mutationOptions: {
      onSuccess: refetchUsers,
    },
  })

  const mutationOptions = {
    onSuccess: refetchUsers,
  }

  const fetchUsers = (page, search) => {
    return {
      queryKey: ['users', companyId, page, search, userState, userRole],
      queryFunction: () =>
        getAllUsers({
          page: page + 1,
          search,
          filter: userRole,
          status: userState,
        }),
    }
  }

  const addUser = (user) => {
    return {
      data: {
        user,
      },
      mutationFunction: createUser,
      mutateOptions: {
        onSuccess: () => trackEvent(integrationEvent.INVITE_COLLABORATOR, user),
      },
    }
  }

  const updateUser = (oldUser, newUser) => {
    const dirtyValues = getDirtyValues(
      oldUser,
      newUser,
      validationSchema.fields
    )

    return {
      data: { id: newUser.id, ...dirtyValues },
      mutationFunction: updateUserState,
    }
  }

  const resendEmail = (user) => {
    usersMutation.mutate(
      {
        mutationMethod: 'POST',
        mutationKey: 'resendUserEmail',
        user: {
          id: user.id,
        },
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  const deleteUser = (user) => {
    usersMutation.mutate(
      {
        mutationMethod: 'DELETE',
        user: {
          id: user.id,
        },
      },
      {
        onSuccess: ({ message }) => showSuccessMessage(message),
      }
    )
  }

  const handleAddRow = () => {
    if (!showPremiumFeatures && hasOneUsers) {
      openPremiumFeatureModal()
      return false
    }
    return true
  }

  const tableActions = useTableActions({
    confirm,
    deleteUser,
    resendEmail,
    userRole,
    setUserRole,
    userState,
    setUserState,
  })

  return (
    <Page header="Configuración usuarios">
      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(7) })}
      >
        Invita a más personas de tu empresa. Puedes leer más sobre cada rol
        visitando{' '}
        <Link
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-los-permisos-de-cada-rol-de-usuario-en-aleluya"
          target="_blank"
          color="accent4.main"
          fontWeight={600}
        >
          nuestro centro de ayuda.
        </Link>
      </Typography>
      <Table
        columns={columnsData}
        data={fetchUsers}
        options={{
          version: {
            toolbar: 'v2',
          },
          customActionsWidth: 150,
          alignActionsCell: 'center',
        }}
        editable={{
          onAddRow: addUser,
          onUpdateRow: updateUser,
          enableAddRow: true,
          addRowActionProps: {
            tooltip: 'Añadir Persona',
            onClick: handleAddRow,
            buttonProps: {
              startIcon: <Icon name="plus" />,
              endIcon: <PremiumFeatureIcon />,
            },
          },
          addRowPosition: 'top',
          validationSchema,
          hideDelete: true,
          tableMinWidth: 1080,
          mutationOptions,
        }}
        actions={tableActions}
      />
    </Page>
  )
}

export default SettingsUsers
