import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'

import { getUserName } from 'utils/auth'
import { rocket } from 'utils/emojis'

import RocketWoman from 'assets/images/views/holisticPayroll/rocket_woman.png'

export default function useHolisticPayrollMVPModal() {
  const modals = useModals()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const userName = getUserName()

  const defaultMessageWhatsApp = `¡Hola! Mi nombre es **${userName}** y quiero conocer sobre las nuevas funcionalidades en Aleluya. **Nómina holística**`

  const handleClose = () => {
    modals.closeModal('holisticPayrollMVPModal')
  }

  const openHolisticPayrollMVPModal = () => {
    modals.openModal({
      id: 'holisticPayrollMVPModal',
      content: (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              mobile: '1fr',
              tablet: '17rem 1fr',
            },
          }}
        >
          {!isMobile ? (
            <>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.accent1.main,
                  width: '27.75rem',
                  height: '27.75rem',
                  borderRadius: '100%',
                  position: 'absolute',
                  left: theme.spacing(-22),
                  top: theme.spacing(-5),
                })}
              />
              <Box
                component="img"
                alt="Personaje volando en un cohete a toda velocidad"
                src={RocketWoman}
                width={417}
                height={355}
                sx={(theme) => ({
                  zIndex: 1,
                  objectFit: 'contain',
                  marginLeft: theme.spacing(-11.25),
                })}
              />
            </>
          ) : null}

          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ButtonBase
                onClick={handleClose}
                data-cy="close-dialog-button"
                sx={(theme) => ({
                  alignSelf: 'self-start',
                  cursor: 'pointer',
                  color: theme.palette.black.main,
                })}
              >
                <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
              </ButtonBase>
            </Box>

            <Box
              sx={(theme) => ({
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(2),
              })}
            >
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  Tenemos nuevas funcionalidades
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <Emoji code={rocket} /> ¡increíbles! <Emoji code={rocket} />
                </Typography>
              </Box>
              <Button
                href={`https://api.whatsapp.com/send?phone=573006368842&text=${defaultMessageWhatsApp}`}
                target="_blank"
                size="large"
                sx={(theme) => ({ marginTop: theme.spacing(5) })}
              >
                ¡Contáctanos y te contamos más!
              </Button>
            </Box>
          </Box>
        </Box>
      ),
      modalProps: {
        onCloseModal: handleClose,
        hideFooter: true,
        paperSx: {
          maxWidth: '50rem',
        },
        contentWrapperSx: {
          overflow: 'hidden',
        },
      },
    })
  }

  return { openHolisticPayrollMVPModal }
}
