import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

const CardButton = ({ payrollConceptValue, onClick, editable, dataCy }) => (
  <Button
    size="small"
    endIcon={
      payrollConceptValue ? (
        <Icon name="pencil-line" />
      ) : (
        <Icon name="move-right" />
      )
    }
    variant="outlined"
    onClick={onClick}
    disabled={!editable}
    data-cy={dataCy}
  >
    {payrollConceptValue ? 'Editar' : 'Ingresar'}
  </Button>
)

export default CardButton
