import { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import { getTabsData } from 'components/Subscription/SubscriptionFormFields/helpers'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import { useCompanyService } from 'utils/hooks/company/companyService'
import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { DASHBOARD, SUBSCRIPTION_STATUS } from 'config/routes'

import { getPaymentData } from '../Index/helpers'
import useTransactionResponseModal from './TransactionResponseModal/useTransactionResponseModal'
import useSubscription from './useSubscription'

const usePayment = ({
  onCancel,
  plan = {},
  extraWorkers = 0,
  subscriptionChange,
  newSubscriptionType,
  isFromExtraWorkersModal,
  subscriptionTypeValidators,
  isEditingPaymentMethod,
  currentPlanPayment,
  isFromUpdateMethodInfo,
  openUpdatePaymentMethodInfoModal,
  isFromSubscriptionPaymentView,
}) => {
  const { showInfoMessage, showErrorMessage } = useNotifications()
  const { subscription } = useSubscription()
  const queryClient = useQueryClient()
  const formRef = useRef()
  const { handleError } = useErrorHandler()
  const companyId = getCompanyId()
  const subscriptionType = subscription?.type
  const { openTransactionResponseModal } = useTransactionResponseModal()
  const navigate = useNavigate()
  const { company, refreshCompany, listenInBackground } = useUser()

  const { paymentMethodMutation } = usePaymentMethodService({
    queryOptions: {
      enabled: false,
    },
  })

  const { companyMutation } = useCompanyService({
    queryOptions: {
      enabled: false,
    },
  })

  const tabsData = getTabsData(
    subscription,
    isEditingPaymentMethod,
    currentPlanPayment
  )

  const handleSubmitForm = () => {
    if (formRef.current) {
      formRef.current?.['handleSubmit']()
    }
  }

  const callbackSuccess = async ({ response, selectedPaymentMethod }) => {
    // Takes the user to pse website in order to complete the payment
    if (selectedPaymentMethod === 'pse') {
      window.location.href = response.bank_url
      return
    }

    // Closes payment modal (if opened) and opens Transaction response modal
    onCancel && onCancel()

    // To avoid unnecessary invalidation of queries
    if (
      !(
        (isFromUpdateMethodInfo || isEditingPaymentMethod) &&
        selectedPaymentMethod === 'automatic_debit'
      )
    ) {
      // Gives the user fresh information regarding subscription and company
      await refreshCompany()
      queryClient.invalidateQueries(['getAllPayments', companyId])
    }

    if (isFromUpdateMethodInfo || isEditingPaymentMethod) {
      if (selectedPaymentMethod === 'automatic_debit') {
        showInfoMessage(
          'Estamos verificando la información del cambio en tu débito automático. Te avisaremos cuando esté listo.'
        )

        const automaticDebitUpdateSocketHandler = async (
          websocketResult,
          connectionRef
        ) => {
          if (websocketResult) {
            if (websocketResult.failure) {
              showErrorMessage(websocketResult.failure, {
                preventDuplicate: true,
              })
            }

            if (websocketResult.success) {
              await queryClient.invalidateQueries([
                'getSubscription',
                companyId,
              ])

              openUpdatePaymentMethodInfoModal({
                actionMessage: 'successful_update',
                activePayment: 'automatic_debit',
                isFromPaymentMethodCard: true,
              })
            }

            connectionRef.off('value')
          }
        }

        return listenInBackground(
          `companies/${companyId}/payments/automatic_debit`,
          automaticDebitUpdateSocketHandler
        )
      }

      return openUpdatePaymentMethodInfoModal({
        actionMessage: 'successful_update',
        activePayment: selectedPaymentMethod,
        isFromPaymentMethodCard: isEditingPaymentMethod,
      })
    }

    // To avoid bugs regarding current plan after getting a new one
    if (response.payment_status === 'APPROVED' && subscriptionChange) {
      queryClient.invalidateQueries(['getPlans', companyId])
    }

    if (isFromSubscriptionPaymentView) {
      if (response.payment_status === 'APPROVED') {
        navigate(SUBSCRIPTION_STATUS())
      }
      if (response.payment_status === 'PENDING') {
        navigate(DASHBOARD)
      }
    }

    openTransactionResponseModal({
      subscriptionTypeValidators,
      transactionStatus: response.payment_status,
      isExtraworkersPayment: isFromExtraWorkersModal,
    })
  }

  // Closes payment modal (if opened) and opens Transaction response modal or displays error message
  const callbackError = (error) => {
    onCancel && onCancel()

    if (isEditingPaymentMethod) {
      return showErrorMessage(
        'Hubo un error y no pudimos actualizar tu método de pago. Por favor, inténtalo nuevamente. Si el problema persiste, contacta con soporte.'
      )
    }

    // Errors related to payment providers (24) or API problems (1101)
    if (
      error.errors?.[0].code.startsWith('24') ||
      error.errors?.[0].code === '1101'
    ) {
      openTransactionResponseModal({
        transactionStatus: 'DECLINED',
        isExtraworkersPayment: isFromExtraWorkersModal,
      })
    } else {
      handleError(error, null, {
        notistackOptions: { preventDuplicate: true },
      })
    }
  }

  const paymentMethodMutationCallback = ({
    selectedPaymentMethod,
    paymentData,
  }) => {
    paymentMethodMutation.mutate(
      {
        paymentMethod: selectedPaymentMethod,
        paymentMethodData: paymentData,
      },
      {
        onSuccess: (response) =>
          callbackSuccess({ response, selectedPaymentMethod }),
        onError: (error) => callbackError(error),
      }
    )
  }

  const createPayment = async ({
    selectedPaymentMethod,
    paymentData,
    values,
  }) => {
    const companyValues = {
      name: values?.company_form?.name,
      phone: values?.company_form?.phone,
      email: values?.company_form?.email,
      document_type: values?.company_form?.document_type,
      id_number: values?.company_form?.id_number,
    }

    let dirtyValues = {}

    if (isFromSubscriptionPaymentView) {
      dirtyValues = getDirtyValues(company, companyValues)
    }

    if (!isObjectEmpty(dirtyValues)) {
      companyMutation.mutate(
        {
          mutationMethod: 'PATCH',
          company: companyValues,
          companyId,
        },
        {
          onSuccess: () => {
            paymentMethodMutationCallback({
              selectedPaymentMethod,
              paymentData,
            })
          },
        }
      )
    } else {
      paymentMethodMutationCallback({
        selectedPaymentMethod,
        paymentData,
      })
    }
  }

  const onSubmit = ({
    values,
    selectedPaymentMethod,
    isCurrentMethodPayment,
  }) => {
    const paymentData = getPaymentData({
      values,
      paymentMethod: selectedPaymentMethod,
      planId: plan?.id,
      subscriptionType,
      isPartner: subscription?.partner,
      extraWorkers,
      subscriptionChange,
      newSubscriptionType,
      currentPlanPayment: subscriptionTypeValidators?.currentPlanPayment,
      isPremiumExpiredSubscription:
        subscriptionTypeValidators?.isPremiumExpiredSubscription,
      activeWorkers: subscription.payrolls_size,
      isEditingPaymentMethod,
      isCurrentMethodPayment,
      isFromSubscriptionPaymentView,
      isFromExtraWorkersModal,
    })

    createPayment({
      selectedPaymentMethod,
      paymentData,
      values,
    })
  }

  const isLoading =
    paymentMethodMutation?.isLoading || companyMutation?.isLoading

  return {
    tabsData,
    onSubmit,
    handleSubmitForm,
    formRef,
    isLoading,
  }
}

export default usePayment
