import { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import { formatCurrency } from 'utils/format'
import useCompanyHolisticPayrollService from 'utils/hooks/HolisticPayroll/CompayService'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import MoneyWad from 'assets/images/views/holisticPayroll/money_wad.png'

import * as routes from 'config/routes'

import HolisticPayrollTabs from './Commons/HolisticPayrollTabs'
import { getCompanyDisabilityClaimsDetailCards, getTabsConfig } from './helpers'

export default function DisabilityClaimsDetails() {
  const [activeTab, setActiveTab] = useState(0)
  const [activeStatus, setActiveStatus] = useState('')
  const { company } = useUser()
  const companyId = getCompanyId()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const { disabilityClaimsV2, flagsReady } = useFeatureFlags({
    flags: ['disabilityClaimsV2'],
    trackingMode: 'attributes',
    attributes: {
      companyId,
    },
  })

  const hasClaimsId = Boolean(company.company_claims_detail_id)
  const tabsConfig = getTabsConfig({
    tabsView: 'disability_claims',
    activeTab,
    activeStatus,
    setActiveStatus,
  })

  const {
    companyHolisticPayrollQuery: {
      data: disabilityClaimsQueryData,
      isLoading: disabilityClaimsQueryIsLoading,
    },
  } = useCompanyHolisticPayrollService({
    serviceParams: {
      queryKey: ['getDisabilityClaimsData', companyId],
      claimsDetailId: company.company_claims_detail_id,
    },
  })

  const companyDisabilityClaimsDetailCards =
    getCompanyDisabilityClaimsDetailCards({
      totalCardsValue: disabilityClaimsQueryData,
    })

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
    if (newTab === 1) {
      setActiveStatus('claimed')
    } else {
      setActiveStatus('pendings')
    }
  }

  if (flagsReady && disabilityClaimsV2?.value === 'off') {
    return <Navigate to={routes.DASHBOARD} />
  }

  if (!hasClaimsId || !disabilityClaimsQueryData?.has_claims) {
    return <Navigate to={routes.HOLISTIC_PAYROLL_DISABILITY_CLAIMS_INDEX()} />
  }

  return (
    <Page
      documentTitle="Mis reclamaciones"
      rootSx={{ backgroundColor: 'accent1.light' }}
      grid
    >
      <Box sx={{ gridColumn: '1 / -1' }}>
        <Paper
          elevation={2}
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(3),
            borderRadius: '1rem',
            backgroundColor: 'accent2.light',
          })}
        >
          <Box
            sx={({ spacing }) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: spacing(2),
            })}
          >
            <Typography variant="h1">
              Reclamaciones de{' '}
              <Box component="span" color="accent4.main">
                {company.name}
              </Box>
            </Typography>
            <Typography color="black.dark">
              Administra y haz seguimiento a tus reclamaciones de forma rápida y
              organizada. Todo lo que necesitas para gestionar tus procesos,
              facilito y en un solo lugar.
            </Typography>
          </Box>
          {!isMobile ? (
            <Box
              component="img"
              alt="Relájate mientras tienes Aleluya Premium"
              src={MoneyWad}
              width={380}
              height={380}
              sx={(theme) => ({
                zIndex: 1,
                objectFit: 'contain',
                margin: theme.spacing(-16, 0, -12, 0),
              })}
            />
          ) : null}
        </Paper>

        {disabilityClaimsQueryIsLoading ? (
          <LoadingBox />
        ) : (
          <Box
            sx={({ breakpoints, spacing }) => ({
              marginTop: spacing(4),
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: spacing(4),
              [breakpoints.down('desktop')]: {
                gap: spacing(3),
              },
              [breakpoints.up('tablet')]: {
                gridTemplateColumns: '1fr 1fr',
              },
            })}
          >
            {companyDisabilityClaimsDetailCards.map((info) => (
              <Paper
                key={info.id}
                sx={{
                  display: 'flex',
                  borderRadius: '1.25rem',
                }}
              >
                <Box
                  sx={({ breakpoints }) => ({
                    backgroundColor: info.iconBackground,
                    borderTopLeftRadius: '1.25rem',
                    borderBottomLeftRadius: '1.25rem',
                    width: '4.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    [breakpoints.up('sm')]: {
                      width: '6rem',
                    },
                    [breakpoints.up('laptop')]: {
                      width: '7rem',
                    },
                  })}
                >
                  <Box
                    component="img"
                    loading="lazy"
                    src={info.icon}
                    alt={info.alt}
                    width={isMobile ? 62 : 80}
                    height={isMobile ? 62 : 80}
                  />
                </Box>
                <Box
                  sx={({ spacing, breakpoints }) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: spacing(1.5),
                    flex: 1,
                    padding: spacing(2),
                    [breakpoints.up('laptop')]: {
                      gap: spacing(3),
                    },
                  })}
                >
                  <Box
                    sx={({ spacing }) => ({
                      display: 'flex',
                      alignItems: 'center',
                      gap: spacing(1),
                    })}
                  >
                    <Typography variant="h4">{info.title}:</Typography>
                    {info.tooltipText ? (
                      <TooltipInfoIcon
                        title={info.tooltipText}
                        iconProps={{
                          sx: {
                            color: 'accent4.main',
                            fontSize: '2rem',
                            zIndex: 1,
                          },
                        }}
                      />
                    ) : null}
                  </Box>
                  <Box
                    sx={({ spacing, breakpoints }) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: spacing(1),
                      [breakpoints.up('lg')]: {
                        gap: spacing(3),
                        flexDirection: 'row',
                        alignItems: 'center',
                      },
                    })}
                  >
                    <Typography variant="h1" color={info.valueColor}>
                      {formatCurrency(info.value)}
                    </Typography>

                    {info.id === 'frozen_money' ? (
                      <Button
                        size="small"
                        variant="outlined"
                        sx={({ breakpoints }) => ({
                          width: '100%',
                          [breakpoints.up('desktop')]: { maxWidth: '16rem' },
                        })}
                        // eslint-disable-next-line no-console
                        onClick={() => console.log('Clicked')}
                      >
                        Revisar
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              </Paper>
            ))}
          </Box>
        )}

        <HolisticPayrollTabs
          activeTab={activeTab}
          handleChangeTab={handleChangeTab}
          tabsConfig={tabsConfig}
        />
      </Box>
    </Page>
  )
}
