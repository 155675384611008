import { Link } from 'react-router-dom'

import { Box, ButtonBase, Paper, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

const StepCard = ({
  status = 'pending',
  number,
  title,
  description,
  disabled,
  path,
}) => {
  return (
    <ButtonBase
      disabled={disabled}
      component={path ? Link : Box}
      to={path || null}
    >
      <Paper
        sx={(theme) => ({
          width: '100%',
          display: 'grid',
          padding: theme.spacing(3),
          gridTemplateColumns: '2.1875rem 1fr 2.1875rem',
          alignItems: 'center',
          columnGap: theme.spacing(2.5),
          '& > h2': {
            justifySelf: 'center',
          },
          ...(status === 'active' && {
            outline: `2px solid ${theme.palette.primary.main}`,
          }),
        })}
        elevation={3}
      >
        <Typography
          variant="h2"
          sx={(theme) => ({
            color: theme.palette.black.light,
            ...(status === 'completed' && {
              color: theme.palette.primary.main,
            }),
            ...(status === 'active' && {
              color: theme.palette.primary.main,
            }),
          })}
        >
          {number}
        </Typography>
        <Box
          sx={{
            width: '100%',
            textAlign: 'left',
          }}
        >
          <Typography
            variant="lead2"
            sx={(theme) => ({
              ...(status === 'completed' && {
                color: theme.palette.black.light,
              }),
              ...(status === 'active' && {
                color: theme.palette.black.dark,
              }),
            })}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={(theme) => ({
              ...(status === 'completed' && {
                color: theme.palette.black.light,
              }),
              ...(status === 'active' && {
                color: theme.palette.black.dark,
              }),
            })}
          >
            {description}
          </Typography>
        </Box>
        {status === 'pending' ? (
          <Icon
            name="chevron-right"
            sx={(theme) => ({
              color: theme.palette.black.light,
            })}
            fontSize="large"
          />
        ) : null}
        {status === 'active' ? (
          <Icon
            name="chevron-right"
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            fontSize="large"
          />
        ) : null}
        {status === 'completed' ? (
          <Icon
            name="circle-check"
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
            fontSize="large"
          />
        ) : null}
      </Paper>
    </ButtonBase>
  )
}

export default StepCard
