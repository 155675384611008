import { usePopupState } from 'material-ui-popup-state/hooks'
import { useContext } from 'react'

import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'
import LoadingModal from 'components/UI/Loading/LoadingModal'

import { formatNumberDisplayDate } from 'utils/dateTime'
import useDocumentsService from 'utils/hooks/worker/documents'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'
import { FoldersContainerContext } from './Index'
import {
  CardIcon,
  DropdownMenu,
  foldersActions,
  validateIsAffiliationFolder,
} from './helpers'

const FolderCard = ({ showAsCard, card }) => {
  const { setOpenFolder } = useContext(DocumentsContext)
  const isAffiliationFolder = validateIsAffiliationFolder(card.name)
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const { isWorker } = useUser()
  const { setActionModal } = useContext(FoldersContainerContext)
  const { documentsMutation } = useDocumentsService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleOpenFolder = () => setOpenFolder(card)

  const optionsFiltered = () => {
    const options = [
      {
        id: foldersActions.change_folder_name.id,
        name: foldersActions.change_folder_name.callToAction,
        onClick: () => {
          setActionModal({
            type: foldersActions.change_folder_name.id,
            context: card,
          })
          popupState.close()
        },
      },
    ]

    if (card.deleteable) {
      options.push({
        id: foldersActions.delete_folder.id,
        name: foldersActions.delete_folder.callToAction,
        onClick: () => {
          setActionModal({
            type: foldersActions.delete_folder.id,
            context: card,
          })
          popupState.close()
        },
      })
    }

    return options
  }

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('laptop'))

  return (
    <>
      <Box
        sx={(theme) => ({
          position: 'relative',
          borderRadius: '0.5rem',
          backgroundColor: theme.palette.accent1.main,
          color: theme.palette.white.main,
          display: 'flex',
          padding: theme.spacing(2),
          '&:hover': {
            cursor: 'pointer',
          },
          ...(showAsCard && {
            minHeight: '9.875rem',
            flexDirection: 'column',
          }),
          ...(!showAsCard && {
            width: '100%',
            minHeight: '4.5rem',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            rowGap: theme.spacing(2),
            [theme.breakpoints.down('laptop')]: {
              justifyContent: 'space-between',
            },
          }),
        })}
        onClick={handleOpenFolder}
        role="button"
      >
        {showAsCard ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
              })}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <CardIcon
                  deleteable={card?.deleteable}
                  isAffiliationFolder={isAffiliationFolder}
                  emptyFolder={card.total_files === 0}
                />
                {!isWorker && !isAffiliationFolder ? (
                  <DropdownMenu
                    popupState={popupState}
                    optionsFiltered={optionsFiltered}
                  />
                ) : null}
              </Box>
              <Typography
                variant="h6"
                align="left"
                sx={(theme) => ({
                  marginBottom: theme.spacing(1),
                  color: theme.palette.black.main,
                })}
              >
                {card.name}
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(0.5),
              })}
            >
              <Typography variant="body1" color="black.dark">
                {`${card.total_files} Archivos`}
              </Typography>
              <Typography variant="body1" color="black.dark">
                {`Creada el ${formatNumberDisplayDate(card.created_at)}`}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              rowGap: theme.spacing(1),
              [theme.breakpoints.up('tablet')]: {
                alignItems: 'center',
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr 3fr repeat(3, 2fr)',
                columnGap: theme.spacing(3),
              },
            })}
          >
            <CardIcon
              deleteable={card?.deleteable}
              isAffiliationFolder={isAffiliationFolder}
              emptyFolder={card.total_files === 0}
            />
            <Typography
              variant="h6"
              align="left"
              sx={(theme) => ({ color: theme.palette.black.main })}
            >
              {card.name}
            </Typography>
            <Typography variant="body1" color="black.dark">
              {`${card.total_files} Archivos`}
            </Typography>
            <Typography variant="body1" color="black.dark">
              {`Creada el ${formatNumberDisplayDate(card.created_at)}`}
            </Typography>
            {!isWorker &&
              !isAffiliationFolder &&
              (!isMobile ? (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'end',
                    [theme.breakpoints.down('laptop')]: {
                      justifyContent: 'start',
                    },
                  })}
                  onClick={(event) => event.stopPropagation()}
                >
                  <IconButton
                    size="large"
                    sx={(theme) => ({
                      backgroundColor: `${theme.palette.complementary1.main} !important`,
                      padding: theme.spacing(0.5),
                      '&:before': {
                        content: 'none',
                      },
                    })}
                    onClick={(event) => {
                      event.stopPropagation()
                      setActionModal({
                        type: foldersActions.change_folder_name.id,
                        context: card,
                      })
                    }}
                  >
                    <Icon name="pencil-line" />
                  </IconButton>
                  {card?.deleteable ? (
                    <IconButton
                      size="large"
                      sx={(theme) => ({
                        backgroundColor: `${theme.palette.complementary1.main} !important`,
                        padding: theme.spacing(0.5),
                        marginLeft: theme.spacing(1),
                        '&:before': {
                          content: 'none',
                        },
                      })}
                      onClick={(event) => {
                        event.stopPropagation()
                        setActionModal({
                          type: foldersActions.delete_folder.id,
                          context: card,
                        })
                      }}
                    >
                      <Icon name="trash-2" />
                    </IconButton>
                  ) : null}
                </Box>
              ) : (
                <Box sx={{ position: 'absolute', right: 16 }}>
                  <DropdownMenu
                    popupState={popupState}
                    optionsFiltered={optionsFiltered}
                  />
                </Box>
              ))}
          </Box>
        )}
      </Box>
      {documentsMutation.isLoading ? <LoadingModal /> : null}
    </>
  )
}

export default FolderCard
