import { IconButton as MIconButton } from '@mui/material'

const IconButton = ({ icon, sx, ...props }) => {
  return (
    <MIconButton
      size="large"
      sx={[
        {
          width: '2.25rem',
          height: '2.25rem',
          fontSize: '2rem',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        sx,
      ]}
      {...props}
    >
      {icon}
    </MIconButton>
  )
}

export default IconButton
