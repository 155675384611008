import { createContext, useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Paper,
  tabClasses,
  tabsClasses,
  useMediaQuery,
} from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'
import LoadingModal from 'components/UI/Loading/LoadingModal'
import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'
import useEditContractModal from 'components/Worker/Contract/Modal/useEditContractModal'
import NewWorkerModal from 'components/Worker/Index/Modals/NewWorkerModal'
import useNewWorkerModal from 'components/Worker/Index/Modals/NewWorkerModal/useNewWorkerModal'
import useWorker from 'components/Worker/useWorker'

import useNotifications from 'utils/hooks/useNotifications'
import useWorkerService from 'utils/hooks/worker/workerService'

import * as routes from 'config/routes'

import { WorkerProfileContext } from '../Show'
import ActivateButton from './ActivateButton'
import UpdateWorkerGeneralDataModal from './Modals/UpdateGeneralDataModal/UpdateGeneralDataModal'
import useUpdateGeneralWorkerDataModal from './Modals/UpdateGeneralDataModal/useUpdateGeneralDataModal'
import DocumentsContainer from './Tabs/DocumentsContainer'
import FreeTimeContent from './Tabs/FreeTimeContent'
import getTabsData from './helpers'

export const WorkerShowContext = createContext()
WorkerShowContext.displayName = 'WorkerShowContext'

export const useWorkerShowContext = () => {
  const value = useContext(WorkerShowContext)

  if (!value) {
    throw new Error(
      'useWorkerShowContext must be used within WorkerShowProvider'
    )
  }

  return value
}

const WorkerBody = () => {
  const { activeTab, setActiveTab } = useContext(WorkerProfileContext)
  const confirm = useConfirm()
  const navigate = useNavigate()
  const { isWorker, user } = useUser()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))
  const { worker, isFromAffiliationView } = useWorker({
    useCache: true,
  })
  const { newWorkerModalState, openNewWorkerModal, closeNewWorkerModal } =
    useNewWorkerModal()
  const queryClient = useQueryClient()
  const { workerMutation } = useWorkerService({
    queryOptions: { enabled: false },
  })

  const {
    editContractModalState,
    openEditContractModal,
    closeEditContractModal,
  } = useEditContractModal()

  const { showHRFeatures } = usePremiumFeature()

  const { showSuccessMessage } = useNotifications()

  const handleFinishPersonRegistration = () => {
    openNewWorkerModal({ workerId: worker.id, workerName: worker.name })
  }

  const tabsData = getTabsData(
    isFromAffiliationView,
    handleFinishPersonRegistration
  )

  const callback = (message) => {
    showSuccessMessage(message)
    queryClient.invalidateQueries('getWorkerTotals')
    navigate(routes.WORKER_INDEX('?tab=active'))
  }

  const deleteWorker = () => {
    workerMutation.mutate(
      {
        mutationMethod: 'DELETE',
        workerId: worker.id,
      },
      {
        onSuccess: (data) => callback(data.message),
      }
    )
  }

  const handleDeleteWorker = () => {
    confirm({
      type: 'error',
      title: 'Eliminar trabajador',
      description: '¿Estás seguro que deseas eliminar al trabajador?',
      okText: 'Eliminar',
      onOk: deleteWorker,
    })
  }

  const handleChangeTab = (__, newTab) => {
    setActiveTab(newTab)
  }

  const {
    updateGeneralDataModalState,
    openGeneralDataModal,
    closeGeneralDataModal,
  } = useUpdateGeneralWorkerDataModal()

  if (
    showHRFeatures &&
    isWorker &&
    (worker.workers_handle_novelties || user.workersHandleNovelties) &&
    worker.contract_category !== 'contractor' &&
    !isFromAffiliationView
  ) {
    tabsData.push({
      label: 'Solicitar tiempo fuera',
      content: <FreeTimeContent />,
    })
  }

  if (
    showHRFeatures &&
    !isWorker &&
    worker.contract_category !== 'contractor' &&
    !isFromAffiliationView
  ) {
    tabsData.push({
      label: 'Historial tiempo fuera',
      content: <FreeTimeContent />,
    })
  }

  if (showHRFeatures && !isFromAffiliationView) {
    tabsData.push({
      label: 'Documentos',
      content: <DocumentsContainer />,
    })
  }

  if (workerMutation.isLoading) return <LoadingModal />

  const contextValue = {
    openGeneralDataModal,
    closeGeneralDataModal,
    editContractModalState,
    openEditContractModal,
    closeEditContractModal,
  }

  return (
    <WorkerShowContext.Provider value={contextValue}>
      <Box
        sx={{
          gridColumn: '1 / -1',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Paper
          elevation={5}
          sx={{
            borderRadius: '1rem',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              margin: theme.spacing(3, 0, 6),
            })}
          >
            <Tabs
              value={activeTab}
              onChange={handleChangeTab}
              tabs={tabsData}
              variant={isMobile ? 'scrollable' : 'fullWidth'}
              scrollButtons={isMobile}
              sx={(theme) => ({
                width: '90%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: theme.palette.white.dark,
                padding: theme.spacing(1),
                borderRadius: '2rem !important',
                minHeight: '3.5rem',
                [`& .${tabsClasses.indicator}`]: {
                  display: 'none',
                },
                [`& .${tabsClasses.flexContainer}`]: {
                  gap: theme.spacing(1),
                },
                [`& .${tabClasses.root}`]: {
                  display: 'flex',
                  color: theme.palette.black.main,
                  ...theme.typography.lead2,
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: '1.9rem !important',
                    transition: 'background-color 250ms ease-in-out',
                  },
                },
                [`& .${tabClasses.selected}`]: {
                  color: `${theme.palette.black.main} !important`,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  borderRadius: '1.9rem',
                },
              })}
            />
          </Box>
          {tabsData[activeTab]?.content}
        </Paper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: {
              mobile: 'space-between',
              tablet: 'end',
            },
            gap: 2,
            marginTop: 3,
          }}
        >
          {!isFromAffiliationView &&
          ['employee', 'part_time_contract'].includes(
            worker?.contract_category
          ) ? (
            <ActivateButton isWorker={isWorker} worker={worker} />
          ) : null}
          {!isFromAffiliationView && !isWorker && worker.deleteable ? (
            <Button
              sx={{ width: { mobile: 1, tablet: 'auto' } }}
              endIcon={<Icon name="trash-2" />}
              onClick={handleDeleteWorker}
              size="large"
            >
              Eliminar
            </Button>
          ) : null}
        </Box>
      </Box>
      {updateGeneralDataModalState.open ? (
        <UpdateWorkerGeneralDataModal
          onCancel={closeGeneralDataModal}
          state={updateGeneralDataModalState}
        />
      ) : null}
      {newWorkerModalState.open ? (
        <NewWorkerModal
          state={newWorkerModalState}
          handleClose={closeNewWorkerModal}
        />
      ) : null}
    </WorkerShowContext.Provider>
  )
}

export default WorkerBody
