import { parseISO } from 'date-fns'
import { bindTrigger } from 'material-ui-popup-state/hooks'
import { useState } from 'react'
import * as yup from 'yup'

import { Box, IconButton, Tooltip } from '@mui/material'

import DropdownButton from 'components/UI/Button/Dropdown'
import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'

import favFolder from 'assets/images/three-dimensional-icons/fav-folder.png'
import fileText from 'assets/images/three-dimensional-icons/file-text.png'
import folder from 'assets/images/three-dimensional-icons/folder.png'
import newFolder from 'assets/images/three-dimensional-icons/new-folder.png'

yup.setLocale(yupLocaleES)

export const validateIsAffiliationFolder = (folderName) =>
  folderName === 'Afiliación a Seguridad Social'

export const useFilteredData = (data = []) => {
  const [searchFilter, setSearchFilter] = useState('')
  const [orderBy, setOrderBy] = useState('')

  const getFilteredData = () => {
    let newData = [...data]

    if (searchFilter) {
      newData = newData
        .map((item) => {
          if (item.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1)
            return item

          return null
        })
        .filter((item) => Boolean(item))
    }

    if (orderBy === 'name') {
      newData = newData.sort((a, b) => {
        return (
          parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime()
        )
      })
    }

    if (orderBy === 'date') {
      newData = newData.sort((a, b) => {
        return (
          parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime()
        )
      })
    }

    return newData
  }

  return {
    filteredData: getFilteredData(),
    searchFilter,
    onChangeFilter: setSearchFilter,
    orderByDate: () => setOrderBy('date'),
    orderByName: () => setOrderBy('name'),
  }
}

export const getInitialValues = (elementType) => {
  if (elementType === 'folder') {
    return {
      name: null,
      worker_folder: false,
      worker_access: false,
    }
  }

  return {
    document_name: null,
  }
}

export const documentsActions = {
  move_file: {
    id: 'move_file',
    title: 'Mover archivo',
    callToAction: 'Mover archivo',
  },
  change_file_name: {
    id: 'change_file_name',
    callToAction: 'Renombrar',
  },
  delete_file: {
    id: 'delete_file',
    callToAction: 'Eliminar',
  },
}

export const foldersActions = {
  change_folder_name: {
    id: 'change_folder_name',
    callToAction: 'Renombrar',
  },
  delete_folder: {
    id: 'delete_folder',
    callToAction: 'Eliminar',
  },
}

export const getValidationSchema = (elementType) => {
  if (elementType === 'folder') {
    return yup.object({
      name: yup.string().nullable().required(),
      worker_folder: yup.boolean().required(),
      worker_access: yup.boolean().required(),
    })
  }

  return yup.object({
    document_name: yup.string().nullable().required(),
  })
}

export const getActionTitle = (isOpenedFolder, actionType) => {
  if (isOpenedFolder) {
    if (actionType === 'move_file')
      return { title: 'Mover archivo', callToAction: 'Mover archivo' }
    if (actionType === 'change_file_name')
      return {
        title: 'Cambiar nombre del archivo',
        callToAction: 'Cambiar nombre',
      }
    if (actionType === 'delete_file')
      return { title: 'Eliminar archivo', callToAction: 'Eliminar archivo' }
  }

  if (actionType === 'change_folder_name')
    return {
      title: 'Cambiar nombre a la carpeta',
      callToAction: 'Cambiar nombre',
    }
  if (actionType === 'delete_folder')
    return { title: 'Eliminar carpeta', callToAction: 'Eliminar carpeta' }

  return {}
}

export const getActionModalInitialValues = (actionType) => {
  if (actionType === 'change_file_name') {
    return {
      document_name: null,
    }
  }

  if (actionType === 'change_folder_name') {
    return {
      name: null,
    }
  }

  if (actionType === 'move_file') {
    return {
      folder: null,
    }
  }

  return {}
}

export const getActionModalValidationSchema = (actionType) => {
  if (actionType === 'change_file_name') {
    return yup.object({
      document_name: yup.string().nullable().required(),
    })
  }

  if (actionType === 'change_folder_name') {
    return yup.object({
      name: yup.string().nullable().required(),
    })
  }

  if (actionType === 'move_file') {
    return yup.object({
      folder: yup.object().nullable().required(),
    })
  }

  return undefined
}

export const getActionModalMutationMethod = (actionType) => {
  if (actionType === 'delete_folder') return 'DELETE_FOLDER'

  if (actionType === 'delete_file') return 'DELETE_DOCUMENT'

  if (['change_file_name', 'move_file'].includes(actionType))
    return 'PUT_DOCUMENT'

  if (actionType === 'change_folder_name') return 'PUT_FOLDER'

  return null
}

export const CardIcon = ({
  fileIcon,
  deleteable,
  isAffiliationFolder,
  emptyFolder,
}) => {
  const iconData = () => {
    if (fileIcon) return { icon: fileText, alt: 'Documento agregado' }
    if (emptyFolder) return { icon: newFolder, alt: 'Carpeta sin archivos' }

    return !deleteable || isAffiliationFolder
      ? { icon: favFolder, alt: 'Carpeta con archivos esenciales' }
      : { icon: folder, alt: 'Carpeta con archivos adicionales' }
  }
  return (
    <Box
      component="img"
      loading="lazy"
      src={iconData().icon}
      alt={iconData().alt}
      width={42}
      height={42}
    />
  )
}

export const DropdownMenu = ({ popupState, optionsFiltered, options }) => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    })}
    // This is required to avoid the card's onClick method to be called when using the actions
    onClick={(event) => event.stopPropagation()}
  >
    <DropdownButton
      popupState={popupState}
      variant="text"
      color="default"
      options={options || optionsFiltered()}
      iconButton
      Icon={
        <IconButton
          size="large"
          sx={(theme) => ({
            backgroundColor: 'transparent !important',
            color: theme.palette.black.main,
            borderRadius: '50%',
          })}
          {...bindTrigger(popupState)}
        >
          <Icon name="ellipsis-vertical" />
        </IconButton>
      }
    />
  </Box>
)

export const ActionButton = ({ icon, action, title, showAsCard }) => {
  return (
    <Tooltip title={title} arrow>
      <IconButton
        size="large"
        sx={(theme) => ({
          backgroundColor: `${theme.palette.complementary1.main} !important`,
          ...(showAsCard && { backgroundColor: 'transparent !important' }),
        })}
        onClick={action}
      >
        <Icon name={icon} />
      </IconButton>
    </Tooltip>
  )
}

export const getLoadMoreLabel = (isLoading, hasNextPage, complement = '') => {
  if (isLoading) return `Cargando ${complement}`

  if (hasNextPage) return `Cargar más ${complement}`

  return `No hay más ${complement}`
}
