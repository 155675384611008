import { Box, tabClasses, tabsClasses, useMediaQuery } from '@mui/material'

import Tabs from 'components/UI/MaterialUI/Tabs/Tabs'

export default function HolisticPayrollTabs({
  activeTab,
  handleChangeTab,
  tabsConfig,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          margin: theme.spacing(7, 0),
        })}
      >
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          tabs={tabsConfig}
          variant={isMobile ? 'scrollable' : 'fullWidth'}
          scrollButtons={isMobile}
          sx={({ spacing, typography, palette }) => ({
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'palette.white.dark',
            padding: 0,
            [`& .${tabsClasses.indicator}`]: {
              display: 'none',
            },
            [`& .${tabsClasses.flexContainer}`]: {
              gap: spacing(2),
            },
            [`& .${tabClasses.root}`]: {
              display: 'flex',
              color: 'black.light',
              ...typography.lead2,
              borderRadius: '0.5rem !important',
              backgroundColor: 'white.main',
              padding: spacing(2, 3),
              '&:hover': {
                color: 'accent4.main',
              },
            },
            [`& .${tabClasses.selected}`]: {
              color: 'accent4.main',
              border: `2px solid ${palette.accent4.main}`,
              borderRadius: '0.5rem',
            },
          })}
        />
      </Box>
      {tabsConfig[activeTab]?.content}
    </>
  )
}
