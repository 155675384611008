import { forwardRef } from 'react'

import { Autocomplete, ListItemButton, OutlinedInput } from '@mui/material'

import Icon from 'components/UI/Icon'

const AutocompleteField = forwardRef(function AutocompleteFieldForwardRef(
  { id, name, options, value, ...props },
  ref
) {
  const {
    setValue,
    setTouched,
    placeholder,
    disabled,
    error,
    dataCy,
    ...restProps
  } = props

  const onChange = (_, newSelectedValue) => {
    setValue(newSelectedValue)
  }

  const onBlur = () => setTouched(name)

  const getOptionLabel = ({ name: optionName }) => {
    if (optionName && optionName !== '') return optionName

    return ''
  }

  const renderOption = (
    optionProps,
    { id: optionId, name: optionName },
    { selected }
  ) => {
    return (
      <ListItemButton
        selected={selected}
        {...optionProps}
        component="li"
        key={optionProps?.id}
      >
        {optionId === 'create' ? (
          <Icon name="plus" sx={{ fontSize: '1.5rem' }} />
        ) : null}
        {optionName}
      </ListItemButton>
    )
  }

  const renderInput = ({ InputProps, InputLabelProps, ...params }) => {
    return (
      <OutlinedInput
        {...params}
        {...InputProps}
        {...InputLabelProps}
        placeholder={placeholder}
        error={error}
        data-cy={dataCy}
      />
    )
  }

  const getOptionSelected = (option, newValue) =>
    option.id === newValue.id ? newValue : null

  return (
    <Autocomplete
      ref={ref}
      id={id}
      options={options || []}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      isOptionEqualToValue={getOptionSelected}
      openOnFocus
      autoHighlight
      {...restProps}
    />
  )
})

export default AutocompleteField
