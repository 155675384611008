import { format, parseISO, subMonths } from 'date-fns'
import esLocale from 'date-fns/locale/es/index.js'

import { Box, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { formatPeriodDateRange } from 'utils/dateTime'

const getDatesFormatted = (periodInformation, nextPayments) => {
  const { social_security: socialSecurity } = nextPayments
  const periodDate = formatPeriodDateRange(
    periodInformation?.initial_day,
    periodInformation?.end_day
  )

  const socialSecurityPaymentLimitDate = socialSecurity?.limit_date
    ? format(parseISO(socialSecurity.limit_date), 'MMMM yyyy', {
        locale: esLocale,
      })
    : 'fecha no disponible'

  const previousMonthToSSPaymentLimitDate = socialSecurity?.limit_date
    ? format(subMonths(parseISO(socialSecurity.limit_date), 1), 'MMMM yyyy', {
        locale: esLocale,
      })
    : 'fecha no disponible'

  return {
    periodDate,
    socialSecurityPaymentLimitDate,
    previousMonthToSSPaymentLimitDate,
  }
}

export const getNextPaymentType = (periodInformation, nextPayments) => {
  const {
    periodDate,
    socialSecurityPaymentLimitDate,
    previousMonthToSSPaymentLimitDate,
  } = getDatesFormatted(periodInformation, nextPayments)

  return {
    payroll_payments: {
      title: 'Pago total nómina',
      backgroundColor: 'accent4.light',
      icon: <Icon name="hand-coins" sx={{ fontSize: '1.5rem' }} />,
      tooltipTitle: 'Pago total nómina',
      tooltipDescription: 'Es la suma del pago neto de todas las personas.',
      descriptionDate: periodDate ? (
        <Typography variant="body2">
          <b>Periodo: </b>
          {periodDate}
        </Typography>
      ) : null,
    },
    social_security: {
      title: 'Seguridad social',
      backgroundColor: 'primary',
      icon: <Icon name="shield-plus" sx={{ fontSize: '1.5rem' }} />,
      tooltipTitle: 'Seguridad social',
      tooltipDescription: (
        <Box>
          <Box sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
            <Typography>
              <Typography component="span" variant="lead1">
                Corresponde a:{' '}
              </Typography>
              {previousMonthToSSPaymentLimitDate}
            </Typography>
            <Typography>
              <Typography component="span" variant="lead1">
                Para pagar en el mes:{' '}
              </Typography>
              {socialSecurityPaymentLimitDate}
            </Typography>
          </Box>
          Es la suma de: Pensión persona + Salud persona + F. Solidaridad
          Pensional + Pensión Empresa + Salud Empresa + Riesgos + Sena + ICBF +
          Caja Compensación.
        </Box>
      ),
      descriptionDate: (
        <Typography variant="body2">
          <b>Corresponde a: </b>
          {previousMonthToSSPaymentLimitDate}
        </Typography>
      ),
    },
    social_benefits: {
      title: 'Prestaciones sociales',
      backgroundColor: 'accent3.light',
      icon: <Icon name="file-pen-line" sx={{ fontSize: '1.5rem' }} />,
      tooltipTitle: 'Prestaciones sociales',
      tooltipDescription:
        'Es la suma de: Cesantías + Intereses a las Cesantías	+ Prima + Vacaciones.',
      descriptionDate: periodDate ? (
        <Typography variant="body2">
          <b>Periodo: </b>
          {periodDate}
        </Typography>
      ) : null,
    },
  }
}
