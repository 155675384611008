import * as yup from 'yup'

import { Box } from '@mui/material'

import { yupLocaleES } from 'utils/form'
import { cellphoneInput } from 'utils/regex'

import colombianPhoneNumber from 'assets/images/views/common/colombian_phone_number.png'

import FormField from '../FormField/Index'

yup.setLocale(yupLocaleES)

const validationSchema = yup
  .string()
  .nullable()
  .trim()
  .matches(
    cellphoneInput,
    'Debes ingresar un número celular válido para Colombia.'
  )
  .required()

const CellphoneField = ({
  name,
  disableValidate,
  optional = false,
  label,
  placeholder,
  showColombianCountryCode,
  ...props
}) => {
  const validate = async (value) => {
    try {
      await validationSchema.validate(value)
      return undefined
    } catch (error) {
      return !value ? 'Debes ingresar este campo.' : error.errors[0]
    }
  }

  return (
    <FormField
      name={name || 'cellphone'}
      label={label || 'Número celular'}
      placeholder={placeholder || 'Número celular'}
      variant="number"
      format="### ### ####"
      optional={optional}
      validate={!disableValidate ? validate : undefined}
      startAdornment={
        showColombianCountryCode ? (
          <Box
            component="img"
            src={colombianPhoneNumber}
            alt="Número de teléfono Colombiano"
            width={50}
            height={20}
          />
        ) : null
      }
      {...props}
    />
  )
}

export default CellphoneField
