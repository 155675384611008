import { Chip, Typography, alpha } from '@mui/material'

import {
  electronicPayrollStatusOptions,
  useCancelable,
  useDownloadFilesAction,
  useDownloadPdf,
} from 'components/Period/common/helpers'
import FilterButton from 'components/UI/Button/FilterButton'
import LinkButton from 'components/UI/Button/LinkButton'
import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import Link from 'components/UI/MaterialUI/Link'
import Tooltip from 'components/UI/Tooltip'

import { formatDateRange } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useNotifications from 'utils/hooks/useNotifications'

import * as routes from 'config/routes'

import ShowSummary from '../../ActionGroup/ShowSummary'
import { OtherIncomesCell } from './Columns/OtherIncomesCell'
import SendElectronicPayrollButton from './SendElectronicPayrollButton'
import PdfDownloadMenu from './pdfDownloadMenu'

const useEmployeesStatus = ({ rowData, openIndividualRejectedModal }) => {
  const { error_messages: errorMessages, status } = rowData

  const handleClickRejected = () => {
    openIndividualRejectedModal(errorMessages)
  }

  const statusOptions = {
    issued: {
      title: 'Emitida',
      color: 'primary',
      tooltip: null,
    },
    rejected: {
      title: 'Rechazada',
      color: 'error',
      tooltip: {
        title: 'NE Rechazada',
        description: (
          <LinkButton onClick={handleClickRejected}>
            Mira aquí el detalle
          </LinkButton>
        ),
      },
    },
    processing: {
      title: 'En proceso',
      color: 'complementary2',
      tooltip: null,
    },
    pending: {
      title: 'Por emitir',
      color: 'accent4',
      tooltip: null,
    },
    modified: {
      title: 'Modificada',
      color: 'accent4',
      tooltip: null,
    },
    canceled: {
      title: 'Anulada',
      color: 'error',
      tooltip: null,
    },
    replaced: {
      title: 'Reemplazada',
      color: 'accent3',
      tooltip: null,
    },
  }
  return statusOptions[status]
}

const ElectronicPayrollStatus = ({ openIndividualRejectedModal, rowData }) => {
  const { title, color, tooltip } = useEmployeesStatus({
    rowData,
    openIndividualRejectedModal,
  })

  return (
    <Tooltip title={tooltip?.title} description={tooltip?.description}>
      <Chip
        label={
          <Typography
            variant="body2"
            fontWeight={600}
            sx={(theme) => ({
              color: theme.palette[color].dark,
            })}
          >
            {title}
          </Typography>
        }
        sx={(theme) => ({
          backgroundColor: alpha(theme.palette[color].main, 0.2),
          width: '100%',
        })}
      />
    </Tooltip>
  )
}

export const getColumnsData = (openIndividualRejectedModal) => {
  return [
    {
      Header: 'Personas',
      accessor: 'worker.name',
      Cell: ({ row }) => (
        <Link
          to={routes.WORKER_SHOW(row.original.worker.id)}
          color="black.main"
        >
          {row.original.worker.name}
        </Link>
      ),
    },
    {
      Header: 'Fecha',
      accessor: 'electronic_period',
      Cell: ({ row }) => {
        const dateRange = formatDateRange(
          row.original.initial_day,
          row.original.end_day
        )
        const segmentedDate = dateRange.split('/')

        return `${segmentedDate[0]}/${segmentedDate[1]}`
      },
      customMinWidth: '7rem',
    },
    {
      Header: 'Novedades',
      accessor: 'incomes',
      Cell: ({ row }) => (
        <OtherIncomesCell
          index={row.index}
          electronicPayrollId={row.original.id}
          bonusIncomes={row.original.bonus_incomes}
          incomes={row.original.incomes}
          provisions={row.original?.provisions}
        />
      ),
      customMinWidth: '10rem',
    },
    {
      Header: 'Deducciones',
      accessor: 'deductions',
      Cell: ({ row }) => formatCurrency(row.original.deductions),
    },
    {
      Header: 'Total emitido',
      accessor: 'payment',
      Cell: ({ row }) => formatCurrency(row.original.payment),
    },
    {
      Header: 'Estado',
      accessor: 'status',
      Cell: ({ row }) => {
        return (
          <ElectronicPayrollStatus
            openIndividualRejectedModal={openIndividualRejectedModal}
            rowData={row.original}
          />
        )
      },
    },
    {
      accessor: 'worker.id_number',
    },
  ]
}

export const useActionsData = ({
  maintenance,
  disabledButtonSubmit,
  activeStatus,
  setActiveStatus,
  periodId,
  endFreeElectronicPayrollModal,
}) => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const { showSuccessMessage, showErrorMessage } = useNotifications()

  const downloadFilesAction = useDownloadFilesAction()
  const downloadPdfAction = useDownloadPdf({
    periodId,
    showLoadingModal,
    hideLoadingModal,
  })
  const cancelableAction = useCancelable({
    showLoadingModal,
    hideLoadingModal,
    showSuccessMessage,
    showErrorMessage,
  })

  return [
    (rowData) => ({
      id: 'history',
      tooltip: 'Ver historial',
      icon: <Icon name="eye" />,
      disabled: rowData.status === 'pending',
      buttonProps: {
        component: Link,
        to: routes.PERIOD_ELECTRONIC_PAYROLL_WORKER_HISTORY(
          periodId,
          rowData.worker.id
        ),
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'to_issued',
      tooltip: 'Emitir',
      icon: <Icon name="send" />,
      disabled: !rowData.options.to_issue || maintenance,
      onClick: endFreeElectronicPayrollModal.isNotValidCompany
        ? () =>
            endFreeElectronicPayrollModal.openEndFreeElectronicPayrollModal()
        : null,
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'download_files',
      tooltip: 'Descargar XML',
      icon: <Icon name="download" />,
      disabled: !rowData.options.download_files,
      onClick: () => downloadFilesAction.handleClick(rowData),
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'download_pdf',
      tooltip: 'Descargar PDF',
      icon: <Icon name="file-pen-line" />,
      onClick: () => downloadPdfAction.handleClick(rowData),
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    (rowData) => ({
      id: 'cancelable',
      tooltip: rowData.status === 'issued' ? 'Anular' : 'Eliminar',
      icon: <Icon name="trash-2" />,
      disabled: !rowData.options.cancelable || maintenance,
      onClick: () => cancelableAction.handleClick(rowData),
      buttonProps: {
        size: 'medium',
        color: 'complementary1',
      },
    }),
    {
      id: 'seeSummary',
      isFreeAction: true,
      Component: <ShowSummary />,
    },
    {
      id: 'PdfDownloadMenu',
      isFreeAction: true,
      Component: <PdfDownloadMenu />,
    },
    {
      id: 'toolbarActions',
      isFreeAction: true,
      Component: (
        <SendElectronicPayrollButton
          disabledButton={disabledButtonSubmit}
          periodId={periodId}
        />
      ),
    },
    {
      id: 'toolbarFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          options={electronicPayrollStatusOptions}
          defaultLabel="Todas"
          value={activeStatus}
          onChange={setActiveStatus}
        />
      ),
    },
  ]
}

export const getDataFilter = (data, status) => {
  const dataFilter =
    status === ''
      ? data
      : data.filter((electronicPayroll) => electronicPayroll.status === status)

  return dataFilter
}
