import { isValidElement } from 'react'
import * as yup from 'yup'

import { Chip, IconButton, Link, Tooltip } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import { useUser } from 'components/App/UserContext/useUser'
import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'

import logoAlegra from 'assets/images/logos/alegra/alegra_logo.svg'
import isotipoAlegra from 'assets/images/logos/alegra/isotipo_alegra.png'
import isotipoAEL from 'assets/images/logos/aportes_en_linea/isotipo_ael.png'
import logoAEL from 'assets/images/logos/aportes_en_linea/logo_aportes_en_linea.png'
import isotipoArus from 'assets/images/logos/arus/isotipo_arus.png'
import logoArus from 'assets/images/logos/arus/logo_arus_dark.png'

import * as routes from 'config/routes'

yup.setLocale(yupLocaleES)

export const getValidationSchema = (provider) => {
  if (provider === 'arus') {
    return yup.object({
      document_type: yup.string().nullable().required(),
      id_number: yup.string().when('document_type', {
        is: 'PA',
        then: () =>
          yup
            .string()
            .nullable()
            .matches(/^[a-zA-Z0-9]+$/)
            .required(),
        otherwise: () =>
          yup
            .string()
            .nullable()
            .matches(
              /^[0-9]+$/,
              'El tipo de documento seleccionado solo puede contener números.'
            )
            .required(),
      }),
      token: yup.string().nullable().required(),
    })
  }

  return yup.object({
    username: yup.string().nullable().required(),
    token: yup.string().nullable().required(),
  })
}

export const getInitialValues = (provider) => {
  if (provider === 'arus') {
    return {
      document_type: null,
      id_number: null,
      token: null,
    }
  }

  return {
    username: null,
    token: null,
  }
}

const providerLogo = {
  alegra: logoAlegra,
  aportes_en_linea: logoAEL,
  arus: logoArus,
}

export const tableActions = (
  openModal,
  deleteIntegration,
  navigate,
  confirm
) => {
  return [
    (rowData) => ({
      id: 'connect',
      icon: <Icon name="circle-check" />,
      tooltip: 'Conectar Integración',
      hidden: rowData.active,
      onClick: (clickData) => {
        openModal(clickData)
      },
      buttonProps: {
        'data-cy': `connect-${rowData.provider}-integration`,
      },
    }),
    (rowData) => ({
      id: 'goToSettings',
      icon: <Icon name="settings" />,
      tooltip: 'Ir a la configuracion',
      onClick: () => navigate(routes.SETTINGS_INTEGRATION_ALEGRA_ACCOUNTING()),
      hidden: !rowData.active || rowData.provider !== 'alegra',
      buttonProps: {
        'data-cy': `open-${rowData.provider}-integration-settings`,
      },
    }),
    (rowData) => ({
      id: 'goToHistory',
      icon: <Icon name="list" />,
      tooltip: 'Ir al historial de planillas',
      onClick: () =>
        navigate(routes.SETTINGS_SOCIAL_SECURITY_INDEX(), {
          state: {
            provider: rowData.provider,
          },
        }),
      hidden:
        !rowData.active ||
        !['aportes_en_linea', 'arus'].includes(rowData.provider),
    }),
    (rowData) => ({
      id: 'disconnect',
      icon: <Icon name="x" />,
      tooltip: 'Desconectar Integración',
      onClick: (clickData) => {
        confirm({
          type: 'warning',
          okText: 'Desconectar',
          onOk: () => deleteIntegration(clickData.id),
        })
      },
      hidden: !rowData.active,
      buttonProps: {
        'data-cy': `disconnect-${rowData.provider}-integration`,
      },
    }),
  ]
}

export const ActionButton = ({ action, completeRow }) => {
  const { distributor } = useUser()

  const isAlegraDistributorAndIntegration =
    ['alegra', 'alegra_nomina', 'alegra_old'].includes(distributor) &&
    completeRow.provider === 'alegra'

  return !action.hidden ? (
    <>
      <Tooltip title={action.tooltip}>
        <span>
          <IconButton
            sx={{
              width: '2rem',
              height: '2rem',
            }}
            disabled={action.disabled}
            onClick={() => action.onClick(completeRow)}
            color="complementary1"
            {...action.buttonProps}
          >
            {isValidElement(action.icon) ? action.icon : <action.icon />}
            {action.tooltip === 'Conectar Integración' &&
            !isAlegraDistributorAndIntegration ? (
              <PremiumFeatureIcon isTableActionIcon />
            ) : null}
          </IconButton>
        </span>
      </Tooltip>
    </>
  ) : null
}

export default ActionButton

export const columnsData = [
  {
    Header: 'Integración',
    accessor: 'provider',
    Cell: ({ row }) => {
      const { provider } = row.original
      return ['aportes_en_linea', 'alegra', 'arus'].includes(provider) ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={providerLogo[provider]}
            alt={`Logo ${provider}`}
            style={{ height: '25px' }}
          />
        </div>
      ) : (
        provider
      )
    },
    alignCell: 'center',
    alignHeader: 'center',
    customWidth: '200px',
  },
  {
    accessor: 'category',
    Header: 'Categoría',
    Cell: ({ row }) =>
      row.original.provider === 'alegra' ? 'Contabilidad' : 'Operador PILA',
  },
  {
    Header: 'Estado',
    accessor: 'active',
    Cell: ({ row }) => (
      <Chip label={row.original.active ? 'Activo' : 'Inactiva'} />
    ),
    customWidth: 'fit-content',
    alignHeader: 'center',
    alignCell: 'center',
  },
  {
    Header: 'Notas',
    accessor: 'inactivation_reason',
    emptyValue: 'N/A',
  },
  {
    Header: 'Última sincronización',
    accessor: 'last_sync',
    emptyValue: '-',
  },
]

export const BoldName = ({ children }) => (
  <span style={{ fontWeight: 'bold' }}>{children}</span>
)

export const integrationsProviders = {
  alegra: {
    name: 'Alegra',
    logo: isotipoAlegra,
    logoWidth: 49,
    logoHeight: 32,
    signUpLink: 'https://app.alegra.com/user/register',
    usernameTooltip: 'Tu usuario de acceso en el portal de Alegra',
    passwordTooltip:
      'Para conocerlo ingresa en Alegra a Configuración/Integraciones con otros sistemas/Integración manual - API',
    usernameLabel: 'Usuario',
    passwordLabel: 'Token',
    description: (
      <>
        Ingresa aquí la información de tu cuenta de <BoldName>Alegra</BoldName>{' '}
        para activar la integración con tu cuenta de Aleluya.
        <br />
        <br />
        <Link
          fontWeight={600}
          color="accent4.main"
          target="_blank"
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/integración-contable-con-alegra"
        >
          Haz clic aquí
        </Link>{' '}
        para conocer más sobre como realizar esta integración y configurar tu
        usuario.
      </>
    ),
  },
  aportes_en_linea: {
    name: 'Aportes en línea',
    logo: isotipoAEL,
    logoWidth: 39,
    logoHeight: 38,
    signUpLink: 'https://pymes.aportesenlinea.com/Registro',
    usernameTooltip: 'Tu usuario de acceso en el portal de Aportes en línea',
    passwordTooltip: 'Contraseña de acceso al portal ',
    usernameLabel: 'Usuario',
    passwordLabel: 'Contraseña',
    description: (
      <>
        Para comenzar a pagar tu planilla PILA sin salir de Aleluya, necesitamos
        conectar Aleluya con tu cuenta de <BoldName>Aportes en Línea.</BoldName>
        <br />
        <br />
        Recuerda debes tener configurados los roles de nómina y tesorería dentro
        del portal de <BoldName>Aportes en Línea</BoldName>,{' '}
        <Link
          fontWeight={600}
          color="accent4.main"
          target="_blank"
          href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-conecto-mi-cuenta-de-aportes-en-línea-para-pagar-la-seguridad-social-sin-salir-de-aleluya"
        >
          haz clic aquí
        </Link>{' '}
        para conocer más sobre como realizar esta integración y configurar tu
        usuario.
      </>
    ),
  },
  arus: {
    name: 'SuAporte',
    logo: isotipoArus,
    logoWidth: 41,
    logoHeight: 32,
    signUpLink: 'https://www.suaporte.com.co/sso/#/new-account',
    description: (
      <>
        Para comenzar a pagar tu planilla PILA sin salir de Aleluya, necesitamos
        conectar Aleluya con tu cuenta de <BoldName>SuAporte.</BoldName>
        <br />
        <br />
        Recuerda que debes tener configurados los roles de nómina y tesorería
        dentro del portal de <BoldName>SuAporte</BoldName>,{' '}
        <Link
          fontWeight={600}
          color="accent4.main"
          target="_blank"
          href="https://ayuda.aleluya.com/portal/es/kb/articles/paga-tu-seguridad-social-con-suaporte-de-arus-sin-salir-de-aleluya"
        >
          haz clic aquí
        </Link>{' '}
        para conocer más sobre cómo realizar esta integración y configurar tu
        usuario.
      </>
    ),
  },
}
