import { useState } from 'react'

import { Box } from '@mui/material'

import Icon from 'components/UI/Icon'
import Table from 'components/UI/Table/Table'

import useDownloadURI from 'utils/hooks/useDownloadURI'

import { getFreetimeRequests } from 'services/freetime/freetime'

import { getColumnsData, getEmptyStateTitle, useTableActions } from './helpers'

const IndexTable = () => {
  const downloadURI = useDownloadURI()
  const [freeTimeActiveStatus, setFreeTimeActiveStatus] = useState('') // Empty fetches all the request
  const actions = useTableActions(freeTimeActiveStatus, setFreeTimeActiveStatus)

  const fetchRequests = (pageIndex, search) => {
    return {
      queryKey: ['getAllRequests', pageIndex, search, freeTimeActiveStatus],
      queryFunction: () =>
        getFreetimeRequests({
          page: pageIndex + 1,
          search,
          filter: freeTimeActiveStatus,
        }),
    }
  }

  const handleDownloadEvidence = (documentLink) => {
    downloadURI(documentLink)
  }

  const columns = getColumnsData(handleDownloadEvidence)

  return (
    <Box
      sx={{
        gridColumn: '1 / -1',
      }}
    >
      <Table
        data={fetchRequests}
        columns={columns}
        actions={actions}
        options={{
          version: {
            toolbar: 'v2',
          },
          customQueryFetch: 'novelty_requests',
          emptyState: {
            title: getEmptyStateTitle(freeTimeActiveStatus),
            icon: (
              <Icon
                name="calendar-days"
                sx={(theme) => ({
                  fontSize: '4rem',
                  color: theme.palette.primary.dark,
                })}
              />
            ),
            hideDescription: true,
          },
          customActionsWidth: '12.5rem',
          alignActionsCell: 'center',
        }}
      />
    </Box>
  )
}

export default IndexTable
