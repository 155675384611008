import useLocalStorage from '@rehooks/local-storage'
import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import DisabilityClaimsTableEmptyState from 'components/HolisticPayroll/Commons/DisabilityClaimsTableEmptyState'
import {
  getColumnsData,
  useTableAction,
} from 'components/HolisticPayroll/helpers'
import Table from 'components/UI/Table/Table'

import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'

import { updateDisabilityClaimsData } from 'services/HolisticPayroll/CompanyService'
import { getDisabilityClaimsShow } from 'services/organizer/holisticPayrollServices'

import { useOrganizerDisabilityClaimsModals } from './Commons/useOrganizerDisabilityClaimsModals'
import { OrganizerDisabilityClaimsContext } from './DisabilityClaimsShow'
import { showTableValidationSchema } from './helpers'

export default function DisabilityClaimsShowTable() {
  const {
    activeStatus,
    claimsTotals,
    setActiveStatus,
    setHeaderData,
    setClaimsTotals,
  } = useContext(OrganizerDisabilityClaimsContext)
  const [, setCompanyId] = useLocalStorage('company_id')
  const { openClaimNoveltyModal, openUploadDisabilityPaymentSupportModal } =
    useOrganizerDisabilityClaimsModals()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const location = useLocation()
  const isOrganizerRoute = location.pathname.includes('organizer')
  const claimDetailId = location.state?.claimDetailId
  const tableActions = useTableAction({
    activeStatus,
    setActiveStatus,
    claimsTotals,
    claimsDetailId: claimDetailId,
  })

  const refreshClaims = () => {
    queryClient.invalidateQueries('organizerDisabilityClaims')
  }

  const fetchClaims = (pageIndex, search) => {
    return {
      queryKey: ['organizerDisabilityClaims', pageIndex, search, activeStatus],
      queryFunction: () =>
        getDisabilityClaimsShow({
          searchParams: {
            page: pageIndex + 1,
            search,
            per_page: 20,
            filter: activeStatus,
          },
          claimDetailId,
        }),
      queryOptions: {
        onSuccess: ({ data }) => {
          setHeaderData(data.header_data)
          setClaimsTotals(data.totals)
          setCompanyId(data.header_data.company_id)
        },
      },
    }
  }

  const handleUpdateClaimsData = (oldClaimData, newClaimData) => {
    const dirtyValues = getDirtyValues(
      oldClaimData,
      newClaimData,
      showTableValidationSchema.fields
    )

    if (!isObjectEmpty(dirtyValues)) {
      if (newClaimData.status === 'in_progress') {
        const correctedClaimData = new FormData()

        correctedClaimData.append('status', newClaimData.status)

        if (dirtyValues.claim_value) {
          correctedClaimData.append('value', dirtyValues.claim_value)
        }

        return {
          data: {
            claimsDetailId: claimDetailId,
            claimNoveltyId: newClaimData.id,
            correctedClaimData: correctedClaimData,
          },
          mutationFunction: updateDisabilityClaimsData,
          mutateOptions: {
            onSuccess: () => {
              refreshClaims()
              showSuccessMessage(
                'La reclamación ha sido actualizada correctamente'
              )
            },
          },
        }
      }

      if (newClaimData.status === 'claimed') {
        return openUploadDisabilityPaymentSupportModal({
          claimsDetailId: claimDetailId,
          claimNoveltyId: newClaimData.id,
          claimData: newClaimData,
        })
      }

      return openClaimNoveltyModal(newClaimData)
    }

    return {}
  }

  const columnsData = getColumnsData(isOrganizerRoute)

  return (
    <Table
      data={fetchClaims}
      columns={columnsData}
      actions={tableActions}
      options={{
        minWidth: '67.5rem',
        customQueryFetch: 'novelty_claims',
        emptyStateComponent: (
          <DisabilityClaimsTableEmptyState textOption="organizer" />
        ),
        alignActionsCell: 'center',
        alignActionsHeader: 'center',
        version: {
          toolbar: 'v2',
        },
      }}
      editable={{
        showTableValidationSchema,
        onUpdateRow: handleUpdateClaimsData,
        hideDelete: true,
        isEditable: (rowData) => rowData.status !== 'claimed',
      }}
    />
  )
}
