import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { PayrollNoveltyButton } from 'components/Period/common/helpers'

import { getCompanyId } from 'utils/company'

import { NoveltyTypeModal } from '../Modals/NoveltyTypeModal'
import { OtherIncomesModal } from '../Modals/OtherIncomesModal'
import { ProvisionsAdjustmentModal } from '../Modals/ProvisionsAdjustmentModal'
import { useNoveltyTypeModal } from '../Modals/useNoveltyTypeModal'
import { useOtherIncomesModal } from '../Modals/useOtherIncomesModal'
import { useProvisionsAdjustmentModal } from '../Modals/useProvisionsAdjustmentModal'

export function OtherIncomesCell({
  bonusIncomes,
  index,
  electronicPayrollId,
  incomes,
  provisions,
}) {
  const queryClient = useQueryClient()
  const params = useParams()

  const { noveltyTypeModalState, openNoveltyTypeModal, closeNoveltyTypeModal } =
    useNoveltyTypeModal()
  const {
    otherIncomesModalState,
    openOtherIncomesModal,
    closeOtherIncomesModal,
  } = useOtherIncomesModal()
  const {
    provisionsAdjustmentModalState,
    openProvisionsAdjustmentModal,
    closeProvisionsAdjustmentModal,
  } = useProvisionsAdjustmentModal()

  const invalidateElectronicPayrollQuery = () => {
    queryClient.invalidateQueries([
      'electronicPayrollPeriod',
      getCompanyId(),
      params.periodId,
      '',
    ])
  }

  return (
    <>
      <PayrollNoveltyButton
        buttonId={`overtime_${index}`}
        bonusIncomes={bonusIncomes}
        incomes={incomes}
        provisions={provisions}
        onClick={() =>
          openNoveltyTypeModal({
            electronicPayrollId,
            bonusIncomes,
            provisions,
          })
        }
      />

      {noveltyTypeModalState.open ? (
        <NoveltyTypeModal
          state={noveltyTypeModalState}
          handleClose={closeNoveltyTypeModal}
          openOtherIncomesModal={openOtherIncomesModal}
          openProvisionsAdjustmentModal={openProvisionsAdjustmentModal}
        />
      ) : null}

      {otherIncomesModalState.open ? (
        <OtherIncomesModal
          state={otherIncomesModalState}
          handleClose={closeOtherIncomesModal}
          invalidateElectronicPayrollQuery={invalidateElectronicPayrollQuery}
        />
      ) : null}

      {provisionsAdjustmentModalState.open ? (
        <ProvisionsAdjustmentModal
          state={provisionsAdjustmentModalState}
          handleClose={closeProvisionsAdjustmentModal}
          invalidateElectronicPayrollQuery={invalidateElectronicPayrollQuery}
        />
      ) : null}
    </>
  )
}
