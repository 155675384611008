import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, CircularProgress, Link, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import Alert from 'components/UI/MaterialUI/Alert/Alert'

import { getCompanyId } from 'utils/company'
import { isObjectEmpty } from 'utils/general'
import useElectronicPayrollConfigurationService from 'utils/hooks/ElectronicPayroll/electronicPayrollConfigurationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import messages from 'messages/electronic_payroll'

import TutorialCard from '../TutorialCard'
import { tutorialCardsActivationDian, useStatusMessages } from '../helpers'

const DianActivation = ({ isEditting }) => {
  const { validateForm, values, setFieldValue, errors } = useFormikContext()
  const { listenInBackground, refreshCompany } = useUser()
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  const companyId = getCompanyId()
  const { showInfoMessage, showSuccessMessage, showErrorMessage } =
    useNotifications()
  const [showMesaggeAlert, setShowMesaggeAlert] = useState(false)
  const isComponentMounted = useRef(false)
  const { closeSnackbar } = useSnackbar()
  const { showStatusMessages } = useStatusMessages()
  const electronicPayrollConfigurationCache = queryClient.getQueryData([
    'electronicPayrollStatus',
    companyId,
  ])
  const electronicPayrollConfigurationData =
    electronicPayrollConfigurationCache?.data
  const isResponseInProgress = ['waiting_response', 'pending_to_send'].includes(
    electronicPayrollConfigurationData?.government_enabled_status
  )

  const isCompanyAuthorized =
    electronicPayrollConfigurationData?.government_enabled_status ===
    'authorized'
  const isCompanyUnauthorized = ['rejected', 'failed', 'unauthorized'].includes(
    electronicPayrollConfigurationData?.government_enabled_status
  )
  const errorsLength = Object.keys(errors || {}).length

  const { electronicPayrollConfigurationMutation } =
    useElectronicPayrollConfigurationService({
      serviceParams: {
        queryKey: ['electronicPayrollStatus', companyId],
      },
      queryOptions: {
        enabled: !electronicPayrollConfigurationCache,
        onSuccess: ({ data }) => {
          setFieldValue('test_set_code', data.test_set_code)
        },
      },
    })

  useEffect(() => {
    isComponentMounted.current = true
    return () => {
      isComponentMounted.current = false
    }
  })

  useEffect(() => {
    if (electronicPayrollConfigurationData) {
      setFieldValue(
        'test_set_code',
        electronicPayrollConfigurationData?.test_set_code
      )
    }
  }, [electronicPayrollConfigurationData, setFieldValue])

  const electronicPayrollStatusHandler = (websocketResult, connectionRef) => {
    if (websocketResult) {
      if (websocketResult.errors) {
        handleError(websocketResult.errors)
        connectionRef.off('value')
      } else {
        if (websocketResult.status === 'authorized') {
          refreshCompany()
          if (isComponentMounted.current) {
            setShowMesaggeAlert(true)
          }
          closeSnackbar('electronicPayrollActivationInProgress')
          closeSnackbar('electronicPayrollActivationUnauthorized')
          showSuccessMessage(
            'Listo, tu empresa quedó habilitada para emitir Nómina Electrónica',
            {
              persist: true,
              preventDuplicate: true,
            }
          )
        }

        if (
          ['waiting_response', 'pending_to_send'].includes(
            websocketResult.status
          )
        ) {
          showInfoMessage(
            'Ya enviamos tu set de pruebas a la DIAN. Su respuesta puede tardar hasta 30 minutos y te confirmaremos por correo una vez estés habilitado.',
            {
              persist: true,
              key: 'electronicPayrollActivationInProgress',
              preventDuplicate: true,
            }
          )
        }

        if (websocketResult.status === 'unauthorized') {
          if (isComponentMounted.current) {
            setShowMesaggeAlert(true)
          }
          closeSnackbar('electronicPayrollActivationInProgress')
          showErrorMessage('Error al enviar el set de pruebas', {
            key: 'electronicPayrollActivationUnauthorized',
            preventDuplicate: true,
          })
        }
        queryClient.invalidateQueries(['electronicPayrollStatus', companyId])
      }
    }
  }

  const handleSendTestSetId = async () => {
    const formStatus = await validateForm()

    if (isObjectEmpty(formStatus)) {
      if (showMesaggeAlert) {
        setShowMesaggeAlert(false)
      }
      electronicPayrollConfigurationMutation.mutate(
        {
          mutationMethod: 'PATCH',
          configuration: {
            test_set_code: values.test_set_code,
          },
        },
        {
          onSuccess: ({ data }) => {
            // This is for E2E testing purposes
            if (window.Cypress) {
              showStatusMessages(data, () => setShowMesaggeAlert(true))
              return
            }
            if (isEditting) {
              queryClient.invalidateQueries([
                'electronicPayrollStatus',
                companyId,
              ])

              if (data.government_enabled_status) {
                setShowMesaggeAlert(true)
                if (['authorized'].includes(data.government_enabled_status)) {
                  showSuccessMessage(
                    'Listo, tu empresa quedó habilitada para emitir nómina electrónica',
                    {
                      persist: true,
                      preventDuplicate: true,
                    }
                  )
                } else if (
                  ['rejected', 'failed'].includes(
                    data.government_enabled_status
                  )
                ) {
                  showErrorMessage(
                    'Error al enviar el set de pruebas, revisa el testSetID e intenta nuevamente',
                    {
                      key: 'electronicPayrollActivationUnauthorized',
                      preventDuplicate: true,
                    }
                  )
                }
              }
            } else {
              listenInBackground(
                `electronic_payroll_configurations/${electronicPayrollConfigurationData?.id}/government_enabled_status`,
                electronicPayrollStatusHandler
              )
            }
          },
        }
      )
    }
  }

  const statusStep = {
    progress: 'Enviando Set de pruebas',
    authorized: 'La Dian aprobó el set de pruebas',
    rejected: 'Hubo un error enviando el set de pruebas',
  }

  return (
    <>
      <Typography variant="h5" color="black" gutterBottom>
        Proceso de habilitación para emitir Nómina Electrónica
      </Typography>
      <Typography
        variant="body1"
        sx={(theme) => ({
          marginBottom: theme.spacing(2.5),
        })}
      >
        <>
          A continuación te presentamos una guía para que realices tu proceso de
          habilitación de forma simple. <b>¿Necesitas ayuda?</b>, Te invitamos a
          leer{' '}
          <Link
            variant=""
            href={messages.ELECTRONIC_PAYROLL_CONFIGURATION_ARTICLE}
            target="_blank"
            underline="hover"
          >
            este artículo
          </Link>
          .
        </>
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: theme.spacing(3.5),
        })}
      >
        {tutorialCardsActivationDian.map((tutorialCard, index) => (
          <TutorialCard
            key={tutorialCard.id}
            id={tutorialCard.id}
            tutorialNumber={tutorialCard.number}
            title={tutorialCard.title}
            description={tutorialCard.description}
            dataCy={`tutorial-card-${index + 1}`}
            icon={tutorialCard.icon}
            backgroundColor={tutorialCard.backgroundColor}
            cardTitle={tutorialCard.cardTitle}
          >
            {tutorialCard.content ? (
              <Box
                sx={(theme) => ({
                  display: 'grid',
                  gridTemplateColumns: '1fr 240px',
                  alignItems: errorsLength ? 'center' : 'end',
                  gap: theme.spacing(4),
                  marginTop: theme.spacing(3),
                  [theme.breakpoints.down('lg')]: {
                    gridTemplateColumns: '1fr',
                  },
                })}
              >
                <FormField
                  name="test_set_code"
                  label="Código TestSetID"
                  placeholder="Ingresa el código TestSetID"
                  margin="none"
                  optional={false}
                />
                <Button
                  variant="outlined"
                  sx={{
                    width: '100%',
                    backgroundColor: 'transparent',
                  }}
                  onClick={handleSendTestSetId}
                  loading={electronicPayrollConfigurationMutation.isLoading}
                  disabled={isResponseInProgress}
                >
                  {isCompanyAuthorized
                    ? 'Reenviar set de pruebas'
                    : 'Enviar set de pruebas'}
                </Button>
              </Box>
            ) : null}
            {(isResponseInProgress ||
              electronicPayrollConfigurationMutation.isLoading) &&
            tutorialCard.number === 2 ? (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginTop: theme.spacing(1),
                  gap: theme.spacing(1.375),
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(0.25),
                  })}
                >
                  <Icon
                    name="circle-check"
                    fontSize="small"
                    sx={(theme) => ({
                      color: theme.palette.primary.main,
                    })}
                  />
                  <Typography variant="small">{statusStep.progress}</Typography>
                </Box>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(0.25),
                  })}
                >
                  <CircularProgress size={15} />
                  <Typography variant="small">
                    Aceptación del Set de pruebas
                  </Typography>
                </Box>
              </Box>
            ) : null}
            {isCompanyAuthorized &&
            showMesaggeAlert &&
            tutorialCard.number === 2 ? (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(0.25),
                })}
              >
                <Icon
                  name="circle-check"
                  fontSize="small"
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                  })}
                />
                <Typography variant="small">{statusStep.authorized}</Typography>
              </Box>
            ) : null}
            {isCompanyUnauthorized &&
            showMesaggeAlert &&
            tutorialCard.number === 2 ? (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(0.25),
                })}
              >
                <Icon
                  name="circle-x"
                  fontSize="small"
                  sx={(theme) => ({
                    color: theme.palette.error.main,
                  })}
                />
                <Typography variant="small">{statusStep.rejected}</Typography>
              </Box>
            ) : null}
          </TutorialCard>
        ))}
        {isResponseInProgress ||
        electronicPayrollConfigurationMutation.isLoading ? (
          <Alert
            icon={false}
            header="¡Tu habilitación está en proceso!"
            severity="info"
            sx={(theme) => ({
              width: '100%',
              [theme.breakpoints.up('lg')]: {
                width: '41.75rem',
              },
            })}
          >
            <Typography variant="body2">
              Actualmente estamos trabajando junto a la DIAN para realizar la
              habilitación de tu Nómina Electrónica (Enviando tu TESTSETID)
              normalmente la DIAN puede tomar hasta 30min en realizar esta
              validación, te estaremos notificando por este medio y por correo
              electrónico una vez esté lista.
            </Typography>
          </Alert>
        ) : null}
        {isCompanyAuthorized && showMesaggeAlert ? (
          <Alert
            icon={false}
            header="¡Has sido habilitado exitosamente!"
            severity="success"
            sx={(theme) => ({
              width: '100%',
              [theme.breakpoints.up('lg')]: {
                width: '41.75rem',
              },
            })}
          >
            <Typography variant="body2">
              Ya estás listo para emitir tu Nómina Electrónica con Aleluya ante
              la DIAN
            </Typography>
          </Alert>
        ) : null}
        {isCompanyUnauthorized && showMesaggeAlert ? (
          <Alert
            icon={false}
            header="¡Parece que ha ocurrido un error!"
            severity="error"
            sx={(theme) => ({
              width: '100%',
              [theme.breakpoints.up('lg')]: {
                width: '41.75rem',
              },
            })}
          >
            <Typography variant="body2">
              Verifica que tu TesSetID sea correcto e intenta de nuevo el
              proceso. la DIAN encontró un error en el set de pruebas puedes{' '}
              <Link
                variant=""
                href={messages.ELECTRONIC_PAYROLL_BLOG}
                target="_blank"
                underline="hover"
              >
                leer este articulo
              </Link>{' '}
              que te ayudará a verificar esta información
            </Typography>
          </Alert>
        ) : null}
      </Box>
    </>
  )
}

export default DianActivation
