import { disabilityClaimsStatus } from 'components/HolisticPayroll/helpers'

export const getStepperData = ({
  status,
  claimedAt,
  createdAt,
  reviewedAt,
}) => {
  const isClaimed = status === 'claimed'

  return [
    {
      id: 'claim_created',
      title: 'Inicio de la reclamación',
      updated_at: createdAt,
      completed: true,
      status: null,
      bagde: null,
      disabilityClaimsStatus: null,
    },
    {
      id: 'claim_study',
      title: 'Estudio de reclamación',
      updated_at: ['claimed', 'in_progress'].includes(status)
        ? reviewedAt
        : null,
      completed: isClaimed,
      status: status,
      bagde: ['claimed', 'in_progress'].includes(status) ? true : false,
      disabilityClaimsStatus,
    },
    {
      id: 'money_sent',
      title: 'Envío del dinero',
      updated_at: isClaimed ? claimedAt : null,
      completed: isClaimed,
      status: status,
      bagde: isClaimed,
      disabilityClaimsStatus,
    },
  ]
}

export const getDisabilityClaimsDocuments = ({
  status,
  claimSupport,
  paymentSupport,
}) => {
  const isClaimed = status === 'claimed'

  return [
    {
      id: 'claim_support_file',
      iconName: 'document-coin',
      backgroundColor: (theme) => theme.palette.accent3.main,
      cardTitle: 'Soporte de incapacidad',
      hasDocument: !!claimSupport,
      documentUrl: claimSupport,
    },
    {
      id: 'payment_support',
      iconName: 'refund',
      backgroundColor: (theme) => theme.palette.complementary2.main,
      cardTitle: 'Soporte de pago',
      hasDocument: !!paymentSupport && isClaimed,
      documentUrl: paymentSupport,
    },
  ]
}
