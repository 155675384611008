import { Typography } from '@mui/material'

import calculator from 'assets/images/three-dimensional-icons/calculator.png'
import chart from 'assets/images/three-dimensional-icons/chart.png'
import copy from 'assets/images/three-dimensional-icons/copy.png'
import dollar from 'assets/images/three-dimensional-icons/dollar.png'
import favFolder from 'assets/images/three-dimensional-icons/fav-folder.png'
import fileFav from 'assets/images/three-dimensional-icons/file-fav.png'
import filePlus from 'assets/images/three-dimensional-icons/file-plus.png'
import mail from 'assets/images/three-dimensional-icons/mail.png'
import mouse from 'assets/images/three-dimensional-icons/mouse.png'
import thumbUp from 'assets/images/three-dimensional-icons/thumb-up.png'

export const endFreePayrollModalInfo = [
  {
    id: 'whatsappTicket',
    backgroundColor: 'accent2.light',
    text: (
      <Typography>
        Envía <b>colilla de pago</b> por <b>Whatsapp</b> y correo electrónico.
      </Typography>
    ),
    icon: mail,
    alt: 'Envía las colillas de pago',
  },
  {
    id: 'payslipsSummary',
    backgroundColor: 'complementary2.light',
    text: (
      <Typography>
        Descarga tu <b>Resumen de nómina en excel</b> con todo detallado.
      </Typography>
    ),
    icon: fileFav,
    alt: 'Descarga el resumen de nómina',
  },
  {
    id: 'socialSecurityPayment',
    backgroundColor: 'accent3.light',
    text: (
      <Typography>
        <b>Paga tu planilla PILA</b> en segundos y evita multas con la UGPP.
      </Typography>
    ),
    icon: dollar,
    alt: 'Paga tu planilla PILA',
  },
]

export const endFreeElectronicPayrollModalInfo = [
  {
    id: 'sendElectronicPeyroll',
    backgroundColor: 'accent2.light',
    text: (
      <Typography>
        <b>Emite nómina electrónica</b> de todas tus personas.
      </Typography>
    ),
    icon: favFolder,
    alt: 'Emite tu nómina electrónica',
  },
  {
    id: 'graphicRepresentation',
    backgroundColor: 'complementary2.light',
    text: (
      <Typography>
        Descarga la <b>Representación gráfica</b> de tu emisión.
      </Typography>
    ),
    icon: chart,
    alt: 'Descarga la representación gráfica',
  },
  {
    id: 'okDian',
    backgroundColor: 'accent3.light',
    text: (
      <Typography>
        <b>Cumple con la DIAN</b> y sálvate de multas.
      </Typography>
    ),
    icon: thumbUp,
    alt: 'Cumple tus deberes con la DIAN',
  },
]

export const endFreeWorkerModalInfo = [
  {
    id: 'incomesCertification',
    backgroundColor: 'accent2.light',
    text: (
      <Typography>
        <b>Descarga certificados de ingresos y retenciones</b> de todas tus
        personas.
      </Typography>
    ),
    icon: fileFav,
    alt: 'Descarga tus certificados',
  },
  {
    id: 'workCertificarion',
    backgroundColor: 'complementary2.light',
    text: (
      <Typography>
        Genera <b>Certificados laborales</b> sin enredos.
      </Typography>
    ),
    icon: copy,
    alt: 'Genera tus certificados',
  },
  {
    id: 'laborSettlement',
    backgroundColor: 'accent3.light',
    text: (
      <Typography>
        <b>Liquidaciones laborales automáticas</b> en un dos por tres!
      </Typography>
    ),
    icon: calculator,
    alt: 'Realiza tus liquidaciones laborales',
  },
]

export const endFreeSocialBenefitsModalInfo = [
  {
    id: 'automaticSocialBenefits',
    backgroundColor: 'accent2.light',
    text: (
      <Typography>
        Prima, intereses a las cesantías y cesantías <b>automáticas.</b>
      </Typography>
    ),
    icon: filePlus,
    alt: 'Cálculos automáticos de las prestaciones sociales',
  },
  {
    id: 'oneClicArus',
    backgroundColor: 'complementary2.light',
    text: (
      <Typography>
        Paga tus cesantías con <b>ARUS a un clic!</b>
      </Typography>
    ),
    icon: mouse,
    alt: 'Pagos a un clic',
  },
  {
    id: 'electronicPayrollSocialBenefits',
    backgroundColor: 'accent3.light',
    text: (
      <Typography>
        <b>Emite nómina electrónica</b> de tus prestaciones sociales.
      </Typography>
    ),
    icon: copy,
    alt: 'Emite nómina electrónica',
  },
]
