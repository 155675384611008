import { Box, IconButton, Typography, useMediaQuery } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import useDownloadURI from 'utils/hooks/useDownloadURI'
import useFileName from 'utils/hooks/useFileName'

const DownloadFile = ({ file }) => {
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const downloadURI = useDownloadURI()
  const fileName = useFileName(file)

  const handleDownload = () => {
    downloadURI(file)
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(2),
        border: `1px dashed ${theme.palette.primary.light}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1, 2),
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
        width: '100%',
      })}
    >
      <Typography variant="small">
        {fileName || 'El documento no está disponible'}
      </Typography>
      {smUp ? (
        <Button size="small" onClick={handleDownload} disabled={!file}>
          Descargar
        </Button>
      ) : (
        <IconButton onClick={handleDownload} disabled={!file}>
          <Icon name="download" />
        </IconButton>
      )}
    </Box>
  )
}

export default DownloadFile
