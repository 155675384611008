import { Box, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

const RequiredDataItem = ({ label }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
      })}
    >
      <Icon name="circle-check" />
      <Typography variant="body1" color="black.light">
        {label}
      </Typography>
    </Box>
  )
}

export default RequiredDataItem
