import { Chip, Typography, alpha } from '@mui/material'

import FilterButton from 'components/UI/Button/FilterButton'
import Icon from 'components/UI/Icon'
import Tooltip from 'components/UI/Tooltip'

import { isOrganizer } from 'utils/auth'
import { roles, rolesOptions, rolesOptionsWithoutWorker } from 'utils/worker'

const userStateOptions = [
  {
    label: 'Activo',
    value: 'active',
  },
  {
    label: 'Inactivo',
    value: 'inactive',
  },
]

const userRoleOptions = [
  {
    label: 'Contador',
    value: 'accountant',
  },
  {
    label: 'Administrador',
    value: 'admin',
  },
  {
    label: 'Trabajador',
    value: 'worker',
  },
]

export const columnsData = [
  {
    Header: 'Nombre',
    placeholder: 'Nombre',
    accessor: 'name',
    editable: 'onAdd',
  },
  {
    Header: 'Correo',
    placeholder: 'Correo',
    accessor: 'email',
    Cell: ({ row }) => (
      <Tooltip description={row.original.email}>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {row.original.email}
        </Typography>
      </Tooltip>
    ),
    editable: 'onAdd',
  },
  {
    Header: 'Celular',
    accessor: 'phone',
    placeholder: 'Agregar',
    editable: true,
    emptyValue: 'Agregar',
    customWidth: '10.5rem',
  },
  {
    Header: 'Rol',
    accessor: 'role',
    Cell: ({ row }) =>
      roles[row.original.role] ? roles[row.original.role] : row.original.role,
    field: 'select',
    editOptions: ({ row }) =>
      row.original?.name !== '' ? rolesOptions : rolesOptionsWithoutWorker,
    editable: true,
    editInitialValue: 'admin',
    customWidth: '10.5rem',
  },
  {
    Header: 'Activo',
    accessor: 'active',
    Cell: ({ row }) => {
      const isActiveUser = row.original.active
      const color = isActiveUser ? 'primary' : 'error'
      return (
        <Chip
          sx={(theme) => ({
            width: '7rem',
            backgroundColor: alpha(theme.palette[color].main, 0.2),
            '& .MuiChip-label': {
              color: theme.palette[color].dark,
            },
          })}
          label={isActiveUser ? 'Activo' : 'Inactivo'}
        />
      )
    },
    field: 'switch',
    editable: 'onUpdate',
    alignHeader: 'center',
    alignCell: 'center',
    customWidth: '9.4rem',
  },
]

export const useTableActions = ({
  confirm,
  deleteUser,
  resendEmail,
  userRole,
  setUserRole,
  userState,
  setUserState,
}) => {
  const handleResendEmail = (user) => {
    confirm({
      type: 'warning',
      title: 'Reenviar correo de invitación',
      description: `Un correo de invitación para usar Aleluya se enviará a ${user.email}`,
      okText: 'Enviar',
      onOk: () => resendEmail(user),
    })
  }

  const handleDeleteUser = (user) => {
    confirm({
      type: 'warning',
      title: 'Eliminar usuario',
      description: `Eliminar al usuario ${user.name} es permanente y no se podrá deshacer. ¿Estás seguro?`,
      okText: 'Eliminar',
      confirmCheckbox: true,
      onOk: () => deleteUser(user),
    })
  }

  return [
    (rowData) => {
      return {
        id: 'resendEmail',
        icon: <Icon name="mail" />,
        tooltip: 'Enviar correo',
        onClick: (onClickRowData) => handleResendEmail(onClickRowData),
        hidden: !rowData.active,
      }
    },
    (rowData) => ({
      id: 'deleteUser',
      icon: <Icon name="trash-2" />,
      tooltip: 'Eliminar usuario',
      onClick: (onClickRowData) => handleDeleteUser(onClickRowData),
      hidden: !rowData.deleteable || !isOrganizer(),
    }),
    {
      id: 'userStateFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Estado"
          options={userStateOptions}
          value={userState}
          onChange={setUserState}
        />
      ),
    },
    {
      id: 'userRoleFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Rol"
          options={userRoleOptions}
          value={userRole}
          onChange={setUserRole}
        />
      ),
    },
  ]
}
