import { isValidElement } from 'react'

import { IconButton, Tooltip } from '@mui/material'

import Icon from 'components/UI/Icon'

const RowActions = ({
  actions = [],
  row,
  ProvidedActionButton,
  editableTable,
  deleteRow,
  editRow,
  isDeletable = () => true,
  isEditable = () => true,
  hideEdit = false,
  hideDelete = false,
  dataCyPrefix,
}) => {
  const canDeleteRow =
    typeof isDeletable === 'boolean' ? isDeletable : isDeletable(row.original)
  const canEditRow =
    typeof isEditable === 'boolean' ? isEditable : isEditable(row.original)
  const showEdit =
    typeof hideEdit === 'boolean' ? hideEdit : hideEdit(row.original)
  const showDelete =
    typeof hideDelete === 'boolean' ? hideDelete : hideDelete(row.original)

  return (
    <>
      {actions.map((actionItem) => {
        const completeRow = {
          ...row.original,
          ...row.values,
          rowIndex: row.index,
        }
        const action =
          typeof actionItem === 'function'
            ? actionItem(completeRow)
            : actionItem

        // Render a custom ActionButton if it is provided
        if (ProvidedActionButton)
          return (
            <ProvidedActionButton
              action={action}
              completeRow={completeRow}
              key={`action_${action.id}_${completeRow.rowIndex}`}
            />
          )

        const handleClick = () => {
          if (action.customUpdateAction) {
            editRow(row.index)
            return
          }

          if (action.customDeleteAction) {
            deleteRow(row.index)
            return
          }

          action?.onClick(completeRow)
        }

        return !action.hidden ? (
          <Tooltip
            title={action.tooltip || ''}
            key={`action_${action.id}_${completeRow.rowIndex}`}
            disableInteractive
            enterDelay={300}
            arrow
          >
            <span>
              <IconButton
                disabled={action.disabled}
                onClick={handleClick}
                color="complementary1"
                {...action.buttonProps}
                sx={{
                  padding: 0,
                  width: '2rem',
                  height: '2rem',
                }}
              >
                {isValidElement(action.icon) ? action.icon : <action.icon />}
              </IconButton>
            </span>
          </Tooltip>
        ) : null
      })}
      {editableTable ? (
        <>
          {!showEdit ? (
            <Tooltip
              title="Editar"
              data-cy={`${dataCyPrefix}-edit-action`}
              disableInteractive
              arrow
            >
              <span>
                <IconButton
                  onClick={() => editRow(row.index)}
                  disabled={!canEditRow}
                  color="complementary1"
                  sx={{
                    padding: 0,
                    width: '2rem',
                    height: '2rem',
                  }}
                >
                  <Icon name="pencil-line" />
                </IconButton>
              </span>
            </Tooltip>
          ) : null}
          {!showDelete ? (
            <Tooltip
              title="Eliminar"
              data-cy={`${dataCyPrefix}-delete-action`}
              disableInteractive
              arrow
            >
              <span>
                <IconButton
                  onClick={() => deleteRow(row.index)}
                  disabled={!canEditRow || !canDeleteRow}
                  color="complementary1"
                  sx={{
                    padding: 0,
                    width: '2rem',
                    height: '2rem',
                  }}
                >
                  <Icon name="trash-2" />
                </IconButton>
              </span>
            </Tooltip>
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default RowActions
