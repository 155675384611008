import { Box, Typography, useMediaQuery } from '@mui/material'

import { formatNumberDisplayDate } from 'utils/dateTime'

const DisabilityDateCard = ({ statusDetailData = {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const {
    novelty_data: { initial_day, end_day } = {},
    worker_data: { institution_name: institutionName } = {},
  } = statusDetailData

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'complementary2.light',
        borderRadius: '0.5rem',
        padding: theme.spacing(1.5, 2, 1, 2),
        boxShadow: theme.shadows[4],
        gap: theme.spacing(1.5),
        height: '100%',
        maxWidth: '21rem',
        minWidth: '21rem',
        minHeight: '9rem',
        [theme.breakpoints.down('tablet')]: {
          minWidth: '100%',
        },
      })}
    >
      <Typography
        variant={isMobile ? 'body2' : 'body1'}
        sx={{
          textAlign: 'center',
          width: '80%',
          zIndex: 2,
        }}
      >
        Fecha de incapacidad:
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: 700 }}>
        {formatNumberDisplayDate(initial_day)} al{' '}
        {formatNumberDisplayDate(end_day)}
      </Typography>
      {institutionName ? (
        <>
          <Typography
            variant={isMobile ? 'body2' : 'body1'}
            sx={{
              textAlign: 'center',
              width: '80%',
              zIndex: 2,
            }}
          >
            Entidad:{' '}
          </Typography>
          <Typography component="span" sx={{ fontWeight: 700 }}>
            {institutionName}
          </Typography>
        </>
      ) : null}
    </Box>
  )
}

export default DisabilityDateCard
