import { Link } from 'react-router-dom'

import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import Avatar from 'components/UI/Avatar'
import Icon from 'components/UI/Icon'

import { formatCurrency } from 'utils/format'

import { WORKER_SHOW } from 'config/routes'

const AffiliateInfoCard = ({ worker }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={(theme) => ({
        flexDirection: 'column',
        backgroundColor: theme.palette.complementary1.light,
        height: '8rem',
        borderRadius: '0.5rem',
        padding: theme.spacing(1, 2),
        boxShadow: theme.shadows[4],
        display: 'grid',
        zIndex: 2,
        columnGap: theme.spacing(2),
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 'auto auto',
        [theme.breakpoints.down('tablet')]: {
          padding: theme.spacing(1),
          height: 'fit-content',
          rowGap: theme.spacing(2),
        },
      })}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          height: '100%',
          alignItems: 'center',
          gridColumn: '1 / 2',
          gridRow: '1 / span 2',
        }}
      >
        <Avatar
          src={worker.picture}
          sx={{
            width: '3.5rem',
            height: '3.5rem',
          }}
        />
      </Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(0.5),
        })}
      >
        <Typography
          variant={isMobile ? 'lead2' : 'lead1'}
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.5),
          })}
        >
          {
            <Icon
              name="user-check"
              sx={{
                fontSize: '1.5rem',
              }}
            />
          }{' '}
          Datos del afiliado
        </Typography>
        <Typography
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            gap: theme.spacing(1),
          })}
        >
          <Typography component="span" variant="body2">
            Nombre:
          </Typography>
          <Typography
            component="span"
            variant="small"
            sx={{ fontWeight: 700, textAlign: 'end' }}
          >
            {worker.name} {worker.last_name}
          </Typography>
        </Typography>
        <Typography
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            gap: theme.spacing(1),
          })}
        >
          <Typography component="span" variant="body2">
            Salario:
          </Typography>
          <Typography component="span" variant="small" sx={{ fontWeight: 700 }}>
            {formatCurrency(worker.base_salary)}
          </Typography>
        </Typography>
      </Box>
      <Button
        component={Link}
        to={WORKER_SHOW(worker.id)}
        target="_blank"
        variant="text"
        startIcon={<Icon name="plus" />}
        size="small"
        sx={{
          width: 'fit-content',
          gridColumn: '1 / -1',
          gridRow: '2 / 3',
          height: '1.5rem',
          alignSelf: 'end',
          justifySelf: 'center',
        }}
      >
        Ver más detalle
      </Button>
    </Box>
  )
}

export default AffiliateInfoCard
