import { Box } from '@mui/material'

import CertificateDownloadCard from 'components/Worker/WorkersOnboarding/SSAffiliation/InProcessModal/atoms/CertificateDownloadCard'

import { getDisabilityClaimsDocuments } from '../helpers'

const DisabilityDocument = ({ statusDetailData = {} }) => {
  const { claim_support, payment_support, status } = statusDetailData

  const disabilityClaimsDocuments = getDisabilityClaimsDocuments({
    status,
    claimSupport: claim_support,
    paymentSupport: payment_support,
  })

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(3),
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('tablet')]: {
          gridTemplateColumns: '1fr',
        },
      })}
    >
      {disabilityClaimsDocuments.map(
        ({
          id,
          iconName,
          backgroundColor,
          hasDocument,
          documentUrl,
          cardTitle,
        }) => {
          return (
            <CertificateDownloadCard
              key={id}
              iconName={iconName}
              backgroundColor={backgroundColor}
              cardTitle={cardTitle}
              isCompleted={hasDocument}
              documentUrl={documentUrl}
            />
          )
        }
      )}
    </Box>
  )
}

export default DisabilityDocument
