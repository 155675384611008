import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'

import Icon from 'components/UI/Icon'
import Table from 'components/UI/Table/Table'

import { accountingCategoryNames } from 'utils/company'
import useIntegrationService from 'utils/hooks/settings/integrationService'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import { updateAlegraAccountingCodes } from 'services/settings/integrationAccountingCodesService'

import * as routes from 'config/routes'

import { getAccountColumns } from '../helpers'

const SetttingsAccountingCategory = ({ category, expanded, handleChange }) => {
  const queryClient = useQueryClient()
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const navigate = useNavigate()
  const queryKey = ['alegraAccountingCodes', category]
  const { integrationsQuery } = useIntegrationService({
    serviceParams: {
      queryKey,
      category,
    },
    queryOptions: {
      onError: (error) => {
        const { errors } = error
        if (
          error.status === 404 &&
          errors[0].code === '0001' &&
          errors[0].object === 'integration'
        ) {
          navigate(routes.SETTINGS_INTEGRATION_INDEX(), {
            state: { error: errors[0] },
          })
        } else if (
          error.status === 422 &&
          errors[0].code === '1202' &&
          errors[0].object === 'integration'
        ) {
          navigate(routes.SETTINGS_INTEGRATION_INDEX(), {
            state: { error: errors[0] },
          })
        } else handleError(error[0])
      },
    },
  })
  const isEmptyData =
    integrationsQuery.data && integrationsQuery.data.length === 0
  const codeOptions = integrationsQuery?.alegraOptions?.codes || []
  const counterpartOptions =
    integrationsQuery?.alegraOptions?.counterpart_codes || []
  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
      showSuccessMessage('La cuenta contable se ha actualizado correctamente')
    },
  }

  const updateAccountingCode = (oldAccountingData, newAccountingData) => {
    return {
      data: {
        data: {
          integration_code: {
            payroll_concept_id: newAccountingData.payroll_concept_id,
            counterpart_code: newAccountingData.counterpart_code?.id,
            code: newAccountingData.integration_code?.id,
          },
        },
        integrationId: oldAccountingData.integration_id,
      },
      mutationFunction: updateAlegraAccountingCodes,
    }
  }

  const columns = getAccountColumns(codeOptions, counterpartOptions, category)

  return (
    <Accordion
      expanded={expanded === category}
      onChange={handleChange(category)}
      disabled={integrationsQuery.isLoading || isEmptyData}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<Icon name="chevron-down" sx={{ fontSize: '1.5rem' }} />}
        aria-controls={`panel-${category}-content`}
        id={`panel-${category}-header`}
        data-cy={`accordion-${category}`}
      >
        <Typography>
          {accountingCategoryNames[category]}
          {integrationsQuery.isLoading && ' - Cargando...'}
          {isEmptyData &&
            ` - Ninguna persona tiene registrada ${accountingCategoryNames[category]}.`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          display: 'block',
          backgroundColor: theme.palette.white.light,
        })}
      >
        <Table
          columns={columns}
          data={integrationsQuery.data || []}
          editable={{
            onUpdateRow: updateAccountingCode,
            hideDelete: true,
            tableMinWidth: 960,
            mutationOptions,
          }}
          options={{
            toolbar: false,
            pagination: false,
            customActionsWidth: 200,
            alignActionsHeader: 'center',
            alignActionsCell: 'center',
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default SetttingsAccountingCategory
