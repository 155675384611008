import * as yup from 'yup'

import { Box, Chip, Tooltip, Typography, alpha } from '@mui/material'

import FilterButton from 'components/UI/Button/FilterButton'
import Icon from 'components/UI/Icon'

import { formatNumberDisplayDate } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { getWordSingular } from 'utils/format'

import RequestFreeTimeModal from '../Modals/RequestFreeTimeModal'

const getTypeLabel = (payrollConceptName) => {
  return payrollConceptName === 'Vacaciones disfrutadas'
    ? 'Vacaciones'
    : payrollConceptName
}

const statusLabel = {
  pending: 'Pendiente',
  approved: 'Aprobado',
  rejected: 'Rechazado',
}

export const columnsData = [
  {
    accessor: 'payroll_concept_name',
    Header: 'Novedades',
    Cell: ({ row }) => getTypeLabel(row.original.payroll_concept_name),
    customMinWidth: '10rem',
  },
  {
    accessor: 'start_date',
    Header: 'Fecha de ausencia',
    Cell: ({ row }) => (
      <Typography>
        {formatNumberDisplayDate(row.original.initial_day)}
        {' - '}
        {formatNumberDisplayDate(row.original.end_day)}
      </Typography>
    ),
    customMinWidth: '10rem',
  },
  {
    accessor: 'comment',
    Header: 'Comentarios',
    Cell: ({ row }) => {
      if (!row.original.comment) return 'N/A'
      return (
        <Box>
          <Tooltip title={row.original.comment} arrow>
            <Typography>
              {row.original.comment.length > 25
                ? `${row.original.comment.slice(0, 25)}...`
                : row.original.comment}
            </Typography>
          </Tooltip>
        </Box>
      )
    },
    customMinWidth: '10rem',
  },
  {
    accessor: 'duration',
    Header: 'Duración',
    Cell: ({ row }) =>
      `${row.original.days} ${getWordSingular(
        'días',
        row.original.days === 1
      )}`,
    customMinWidth: '6rem',
  },
  {
    accessor: 'status',
    Header: 'Estado',
    Cell: ({ row }) => (
      <Chip
        sx={(theme) => ({
          fontWeight: 600,
          minWidth: '7rem',
          ...(row.original.status === 'approved' && {
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.dark,
          }),
          ...(row.original.status === 'rejected' && {
            backgroundColor: alpha(theme.palette.error.main, 0.2),
            color: theme.palette.error.dark,
          }),
          ...(row.original.status === 'pending' && {
            backgroundColor: alpha(theme.palette.complementary2.main, 0.2),
            color: theme.palette.complementary2.dark,
          }),
        })}
        label={statusLabel[row.original.status]}
      />
    ),
    alignHeader: 'center',
    alignCell: 'center',
    customWidth: '9rem',
  },
]

const optionsData = [
  { value: '', label: 'Todas las solicitudes' },
  {
    value: 'pending',
    label: 'Pendientes',
  },
  {
    value: 'approved',
    label: 'Aprobadas',
  },
  {
    value: 'rejected',
    label: 'Rechazadas',
  },
]

export const getActions = (
  workerId,
  handleNewRequest,
  modals,
  handleDownloadEvidence,
  freeTimeActiveStatus,
  setfreeTimeActiveStatus
) => {
  const handleClose = () => modals.closeAll()

  const handleEdit = (rowData) => {
    modals.openModal({
      id: 'editFreeTimeRequest',
      content: (
        <RequestFreeTimeModal
          requestData={rowData}
          onClose={handleClose}
          isEditing
          workerId={workerId}
        />
      ),
      modalProps: {
        header: 'Editar solicitud',
        onCloseModal: handleClose,
        hideFooter: true,
        dialogProps: {
          maxWidth: 750,
        },
      },
    })
  }

  return [
    (rowData) => ({
      id: 'downloadEvidence',
      tooltip: 'Ver documento',
      icon: <Icon name="download" />,
      hidden: !rowData.evidence,
      onClick: () => handleDownloadEvidence(rowData.evidence),
    }),
    {
      id: 'requestFreeTime',
      tooltip: 'Solicitar tiempo fuera',
      endIcon: <Icon name="calendar-1" />,
      isFreeAction: true,
      onClick: handleNewRequest,
      buttonProps: {
        'data-cy': 'request-free-time',
      },
    },
    (rowData) => ({
      id: 'editFreeTimeRequest',
      tooltip: 'Editar solicitud',
      icon: <Icon name="pencil-line" />,
      disabled: rowData.status === 'approved',
      onClick: () => handleEdit(rowData),
    }),
    {
      id: 'filterFreeTimeType',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          options={optionsData}
          defaultLabel="Todas las solicitudes"
          value={freeTimeActiveStatus}
          onChange={setfreeTimeActiveStatus}
        />
      ),
    },
  ]
}

yup.setLocale(yupLocaleES)

const getNoveltyType = (payrollConcepts, noveltyType) => {
  const noveltyTypeId = payrollConcepts.find(
    (concept) => concept.id === noveltyType
  )
  return noveltyTypeId?.coded_name
}

export const getInitialValues = (values = {}, payrollConcepts) => ({
  initial_day: values?.initial_day,
  end_day: values?.end_day,
  novelty_type: getNoveltyType(payrollConcepts, values?.payroll_concept_id),
  evidence: values?.evidence || null,
  comment: values?.comment,
})

export const validationSchema = yup.object({
  initial_day: yup.date().required('La fecha de inicio es requerida'),
  end_day: yup.date().required('La fecha de fin es requerida'),
  novelty_type: yup.string().required('El tipo de novedad es requerido'),
  evidence: yup.array().of(yup.object(), yup.string()).nullable(),
  comment: yup.string().nullable(),
})

export const conceptsToDelete = (contractCategory) => {
  const concepts = ['compensated_days', 'job_abandonment', 'suspension']

  if (contractCategory === 'schooling_trainee')
    concepts.push(
      'enjoyed_days',
      'labor_incapacity',
      'unpaid_leave',
      'paid_leave'
    )
  if (
    contractCategory === 'productive_trainee' ||
    contractCategory === 'intern'
  )
    concepts.push('enjoyed_days', 'unpaid_leave', 'paid_leave')
  if (contractCategory === 'student_decree_055')
    concepts.push(
      'enjoyed_days',
      'general_incapacity',
      'material_leave',
      'paternity_leave',
      'unpaid_leave',
      'paid_leave'
    )
  if (contractCategory === 'part_time_contract')
    concepts.push('general_incapacity', 'material_leave', 'paternity_leave')

  return concepts
}

export const conceptsTypes = ['holidays', 'incapacities', 'licenses']

export const getEmptyStateTitle = (freeTimeActiveStatus) => {
  let status = ''

  if (freeTimeActiveStatus === 'approved') {
    status = 'aprobadas'
  } else if (freeTimeActiveStatus === 'rejected') {
    status = 'rechazadas'
  } else if (freeTimeActiveStatus === 'pending') {
    status = 'pendientes'
  }

  return `No tienes solicitudes de tiempo fuera ${status}`
}
