import { isBefore, isSameDay, parseISO } from 'date-fns'
import { createContext, useContext } from 'react'

import { Box, useMediaQuery } from '@mui/material'

import Icon from 'components/UI/Icon'

import RangeField from './RangeField'
import { getFocusedValue, parseDateSegment } from './helpers'

export const DateRangePickerContext = createContext({
  isDesktop: true,
  tooltipTitle: undefined,
  tooltipDescription: undefined,
  locale: 'es-ES',
  initialDay: null,
  endDay: null,
})
DateRangePickerContext.displayName = 'DateRangePickerContext'

export const useDateRangePickerContext = () => {
  const context = useContext(DateRangePickerContext)

  if (!context)
    throw Error(
      'useDateRangePickerContext needs to be used inside DateRangePickerContext'
    )

  return context
}

const DateRangePicker = ({
  startDateId,
  startDateDataCy,
  endDateId,
  endDateDataCy,
  disabled,
  onChange,
  initialDay,
  endDay,
  periodDate,
  allExcludedDates = [],
  initialMinDate = null,
  initialMaxDate = null,
  endMinDate = null,
  endMaxDate = null,
}) => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('tablet'))
  const initialFocusedValue = getFocusedValue(initialDay, periodDate)
  const endFocusedValue = getFocusedValue(endDay, periodDate)
  const disableInitialDay =
    initialDay && initialMinDate
      ? isBefore(parseISO(initialDay), parseISO(initialMinDate))
      : false

  const checkInitialUnavailableDates = (calendarDate) => {
    const parsedCalendarDate = new Date(
      `${calendarDate.year},${calendarDate.month},${calendarDate.day}`
    )
    const isUnavailable = allExcludedDates.some((date) => {
      return isSameDay(date, parsedCalendarDate)
    })
    const isBeforeCurrentPeriod = initialMinDate
      ? isBefore(parsedCalendarDate, parseISO(initialMinDate))
      : false

    return isBeforeCurrentPeriod || isUnavailable
  }

  const checkEndUnavailableDates = (calendarDate) => {
    const parsedCalendarDate = new Date(
      `${calendarDate.year},${calendarDate.month},${calendarDate.day}`
    )
    const isUnavailable = allExcludedDates.some((date) => {
      return isSameDay(date, parsedCalendarDate)
    })
    const isBeforeCurrentPeriod = initialMinDate
      ? isBefore(parsedCalendarDate, parseISO(initialMinDate))
      : false

    return isUnavailable || isBeforeCurrentPeriod
  }

  const contextValue = {
    isDesktop,
    tooltipTitle: 'Novedades',
    tooltipDescription:
      'Los días que quieres seleccionar están deshabilitados porque tienen novedades o están fuera del periodo actual.',
    initialDay,
    endDay,
  }

  return (
    <DateRangePickerContext.Provider value={contextValue}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          flexDirection: 'row',
        })}
      >
        <RangeField
          name="Initial day"
          value={initialDay}
          onChange={(date) => {
            const selectedDate = `${date.year}-${parseDateSegment(
              date.month
            )}-${parseDateSegment(date.day)}`

            onChange(selectedDate, 'initial_day')
          }}
          isDateUnavailable={checkInitialUnavailableDates}
          minValue={initialMinDate}
          maxValue={initialMaxDate}
          focusedValue={initialFocusedValue}
          disabled={disabled || disableInitialDay}
          id={startDateId}
          dataCy={startDateDataCy}
          placeholder="Día inicial"
        />
        <Icon name="move-right" />
        <RangeField
          name="End day"
          value={endDay}
          onChange={(date) => {
            const selectedDate = `${date.year}-${parseDateSegment(
              date.month
            )}-${parseDateSegment(date.day)}`

            onChange(selectedDate, 'end_day')
          }}
          isDateUnavailable={checkEndUnavailableDates}
          minValue={endMinDate}
          maxValue={endMaxDate}
          focusedValue={endFocusedValue}
          disabled={disabled}
          id={endDateId}
          dataCy={endDateDataCy}
          placeholder="Día final"
        />
      </Box>
    </DateRangePickerContext.Provider>
  )
}

export default DateRangePicker
