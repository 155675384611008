import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

import generateSimpleMessage from './utils'

export default {
  loans: (
    <>
      <TooltipLink href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/agrega-préstamos-a-tus-empleados">
        Aquí
      </TooltipLink>{' '}
      podrás ver cómo ingresar un préstamo.
    </>
  ),
  enable_loans: (
    <>
      Si deseas activar o inactivar un préstamo puedes{' '}
      <TooltipLink href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conceptos-recurrentes-ingresos-deducciones-y-préstamos-de-un-empleado">
        ver aquí
      </TooltipLink>{' '}
      cómo hacerlo.
    </>
  ),
  deductions: (
    <>
      <TooltipLink href="https://ayuda.aleluya.com/portal/es/kb/articles/conoce-cómo-registrar-deducciones-recurrentes-a-tu-empleado">
        Aquí
      </TooltipLink>{' '}
      podrás ver cómo ingresar deducciones recurrentes.
    </>
  ),
  salary_income:
    'Agrega aquí todos los ingresos que sumarán para la base de seguridad social y prestaciones.',
  non_salary_income:
    'Agrega aquí todos los ingresos que No sumarán para la base de seguridad social y prestaciones.',
  early_payment: (
    <>
      Al elegir Vacaciones + nómina o solo vacaciones, se incluirá el saldo
      pendiente de esta persona en el archivo final del banco.{' '}
      <TooltipLink href="https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-pagar-vacaciones-de-forma-anticipada">
        Aquí
      </TooltipLink>{' '}
      verás más información
    </>
  ),
  average_salary: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/cómo-puedo-editar-el-salario-promedio-para-el-cálculo-de-vacaciones-incapacidades-o-licencias-de-un-empleado',
    'Sobre este salario se realizará el pago de las vacaciones disfrutadas.'
  ),
  compensated_days_average_salary: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-el-salario-base-para-liquidar-las-vacaciones',
    'Sobre este salario se realizará el pago de las vacaciones compensadas.'
  ),
  novelties_average_salary: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-el-salario-base-para-liquidar-las-incapacidades-y-licencias',
    'Sobre este salario se realizará el pago de las incapacidades.'
  ),
  licenses_average_salary: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-el-salario-base-para-liquidar-las-incapacidades-y-licencias',
    'Sobre este salario se realizará el pago de las licencias. No aplica para licencias de maternidad o paternidad.'
  ),
  suspention: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-licencias-no-remuneradas-suspensiones-y-ausencia-injustificada'
  ),
  job_abandonment: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-licencias-no-remuneradas-suspensiones-y-ausencia-injustificada'
  ),
  compensated_days: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-vacaciones-compensadas-de-tus-empleados'
  ),
  enjoyed_days: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-vacaciones-disfrutadas-por-un-empleado'
  ),
  general_incapacity: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-el-pago-de-las-incapacidades-de-tus-empleados'
  ),
  maternity_leave: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-licencias-de-maternidad'
  ),
  paternity_leave: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-licencias-de-paternidad'
  ),
  unpaid_leave: generateSimpleMessage(
    'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/conoce-cómo-calcula-aleluya-las-licencias-no-remuneradas-suspensiones-y-ausencia-injustificada'
  ),
  SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_OK_TEXT: 'Editar trabajadores',
  SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_TITLE:
    'Completa la información de tus empleados',
  SOCIAL_SECURITY_MISING_INFO_CONFIRM_MODAL_DESCRIPTION:
    'Nos falta la información de la seguridad social de algunos trabajadores. Debes agregar esta información para generar la planilla.',
  parental_licenses_average_salary:
    'IBC del mes anterior al comienzo de la licencia de maternidad o paternidad',
}
