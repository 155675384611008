import * as yup from 'yup'

import { Chip, Link, Typography, alpha } from '@mui/material'

import { yupLocaleES } from 'utils/form'
import { areObjectsEqual } from 'utils/general'
import useNotifications from 'utils/hooks/useNotifications'

import computer from 'assets/images/three-dimensional-icons/computer.png'
import copy from 'assets/images/three-dimensional-icons/copy.png'
import thumbUp from 'assets/images/three-dimensional-icons/thumb-up.png'

import messages from 'messages/electronic_payroll'

yup.setLocale(yupLocaleES)

export const configurationSteps = [
  {
    step: 0,
    id: 'configuration-step-1',
    title: 'Información de la empresa',
    description: 'Información de la empresa',
  },
  {
    step: 1,
    id: 'configuration-step-2',
    title: 'Habilitación en la DIAN',
    description: 'Habilitación en la DIAN',
  },
  {
    step: 2,
    id: 'configuration-step-3',
    title: 'Verificación en la DIAN',
    description: 'Verificación en la DIAN',
  },
  {
    step: 3,
    id: 'configuration-step-4',
    title: 'Firma electrónica',
    description: 'Firma electrónica',
  },
  {
    step: 4,
    id: 'configuration-step-5',
    title: 'Configuración de emisión NE',
    description: 'Configuración de emisión NE',
  },
]

export const tutorialCardsActivationDian = [
  {
    id: 'activationTutorialCard-1',
    number: 1,
    title: 'Paso 1 - Habilitarse como emisor de Nómina Electrónica',
    description:
      'Ingresa al portal de la DIAN y registra tu empresa como emisor de Nómina Electrónica',
    icon: computer,
    alt: 'Habilítate como emisor de Nómina Electrónica ante la DIAN',
    backgroundColor: 'accent2.main',
    cardTitle: '¿Cómo habilitarse?',
  },
  {
    id: 'activationTutorialCard-2',
    number: 2,
    title: 'Paso 2 - Enviar set de pruebas a la DIAN',
    description:
      'Envía el set de pruebas para sincronizar Aleluya con tu Nómina Electrónica',
    content: true,
    icon: copy,
    alt: 'Envía el set de pruebas a la DIAN',
    backgroundColor: 'accent3.main',
    cardTitle: '¿Cómo enviar pruebas?',
  },
]

export const tutorialCardsConfirmationDian = [
  {
    id: 'tutorialCard-1',
    number: 1,
    title:
      'Paso 1 - Ingresar a la DIAN y verificar si ya te encuentras habilitado',
    description:
      'Debes ingresar al portal de la DIAN y verificar si ya te encuentras habilitado.',
    article: true,
    link: messages.ELECTRONIC_PAYROLL_AUTHORIZATION_CONFIRMATION,
    icon: thumbUp,
    alt: 'Verifíca si ya estás habilitado',
    backgroundColor: 'accent3.main',
    cardTitle: '¿Cómo verificar la habilitación?',
  },
  {
    id: 'tutorialCard-2',
    number: 2,
    title: 'Paso 2 - Verificar tu estado de SET de pruebas',
    description:
      'Una vez completes el proceso anterior habrás verificado el estado de tu SET de pruebas dentro de Aleluya, dependiendo del estado debes realizar un procedimiento diferente.',
  },
]

export const configurationInitialValues = {
  name: '',
  government_email: '',
  id_number: '',
  verification_digit: null,
  document_type: '',
  department: null,
  city: null,
  address: '',
  test_set_code: '',
  electronic_payroll: 1,
  electronic_payroll_update: 1,
  company_certificate: false,
  certificate_file: null,
  certificate_file_name: null,
  certificate_password: '',
}

export const getValidationSchema = (currentStep) => {
  if (currentStep === 0) {
    return yup.object({
      name: yup
        .string()
        .trim()
        .nullable()
        .min(4, 'Razón social demasiado corta, 4 caracteres mínimo')
        .max(60, 'Razón social demasiado larga, 60 caracteres máximo')
        .required('Debes ingresar la razón social'),
      government_email: yup
        .string()
        .trim()
        .email()
        .nullable()
        .required('Debes ingresar el correo electrónico del RUT'),
      id_number: yup
        .string()
        .trim()
        .nullable()
        .required('Debes ingresar el número de documento'),
      document_type: yup
        .string()
        .trim()
        .nullable()
        .required('Debes ingresar el tipo de documento'),
      department: yup
        .object()
        .nullable()
        .required('Debes ingresar el departamento de ubicación'),
      city: yup
        .object()
        .nullable()
        .required('Debes ingresar la ciudad de ubicación'),
      address: yup
        .string()
        .trim()
        .nullable()
        .min(5, 'Dirección demasiado corta, 5 caracteres mínimo')
        .max(100, 'Dirección demasiado larga, 100 caracteres máximo')
        .required('Debes ingresar la dirección de ubicación'),
    })
  }
  if (currentStep === 1) {
    return yup.object({
      test_set_code: yup
        .string()
        .trim()
        .nullable()
        .required('Debes ingresar el código TestSetID'),
    })
  }
  if (currentStep === 3) {
    return yup.object({
      electronic_payroll: yup.number().nullable().required(),
      electronic_payroll_update: yup.number().nullable().required(),
      company_certificate: yup.boolean().nullable().required(),
      certificate_file_name: yup
        .string()
        .nullable()
        .when('company_certificate', {
          is: (companyCertificate) => companyCertificate === true,
          then: yup
            .string()
            .nullable()
            .required('Debes adjuntar tu certificado'),
        }),
      certificate_password: yup
        .string()
        .nullable()
        .when('company_certificate', {
          is: (companyCertificate) => companyCertificate === true,
          then: yup.string().trim().nullable().required(),
        }),
    })
  }
  if (currentStep === 4) {
    return yup.object({
      social_benefits: yup.string().required(),
      holidays: yup.string().required(),
      consecutive_number: yup
        .number()
        .min(1, 'Debe ser mayor o igual a 1.')
        .required(),
      canceled_consecutive_number: yup
        .number()
        .min(1, 'Debe ser mayor o igual a 1.')
        .required(),
    })
  }
  return null
}

export const tutorialsInformation = {
  1: {
    videoLink: messages.TUTORIAL_STEP_ONE,
    title: 'Regístrate como emisor de Nómina Electrónica',
    description:
      'A continuación te presentamos una guía para que realices tu proceso de habilitación de forma simple. Este proceso deberás realizarlo en el portal de la DIAN.',
    footer: (
      <>
        Si tienes duda sobre este proceso, puedes ver{' '}
        <Link
          href={messages.ELECTRONIC_PAYROLL_TOTAL_INFORMATION}
          target="_blank"
          underline="hover"
        >
          este artículo.
        </Link>
      </>
    ),
  },
  2: {
    videoLink: messages.TUTORIAL_STEP_TWO,
    title: 'Enviemos el set de pruebas a la DIAN',
    description:
      'Ahora que ya has seleccionado tu proveedor tecnológico en el paso anterior, es momento de realizar unas cuantas pruebas para sincronizar tu cuenta de Aleluya con la DIAN. En este video te enseñamos cómo realizar este proceso en un clic.',
    footer: (
      <>
        Si tienes duda sobre este proceso, puedes ver{' '}
        <Link
          href={messages.ELECTRONIC_PAYROLL_STEP_BY_STEP_ENABLING}
          target="_blank"
          underline="hover"
        >
          este artículo.
        </Link>
      </>
    ),
  },
}

export const electronicPayrollCertificateOptions = [
  {
    label: (
      <>
        {`Usar certificado electrónico del proveedor tecnológico  `}
        <Chip
          label={<Typography variant="lead2">Recomendado</Typography>}
          sx={(theme) => ({
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
          })}
        />
      </>
    ),
    value: false,
  },
  {
    label: 'Usar mi propio certificado electrónico',
    value: true,
  },
]

export const getConfigurationInitialValues = (
  initialData,
  configurationData
) => {
  return {
    ...configurationInitialValues,
    name: initialData.name,
    government_email: initialData.government_email,
    id_number: initialData.id_number,
    document_type: initialData.document_type,
    verification_digit: initialData.verification_digit,
    city: initialData?.city?.id ? initialData.city : null,
    department: initialData?.department?.id ? initialData.department : null,
    address: initialData.address,
    social_benefits: configurationData?.social_benefits,
    holidays: configurationData?.holidays,
    consecutive_number: configurationData?.consecutive_number || 1,
    canceled_consecutive_number:
      configurationData?.canceled_consecutive_number || 1,
  }
}

export const compareFinalValues = (
  currentStep,
  currentValues,
  initialValues
) => {
  if (currentStep === 0) {
    return areObjectsEqual(
      {
        name: currentValues.name,
        government_email: currentValues.government_email,
        id_number: currentValues.id_number,
        document_type: currentValues.document_type,
        verification_digit: currentValues.verification_digit,
        city: currentValues.city,
        department: currentValues.department,
        address: currentValues.address,
      },
      {
        name: initialValues.name,
        government_email: initialValues.government_email,
        id_number: initialValues.id_number,
        document_type: initialValues.document_type,
        verification_digit: initialValues.verification_digit,
        city: initialValues.city,
        department: initialValues.department,
        address: initialValues.address,
      }
    )
  }

  if (currentStep === 1) {
    return areObjectsEqual(
      {
        test_set_code: currentValues.test_set_code,
      },
      {
        test_set_code: initialValues.test_set_code,
      }
    )
  }

  // step 2 doesn't send data
  if (currentStep === 2) return true

  if (currentStep === 3)
    return areObjectsEqual(
      {
        company_certificate: currentValues.company_certificate,
        certificate_file: currentValues.certificate_file,
        certificate_password: currentValues.certificate_password,
      },
      {
        company_certificate: initialValues.company_certificate,
        certificate_file: initialValues.certificate_file,
        certificate_password: initialValues.certificate_password,
      }
    )

  if (currentStep === 4)
    return areObjectsEqual(
      {
        social_benefits: currentValues.social_benefits,
        holidays: currentValues.holidays,
        consecutive_number: Number(currentValues.consecutive_number),
        canceled_consecutive_number: Number(
          currentValues.canceled_consecutive_number
        ),
      },
      {
        social_benefits: initialValues.social_benefits,
        holidays: initialValues.holidays,
        consecutive_number: Number(initialValues.consecutive_number),
        canceled_consecutive_number: Number(
          initialValues.canceled_consecutive_number
        ),
      }
    )

  return false
}

// This is for E2E testing purposes
export const useStatusMessages = () => {
  const { showInfoMessage, showSuccessMessage, showErrorMessage } =
    useNotifications()

  const showStatusMessages = (configurationData, aditionalCallback) => {
    if (configurationData?.government_enabled_status === 'unauthorized') {
      aditionalCallback()
      showErrorMessage('Error al enviar el set de pruebas', {
        key: 'electronicPayrollActivationUnauthorized',
        preventDuplicate: true,
      })
    }

    if (
      ['waiting_response', 'pending_to_send'].includes(
        configurationData?.government_enabled_status
      )
    ) {
      showInfoMessage(
        'Tu solicitud de habilitación ante la DIAN está en proceso. Te avisaremos cuando este proceso termine',
        {
          persist: true,
          key: 'electronicPayrollActivationInProgress',
          preventDuplicate: true,
        }
      )
    }

    if (configurationData?.government_enabled_status === 'authorized') {
      showSuccessMessage(
        'Listo, tu empresa quedó habilitada para emitir Nómina Electrónica',
        {
          persist: true,
          preventDuplicate: true,
        }
      )
    }
  }

  return { showStatusMessages }
}
