import { Box, Paper, Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Icon from 'components/UI/Icon'

import PaymentMethodSection from './PaymentMethodSection'

const PaymentMethod = () => {
  const { subscription } = useSubscription()

  const { payment_methods: paymentMethods } = subscription || {}
  const {
    credit_card: showCreditCardMethod,
    automatic_debit: showAutomaticDebitMethod,
    pse: showPseMethod,
  } = paymentMethods || {}

  return (
    <Paper
      sx={(theme) => ({
        width: '100%',
        backgroundColor: 'white.main',
        padding: theme.spacing(3),
        borderRadius: '1rem',
      })}
    >
      <PaymentMethodSection
        showCreditCard={showCreditCardMethod}
        showAutomaticDebitMethod={showAutomaticDebitMethod}
        showPseMethod={showPseMethod}
      />
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1.5rem auto',
          gridGap: theme.spacing(2),
          alignItems: 'center',
        })}
      >
        <Icon
          name="shield-check"
          sx={(theme) => ({
            fontSize: '2rem',
            color: theme.palette.primary.dark,
          })}
        />
        <Typography variant="body2">Pago 100% seguro</Typography>
      </Box>
    </Paper>
  )
}

export default PaymentMethod
