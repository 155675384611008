import http, { getApiURL } from '../httpService'

export const getDisabilityClaimsShow = ({
  claimDetailId,
  searchParams = {},
}) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: `/organizers/company_claims_details/${claimDetailId}`,
    searchParams,
  })

  return http.getV2(url)
}

export const getDisabilityClaimsIndex = ({ searchParams = {} }) => {
  const url = getApiURL({
    withoutVersion: true,
    pathname: '/organizers/company_claims_details',
    searchParams,
  })

  return http.getV2(url)
}

export default {
  getDisabilityClaimsShow,
  getDisabilityClaimsIndex,
}
