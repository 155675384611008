import useModals from 'components/App/ModalsManager/useModals'
import { default as UploadPaymentSupportModalContent } from 'components/HolisticPayroll/Commons/UploadDisabilitySupportModalContent'

import ClaimNoveltyModalContent from './ClaimNoveltyModalContent'
import ClaimSupportModalContent from './ClaimSupportModalContent'

export function useOrganizerDisabilityClaimsModals() {
  const modals = useModals()

  function handleCloseModal() {
    modals.closeAll()
  }

  function openClaimSupportModal(claimData = {}, claimsDetailId) {
    const { id, worker_name } = claimData

    modals.openModal({
      id: 'claimNoveltyModal',
      content: (
        <ClaimSupportModalContent
          claimNoveltyId={id}
          claimsDetailId={claimsDetailId}
        />
      ),
      modalProps: {
        onCancel: handleCloseModal,
        header: `Soporte de incapacidad de ${worker_name || 'la persona'}`,
        onOk: handleCloseModal,
        okText: 'Cerrar',
        hideCancelButton: true,
        paperSx: ({ breakpoints }) => ({
          maxWidth: '49rem',
          position: 'relative',
          [breakpoints.up('md')]: {
            width: '49rem',
          },
        }),
      },
    })
  }

  function openClaimNoveltyModal(claimData) {
    modals.openModal({
      id: 'claimNoveltyModal',
      content: (
        <ClaimNoveltyModalContent
          handleClose={handleCloseModal}
          claimData={claimData}
        />
      ),
      modalProps: {
        onCancel: handleCloseModal,
        header: `Reportar una novedad de la reclamación de ${claimData?.worker_name || 'la persona'}`,
        paperSx: ({ breakpoints }) => ({
          maxWidth: '49rem',
          position: 'relative',
          [breakpoints.up('md')]: {
            width: '49rem',
          },
        }),
        hideFooter: true,
      },
    })
  }

  function openUploadDisabilityPaymentSupportModal({
    claimsDetailId,
    claimNoveltyId,
    claimData = {},
  }) {
    modals.openModal({
      id: 'uploadDisabilitySupportModal',
      content: (
        <UploadPaymentSupportModalContent
          handleClose={handleCloseModal}
          claimsDetailId={claimsDetailId}
          claimNoveltyId={claimNoveltyId}
          claimData={claimData}
        />
      ),
      modalProps: {
        onCancel: handleCloseModal,
        header: 'Carga el soporte de pago de la reclamación',
        hideFooter: true,
        paperSx: {
          maxWidth: '45rem',
          position: 'relative',
        },
      },
    })
  }

  return {
    openClaimSupportModal,
    openUploadDisabilityPaymentSupportModal,
    openClaimNoveltyModal,
  }
}
