import { useState } from 'react'

import { Box } from '@mui/material'

import Icon from 'components/UI/Icon'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import useDownloadURI from 'utils/hooks/useDownloadURI'

import { getFreeTimeRequestForAdmin } from 'services/worker/freeTimeService'

import FreeTimeEmptyState from '../common/EmptyState'
import { getActions, getColumnsData, getEmptyStateTitle } from './helpers'

const FreeTimeAdminView = () => {
  const { worker: { id: workerId } = {} } = useWorker({ useCache: true })
  const [freeTimeActiveStatus, setfreeTimeActiveStatus] = useState('')

  const fetchRequests = (pageIndex, search) => {
    return {
      queryKey: [
        'allWorkerRequests',
        workerId,
        pageIndex,
        search,
        freeTimeActiveStatus,
      ],
      workerId,
      queryFunction: () =>
        getFreeTimeRequestForAdmin({
          workerId,
          page: pageIndex + 1,
          search,
          filter: freeTimeActiveStatus,
        }),
    }
  }

  const downloadURI = useDownloadURI()

  const handleDownloadEvidence = (documentLink) => {
    downloadURI(documentLink)
  }

  const actions = getActions(freeTimeActiveStatus, setfreeTimeActiveStatus)

  return (
    <>
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Table
          data={fetchRequests}
          columns={getColumnsData(handleDownloadEvidence)}
          actions={actions}
          options={{
            version: {
              toolbar: 'v2',
            },
            emptyState: {
              title: getEmptyStateTitle(freeTimeActiveStatus),
              icon: (
                <Icon
                  name="calendar-days"
                  sx={(theme) => ({
                    fontSize: '5rem',
                    color: theme.palette.primary.dark,
                  })}
                />
              ),
              hideDescription: true,
            },
            emptyStateComponent: <FreeTimeEmptyState />,
            customQueryFetch: 'novelty_requests',
          }}
        />
      </Box>
    </>
  )
}

export default FreeTimeAdminView
