import { Box } from '@mui/material'

import Icon from '../Icon'
import Tooltip from '../Tooltip'

const TooltipInfoIcon = ({ title, tooltipProps, iconProps, icon }) => {
  return (
    <Tooltip
      arrow
      description={title}
      aria-label="info"
      enterDelay={300}
      disableInteractive
      {...tooltipProps}
    >
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
        }}
      >
        {icon || (
          <Icon
            name="info"
            sx={[
              (theme) => ({
                color: theme.palette.primary.dark,
                cursor: 'help',
              }),
              iconProps?.sx,
            ]}
            fontSize="small"
          />
        )}
      </Box>
    </Tooltip>
  )
}

export default TooltipInfoIcon
