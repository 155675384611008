import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'

const CheckboxField = ({ id, field, checkboxLabel, checkboxSx, ...props }) => {
  return (
    <Box sx={[checkboxSx]}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox id={id} {...field} size="medium" {...props} />}
          label={checkboxLabel}
        />
      </FormGroup>
    </Box>
  )
}

export default CheckboxField
