import { Box, Typography, useMediaQuery } from '@mui/material'

import { formatCurrency, getWordSingular } from 'utils/format'

const MoneyToClaimCard = ({ statusDetailData = {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  const { value, novelty_data: { days } = {} } = statusDetailData

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'accent2.light',
        borderRadius: '0.5rem',
        padding: theme.spacing(1.5, 2, 1, 2),
        boxShadow: theme.shadows[4],
        gap: theme.spacing(1.5),
        minHeight: '9rem',
        maxWidth: '21rem',
        minWidth: '21rem',
        [theme.breakpoints.down('tablet')]: {
          minWidth: '100%',
        },
      })}
    >
      <Typography
        variant={isMobile ? 'body2' : 'body1'}
        sx={{
          textAlign: 'center',
          width: '80%',
          zIndex: 2,
        }}
      >
        Platica a reclamar:
      </Typography>
      <Typography variant="h2" sx={{ fontWeight: 700 }}>
        {formatCurrency(value)}
      </Typography>
      <Typography
        variant={isMobile ? 'body2' : 'body1'}
        sx={{
          textAlign: 'center',
        }}
      >
        Días de incapacidad:{' '}
        <Typography component="span" sx={{ fontWeight: 700 }}>
          {days} {getWordSingular('días', days === 1)}
        </Typography>
      </Typography>
    </Box>
  )
}

export default MoneyToClaimCard
