import dollar from 'assets/images/three-dimensional-icons/dollar.png'
import heart from 'assets/images/three-dimensional-icons/heart.png'
import notebook from 'assets/images/three-dimensional-icons/notebook.png'
import phoneMissing from 'assets/images/three-dimensional-icons/phone-missing.png'
import plus from 'assets/images/three-dimensional-icons/plus.png'
import travel from 'assets/images/three-dimensional-icons/travel.png'

export const detailsRole = [
  {
    id: 'payroll_assistant',
    role: 'Soy responsable de nómina donde trabajo',
    icon: dollar,
    alt: 'Responsable de la nómina',
  },
  {
    id: 'accountant',
    role: 'Contador independiente',
    icon: notebook,
    alt: 'Contador independiente',
  },
  {
    id: 'outsourcer',
    role: 'Outsourcing de nómina',
    icon: phoneMissing,
    alt: 'Responsable de nómina tercerizada',
  },
  {
    id: 'businessman',
    role: 'Empresario o gerente',
    icon: travel,
    alt: 'Empresario o gerente',
  },
  {
    id: 'hr_lead',
    role: 'Trabajo en recursos humanos',
    icon: heart,
    alt: 'Responsable de recursos humanos',
  },
  {
    id: 'other',
    role: 'Otro',
    detail:
      'Selecciona esta opción si no te identificas con ninguno de los roles',
    dataCy: 'other-role-button',
    icon: plus,
    alt: 'Otro rol',
  },
]

export const getValuesToSend = (selectRole, otherRoleInformation) => {
  let valuesToSend = { onboarding_step: 'data' }

  if (selectRole === 'other') {
    valuesToSend = {
      ...valuesToSend,
      user_role: 'other',
      user_role_other_info: otherRoleInformation,
    }
  } else {
    valuesToSend = { ...valuesToSend, user_role: selectRole }
  }

  return valuesToSend
}
