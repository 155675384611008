import { useState } from 'react'

export function useOtherIncomesModal() {
  const [state, setState] = useState({
    open: false,
    electronicPayrollId: '',
  })

  const openOtherIncomesModal = ({ electronicPayrollId }) => {
    setState({
      electronicPayrollId,
      open: true,
    })
  }

  const closeOtherIncomesModal = () => {
    setState({ open: false, electronicPayrollId: '' })
  }

  return {
    otherIncomesModalState: state,
    openOtherIncomesModal,
    closeOtherIncomesModal,
  }
}
