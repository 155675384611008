import * as yup from 'yup'

import { Box, TextField, Typography } from '@mui/material'

import PasswordField from 'components/UI/Formik/CommonFields/PasswordField'
import FormField from 'components/UI/Formik/FormField/Index'

export const getInitialValues = (
  invalidCredentials,
  processType,
  isMissingCredential
) => {
  return invalidCredentials?.reduce((initialValuesObject, entity, index) => {
    initialValuesObject[`entity_${index + 1}`] = {
      [`${processType === 'claim' && isMissingCredential ? 'institution_id' : 'id'}`]:
        entity.credential_id || entity.institution_id,
      username: '',
      password: '',
    }

    return initialValuesObject
  }, {})
}

const baseCredentialsSchema = (processType, isMissingCredential) =>
  yup.object().shape({
    [`${processType === 'claim' && isMissingCredential ? 'institution_id' : 'id'}`]:
      yup.string().required(),
    username: yup.string().required(),
    password: yup.string().required(),
  })

export const getValidationSchema = (
  invalidCredentials = [],
  processType,
  isMissingCredential
) =>
  yup.object().shape(
    invalidCredentials.reduce(
      (schemaFields, _, index) => ({
        ...schemaFields,
        [`entity_${index + 1}`]: baseCredentialsSchema(
          processType,
          isMissingCredential
        ),
      }),
      {}
    )
  )

export const getInvalidCredentialEntities = (
  entitiesArray = [],
  processType
) => {
  if (processType === 'affiliation') {
    return entitiesArray?.filter((entity) => entity?.missing_credentials)
  }

  return entitiesArray
}

const entityLabels = {
  health_provider: 'EPS',
  risk_provider: 'ARL',
  compensation_fund: 'Caja de compensación',
  pension_provider: 'Fondo de pensiones',
}

const DefaultCredentialInput = ({ type, value }) => {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.5),
      })}
    >
      <Typography
        variant="lead2"
        sx={(theme) => ({
          color: theme.palette.black.main,
        })}
      >
        {type === 'provider_type' ? 'Tipo entidad' : 'Nombre entidad'}
        <Typography component="span" color="error.main" variant="lead2">
          *
        </Typography>
      </Typography>
      <TextField value={value} disabled />
    </Box>
  )
}

export const renderAffiliationCredentialsFields = (
  certificatesData,
  processType
) => {
  const invalidCredentials = getInvalidCredentialEntities(
    certificatesData,
    processType
  )

  return invalidCredentials?.map((entity, index) => (
    <Box
      key={entity.provider_type}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(1),
      })}
    >
      {invalidCredentials.length > 1 ? (
        <Typography variant="lead1" color="accent4.main">
          Entidad {index + 1}
        </Typography>
      ) : null}
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: theme.spacing(3),
          [theme.breakpoints.down('tablet')]: {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <DefaultCredentialInput
          type="provider_type"
          value={entityLabels[entity.provider_type]}
        />
        <DefaultCredentialInput
          type="provider_name"
          value={entity.institution_name}
        />
        <FormField
          name={`entity_${index + 1}.username`}
          label="Usuario"
          optional={false}
        />
        <PasswordField
          name={`entity_${index + 1}.password`}
          label="Contraseña"
          optional={false}
        />
      </Box>
    </Box>
  ))
}
