import { useState } from 'react'

import { IconButton, InputAdornment } from '@mui/material'

import Icon from 'components/UI/Icon'

import FormField from '../FormField/Index'

const PasswordField = ({ name, label = 'Contraseña', ...props }) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleOnClick = () => setShowPassword((previousValue) => !previousValue)

  return (
    <FormField
      name={name}
      label={label}
      data-cy={name}
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={handleOnClick}
            edge="end"
            size="small"
            sx={(theme) => ({
              backgroundColor: 'transparent',
              color: theme.palette.black.light,
              '::before': {
                content: 'none',
              },
              '&:focus': {
                border: 'none',
                backgroundColor: 'transparent',
                color: theme.palette.black.light,
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
            })}
          >
            {showPassword ? <Icon name="eye-off" /> : <Icon name="eye" />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default PasswordField
