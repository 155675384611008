import Avatar from 'components/UI/Avatar'
import Link from 'components/UI/MaterialUI/Link'

import { formatCurrency } from 'utils/format'

import * as routes from 'config/routes'

const columnsData = [
  {
    Header: 'Personas',
    accessor: 'name',
    Cell: ({ row }) => {
      const avatarId = (row.index % 5) + 1

      return (
        <>
          <Avatar
            avatarId={avatarId}
            src={row.original.picture}
            sx={(theme) => ({
              marginRight: theme.spacing(1),
            })}
          />
          <Link to={routes.WORKER_SHOW(row.original.id)} color="black.main">
            {`${row.original.name}\u00A0${row.original.last_name}`}
          </Link>
        </>
      )
    },
    alignHeader: 'left',
    alignCell: 'left',
    customMinWidth: '17rem',
  },
  {
    Header: 'Salario base',
    accessor: 'base_salary',
    Cell: ({ row }) => formatCurrency(row.original.base_salary),
    noWrapHeader: true,
    alignHeader: 'left',
    alignCell: 'left',
  },
  {
    Header: 'Cargo',
    accessor: 'position',
    alignHeader: 'left',
    alignCell: 'left',
    customMinWidth: '14rem',
  },
]

export default columnsData
