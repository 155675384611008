import { Box, Typography } from '@mui/material'

import TutorialCard from '../TutorialCard'
import { tutorialCardsConfirmationDian } from '../helpers'

const DianConfirmation = () => {
  return (
    <article>
      <Typography
        variant="h5"
        color="black"
        sx={(theme) => ({ marginBottom: theme.spacing(1) })}
      >
        Proceso de habilitación para emitir Nómina Electrónica
      </Typography>
      <Typography variant="body1">
        Debes verificar el estado de tu SET de pruebas en el portal de la DIAN y
        validar que estás habilitado para emitir tu Nómina Electrónica:
      </Typography>
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing(2.5),
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: theme.spacing(3.5),
        })}
      >
        {tutorialCardsConfirmationDian.map((tutorialCard, index) => (
          <TutorialCard
            key={tutorialCard.id}
            id={tutorialCard.id}
            tutorialNumber={tutorialCard.number}
            title={tutorialCard.title}
            description={tutorialCard.description}
            article={tutorialCard?.article}
            link={tutorialCard?.link}
            dataCy={`tutorial-card-${index + 1}`}
            icon={tutorialCard?.icon}
            alt={tutorialCard?.alt}
            backgroundColor={tutorialCard?.backgroundColor}
            cardTitle={tutorialCard?.cardTitle}
          />
        ))}
      </Box>
    </article>
  )
}

export default DianConfirmation
