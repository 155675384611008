import { useQueryClient } from 'react-query'
import { Navigate } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'

import { getDirtyValues } from 'utils/form'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import useNotifications from 'utils/hooks/useNotifications'

import { getUsers, updateUser } from 'services/organizer/userService'

import * as routes from 'config/routes'

import notification from 'messages/notification'

import useGetColumnsData from './helpers'

const OrganizerUsersIndex = () => {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()
  const { user } = useUser()
  const columnsData = useGetColumnsData()

  const { externalOrganizer, flagsReady } = useFeatureFlags({
    flags: ['externalOrganizer'],
    trackingMode: 'attributes',
    attributes: {
      userEmail: user.email,
    },
  })
  const isExternalOrganizer = flagsReady && externalOrganizer?.value === 'on'

  const fetchData = (pageIndex, search) => {
    return {
      queryKey: ['getAll', pageIndex + 1, search],
      queryFunction: () => getUsers({ page: pageIndex + 1, search }),
    }
  }

  const handleUpdateUser = (oldUser, newUser) => {
    const dirtyValues = getDirtyValues(oldUser, newUser)
    return {
      data: { id: newUser.id, ...dirtyValues },
      mutationFunction: updateUser,
    }
  }

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries(['getAll'], {
        exact: false,
      })
      showSuccessMessage(notification.SETTINGS_USER_PROFILE_SUCCESS)
    },
  }

  if (isExternalOrganizer) {
    return <Navigate to={routes.ORGANIZER_DISABILITY_CLAIMS_INDEX()} />
  }

  return (
    <Page header="Usuarios">
      <Table
        columns={columnsData}
        data={fetchData}
        editable={{
          onUpdateRow: handleUpdateUser,
          hideDelete: true,
          mutationOptions,
        }}
        options={{
          customActionsWidth: 90,
        }}
      />
    </Page>
  )
}

export default OrganizerUsersIndex
