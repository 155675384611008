import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'
import TooltipInfoIcon from 'components/UI/MaterialUI/TooltipInfoIcon'
import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

import {
  getCurrentDistributor,
  subscriptionPaymentCategories,
} from 'utils/company'
import { booleanLookup, booleanLookupOptions, plansOptions } from 'utils/table'

import * as routes from 'config/routes'

export function getColumnsData(distributors, onClick) {
  const distributorsOptions = distributors.map((distributor) => ({
    value: distributor.id,
    label: distributor.name,
    id: distributor.id,
  }))

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'name',
      Cell: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Link
            onClick={() => onClick(row.original)}
            to={routes.DASHBOARD}
            color="black.main"
          >
            {row.original.name}
          </Link>
          {row.original.partner_id ? (
            <TooltipInfoIcon
              title={
                <>
                  <strong>Nombre del partner:</strong>{' '}
                  {row.original.partner_name}
                  <br />
                  <strong> ID del partner:</strong> {row.original.partner_id}
                </>
              }
              enterDelay={300}
              tooltipProps={{
                placement: 'bottom',
                disableInteractive: false,
                tooltipSx: (theme) => ({
                  boxShadow: theme.shadows[4],
                }),
              }}
              icon={
                <Icon
                  name="user-search"
                  sx={(theme) => ({
                    fontSize: '1.5rem',
                    color: theme.palette.primary.dark,
                  })}
                />
              }
              iconProps={{ sx: { cursor: 'default' } }}
            />
          ) : null}
        </Box>
      ),
      customWidth: '15rem',
    },
    {
      Header: 'NIT',
      accessor: 'id_number',
      emptyValue: '-',
      customWidth: '8rem',
      sortType: 'alphanumericSort',
    },
    {
      Header: 'Activo',
      accessor: 'active',
      Cell: ({ row }) => booleanLookup[row.original.active] || null,
      field: 'select',
      editOptions: booleanLookupOptions,
      editable: true,
      customWidth: '6.5rem',
      alignHeader: 'center',
      alignCell: 'center',
      sortType: 'booleanSort',
    },
    {
      Header: 'Suscripción activa',
      accessor: 'active_subscription',
      Cell: ({ row }) =>
        booleanLookup[row.original.active_subscription] || null,
      emptyValue: '-',
      customWidth: '6.5rem',
      alignCell: 'center',
      sortType: 'booleanSort',
    },
    {
      Header: 'Freemium',
      accessor: 'freemium',
      Cell: ({ row }) => booleanLookup[row.original.freemium] || null,
      field: 'switch',
      editOptions: booleanLookupOptions,
      editable: (props) => !props.value,
      customWidth: '6.5rem',
      alignCell: 'center',
      sortType: 'booleanSort',
    },
    {
      Header: 'Categoría de plan',
      accessor: 'plan_category',
      Cell: ({ row }) => row.original.plan_category,
      field: 'select',
      editOptions: plansOptions,
      editable: (props) => props.value === 'workers_range_plan',
      customWidth: '14rem',
      tooltip: (
        <>
          Ver información de la categoría,{' '}
          <TooltipLink href="https://playbook.aleluya.com/categorias-de-precios">
            click aquí.
          </TooltipLink>
        </>
      ),
      noWrapHeader: true,
    },
    {
      Header: 'Distribuidor',
      accessor: 'distributor_id',
      Cell: ({ row }) =>
        getCurrentDistributor(distributors, row.original.distributor_id),
      field: 'select',
      editOptions: distributorsOptions,
      editable: true,
      customWidth: '9.5rem',
    },
    {
      Header: 'Medio de pago',
      accessor: 'payment_category',
      Cell: ({ row }) =>
        subscriptionPaymentCategories[row.original.payment_category]
          ? subscriptionPaymentCategories[row.original.payment_category]
          : row.original.payment_category,
      emptyValue: '-',
      customWidth: '9.5rem',
      sortType: 'alphanumericSort',
    },
  ]

  return columns
}

export const useGetTableActions = (
  handleClick,
  handleDeleteCompany,
  confirm
) => {
  const navigate = useNavigate()

  return [
    (rowData) => ({
      id: 'payments',
      tooltip: 'Ver suscripción',
      icon: <Icon name="list" />,
      onClick: () => {
        handleClick(rowData)
        navigate(routes.SUBSCRIPTION_STATUS())
      },
      hidden: rowData.payment_category === 'distributor_payment',
    }),
    (rowData) => ({
      id: 'add_payment',
      tooltip: 'Agregar pagos y comentarios',
      icon: <Icon name="file-plus" />,
      onClick: () => navigate(routes.ORGANIZER_COMPANY_PAYMENT(rowData.id)),
    }),
    (rowData) => ({
      id: 'delete_company',
      tooltip: 'Eliminar cuenta',
      icon: <Icon name="trash-2" />,
      onClick: () => {
        confirm({
          type: 'warning',
          title: 'Eliminar cuenta',
          description: `Eliminar la cuenta de la empresa ${rowData.name} es permanente y no se podrá deshacer. ¿Estás seguro?`,
          okText: 'Eliminar',
          confirmCheckbox: true,
          onOk: () => handleDeleteCompany(rowData.id),
        })
      },
    }),
  ]
}
