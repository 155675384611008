import { Box, Card, Link, Typography, useTheme } from '@mui/material'

const HelperCard = ({ icon, title, link, color = 'primary' }) => {
  const themeInstance = useTheme()
  const colorKeys = color.split('.')
  const backgroundColor = color?.includes('.')
    ? themeInstance.palette[colorKeys[0]][colorKeys[1]]
    : themeInstance.palette[colorKeys[0]]?.main

  return (
    <Card
      sx={{
        display: 'grid',
        gridTemplateColumns: '5.5rem 1fr',
        height: '5.5rem',
        borderRadius: '1rem',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={(theme) => ({
          backgroundColor,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          paddingTop: theme.spacing(0.75),
        })}
      >
        {icon}
      </Box>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(0, 1),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: theme.spacing(1),
        })}
      >
        <Typography variant="h6">{title}</Typography>
        <Link
          href={link}
          color="accent4.main"
          sx={{
            width: 'fit-content',
          }}
          target="_blank"
        >
          <Typography variant="lead1" color="accent4.main">
            Ver más
          </Typography>
        </Link>
      </Box>
    </Card>
  )
}

export default HelperCard
