import { useQueryClient } from 'react-query'
import * as yup from 'yup'

import useModals from 'components/App/ModalsManager/useModals'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'

import { getCompanyId } from 'utils/company'
import { formatNumberDisplayDate } from 'utils/dateTime'
import useWorkHoursConfigurationService from 'utils/hooks/company/workHoursConfigurationService.js'
import useNotifications from 'utils/hooks/useNotifications'
import { parseNumberToString } from 'utils/number'

import CreateWorkHoursConfigurationModal from './Modals/CreateWorkHoursConfigurationModal'

export const LINK_REF =
  'https://cdn.actualicese.com/normatividad/2021/Leyes/L2101-21.pdf'

export const WORK_HOURS_INFO =
  'https://efectoaleluya.zohodesk.com/portal/es/kb/articles/reducción-de-la-jornada-laboral-en-aleluya'

export const getInitialValues = (dataToUpdate) => ({
  work_hours_configurations_id: dataToUpdate?.id || '',
  initial_day: dataToUpdate?.initial_day || '',
  end_day: dataToUpdate?.end_day || '',
  weekly_hours: dataToUpdate?.weekly_hours || '',
})

export const documentOptions = [
  {
    value: '',
    label: 'Sin documento',
  },
  {
    value: 'cc',
    label: 'Cédula de ciudadanía',
  },
  {
    value: 'ce',
    label: 'Cédula de extranjería',
  },
  {
    value: 'ti',
    label: 'Tarjeta de identidad',
  },
  {
    value: 'pa',
    label: 'Pasaporte',
  },
  {
    value: 'pt',
    label: 'Permiso de protección temporal',
  },
]

export const validationSchema = yup.object({
  initial_day: yup.string().nullable().required('Debes ingresar una fecha'),
  end_day: yup.string().nullable(),
  weekly_hours: yup.number().nullable().required(),
})

export const useTableActions = () => {
  const modals = useModals()
  const confirm = useConfirm()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const { workHoursConfigurationMutation } = useWorkHoursConfigurationService({
    queryOptions: { enabled: false },
  })

  const companyId = getCompanyId()

  const handleCloseModal = () => modals.closeAll()

  const getActionNotification = (action) => {
    handleCloseModal()
    showSuccessMessage(
      `La configuración de jornada laboral fue ${action} correctamente`
    )
  }

  const handleCreateWorkHoursConfiguration = () => {
    modals.openModal({
      id: 'createWorkHoursConfiguration',
      content: (
        <CreateWorkHoursConfigurationModal
          handleCloseModal={handleCloseModal}
        />
      ),
      modalProps: {
        header: 'Crear configuración de jornada laboral',
        onCloseModal: handleCloseModal,
        disableClickOutsideModal: true,
        hideFooter: true,
      },
    })
  }

  const handleEditWorkHoursConfiguration = (dataToUpdate) => {
    modals.openModal({
      id: 'editWorkHoursConfiguration',
      content: (
        <CreateWorkHoursConfigurationModal
          handleCloseModal={handleCloseModal}
          isEditing
          dataToUpdate={dataToUpdate}
        />
      ),
      modalProps: {
        header: 'Editar configuración de jornada laboral',
        onCloseModal: handleCloseModal,
        disableClickOutsideModal: true,
        hideFooter: true,
      },
    })
  }

  const handleDeleteWorkHoursConfiguration = (dataToDelete) => {
    confirm({
      title: 'Eliminar configuración de jornada laboral',
      description:
        'Si eliminas el cambio en la jornada laboral, se recalcularán las horas extras de acuerdo a la ley.',
      okText: 'Eliminar jornada laboral',
      confirmCheckbox: true,
      onOk: async () => {
        await workHoursConfigurationMutation.mutateAsync(
          {
            mutationMethod: 'DELETE',
            workHoursConfigurationsId: dataToDelete?.id,
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries([
                'getWorkHoursConfiguration',
                companyId,
              ])
              getActionNotification('eliminada')
            },
          }
        )
      },
    })
  }

  return {
    handleCreateWorkHoursConfiguration,
    handleEditWorkHoursConfiguration,
    handleDeleteWorkHoursConfiguration,
    getActionNotification,
  }
}

export const columnData = [
  {
    Header: 'Fecha inicial',
    accesor: 'initial_day',
    Cell: ({ row }) => formatNumberDisplayDate(row.original.initial_day),
  },
  {
    Header: 'Fecha final',
    accesor: 'end_day',
    Cell: ({ row }) => formatNumberDisplayDate(row.original.end_day),
  },
  {
    Header: 'Número de horas',
    accesor: 'weekly_hours',
    Cell: ({ row }) => parseNumberToString(row.original.weekly_hours),
  },
]

export const getTableActions = (
  createConfiguration,
  editConfiguration,
  deleteConfiguration
) => {
  return [
    {
      id: 'addWorkHorsConfiguration',
      isFreeAction: true,
      tooltip: 'Nuevo rango de fechas',
      onClick: createConfiguration,
    },
    (rowData) => ({
      id: 'editWorkHorsConfiguration',
      tooltip: 'Editar configuración',
      icon: <Icon name="pencil-line" />,
      onClick: () => editConfiguration(rowData),
    }),
    (rowData) => ({
      id: 'deleteWorkHorsConfiguration',
      tooltip: 'Eliminar configuración',
      icon: <Icon name="trash-2" />,
      onClick: () => deleteConfiguration(rowData),
    }),
  ]
}
