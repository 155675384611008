import * as yup from 'yup'

import Avatar from 'components/UI/Avatar'
import FormField from 'components/UI/Formik/FormField/Index'
import Link from 'components/UI/MaterialUI/Link'

import { formatNumberDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import { getMinimumSalary, wageCategories } from 'utils/worker'

import * as routes from 'config/routes'

import WageCategoryField from '../Fields/Category'

export const columnsData = [
  {
    Header: 'Nombre',
    accessor: 'name',
    Cell: ({ row }) => {
      const avatarId = (row.index % 5) + 1

      return (
        <>
          <Avatar
            avatarId={avatarId}
            src={row.original.picture}
            sx={(theme) => ({
              marginRight: theme.spacing(1),
            })}
          />
          <Link
            to={routes.WORKER_SHOW(row.original.worker_id)}
            color="black.main"
          >
            {row.original.name}
          </Link>
        </>
      )
    },
    customWidth: '13rem',
  },
  {
    Header: 'Tipo de salario',
    accessor: 'wage_category',
    editable: true,
    Cell: ({ row }) =>
      wageCategories[row.original.wage_category]
        ? wageCategories[row.original.wage_category]
        : row.original.wage_category,
    Edit: ({ row }) => (
      <WageCategoryField
        name="wage_category"
        contractCategory={row.original.contract_category}
      />
    ),
    customWidth: '11.5rem',
  },
  {
    Header: 'Salario actual',
    accessor: 'base_salary',
    Cell: ({ row }) => formatCurrency(row.original.base_salary),
    noWrapHeader: true,
    customWidth: '9rem',
  },
  {
    Header: 'Subsidio de transporte',
    accessor: 'transport_subsidy',
    editable: true,
    Cell: ({ row }) => (row.original.transport_subsidy ? 'Sí' : 'No'),
    Edit: () => (
      <FormField
        name="transport_subsidy"
        variant="radio-group"
        options={[
          {
            value: true,
            label: 'Sí',
          },
          {
            value: false,
            label: 'No',
          },
        ]}
        row
      />
    ),
    customWidth: '10.7rem',
  },
  {
    Header: 'Nuevo salario',
    accessor: 'new_base_salary',
    editable: true,
    Cell: ({ row }) => formatCurrency(row.original.new_base_salary),
    Edit: () => <FormField name="new_base_salary" variant="currency" />,
    customWidth: '11rem',
  },
  {
    Header: 'Aplica desde',
    accessor: 'date_new_salary',
    editable: true,
    Cell: ({ row }) => formatNumberDisplayDate(row.original.date_new_salary),
    Edit: () => <FormField name="date_new_salary" variant="datepicker" />,
    customWidth: '12rem',
  },
]

export const validationSchema = yup.object({
  wage_category: yup.string().nullable().required(),
  date_new_salary: yup.date().nullable().required(),
  new_base_salary: yup.number().nullable().required(),
  transport_subsidy: yup
    .boolean()
    .nullable()
    .when(['wage_category'], {
      is: (wageCategory) => wageCategory === 'integral_salary',
      then: yup
        .boolean()
        .nullable()
        .test(
          'transport_subsidy_value',
          'Según el tipo de salario, no debe incluir Subsidio de Transporte',
          (value) => !value
        ),
      otherwise: yup.boolean().nullable().required(),
    })
    .when(['new_base_salary'], {
      is: (newBaseSalary) =>
        newBaseSalary > getMinimumSalary({ multiplier: 2 }),
      then: yup
        .boolean()
        .nullable()
        .test(
          'transport_subsidy_value',
          'Salario supera tope máximo para Subsidio de Transporte',
          (value) => !value
        ),
      otherwise: yup.boolean().nullable().required(),
    })
    .required(),
})
