import {
  Box,
  Button,
  LinearProgress,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'

import Avatar from 'components/UI/Avatar'
import FilterButton from 'components/UI/Button/FilterButton'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { WORKER_SHOW } from 'config/routes'

const OnboardingStateButton = ({ data, disabled, onClick, id }) => {
  const themeInstance = useTheme()
  const defaultConfiguration = {
    backgroundColor: alpha(themeInstance.palette.black.light, 0.4),
    color: themeInstance.palette.black.main,
    label: 'No aplica',
  }
  const stateConfiguration = {
    unstarted: {
      label: 'Sin comenzar',
      color: themeInstance.palette.black.main,
      backgroundColor: themeInstance.palette.black.light,
    },
    in_progress: {
      label: 'En proceso',
      color: themeInstance.palette.info.dark,
      backgroundColor: alpha(themeInstance.palette.info.light, 0.4),
    },
    review: {
      label: 'Revisar',
      color: themeInstance.palette.error.dark,
      backgroundColor: alpha(themeInstance.palette.error.light, 0.4),
    },
    finished: {
      label: 'Todo OK!',
      color: themeInstance.palette.primary.dark,
      backgroundColor: alpha(themeInstance.palette.primary.light, 0.4),
    },
    not_apply: {
      label: defaultConfiguration.label,
      color: defaultConfiguration.color,
      backgroundColor: defaultConfiguration.backgroundColor,
    },
    occurrence: {
      label: 'Novedad',
      color: themeInstance.palette.complementary2.dark,
      backgroundColor: alpha(themeInstance.palette.complementary2.light, 0.4),
    },
  }

  return (
    <Button
      sx={(theme) => ({
        width: '14rem',
        marginY: theme.spacing(2),
        backgroundColor:
          stateConfiguration[data?.status]?.backgroundColor ||
          defaultConfiguration.backgroundColor,
        color:
          stateConfiguration[data?.status]?.color || defaultConfiguration.color,
        opacity: disabled ? 0.4 : 1,
        ...(disabled && {
          backgroundColor: `${
            stateConfiguration[data?.status]?.backgroundColor
          } !important`,
          color: `${stateConfiguration[data?.status]?.color} !important`,
          '& > span > svg': {
            color: `${stateConfiguration[data?.status]?.color} !important`,
          },
        }),
      })}
      endIcon={<Icon name="chevron-right" />}
      onClick={onClick}
      disabled={disabled}
      id={id}
    >
      {stateConfiguration[data?.status]?.label || defaultConfiguration.label}
    </Button>
  )
}

export const getColumns = ({
  openNewWorkerModal,
  openAffiliationModal,
  openDiscardedModal,
  openInProcessModal,
  openAffiliationNoveltyModal,
  openElectronicSignatureModal,
  openFinalSigningsStatusModal,
  openAffiliationFilesModal,
  openUpdateCredentialsModal,
  openBackgroundCheckInitialConsultModal,
  openBackgroundCheckModal,
  openCommentsModal,
  handleCloseTour,
}) => {
  return [
    {
      Header: 'Personas',
      accessor: 'name',
      Cell: ({ row }) => {
        const avatarId = (row.index % 5) + 1
        return (
          <Box
            id="tour_start"
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(0.75),
              width: '100%',
              marginBottom: theme.spacing(1.75),
              paddingTop: theme.spacing(1.75),
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'grid',
                alignItems: 'start',
                gap: theme.spacing(1.3),
                gridTemplateColumns: '2.5rem 1fr',
              })}
            >
              <Avatar avatarId={avatarId} src={row.original.worker.picture} />
              <Box>
                <Link
                  to={WORKER_SHOW(row.original.worker.id)}
                  color="black.dark"
                >
                  <Typography variant="h6" color="black.dark">
                    {row.original.worker.full_name}
                  </Typography>
                </Link>
                <Typography variant="small" color="black.main">
                  {row.original.worker.position}
                </Typography>
              </Box>
            </Box>
            {!row.original.worker.finished_registration ? (
              <Box>
                <Button
                  id="finished_registration"
                  variant="outlined"
                  size="small"
                  sx={(theme) => ({
                    marginLeft: theme.spacing(6.3),
                  })}
                  onClick={() => {
                    handleCloseTour()
                    openNewWorkerModal({
                      workerId: row.original.worker.id,
                      workerName: row.original.worker.full_name,
                    })
                  }}
                  endIcon={<Icon name="circle-check" />}
                >
                  Completar
                </Button>
              </Box>
            ) : (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(2),
                })}
              >
                <Box
                  sx={(theme) => ({
                    width: '2.25rem',
                    paddingLeft: theme.spacing(0.5),
                  })}
                >
                  <Typography variant="h6">
                    {`${row.original.onboarding_percentage}%`}
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={row.original.onboarding_percentage}
                  sx={(theme) => ({
                    width: '8.875rem',
                    backgroundColor: theme.palette.white.dark,
                  })}
                  color="primary"
                />
              </Box>
            )}
          </Box>
        )
      },
      customMinWidth: '14rem',
    },
    {
      Header: 'Validación antecedentes',
      accessor: 'background_check',
      Cell: ({ row }) => (
        <OnboardingStateButton
          id="background_check"
          data={row.original.background_check}
          disabled={
            !row.original.worker.finished_registration ||
            (!row.original.background_check.id &&
              (row.original.completed || row.original.canceled))
          }
          onClick={() => {
            handleCloseTour()
            if (
              row.original.background_check?.status === 'not_apply' ||
              (row.original.background_check?.status === 'finished' &&
                !row.original.background_check?.id)
            ) {
              openDiscardedModal({
                process: 'backgroundCheck',
                backgroundCheck: row.original.background_check,
                onboardingId: row.original.id,
                workerName: row.original.worker.full_name,
                workerId: row.original.worker.id,
              })
            } else if (row.original.background_check?.status === 'unstarted') {
              openBackgroundCheckInitialConsultModal({
                backgroundCheck: row.original.background_check,
                onboardingId: row.original.id,
                workerName: row.original.worker.full_name,
                workerId: row.original.worker.id,
              })
            } else {
              openBackgroundCheckModal({
                onboardingId: row.original.id,
                workerId: row.original.worker.id,
                workerName: row.original.worker.full_name,
                backgroundCheck: row.original.background_check,
              })
            }
          }}
        />
      ),
      alignHeader: 'center',
      alignCell: 'center',
      disableSortBy: true,
    },
    {
      accessor: 'electronic_signature',
      Header: 'Firma de documentos',
      Cell: ({ row }) => (
        <OnboardingStateButton
          id="electronic_signature"
          data={row.original.electronic_signature}
          disabled={
            !row.original.worker.finished_registration ||
            (!row.original.electronic_signature.id &&
              (row.original.completed || row.original.canceled))
          }
          onClick={() => {
            handleCloseTour()
            if (
              row.original.electronic_signature?.status === 'not_apply' ||
              (row.original.electronic_signature?.status === 'finished' &&
                !row.original.electronic_signature?.id)
            ) {
              openDiscardedModal({
                process: 'electronicSignature',
                electronicSignature: row.original.electronic_signature,
                onboardingId: row.original.id,
                workerName: row.original.worker.full_name,
                workerId: row.original.worker.id,
              })
            } else if (
              row.original.electronic_signature?.status === 'unstarted'
            ) {
              openElectronicSignatureModal({
                electronicSignature: row.original.electronic_signature,
                onboardingId: row.original.id,
                workerName: row.original.worker.full_name,
                workerId: row.original.worker.id,
                workerEmail: row.original.worker.email,
              })
            } else {
              openFinalSigningsStatusModal({
                electronicSignature: row.original.electronic_signature,
                onboardingId: row.original.id,
                workerName: row.original.worker.full_name,
                workerId: row.original.worker.id,
              })
            }
          }}
        />
      ),
      noWrapHeader: true,
      alignHeader: 'center',
      alignCell: 'center',
      disableSortBy: true,
    },
    {
      Header: 'Afiliación seguridad social',
      accessor: 'affiliation',
      Cell: ({ row }) => (
        <OnboardingStateButton
          id="affiliation"
          data={row.original.affiliation}
          disabled={
            !row.original.worker.finished_registration ||
            (!row.original.affiliation.id &&
              (row.original.completed || row.original.canceled))
          }
          onClick={() => {
            handleCloseTour()
            if (
              row.original.affiliation?.status === 'not_apply' ||
              (row.original.affiliation?.status === 'finished' &&
                !row.original.affiliation?.id)
            ) {
              openDiscardedModal({
                process: 'affiliation',
                affiliation: row.original.affiliation,
                onboardingId: row.original.id,
                workerName: row.original.worker.full_name,
                workerId: row.original.worker.id,
              })
            } else if (row.original.affiliation?.status === 'occurrence') {
              openAffiliationNoveltyModal(row.original.worker.id)
            } else if (
              ['in_progress', 'finished'].includes(
                row.original.affiliation?.status
              )
            ) {
              openInProcessModal({
                workerId: row.original.worker.id,
              })
            } else if (row.original.affiliation?.status === 'pending') {
              openCommentsModal({
                affiliation: row.original.affiliation,
                onboardingId: row.original.id,
                workerName: row.original.worker.full_name,
                workerId: row.original.worker.id,
              })
            } else if (row.original.affiliation?.status === 'review') {
              if (
                row.original.affiliation?.rejected_reason ===
                'error_in_credentials'
              ) {
                openUpdateCredentialsModal(
                  row.original.worker.id,
                  'affiliation'
                )
              } else {
                openAffiliationFilesModal(row.original.worker.id)
              }
            } else {
              openAffiliationModal({
                affiliation: row.original.affiliation,
                onboardingId: row.original.id,
                workerName: row.original.worker.full_name,
                workerId: row.original.worker.id,
              })
            }
          }}
        />
      ),
      noWrapHeader: true,
      alignHeader: 'center',
      alignCell: 'center',
      disableSortBy: true,
    },
  ]
}

const statusOptions = [
  {
    label: 'Completado',
    value: 'completed',
  },
  {
    label: 'En proceso',
    value: 'in_progress',
  },
  {
    label: 'Descartado',
    value: 'canceled',
  },
  {
    label: 'Sin comenzar',
    value: 'unstarted',
  },
]

export const getActions = ({
  openHiringAlertModal,
  onboardingStatus,
  setOnboardingStatus,
  handleAddWorker,
  handleCloseTour,
}) => {
  return [
    (rowData) => ({
      id: 'completeAction',
      tooltip: 'Completar',
      icon: <Icon name="check" />,
      buttonProps: {
        size: 'large',
      },
      onClick: () => {
        handleCloseTour()
        openHiringAlertModal({
          actionType: 'complete',
          onboardingId: rowData.id,
        })
      },
      disabled:
        !rowData.worker.finished_registration ||
        rowData.completed ||
        rowData.canceled,
    }),
    (rowData) => ({
      id: 'discardAction',
      tooltip: 'Descartar',
      icon: <Icon name="x" />,
      buttonProps: {
        size: 'large',
        id: 'tour_end',
      },
      onClick: () => {
        handleCloseTour()
        openHiringAlertModal({
          actionType: 'discard',
          onboardingId: rowData.id,
        })
      },
      disabled:
        !rowData.worker.finished_registration ||
        rowData.canceled ||
        rowData.completed,
    }),
    {
      id: 'contractFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <FilterButton
          defaultLabel="Estado"
          value={onboardingStatus}
          onChange={setOnboardingStatus}
          options={statusOptions}
        />
      ),
    },
    {
      id: 'add_worker',
      isFreeAction: true,
      Component: (
        <Button
          onClick={handleAddWorker}
          variant="contained"
          endIcon={<Icon name="user-plus" />}
        >
          Agregar persona
        </Button>
      ),
    },
  ]
}

export const onboardingTourSteps = [
  {
    highlightedSelectors: ['#tour_start', '#tour_end'],
    resizeObservables: ['#tour_start', '#tour_end'],
    selector: '#tour_start',
    position: 'bottom',
    content: (
      <>
        <Typography
          variant="h5"
          color="black.main"
          sx={(theme) => ({ marginBottom: theme.spacing(1) })}
        >
          Wujuuu!
        </Typography>
        <Typography>
          Ya estás listo para la acción. Da clic en “Sin comenzar” y{' '}
          <b>Valida los antecedentes</b> de la persona, envíale un documento
          para que lo <b>Firme digitalmente</b> y{' '}
          <b>Afílialo a Seguridad Social</b>
        </Typography>
      </>
    ),
  },
]
