import { Box } from '@mui/material'

import Button from 'components/UI/Button/Button'

const Footer = ({ primaryButtonText, onClick, page = false }) => {
  return (
    <Box>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(1.5),
        })}
      >
        <Button size={page ? 'medium' : 'small'} onClick={onClick}>
          {primaryButtonText}
        </Button>
      </Box>
    </Box>
  )
}

export default Footer
