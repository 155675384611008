import { Box, ButtonBase, IconButton, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { capitalize } from 'utils/general'

const CalendarHeader = ({
  title,
  prevButtonProps,
  nextButtonProps,
  setShowYearGrid,
  showYearGrid,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <IconButton
        aria-label={prevButtonProps['aria-label']}
        onFocus={prevButtonProps.onFocus}
        onBlur={prevButtonProps.onBlur}
        onClick={prevButtonProps.onPress}
        disabled={prevButtonProps.isDisabled}
        size="small"
        sx={{
          visibility: 'visible',
          opacity: 1,
          transition: 'visibility 0s linear 0s, opacity 300ms',
          ...(showYearGrid && {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s linear 300ms, opacity 300ms',
          }),
        }}
      >
        <Icon name="chevron-left" />
      </IconButton>
      <ButtonBase onClick={() => setShowYearGrid((prevValue) => !prevValue)}>
        <Typography variant="lead1" color="black.main">
          {capitalize(title)}
        </Typography>
        <Icon
          name="chevron-down"
          sx={(theme) => ({
            marginLeft: theme.spacing(0.5),
            fontSize: '1rem',
            transform: 'rotate(0deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            ...(showYearGrid && {
              transform: 'rotate(180deg)',
            }),
          })}
        />
      </ButtonBase>
      <IconButton
        size="small"
        aria-label={nextButtonProps['aria-label']}
        onFocus={nextButtonProps.onFocus}
        onBlur={nextButtonProps.onBlur}
        onClick={nextButtonProps.onPress}
        disabled={nextButtonProps.isDisabled}
        sx={{
          visibility: 'visible',
          opacity: 1,
          transition: 'visibility 0s linear 0s, opacity 300ms',
          ...(showYearGrid && {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s linear 300ms, opacity 300ms',
          }),
        }}
      >
        <Icon name="chevron-right" />
      </IconButton>
    </Box>
  )
}

export default CalendarHeader
