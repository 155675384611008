import { useUser } from 'components/App/UserContext/useUser'

import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import * as routes from 'config/routes'

import useCloseDrawer from '../../useCloseDrawer'

export default function useOrganizer() {
  const { user } = useUser()

  const { humanResourcesSA, externalOrganizer, flagsReady } = useFeatureFlags({
    flags: ['humanResourcesSA', 'externalOrganizer'],
    trackingMode: 'attributes',
    attributes: {
      userEmail: user.email,
    },
  })
  const isExternalOrganizer = flagsReady && externalOrganizer?.value === 'on'

  const handleCloseMobileDrawer = useCloseDrawer()

  return [
    {
      key: 'companies',
      icon: 'factory',
      text: 'Empresas',
      hidden: isExternalOrganizer,
      to: routes.ORGANIZER_COMPANY_INDEX(),
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [routes.ORGANIZER_COMPANY_PAYMENT()],
    },
    {
      key: 'users',
      icon: 'users',
      text: 'Usuarios',
      hidden: isExternalOrganizer,
      to: routes.ORGANIZER_USERS(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'files',
      icon: 'file-cog',
      text: 'Acciones generales',
      hidden: isExternalOrganizer,
      to: routes.ORGANIZER_GENERAL_ACTIONS(),
      onClick: () => handleCloseMobileDrawer(),
    },
    {
      key: 'partners',
      icon: 'heart-handshake',
      text: 'Partners',
      hidden: isExternalOrganizer,
      to: routes.ORGANIZER_PARTNER_INDEX(),
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [
        routes.ORGANIZER_PARTNER_SHOW(),
        routes.ORGANIZER_PARTNER_EDIT_PAYMENT(),
      ],
    },
    {
      key: 'organizer_disability_claims',
      text: 'Reclamaciones',
      icon: 'hand-coins',
      to: routes.ORGANIZER_DISABILITY_CLAIMS_INDEX(),
      onClick: () => handleCloseMobileDrawer(),
      pathsToHighlight: [
        routes.ORGANIZER_DISABILITY_CLAIMS_INDEX(),
        routes.ORGANIZER_DISABILITY_CLAIMS_SHOW(),
      ],
    },
    ...(flagsReady && humanResourcesSA?.value === 'on'
      ? [
          {
            key: 'afiliations',
            icon: 'shield-check',
            text: (
              <span>
                Afiliaciones a <br />
                seguridad social
              </span>
            ),
            to: routes.ORGANIZER_AFFILIATIONS(),
            onClick: () => handleCloseMobileDrawer(),
            pathsToHighlight: [routes.ORGANIZER_WORKER_SHOW()],
          },
        ]
      : []),
  ]
}
