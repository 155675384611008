import { useQueryClient } from 'react-query'
import { Navigate, useNavigate } from 'react-router-dom'

import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'
import Loading from 'components/UI/Loading/Loading'
import Page from 'components/UI/Page/Page'

import { formatCurrency } from 'utils/format'
import useCompanyHolisticPayrollService from 'utils/hooks/HolisticPayroll/CompayService'
import useFeatureFlags from 'utils/hooks/useFeatureFlags'
import useNotifications from 'utils/hooks/useNotifications'

import Chepito from 'assets/images/views/holisticPayroll/chepito.png'

import * as routes from 'config/routes'

import useDisabilityClaimsModals from './Commons/useDisabilityClaimsModals'
import { DialogFrame, claimsSectionCardInfo } from './helpers'

const DisabilityClaims = () => {
  const Theme = useTheme()
  const { company, listenInBackground } = useUser()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { showSuccessMessage, showInfoMessage } = useNotifications()
  const { openClaimSubmissionSuccessModal } = useDisabilityClaimsModals()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { disabilityClaimsV2, flagsReady } = useFeatureFlags({
    flags: ['disabilityClaimsV2'],
    trackingMode: 'attributes',
    attributes: {
      companyId: company.id,
    },
  })

  const companyId = company.id
  const hasClaimsId = Boolean(company.company_claims_detail_id)

  const {
    companyHolisticPayrollQuery: disabilityClaimsQuery,
    companyHolisticPayrollMutation: disabilityClaimsMutation,
  } = useCompanyHolisticPayrollService({
    serviceParams: {
      queryMethod: 'POST',
      queryKey: hasClaimsId
        ? ['getDisabilityClaimsData', companyId]
        : ['createDisabilityClaimsData', companyId],
      claimsDetailId: company.company_claims_detail_id,
    },
    queryOptions: {
      enabled: flagsReady && disabilityClaimsV2.value === 'on',
      onSuccess: async () => {
        if (!hasClaimsId) {
          await queryClient.invalidateQueries(['companyInformation', companyId])
        }
      },
    },
  })

  const disabilityClaimsQueryData = disabilityClaimsQuery?.data
  const hasClaims = disabilityClaimsQueryData?.has_claims
  const estimatedValueToClaim =
    disabilityClaimsQueryData?.estimated_total_value_to_claim || 0

  const disabilityClaimsSocketHandler = (websocketResult, connectionRef) => {
    if (websocketResult) {
      if (websocketResult.novelty_claims.failure) {
        showInfoMessage(websocketResult.novelty_claims.failure)
      }

      if (websocketResult.novelty_claims.success) {
        showSuccessMessage(websocketResult.novelty_claims.success)
        navigate(routes.HOLISTIC_PAYROLL_DISABILITY_CLAIMS_DETAILS())
        openClaimSubmissionSuccessModal()
      }

      connectionRef.off('value')
    }
  }

  const handleOnClick = () => {
    disabilityClaimsMutation.mutate(
      {
        mutationMethod: 'POST',
        claimsDetailId: company.company_claims_detail_id,
      },
      {
        onSuccess: async () => {
          await listenInBackground(
            `companies/${companyId}/company_claims_details/${company.company_claims_detail_id}`,
            disabilityClaimsSocketHandler
          )
          showSuccessMessage(
            'Estamos consultado tu información, te avisaremos cuando esté todo listo'
          )
        },
      }
    )
  }

  if (flagsReady && disabilityClaimsV2?.value === 'off') {
    return <Navigate to={routes.DASHBOARD} />
  }

  if (hasClaims) {
    return <Navigate to={routes.HOLISTIC_PAYROLL_DISABILITY_CLAIMS_DETAILS()} />
  }

  return !hasClaimsId || disabilityClaimsQuery.isLoading ? (
    <Loading />
  ) : (
    <Box
      sx={({ breakpoints }) => ({
        display: 'grid',
        gridTemplateColumns: '1fr',
        height: '100%',
        [breakpoints.up('md')]: {
          gridTemplateColumns: '60% 40%',
        },
      })}
    >
      <Page
        documentTitle="Reclamaciones"
        rootSx={{
          overflow: 'clip',
          backgroundColor: 'accent1.light',
          height: '100%',
        }}
        grid
      >
        <Box
          sx={({ spacing }) => ({
            gridColumn: '1 / -1',
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(3),
            justifyContent: 'center',
          })}
        >
          <Typography variant="h1">
            Somos el Chepito de las reclamaciones
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Button
              onClick={handleOnClick}
              loading={disabilityClaimsMutation.isLoading}
              endIcon={<Icon name="move-right" />}
              sx={({ breakpoints }) => ({
                [breakpoints.down('tablet')]: {
                  width: '100%',
                },
              })}
            >
              Iniciar reclamación
            </Button>
          </Box>
          <Typography color="black.dark">
            Te ayudamos a recuperar el dinero de tus incapacidades en tiempo
            record, para que tengas la platica en tu cuenta en estos 3 sencillos
            pasos:
          </Typography>
          <Box
            sx={({ spacing, breakpoints }) => ({
              display: 'flex',
              flexDirection: 'column',
              rowGap: spacing(4),
              marginTop: spacing(2.5),
              marginBottom: spacing(4),
              [breakpoints.up('md')]: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                columnGap: spacing(4),
                rowGap: 0,
              },
            })}
          >
            {claimsSectionCardInfo.map((info) => {
              const boxShadowColor =
                Theme.palette[info.numberBackgroundColor.split('.')[0]][
                  info.numberBackgroundColor.split('.')[1]
                ]
              return (
                <Paper
                  key={info.id}
                  sx={({ palette, spacing }) => ({
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingX: spacing(2.25),
                    paddingY: spacing(2),
                    borderRadius: '0.32rem',
                    border: `0.063rem solid ${palette.black.main}`,
                    boxShadow: `0.5rem 0.5rem ${boxShadowColor}`,
                  })}
                >
                  <Box
                    sx={({ breakpoints }) => ({
                      position: 'absolute',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: info.numberBackgroundColor,
                      width: '2.5rem',
                      height: '2.5rem',
                      borderRadius: '100%',
                      top: '-13%',
                      left: '8%',
                      [breakpoints.up('md')]: {
                        width: '1.5rem',
                        height: '1.5rem',
                        top: '-8%',
                        left: '8%',
                      },
                    })}
                  >
                    <Typography
                      sx={({ breakpoints }) => ({
                        fontSize: '1.438rem',
                        [breakpoints.up('md')]: { fontSize: '0.75rem' },
                      })}
                    >
                      <b>{info.number}</b>
                    </Typography>
                  </Box>
                  <Box
                    component="img"
                    loading="lazy"
                    src={info.icon}
                    alt={info.alt}
                    width={64}
                    height={64}
                  />
                  <Typography
                    textAlign="center"
                    variant="h6"
                    sx={(theme) => ({ marginTop: theme.spacing(2) })}
                  >
                    {info.title}
                  </Typography>
                </Paper>
              )
            })}
          </Box>
          <Box>
            <Typography
              sx={({ breakpoints }) => ({
                fontSize: '1.5rem',
                fontFamily: 'Montserrat',

                [breakpoints.down('tablet')]: {
                  textAlign: 'center',
                },
              })}
            >
              Mejora tu <b>flujo de caja</b> y olvídate de todo lo operativo de
              las reclamaciones.
            </Typography>
          </Box>

          <Box sx={({ spacing }) => ({ marginTop: spacing(1) })}>
            <Typography
              color="black.dark"
              sx={({ spacing }) => ({ marginBottom: spacing(2) })}
            >
              <b>
                *Recuerda que de acuerdo a tu plan, Aleluya cobra un porcentaje
                de comisión por incapacidad o licencia reclamada:
              </b>
            </Typography>
            <Typography
              color="black.dark"
              sx={({ spacing }) => ({ marginBottom: spacing(0.5) })}
            >
              1. Si tienes un plan de Nómina Holística: 10% del valor recaudado.
            </Typography>
            <Typography color="black.dark">
              2. Si tienes un plan DIFERENTE a Nómina Holística: 15% del valor
              recaudado.
            </Typography>
          </Box>
        </Box>
      </Page>

      {!isMobile ? (
        <Box
          sx={({ palette, spacing }) => ({
            backgroundColor: palette.accent2.light,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingY: spacing(4),
          })}
        >
          <DialogFrame>
            <Typography sx={{ fontSize: '1.25rem' }}>
              Tienes una platica pa reclamar:
            </Typography>
            <Typography
              variant="h2"
              color="accent4.main"
              sx={({ spacing }) => ({ marginTop: spacing(2) })}
            >
              {formatCurrency(estimatedValueToClaim)}
            </Typography>
          </DialogFrame>
          <Box component="img" src={Chepito} sx={{ width: '95%' }} />
        </Box>
      ) : null}
    </Box>
  )
}

export default DisabilityClaims
