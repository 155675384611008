import * as yup from 'yup'

import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'
import TooltipLink from 'components/UI/MaterialUI/TooltipLink'

import { yupLocaleES } from 'utils/form'
import { formatCurrency } from 'utils/format'

import { ORGANIZER_PARTNER_SHOW } from 'config/routes'

const PLAN_CATEGORY = 'partner_plan20220824'

yup.setLocale(yupLocaleES)

export const validationSchema = yup.object({
  name: yup.string().trim().required(),
  id_number: yup.string().trim().required(),
  email: yup.string().email().required(),
  discount: yup.number().min(0).max(100).required(),
  base_discount_type: yup.string().required(),
  base_discount: yup.number().min(0).required(),
})

export const getInitialValues = (partner) => {
  let baseDiscountType = 'percentage'

  if (partner.plan_category && partner.plan_category !== PLAN_CATEGORY) {
    baseDiscountType = 'value'
  }

  return {
    base_discount_type: baseDiscountType,
    discount: 0,
    base_discount: 0,
  }
}

export const columnsData = [
  {
    Header: 'Nombre',
    accessor: 'name',
    Cell: ({ row }) => (
      <Link to={ORGANIZER_PARTNER_SHOW(row.original.id)} color="black.main">
        {row.original.name}
      </Link>
    ),
  },
  {
    Header: 'No. Identificación',
    accessor: 'id_number',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Categoría de plan',
    accessor: 'plan_category',
    customWidth: '14rem',
    tooltip: (
      <>
        Ver información de la categoría,{' '}
        <TooltipLink href="https://playbook.aleluya.com/categorias-de-precios">
          click aquí.
        </TooltipLink>
      </>
    ),
    noWrapHeader: true,
  },
  {
    Header: 'Desc. por persona',
    accessor: 'discount',
    Cell: ({ row }) => `${row.original.discount}%`,
    alignCell: 'right',
    alignHeader: 'right',
  },
  {
    Header: 'Desc. base',
    accessor: 'base_discount',
    Cell: ({ row }) =>
      row.original.base_discount_type === 'value'
        ? formatCurrency(row.original.base_discount)
        : `${row.original.base_discount}%`,
    alignCell: 'right',
    alignHeader: 'right',
  },
  {
    Header: 'Activo',
    accessor: 'active',
    Cell: ({ row }) => (row.original.active ? 'Si' : 'No'),
    alignCell: 'center',
    alignHeader: 'center',
  },
]

export const getTableActions = (setModalState) => {
  return [
    {
      id: 'newPartner',
      icon: <Icon name="plus" />,
      tooltip: 'Nuevo Partner',
      onClick: () => setModalState({ open: true, partner: {} }),
      isFreeAction: true,
    },
    {
      id: 'editPartner',
      icon: <Icon name="pencil-line" />,
      tooltip: 'Editar Partner',
      onClick: (rowData) => setModalState({ open: true, partner: rowData }),
    },
  ]
}

export const customBaseDiscountType = (isEdit, partner) => {
  const values = []

  if (isEdit && partner.plan_category !== PLAN_CATEGORY) {
    values.push(
      { label: 'Valor monetario ($)', value: 'value' },
      { label: 'Porcentaje (%)', value: 'percentage' }
    )
  } else {
    values.push({ label: 'Porcentaje (%)', value: 'percentage' })
  }

  return values
}
