import { Formik } from 'formik'
import { Fragment } from 'react'

import { Box, Paper, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Modal from 'components/UI/Modal/Modal'

import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'
import useNotifications from 'utils/hooks/useNotifications'

const TRANSLATED_LABELS = {
  enjoyed_days: 'Vacaciones',
  service_bonus: 'Prima de servicios',
  severance_interests: 'Intereses a las cesantías',
  severance: 'Cesantías',
}

export function ProvisionsAdjustmentModal({
  state,
  handleClose,
  invalidateElectronicPayrollQuery,
}) {
  const { electronicPayrollId, open, provisions } = state

  const { showSuccessMessage } = useNotifications()
  const { electronicPayrollMutation } = useElectronicPayrollPeriodService({
    queryOptions: { enabled: false },
  })

  const handleSubmit = (values) => {
    electronicPayrollMutation.mutate(
      {
        mutationMethod: 'PATCH',
        mutationKey: 'updateElectronicPayrollProvisions',
        electronicPayrollId,
        provisionAdjustment: values,
      },
      {
        onSuccess: ({ message }) => {
          showSuccessMessage(message)
          invalidateElectronicPayrollQuery()
        },
      }
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={provisions}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit: onSubmit }) => (
        <Modal
          open={open}
          header="Ajuste de provisiones"
          onOk={onSubmit}
          okText="Guardar"
          onCancel={handleClose}
          isLoading={electronicPayrollMutation.isLoading}
          dialogProps={{
            fullWidth: true,
            maxWidth: 'tablet',
          }}
        >
          <Typography color="black.dark">
            Aquí podrás modificar y agregar el ajuste de pago de tus
            provisiones.
          </Typography>

          <Paper
            sx={(theme) => ({
              borderRadius: '1rem',
              marginTop: theme.spacing(4),
              padding: theme.spacing(2.5, 4),
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              rowGap: theme.spacing(3),
              alignItems: 'center',
            })}
          >
            <Box sx={{ display: 'contents' }}>
              <Typography variant="lead1">Concepto</Typography>
              <Typography variant="lead1">Valor</Typography>
            </Box>

            <Box sx={{ display: 'contents' }}>
              {Object.keys(provisions).map((provision) => (
                <Fragment key={provision}>
                  <Typography variant="h6" color="black.dark">
                    {TRANSLATED_LABELS[provision]}
                  </Typography>
                  <FormField variant="currency" name={provision} />
                </Fragment>
              ))}
            </Box>
          </Paper>
        </Modal>
      )}
    </Formik>
  )
}
