import { useQueryClient } from 'react-query'

import { Button } from '@mui/material'

import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'

import { getCompanyId } from 'utils/company'
import useElectronicPayrollPeriodService from 'utils/hooks/ElectronicPayroll/electronicPayrollPeriod'
import useNotifications from 'utils/hooks/useNotifications'

const CreatePreviousElectronicPayrollPeriod = () => {
  const companyId = getCompanyId()
  const queryClient = useQueryClient()
  const confirm = useConfirm()
  const { showInfoMessage } = useNotifications()

  const { electronicPayrollMutation } = useElectronicPayrollPeriodService({
    queryOptions: { enabled: false },
  })

  const handleCreatePreviousPeriod = () => {
    confirm({
      title: 'Crear periodo de nómina electrónica anterior',
      description: (
        <>
          Al continuar se creará el periodo de nómina electrónica anterior al
          último periodo activo que tiene tu compañía en Aleluya.
        </>
      ),
      okText: 'Generar periodo',
      onOk: () => {
        electronicPayrollMutation.mutate(
          {
            mutationMethod: 'POST',
          },
          {
            onSuccess: ({ message }) => {
              showInfoMessage(message)
              queryClient.invalidateQueries([
                'getAllElectronicPayrolls',
                companyId,
              ])
            },
          }
        )
      },
    })
  }

  return (
    <Button endIcon={<Icon name="plus" />} onClick={handleCreatePreviousPeriod}>
      Crear periodo anterior
    </Button>
  )
}

export default CreatePreviousElectronicPayrollPeriod
