import { Box, ButtonBase, Typography, useMediaQuery } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'

import { sunglassesFace } from 'utils/emojis'

import headerBackground from 'assets/images/views/common/modal_header_background.svg'
import rocketWoman from 'assets/images/views/holisticPayroll/rocket_woman.png'

const InProcessModalHeader = ({
  worker = {},
  handleClose,
  isProcessCompleted = false,
  processType = 'affiliation',
}) => {
  const isMobileBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.down('tablet')
  )

  const titleText = () => {
    if (processType === 'claim') {
      return (
        <>
          {isProcessCompleted ? '¡Que emoción! ' : '¡'}Tu reclamación{' '}
          {isProcessCompleted ? (
            <>
              está lista. <Emoji code={sunglassesFace} />
            </>
          ) : (
            'está en camino!'
          )}
        </>
      )
    }

    return `¡La afiliación de ${worker.name} ${isProcessCompleted ? 'se ha completado' : 'está en camino'}!`
  }

  return (
    <Box
      sx={(theme) => ({
        overflowX: 'clip',
        position: 'relative',
        display: 'flex',
        width: `calc(100% + ${theme.spacing(4)})`,
        height: '17.5rem',
        marginLeft: theme.spacing(-2),
        [theme.breakpoints.up('tablet')]: {
          marginLeft: theme.spacing(-3),
          width: `calc(100% + ${theme.spacing(6)})`,
        },
        [theme.breakpoints.up('laptop')]: {
          marginLeft: theme.spacing(-4),
          width: `calc(100% + ${theme.spacing(8)})`,
        },
        backgroundImage: `url(${headerBackground})`,
      })}
    >
      {!isMobileBreakpoint ? (
        <Box
          component="img"
          alt="Afiliaciones rápidas y ágiles como un cohete con Aleluya"
          src={rocketWoman}
          sx={{
            position: 'absolute',
            right: '-1rem',
            top: '-4.5rem',
            height: '30.5rem',
            width: '30rem',
          }}
        />
      ) : null}
      <ButtonBase
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: '0.7rem',
          top: '1.1rem',
          cursor: 'pointer',
          color: theme.palette.black.main,
          zIndex: 2,
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.5rem' }} />
      </ButtonBase>
      <Typography
        variant="h2"
        sx={(theme) => ({
          alignSelf: 'center',
          marginX: theme.spacing(4),
          width: '50%',
          [theme.breakpoints.down('tablet')]: {
            width: '100%',
          },
        })}
      >
        {titleText()}
      </Typography>
    </Box>
  )
}

export default InProcessModalHeader
