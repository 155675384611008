import { Button } from '@mui/material'

import Icon from 'components/UI/Icon'

import { isOrganizer } from 'utils/auth'

const CreateNewElectronicPayrollPeriod = ({
  setNewElectronicPayrollPeriodModalOpen,
}) =>
  isOrganizer() ? (
    <Button
      endIcon={<Icon name="plus" />}
      onClick={() => setNewElectronicPayrollPeriodModalOpen(true)}
      variant="outlined"
    >
      Crear nuevo periodo
    </Button>
  ) : null

export default CreateNewElectronicPayrollPeriod
