import { Button } from '@mui/material'

import Icon from '../Icon'

const AddButton = ({ children = 'Agregar', sx, ...rest }) => {
  return (
    <Button
      variant="text"
      startIcon={<Icon name="plus" />}
      {...rest}
      sx={[
        {
          width: 'fit-content',
        },
        sx,
      ]}
      style={{ whiteSpace: 'nowrap', ...rest.style }}
    >
      {children}
    </Button>
  )
}

export default AddButton
