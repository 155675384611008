import {
  Box,
  Chip,
  Divider,
  Step,
  Stepper,
  Typography,
  stepConnectorClasses,
  useMediaQuery,
} from '@mui/material'

import { formatDisplayDateString } from 'utils/dateTime'

import { getStepperData } from '../helpers'

const StepIcon = ({ isCertificateCompleted, index }) => {
  return (
    <Typography
      variant="h5"
      sx={(theme) => ({
        color: theme.palette.white.main,
        minWidth: '2.5rem !important',
        minHeight: '2.5rem !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.black.light,
        ...(isCertificateCompleted && {
          backgroundColor: theme.palette.primary.dark,
        }),
      })}
    >
      {index + 1}
    </Typography>
  )
}

const ClaimStatusStepper = ({ statusDetailData = {} }) => {
  const isMobileBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.down('tablet')
  )

  const {
    status,
    claimed_at: claimedAt,
    created_at: createdAt,
    review_at: reviewedAt,
  } = statusDetailData
  const stepperData = getStepperData({
    status,
    claimedAt,
    createdAt,
    reviewedAt,
  })

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        marginTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('tablet')]: {
          alignItems: 'center',
          gap: theme.spacing(2),
          marginLeft: theme.spacing(2),
        },
      })}
    >
      <Stepper
        alternativeLabel={isMobileBreakpoint ? false : true}
        orientation={isMobileBreakpoint ? 'vertical' : 'horizontal'}
        sx={{
          [`& .${stepConnectorClasses.line}`]: {
            display: 'none',
          },
        }}
      >
        {stepperData.map(
          (
            {
              id,
              title,
              bagde,
              updated_at: updatedAt,
              status,
              completed,
              disabilityClaimsStatus,
            },
            index
          ) => {
            const getActionTypeText = () => {
              if (index === 0) {
                return 'Iniciada'
              }

              if (index === 1) {
                return 'Actualizada'
              }

              return 'Reclamada'
            }

            return (
              <Step
                key={id}
                completed={completed}
                sx={(theme) => ({
                  padding: 0,
                  marginRight: index !== 2 ? theme.spacing(2) : 0,
                  [theme.breakpoints.down('tablet')]: {
                    marginRight: 0,
                    marginBottom: theme.spacing(4),
                    height: '5rem',
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: theme.spacing(1),
                    [theme.breakpoints.down('tablet')]: {
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: 'fit-content',
                      gap: theme.spacing(2),
                    },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      width: 'calc(100% + 2rem)',
                      alignItems: 'center',
                      [theme.breakpoints.down('tablet')]: {
                        flexDirection: 'column',
                        alignItems: 'center',
                        verticalAlign: 'top',
                        width: 'fit-content',
                        height: 'calc(100% + 1rem)',
                      },
                    })}
                  >
                    <StepIcon
                      isCertificateCompleted={completed}
                      index={index}
                    />
                    {index + 1 < stepperData?.length ? (
                      <Divider
                        orientation={
                          isMobileBreakpoint ? 'vertical' : 'horizontal'
                        }
                        sx={(theme) => ({
                          width: 'calc(100% - 2.5rem)',
                          border: '0.085rem solid',
                          borderColor: theme.palette.black.light,
                          ...(completed && {
                            borderColor: theme.palette.primary.dark,
                          }),
                        })}
                      />
                    ) : null}
                  </Box>

                  <Box
                    sx={(theme) => ({
                      display: 'flex',
                      flexDirection: 'column',
                      gap: id === 'claim_created' ? theme.spacing(1.5) : 0,
                      [theme.breakpoints.down('tablet')]: {
                        height: 'calc(100% + 1rem)',
                        gap: theme.spacing(0.5),
                      },
                    })}
                  >
                    <Box
                      sx={({ spacing, breakpoints }) => ({
                        display: 'flex',
                        gap: spacing(1),
                        flexDirection: 'column',
                        alignItems: 'center',
                        [breakpoints.up('tablet')]: {
                          flexDirection: 'row',
                        },
                      })}
                    >
                      <Typography
                        variant="lead1"
                        sx={{
                          textWrap: id === 'claim_study' ? 'nowrap' : 'wrap',
                        }}
                      >
                        {title}
                      </Typography>
                      {bagde && status ? (
                        <Chip
                          label={disabilityClaimsStatus[status].label}
                          sx={() => ({
                            backgroundColor:
                              disabilityClaimsStatus[status].backgroundColor,
                            color: disabilityClaimsStatus[status].textColor,
                          })}
                        />
                      ) : null}
                    </Box>
                    {updatedAt ? (
                      <Typography color="black.dark">
                        {getActionTypeText()} el{' '}
                        {formatDisplayDateString(updatedAt)}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              </Step>
            )
          }
        )}
      </Stepper>
    </Box>
  )
}

export default ClaimStatusStepper
