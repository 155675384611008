import Icon from '../Icon'
import Link from '../MaterialUI/Link'

const GoBackLink = ({ children = 'Regresar', to, ...rest }) => {
  return (
    <Link
      to={to}
      style={{ display: 'inline-flex', alignItems: 'center' }}
      {...rest}
    >
      <Icon name="chevron-left" />
      {children}
    </Link>
  )
}

export default GoBackLink
