import { useFormikContext } from 'formik'
import { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import AddButton from 'components/UI/Button/AddButton'
import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import ContractCategoryField from 'components/Worker/Contract/Fields/Category'
import ContributorSubtype from 'components/Worker/Contract/Fields/ContributorSubtype'
import EndDayField from 'components/Worker/Contract/Fields/EndDay'
import InitialDayField from 'components/Worker/Contract/Fields/InitialDay'
import RiskTypeField from 'components/Worker/Contract/Fields/RiskType'
import TermField from 'components/Worker/Contract/Fields/Term'
import { formatContracts } from 'components/Worker/Contract/Index/Index'
import ContractModal, {
  validateCreateContract,
} from 'components/Worker/Contract/Modal/ContractModal'
import useContractModal from 'components/Worker/Contract/Modal/useContractModal'
import TransportSubsidyField from 'components/Worker/Wage/Fields/TransportSubsidy'
import useWorker from 'components/Worker/useWorker'

import { weekdaysOptions } from 'utils/dateTime'
import useContractsService from 'utils/hooks/worker/contracts'
import { closedQuestionOptions } from 'utils/worker'

import * as routes from 'config/routes'

import messages from 'messages/worker'

import { useWorkerShowContext } from '../../../Body'

const ContractDataSection = ({ onClose }) => {
  const { values } = useFormikContext()
  const queryClient = useQueryClient()
  const { worker, refreshWorker } = useWorker({ useCache: true })
  const confirm = useConfirm()
  const navigate = useNavigate()
  const initialContractCategory = useRef(values.contract_category)

  const { contractModalState, openContractModal, closeContractModal } =
    useContractModal(worker.id)
  const { openEditContractModal } = useWorkerShowContext()
  const currentContractQueryKey = ['currentContract', worker.id]

  const { contractsQuery } = useContractsService({
    serviceParams: { queryKey: currentContractQueryKey, workerId: worker.id },
    queryOptions: { enabled: Boolean(worker.contract_id) },
  })
  const [formattedContract] = formatContracts([
    ...(contractsQuery.data ? [contractsQuery.data] : []),
  ])

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  const contractModalCallback = () => {
    queryClient.invalidateQueries(currentContractQueryKey)
    refreshWorker()
    onClose()
  }

  const handleCreateContract = () => {
    validateCreateContract({
      currentContract: formattedContract,
      currentPeriod: { end_day: formattedContract?.period_end_day },
      handleRedirectToTermination: () => {
        navigate(
          routes.WORKER_CONTRACT_TERMINATION_NEW(
            worker.id,
            formattedContract?.id
          )
        )
      },
      handleShowModal: openContractModal,
      confirm,
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5"
        color="primary.dark"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
        })}
      >
        Datos de cotización
      </Typography>
      <Box
        sx={(theme) => ({
          marginBottom: !['contractor', 'schooling_trainee'].includes(
            values.contract_category
          )
            ? theme.spacing(3)
            : 0,
          display: 'grid',
          gridTemplateColumns: '1fr 10.5rem',
          gap: theme.spacing(4),
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            rowGap: theme.spacing(2),
          },
        })}
      >
        <ContractCategoryField
          name="contract_category"
          optional={false}
          isEditing
        />
        {!contractsQuery.isLoading ? (
          <AddButton
            variant="text"
            onClick={handleCreateContract}
            sx={{ alignSelf: 'end', justifySelf: 'end' }}
          >
            Nuevo contrato
          </AddButton>
        ) : null}
      </Box>
      {!['employee', 'part_time_contract'].includes(
        values.contract_category
      ) ? (
        <RiskTypeField
          contractCategory={values.contract_category}
          isEditContract
          optional={false}
        />
      ) : null}
      {['employee', 'part_time_contract'].includes(values.contract_category) ? (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr 10.5rem',
              gap: theme.spacing(4),
              [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: '1fr auto',
              },
            })}
          >
            <ContributorSubtype
              name="contributor_subtype"
              optional={false}
              contractCategory={values.contract_category}
              disabled={
                !(
                  initialContractCategory.current &&
                  values.contract_category !== initialContractCategory.current
                )
              }
            />
            <Button
              startIcon={<Icon name="pencil-line" />}
              variant="text"
              sx={{
                width: 'fit-content',
                alignSelf: 'end',
                justifySelf: 'end',
              }}
              onClick={openEditContractModal}
            >
              Editar
            </Button>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'grid',
              gridTemplateColumns: '1fr 10.5rem',
              gap: theme.spacing(4),
              [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: '1fr auto',
              },
            })}
          >
            <RiskTypeField
              contractCategory={values.contract_category}
              isEditContract
              optional={false}
              disabled={
                !(
                  initialContractCategory.current &&
                  values.contract_category !== initialContractCategory.current
                )
              }
            />
            <Button
              startIcon={<Icon name="pencil-line" />}
              variant="text"
              sx={{
                width: 'fit-content',
                alignSelf: 'end',
                justifySelf: 'end',
              }}
              onClick={openEditContractModal}
            >
              Editar
            </Button>
          </Box>
          {values.contract_detail.contributor_subtype !== 'no_subtype' ? (
            <Box
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '1fr 10.5rem',
                gap: theme.spacing(4),
                [theme.breakpoints.down('sm')]: {
                  gridTemplateColumns: '1fr auto',
                },
              })}
            >
              <FormField
                name="high_risk_pension"
                variant="radio-group"
                label="¿Pensión de alto riesgo?"
                optional={false}
                disabled
                options={closedQuestionOptions}
                row
              />
              <Button
                startIcon={<Icon name="pencil-line" />}
                variant="text"
                sx={{
                  width: 'fit-content',
                  alignSelf: 'end',
                  justifySelf: 'end',
                }}
                onClick={openEditContractModal}
              >
                Editar
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Typography
        variant="h5"
        color="primary.dark"
        sx={(theme) => ({
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(5),
        })}
      >
        Información general
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
        })}
      >
        <TermField
          contractCategory={values.contract_category}
          optional={false}
        />
        <InitialDayField optional={false} showConfirmation />
        <EndDayField optional={false} />
        <TransportSubsidyField contractCategory={values.contract_category} />
        {values.contract_category &&
        [
          'employee',
          'student_law_789',
          'student_decree_055',
          'part_time_contract',
        ].includes(values.contract_category) ? (
          <>
            {values.contract_category !== 'part_time_contract' ? (
              <FormField
                name="rest_days"
                label="Días de descanso"
                variant="checkbox-group"
                options={weekdaysOptions}
                row={lgUp}
              />
            ) : null}
            <FormField
              name="accumulated_holidays"
              label="Días de Vacaciones acumuladas"
              type="number"
              align="left"
              tooltipContent={messages.accumulated_holidays_editing}
            />
          </>
        ) : null}
      </Box>
      <ContractModal
        state={contractModalState}
        handleClose={closeContractModal}
        submitCallback={contractModalCallback}
      />
    </Box>
  )
}

export default ContractDataSection
