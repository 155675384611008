import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import useCompanyHolisticPayrollService from 'utils/hooks/HolisticPayroll/CompayService'
import useNotifications from 'utils/hooks/useNotifications'

import { disabilityClaimSupporModalValidationSchema } from '../helpers'

export default function UploadDisabilitySupportModalContent({
  handleClose,
  claimsDetailId,
  claimNoveltyId,
  workerName,
  claimData = {},
}) {
  const queryClient = useQueryClient()
  const location = useLocation()
  const { showSuccessMessage } = useNotifications()

  const { claim_value } = claimData

  const isOrganizerPath = location.pathname.includes('organizer')
  const companyId = getCompanyId()

  const refreshClaimsTable = () => {
    queryClient.invalidateQueries(
      isOrganizerPath ? 'organizerDisabilityClaims' : 'disabilityClaimsDetail'
    )

    if (!isOrganizerPath) {
      queryClient.invalidateQueries(['getDisabilityClaimsData', companyId])
    }
  }

  const {
    companyHolisticPayrollQuery: {
      data: { created_at: createdAt, review_at: reviewedAt } = {},
      isLoading: claimStatusQueryIsLoading,
    } = {},
    companyHolisticPayrollMutation: disabilityClaimMutation,
  } = useCompanyHolisticPayrollService({
    serviceParams: {
      queryKey: ['getClaimStatusDetail', companyId],
      claimsDetailId,
      claimNoveltyId,
    },
  })

  const handleSubmit = (values) => {
    const correctedClaimData = new FormData()
    if (isOrganizerPath) {
      correctedClaimData.append('value', claim_value)
    }
    correctedClaimData.append(
      'status',
      isOrganizerPath ? 'claimed' : 'in_progress'
    )
    correctedClaimData.append(
      isOrganizerPath ? 'payment_support' : 'claim_support',
      values.claim_support
    )

    disabilityClaimMutation.mutate(
      {
        mutationMethod: 'PUT',
        claimsDetailId,
        claimNoveltyId,
        correctedClaimData,
      },
      {
        onSuccess: () => {
          handleClose()
          showSuccessMessage('El soporte fue enviado correctamente')
          refreshClaimsTable()
        },
      }
    )
  }

  return claimStatusQueryIsLoading ? (
    <LoadingBox />
  ) : (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(3),
      })}
    >
      <Typography variant="h4" color="accent4.main">
        Fecha de creación: {formatDisplayDateString(createdAt)}
      </Typography>

      {!isOrganizerPath ? (
        <Typography color="black.dark">
          Tu solicitud está pendiente. Para gestionar esta reclamación,
          necesitamos que nos proporciones el certificado de incapacidad de{' '}
          {workerName || 'la persona'}.
        </Typography>
      ) : null}

      {!isOrganizerPath ? (
        <Typography color="black.dark">
          <b>Fecha de actualización: {formatDisplayDateString(reviewedAt)}</b>
        </Typography>
      ) : null}

      <Formik
        initialValues={{
          claim_support: claimStatusQueryIsLoading?.claim_support || null,
        }}
        validationSchema={disabilityClaimSupporModalValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormField
            name="claim_support"
            variant="file"
            fileType="application/pdf"
            accept="application/pdf"
            label={
              !isOrganizerPath
                ? 'Agrega el documento soporte de la incapacidad'
                : 'Agrega el soporte de pago de la incapacidad'
            }
            optional={false}
          />

          <Box
            sx={({ breakpoints, spacing }) => ({
              display: 'flex',
              gap: spacing(2),
              flexDirection: 'column',
              marginTop: spacing(7),

              [breakpoints.up('tablet')]: {
                flexDirection: 'row',
              },
            })}
          >
            <Button type="submit" loading={disabilityClaimMutation.isLoading}>
              Enviar información
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancelar
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  )
}
