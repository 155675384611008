import { createContext, useState } from 'react'

import {
  Box,
  Chip,
  Paper,
  Skeleton,
  Typography,
  alpha,
  tabClasses,
} from '@mui/material'

import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'
import Page from 'components/UI/Page/Page'

import { documentTypes } from 'utils/company'
import { isObjectEmpty } from 'utils/general'

import { tabsConfig } from './helpers'

export const OrganizerDisabilityClaimsContext = createContext({})
OrganizerDisabilityClaimsContext.displayName =
  'OrganizerDisabilityClaimsContext'

export default function OrganizerDisabilityClaimsShow() {
  const [activeTab, setActiveTab] = useState(0)
  const [headerData, setHeaderData] = useState({})
  const [claimsTotals, setClaimsTotals] = useState({})
  const [activeStatus, setActiveStatus] = useState('')

  const { email, id_number, name, phone, plan, logo_url, document_type } =
    headerData

  const onChangeTab = (_, newTab) => {
    setActiveTab(newTab)
  }

  return (
    <Page documentTitle="Reclamación de incapacidades" grid>
      <OrganizerDisabilityClaimsContext.Provider
        value={{
          activeStatus,
          claimsTotals,
          setActiveStatus,
          setHeaderData,
          setClaimsTotals,
        }}
      >
        <Box sx={{ gridColumn: '1 / -1' }}>
          <Paper
            sx={({ breakpoints, spacing }) => ({
              display: 'flex',
              flexDirection: 'column',
              padding: spacing(6.5, 4),
              backgroundColor: 'accent1.main',
              borderRadius: '1rem',
              marginBottom: spacing(4),
              gap: spacing(4),
              alignItems: 'center',
              [breakpoints.up('tablet')]: {
                flexDirection: 'row',
              },
            })}
          >
            {isObjectEmpty(headerData) ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  sx={{
                    width: '8.75rem',
                    height: '8.75rem',
                  }}
                />
                <Box
                  sx={({ breakpoints, spacing }) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: spacing(1),
                    alignItems: 'center',
                    [breakpoints.up('tablet')]: {
                      alignItems: 'flex-start',
                    },
                  })}
                >
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={{
                      width: '11.5rem',
                      height: '1.5rem',
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={{
                      width: '11.5rem',
                      height: '2.5rem',
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={{
                      width: '11.5rem',
                      height: '1.5rem',
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={{
                      width: '11.5rem',
                      height: '1.5rem',
                    }}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  component="img"
                  src={logo_url}
                  alt="Logo de la empresa"
                  sx={{
                    width: '8.75rem',
                    height: '8.75rem',
                    borderRadius: '50%',
                    objectFit: 'contain',
                  }}
                />

                <Box
                  sx={({ breakpoints, spacing }) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    gap: spacing(1),
                    alignItems: 'center',
                    [breakpoints.up('tablet')]: {
                      alignItems: 'flex-start',
                    },
                  })}
                >
                  <Chip
                    label={`Plan ${plan}`}
                    size="small"
                    sx={({ palette, spacing }) => ({
                      backgroundColor: alpha(palette.primary.main, 0.2),
                      color: 'primary.dark',
                      width: 'fit-content',
                      paddingX: spacing(3),
                    })}
                  />
                  <Typography variant="h2">{name}</Typography>
                  <Typography color="black.dark">
                    {documentTypes[document_type]}: {id_number}
                  </Typography>
                  <Typography color="black.dark">Correo: {email}</Typography>
                  <Typography color="black.dark">Teléfono: {phone}</Typography>
                </Box>
              </>
            )}
          </Paper>

          <Paper
            sx={({ spacing }) => ({
              display: 'flex',
              padding: spacing(3),
              flexDirection: 'column',
              gap: spacing(6),
              borderRadius: '1rem',
            })}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <RoundedTabs
                value={activeTab}
                onChange={onChangeTab}
                tabsConfig={tabsConfig}
                sx={{
                  width: 'fit-content',
                  [`& .${tabClasses.root}`]: {
                    minWidth: 'fit-content',
                  },
                }}
              />
            </Box>
            {tabsConfig[activeTab].content}
          </Paper>
        </Box>
      </OrganizerDisabilityClaimsContext.Provider>
    </Page>
  )
}
