import { Box, Typography } from '@mui/material'

import HelpModal from 'components/UI/Modal/HelpModal'
import useHelpModal from 'components/UI/Modal/useHelpModal'

import TutorialButtonCard from './Steps/TutorialButtonCard'
import { tutorialsInformation } from './helpers'

const TutorialCard = ({
  children,
  id,
  title,
  description,
  tutorialNumber,
  article,
  link,
  dataCy,
  icon,
  alt,
  backgroundColor,
  cardTitle,
}) => {
  const { closeHelpModal, openHelpModal, helpModalState } = useHelpModal()

  return (
    <>
      <Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            rowGap: theme.spacing(2),
            alignItems: 'flex-start',
            [theme.breakpoints.up('tablet')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              marginRight: theme.spacing(2),
              [theme.breakpoints.up('tablet')]: {
                maxWidth: '80%',
              },
            })}
          >
            <Typography
              color="black"
              variant="h6"
              sx={(theme) => ({ marginBottom: theme.spacing(1) })}
            >
              {title}
            </Typography>
            <Typography variant="body2">{description}</Typography>
          </Box>
          {id !== 'tutorialCard-2' ? (
            <TutorialButtonCard
              openHelpModal={openHelpModal}
              article={article}
              link={link}
              dataCy={dataCy}
              icon={icon}
              alt={alt}
              backgroundColor={backgroundColor}
              cardTitle={cardTitle}
            />
          ) : null}
        </Box>
        {children}
      </Box>
      {helpModalState.open ? (
        <HelpModal
          handleClose={closeHelpModal}
          state={helpModalState}
          modalInfo={tutorialsInformation[tutorialNumber]}
        />
      ) : null}
    </>
  )
}

export default TutorialCard
