import { Link } from 'react-router-dom'

import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import Avatar from 'components/UI/Avatar'
import Icon from 'components/UI/Icon'

import { WORKER_SHOW } from 'config/routes'

const ClaimDataCard = ({ worker = {} }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'complementary1.light',
        borderRadius: '0.5rem',
        padding: theme.spacing(1, 2, 0, 2),
        boxShadow: theme.shadows[4],
        minWidth: '21rem',
        minHeight: '9rem',
        [theme.breakpoints.down('tablet')]: {
          minWidth: '100%',
        },
      })}
    >
      <Typography
        variant={isMobile ? 'lead2' : 'lead1'}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(0.5),
          marginBottom: theme.spacing(2),
        })}
      >
        {
          <Icon
            name="avatar-approved"
            sx={{
              fontSize: '1.5rem',
              fontWeight: 400,
            }}
          />
        }{' '}
        Datos de la reclamación
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          alignItems: 'center',
          gap: theme.spacing(1),
          gridTemplateColumns: '1fr 3fr',
          [theme.breakpoints.down('tablet')]: {
            gap: theme.spacing(2),
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            height: '100%',
            alignItems: 'flex-end',
            [theme.breakpoints.down('tablet')]: {
              alignItems: 'center',
            },
          })}
        >
          <Avatar
            src={worker?.picture}
            sx={{
              width: '3.5rem',
              height: '3.5rem',
            }}
          />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(0.5),
            [theme.breakpoints.down('laptop')]: {
              gap: theme.spacing(1),
              width: '90%',
            },
          })}
        >
          <Typography
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              gap: theme.spacing(3),
            })}
          >
            <Typography component="span" variant="body2">
              Nombre:
            </Typography>
            <Typography
              component="span"
              variant="small"
              sx={{ fontWeight: 700, textAlign: 'end' }}
            >
              {worker?.name}
            </Typography>
          </Typography>
          <Typography
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              gap: theme.spacing(1),
            })}
          >
            <Typography component="span" variant="body2">
              Área:
            </Typography>
            <Typography
              component="span"
              variant="small"
              sx={{ fontWeight: 700 }}
            >
              {worker?.area}
            </Typography>
          </Typography>
          <Typography
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              gap: theme.spacing(1),
            })}
          >
            <Typography component="span" variant="body2">
              Cargo:
            </Typography>
            <Typography
              component="span"
              variant="small"
              sx={{ fontWeight: 700 }}
            >
              {worker?.position}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Button
        component={Link}
        to={WORKER_SHOW(worker?.id)}
        target="_blank"
        variant="text"
        startIcon={<Icon name="plus" />}
        size="small"
        sx={(theme) => ({
          fontSize: '12px',
          marginTop: theme.spacing(0.5),
        })}
      >
        Ver más detalle
      </Button>
    </Box>
  )
}

export default ClaimDataCard
