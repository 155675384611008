import { Box, Button, Typography, useMediaQuery } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { partyingFace, rocket } from 'utils/emojis'

import celebrationIllustration from 'assets/images/views/holisticPayroll/celebration_illustration.png'

export default function ClaimSubmissionSuccessModalContent({ handleClose }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: { mobile: '1fr', tablet: '18rem 1fr' },
      }}
    >
      <Box
        onClick={handleClose}
        sx={(theme) => ({
          cursor: 'pointer',
          position: 'absolute',
          right: '-0.5rem',
          [theme.breakpoints.down('tablet')]: {
            right: '1rem',
          },
        })}
      >
        <Icon name="circle-x" sx={{ fontSize: '1.75rem' }} />
      </Box>
      {!isMobile ? (
        <>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.accent1.main,
              width: '27.75rem',
              height: '27.75rem',
              borderRadius: '100%',
              left: -180,
              top: -50,
              position: 'absolute',
            })}
          />
          <Box
            sx={({ spacing }) => ({
              display: 'flex',
              zIndex: 1,
              marginLeft: spacing(-4),
              marginTop: spacing(-1),
            })}
          >
            <Box
              component="img"
              alt="Relájate mientras tienes Aleluya Premium"
              src={celebrationIllustration}
              width={340}
              height={340}
            />
          </Box>
        </>
      ) : null}
      <Box
        sx={({ spacing }) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(2),
        })}
      >
        <Typography variant="h2">
          ¡Aleluya! <Emoji code={partyingFace} />
        </Typography>
        <Typography>
          Tu solicitud de reclamación está en manos de nuestro equipo{' '}
          <Emoji code={rocket} />. Desde Reclamaciones, podrás seguir su estado
          en tiempo real y anexar documentos, si es necesario.
        </Typography>
        <Typography>
          En caso de necesitar información adicional, uno de nuestros asesores
          podrá llamarte. Si tienes dudas, escríbenos a{' '}
          <b>
            <OrganizationEmail color="accent4.main" variant="claims" />
          </b>
          .
        </Typography>

        <Box
          sx={({ spacing }) => ({
            display: 'flex',
            marginTop: spacing(3.5),
          })}
        >
          <Button
            onClick={handleClose}
            sx={({ spacing, breakpoints }) => ({
              paddingX: spacing(7),
              [breakpoints.down('tablet')]: {
                width: '100%',
                paddingX: 0,
              },
            })}
          >
            Cerrar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
