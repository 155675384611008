import { usePopupState } from 'material-ui-popup-state/hooks'
import { createContext, useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { Box, IconButton, Tooltip } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import Button from 'components/UI/Button/Button'
import DropdownButton from 'components/UI/Button/Dropdown'
import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import SearchInput from 'components/UI/Table/Toolbar/ToolbarSearch'

import { getCompanyId } from 'utils/company'
import { useInfiniteDocumentsService } from 'utils/hooks/worker/documents'

import { DocumentsContext } from '../Show/Body/Tabs/DocumentsContainer'
import useWorker from '../useWorker'
import ActionModal from './ActionModal'
import Content from './Content'
import NewElementModal from './NewElementModal'
import {
  getLoadMoreLabel,
  useFilteredData,
  validateIsAffiliationFolder,
} from './helpers'

export const FoldersContainerContext = createContext()
FoldersContainerContext.displayName = 'FoldersContainerContext'

const Documents = () => {
  const { ref: foldersActionRef, inView: foldersActionInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
    fallbackInView: false,
  })
  const { ref: documentsActionRef, inView: documentsActionInView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
    fallbackInView: false,
  })
  const { openFolder, setOpenFolder } = useContext(DocumentsContext)
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'userMenu',
  })
  const { isWorker } = useUser()
  const { worker } = useWorker({ useCache: true })
  const companyId = getCompanyId()
  const workerId = worker.id
  const [view, setView] = useState('grid')
  const [orderByLabel, setOrderByLabel] = useState()
  const [elementModal, setElementModal] = useState({
    open: false,
    type: null,
  })
  const [actionModal, setActionModal] = useState({
    type: null,
    context: {},
  })
  const [selectedFiles, setSelectedFiles] = useState([])
  const showAsGrid = view === 'grid'
  const showAsList = view === 'list'
  const isFolderEmpty = openFolder?.files?.length === 0
  const hasSomeSelectedFiles = selectedFiles.length > 0
  const hasAllSelectedFiles = selectedFiles.length === openFolder?.files?.length
  const foldersQueryKey = isWorker
    ? ['getAllWorkerFolders', worker.id]
    : ['getAllFolders', companyId, worker.id]
  const documentsQueryKey = isWorker
    ? ['getAllWorkerDocuments', worker.id, openFolder?.id]
    : ['getAllDocuments', companyId, worker.id, openFolder?.id]
  const { infiniteDocumentsQuery: infiniteFoldersQuery } =
    useInfiniteDocumentsService({
      serviceParams: {
        queryKey: foldersQueryKey,
        workerId,
      },
    })
  const { infiniteDocumentsQuery } = useInfiniteDocumentsService({
    serviceParams: {
      queryKey: documentsQueryKey,
      workerId,
      folderId: openFolder?.id,
    },
    queryOptions: {
      enabled: Boolean(openFolder?.id),
    },
  })
  const { fetchNextPage: fetchNextFoldersPage } = infiniteFoldersQuery
  const { fetchNextPage: fetchNextDocumentsPage } = infiniteDocumentsQuery
  const allFolders = [].concat(
    ...(infiniteFoldersQuery.data?.pages || []).map((page) => page.data.folders)
  )
  const allDocuments = [].concat(
    ...(infiniteDocumentsQuery.data?.pages || []).map(
      (page) => page.data.folder.files
    )
  )
  const showLoadMoreAction = openFolder
    ? infiniteDocumentsQuery.data?.pages?.[0]?.totalRecords > 20 || false
    : infiniteFoldersQuery.data?.pages?.[0]?.totalRecords > 20 || false

  const {
    filteredData,
    searchFilter,
    onChangeFilter,
    orderByName,
    orderByDate,
  } = useFilteredData(openFolder ? allDocuments || [] : allFolders || [])

  const isAffiliationFolder = validateIsAffiliationFolder(openFolder?.name)

  const closePopup = () => popupState.close()

  const handleChangeView = (viewType) => {
    setView(viewType)
  }

  const handleAddElement = () =>
    setElementModal({
      open: true,
      type: openFolder ? 'file' : 'folder',
    })

  const handleCancelAddFile = () =>
    setElementModal({
      open: false,
      type: null,
    })

  const orderOptions = [
    {
      id: 'by_name',
      name: 'Por orden alfabético',
      onClick: () => {
        setOrderByLabel('Por orden alfabético')
        orderByName()
        closePopup()
      },
    },
    {
      id: 'by_date',
      name: 'Por fecha de creación',
      onClick: () => {
        setOrderByLabel('Por fecha de creación')
        orderByDate()
        closePopup()
      },
    },
  ]

  const handleSelectFile = (fileId) =>
    setSelectedFiles((previousFiles) => {
      if (!previousFiles.includes(fileId)) return [...previousFiles, fileId]

      return previousFiles.filter((item) => item !== fileId)
    })

  useEffect(() => {
    if (openFolder) {
      setView('list')
    } else {
      setView('grid')
    }
  }, [openFolder])

  useEffect(() => {
    if (foldersActionInView) {
      fetchNextFoldersPage()
    }
  }, [fetchNextFoldersPage, foldersActionInView])

  useEffect(() => {
    if (documentsActionInView) {
      fetchNextDocumentsPage()
    }
  }, [fetchNextDocumentsPage, documentsActionInView])

  const value = {
    showAsGrid,
    actionModal,
    setActionModal,
    handleSelectFile,
    hasSomeSelectedFiles,
    hasAllSelectedFiles,
    selectedFiles,
    setSelectedFiles,
  }

  return (
    <FoldersContainerContext.Provider value={value}>
      {!infiniteFoldersQuery.isLoading ? (
        <Box sx={{ overflow: 'hidden' }}>
          <Box
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: theme.spacing(3),
              marginBottom: theme.spacing(5),
              paddingTop: theme.spacing(5),
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                gap: theme.spacing(1),
                flexWrap: 'wrap',
              })}
            >
              <SearchInput
                searchProps={{
                  sx: {
                    width: {
                      mobile: 1,
                      tablet: 'auto',
                    },
                    maxWidth: '16rem',
                  },
                }}
                globalFilter={searchFilter}
                setGlobalFilter={onChangeFilter}
              />
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(1),
                  flexWrap: 'wrap',
                  [theme.breakpoints.down('tablet')]: {
                    rowGap: theme.spacing(3),
                  },
                })}
              >
                <Tooltip title="Ver como cuadrícula" arrow>
                  <IconButton
                    sx={(theme) => ({
                      backgroundColor: 'transparent',
                      border: `0.063rem solid ${theme.palette.black.main}`,
                      ...(showAsGrid && {
                        backgroundColor: theme.palette.black.main,
                        color: theme.palette.white.main,
                        '&:hover': {
                          color: theme.palette.black.main,
                        },
                        '&:focus': {
                          color: theme.palette.black.main,
                        },
                      }),
                    })}
                    disableFocusRipple
                    disableRipple
                    onClick={() => handleChangeView('grid')}
                    size="large"
                  >
                    <Icon name="layout-grid" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ver como lista" arrow>
                  <IconButton
                    sx={(theme) => ({
                      backgroundColor: 'transparent',
                      border: `0.063rem solid ${theme.palette.black.main}`,
                      ...(showAsList && {
                        backgroundColor: theme.palette.black.main,
                        color: theme.palette.white.main,
                        '&:hover': {
                          color: theme.palette.black.main,
                        },
                        '&:focus': {
                          color: theme.palette.black.main,
                        },
                      }),
                    })}
                    disableFocusRipple
                    disableRipple
                    onClick={() => handleChangeView('list')}
                    size="large"
                  >
                    <Icon name="list" />
                  </IconButton>
                </Tooltip>
                <DropdownButton
                  title={orderByLabel || 'Ordenar'}
                  popupState={popupState}
                  variant="outlined"
                  endIcon={<Icon name="chevron-down" />}
                  options={orderOptions}
                />
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column-reverse',
                width: '100%',
                rowGap: theme.spacing(1),
                columnGap: theme.spacing(2),
                [theme.breakpoints.up('tablet')]: {
                  flexDirection: 'row',
                  width: 'auto',
                },
              })}
            >
              {openFolder ? (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => setOpenFolder(null)}
                  sx={{
                    width: {
                      mobile: 1,
                      tablet: 'auto',
                    },
                  }}
                >
                  <Icon
                    name="move-left"
                    sx={(theme) => ({
                      marginRight: theme.spacing(1),
                    })}
                  />
                  Regresar
                </Button>
              ) : null}
              {!isWorker && !isFolderEmpty && !isAffiliationFolder ? (
                <Button
                  variant="outlined"
                  onClick={handleAddElement}
                  sx={{
                    width: {
                      mobile: 1,
                      tablet: 'auto',
                    },
                  }}
                >
                  {openFolder ? `Agregar archivo` : 'Crear carpeta'}
                </Button>
              ) : null}
            </Box>
          </Box>
          {!infiniteDocumentsQuery.isLoading ? (
            <Box
              sx={(theme) => ({
                ...(showAsList && {
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: theme.spacing(3),
                }),
                ...(showAsGrid && {
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gap: theme.spacing(3),
                  [theme.breakpoints.up('tablet')]: {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                  },
                  [theme.breakpoints.up('md')]: {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                  },
                  [theme.breakpoints.up('xdesktop')]: {
                    gridTemplateColumns: 'repeat(4, 1fr)',
                  },
                }),
                maxHeight: '32.6rem',
                overflowY: 'auto',
                /* Firefox */
                scrollbarWidth: 'thin',
                scrollbarColor: `${theme.palette.white.dark} ${theme.palette.white.main}`,
                /* Chrome, Edge, and Safari */
                '::-webkit-scrollbar': {
                  width: '0.375rem',
                },
                '::-webkit-scrollbar-track': {
                  background: theme.palette.white.main,
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.white.dark,
                  borderRadius: '0.1875rem',
                  border: 'none',
                },
              })}
            >
              <Content
                filteredData={filteredData}
                isAffiliationFolder={isAffiliationFolder}
              />
              {showLoadMoreAction ? (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    paddingBottom: theme.spacing(0.5),
                    gridColumn: '1 / -1',
                  })}
                >
                  <Button
                    ref={openFolder ? documentsActionRef : foldersActionRef}
                    onClick={() => {
                      if (openFolder) {
                        infiniteDocumentsQuery.fetchNextPage()
                      } else {
                        infiniteFoldersQuery.fetchNextPage()
                      }
                    }}
                    disabled={
                      openFolder
                        ? !infiniteDocumentsQuery.hasNextPage ||
                          infiniteDocumentsQuery.isFetchingNextPage
                        : !infiniteFoldersQuery.hasNextPage ||
                          infiniteFoldersQuery.isFetchingNextPage
                    }
                    loading={
                      openFolder
                        ? infiniteDocumentsQuery.isFetchingNextPage
                        : infiniteFoldersQuery.isFetchingNextPage
                    }
                  >
                    {getLoadMoreLabel(
                      openFolder
                        ? infiniteDocumentsQuery.isFetchingNextPage
                        : infiniteFoldersQuery.isFetchingNextPage,
                      openFolder
                        ? infiniteDocumentsQuery.hasNextPage
                        : infiniteFoldersQuery.hasNextPage,
                      openFolder ? 'documentos' : 'carpetas'
                    )}
                  </Button>
                </Box>
              ) : null}
            </Box>
          ) : (
            <LoadingBox />
          )}
        </Box>
      ) : (
        <LoadingBox />
      )}
      {elementModal.open ? (
        <NewElementModal
          onClose={handleCancelAddFile}
          type={elementModal.type}
          folderId={openFolder?.id || undefined}
        />
      ) : null}
      {actionModal.type !== null ? (
        <ActionModal
          open
          onClose={() =>
            setActionModal({
              type: null,
              context: {},
            })
          }
        />
      ) : null}
    </FoldersContainerContext.Provider>
  )
}

export default Documents
