import * as yup from 'yup'

import { Typography } from '@mui/material'

import Link from 'components/UI/MaterialUI/Link'

import { formatDisplayDateString } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'

import { ORGANIZER_DISABILITY_CLAIMS_SHOW } from 'config/routes'

import DisabilityClaimsEntitiesTable from './DisabilityClaimsEntitiesTable'
import DisabilityClaimsShowTable from './DisabilityClaimsShowTable'

export const claimsIndexColumnsData = [
  {
    Header: 'Empresa',
    accessor: 'name',
    Cell: ({ row }) => (
      <Link
        to={ORGANIZER_DISABILITY_CLAIMS_SHOW(row.original.id)}
        color="black.main"
        state={{ claimDetailId: row.original.id }}
        sx={{ textDecoration: 'none' }}
      >
        {row.original.name}
      </Link>
    ),
  },
  {
    Header: 'Fecha de reclamación',
    accessor: 'created_at',
    Cell: ({ row }) => (
      <Typography>
        {formatDisplayDateString(row.original.created_at)}
      </Typography>
    ),
  },
  {
    Header: 'Plata a reclamar',
    accessor: 'total_value_to_claim',
    Cell: ({ row }) => (
      <Typography>
        {formatCurrency(row.original.total_value_to_claim)}
      </Typography>
    ),
  },
]

export const tabsConfig = [
  {
    label: 'Reclamaciones',
    content: <DisabilityClaimsShowTable />,
  },
  {
    label: 'Credenciales',
    content: <DisabilityClaimsEntitiesTable />,
  },
]

export const noveltyModalValidationSchema = yup.object({
  status: yup.string().nullable().required(),
  comment: yup
    .string()
    .nullable()
    .when('status', {
      is: (status) => status === 'rejected',
      then: yup.string().nullable().required(),
    }),
})

export const showTableValidationSchema = yup.object({
  claim_value: yup.string().nullable(),
  status: yup.string().nullable(),
})

export const claimEntitiesValidationSchema = yup.object({
  institution_id: yup.string().nullable().required(),
  username: yup.string().nullable().required(),
  password: yup.string().nullable().required(),
})
