import useConfirm from 'components/UI/ConfirmModal/useConfirm'
import Icon from 'components/UI/Icon'
import Link from 'components/UI/MaterialUI/Link'

import { isOrganizer } from 'utils/auth'
import { formatDateRange } from 'utils/dateTime'

import * as routes from 'config/routes'

import PayrollHistoryFilter from '../PayrollHistoryFilter/Index'
import CreateNewElectronicPayrollPeriod from './CreateNewElectronicPayrollPeriod'
import CreatePreviousElectronicPayrollPeriod from './CreatePreviousElectronicPayrollPeriod'

export const columnsData = [
  {
    Header: 'Periodo',
    accessor: 'period_range',
    Cell: ({ row }) => (
      <Link
        to={routes.PERIOD_PAY_ELECTRONIC_PAYROLL(row.original.id)}
        data-cy={`electronic-period-${row.original.id}`}
        color="black.main"
      >
        {formatDateRange(row.original.initial_day, row.original.end_day)}
      </Link>
    ),
    noWrapCell: true,
  },
  {
    Header: 'Personas',
    accessor: 'total',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.total,
  },
  {
    Header: 'Emitidos',
    accessor: 'issued',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.issued,
  },
  {
    Header: 'Rechazados',
    accessor: 'rejected',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.rejected,
  },
  {
    Header: 'Procesando',
    accessor: 'processing',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.processing,
  },
  {
    Header: 'Sin emitir',
    accessor: 'pending',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.pending,
    noWrapHeader: true,
  },
  {
    Header: 'Anulados',
    accessor: 'canceled',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.canceled,
  },
  {
    Header: 'Modificados',
    accessor: 'modified',
    Cell: ({ row }) => row.original.electronic_payrolls_counter.modified,
  },
]

export const useGetActions = (
  handleOrganizerElectronicPeriodAction,
  setNewElectronicPayrollPeriodModalOpen,
  setSelectedMonth,
  selectedMonth,
  setSelectedYear,
  selectedYear
) => {
  const confirm = useConfirm()

  let actions = [
    {
      id: 'dateFilter',
      isFreeAction: true,
      position: 'left',
      Component: (
        <PayrollHistoryFilter
          setSelectedMonth={setSelectedMonth}
          selectedMonth={selectedMonth}
          setSelectedYear={setSelectedYear}
          selectedYear={selectedYear}
        />
      ),
    },
    ...(isOrganizer()
      ? [
          {
            id: 'createNewPeriod',
            isFreeAction: true,
            Component: (
              <CreateNewElectronicPayrollPeriod
                setNewElectronicPayrollPeriodModalOpen={
                  setNewElectronicPayrollPeriodModalOpen
                }
              />
            ),
          },
        ]
      : []),
    {
      id: 'createPreviousPeriod',
      isFreeAction: true,
      Component: <CreatePreviousElectronicPayrollPeriod />,
    },
  ]

  if (isOrganizer()) {
    actions = actions.concat([
      (rowData) => ({
        id: 'recalculateElectronicPayroll',
        icon: <Icon name="refresh-cw" />,
        tooltip: 'Recalcular nómina electrónica',
        buttonProps: {
          color: 'complementary1',
          size: 'medium',
        },
        onClick: () => {
          confirm({
            type: 'warning',
            title: '¿Desea recalcular la nómina electrónica de este periodo?',
            okText: 'Continuar',
            onOk: () =>
              handleOrganizerElectronicPeriodAction(rowData.id, 'recalculate'),
          })
        },
      }),
    ])
  }

  return actions
}

export const dataFormatted = (months) =>
  months.map((month) => ({
    id: month.id,
    name: month.name,
  }))
