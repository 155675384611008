import { Box, Card, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { capitalize } from 'utils/general'

import { findPaymentMethod } from '../../Index/PaymentMethod/helpers'
import { paymentMethodLogo } from '../Show/ActionGroup/helpers'
import useSubscription from '../useSubscription'

const CurrentPaymentMethodSelector = ({ activePayment }) => {
  const { subscription } = useSubscription()
  const isAutomaticDebit = activePayment === 'automatic_debit'
  const isCreditCardPayment = activePayment === 'credit_card'

  const { subscription_payment_methods_info: currentPaymentMethodsInfo } =
    subscription

  const paymentMethod = findPaymentMethod(
    currentPaymentMethodsInfo,
    activePayment
  )

  const ccType = paymentMethod.credit_card_brand?.toLowerCase()
  const ccLastNumbers = paymentMethod.credit_card_last_numbers
  const accountNumber = paymentMethod.automatic_debit_account_last_numbers

  const currentPaymentMethodTitle = isCreditCardPayment
    ? `Tarjeta ${capitalize(paymentMethod.credit_card_brand)}`
    : `Cuenta ${capitalize(paymentMethod.automatic_debit_bank)}`

  return (
    <Card
      sx={(theme) => ({
        width: '100%',
        padding: theme.spacing(1, 3),
        '& p': {
          color: theme.palette.black.light,
        },
        '& b': {
          color: theme.palette.black.main,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          '& img': {
            marginRight: theme.spacing(1.5),
          },
        })}
      >
        {isCreditCardPayment ? (
          <>
            {paymentMethodLogo[ccType] ? (
              <Box
                component="img"
                src={paymentMethodLogo[ccType]}
                alt="Credit card logo"
                width={70}
                height={70}
              />
            ) : (
              <Icon
                name="credit-card"
                sx={(theme) => ({
                  marginRight: theme.spacing(1),
                  fontSize: '2rem',
                })}
              />
            )}
          </>
        ) : null}
        {isAutomaticDebit ? (
          <Box
            component="img"
            src={paymentMethodLogo.automaticDebit}
            alt="Automatic Debit"
            width={70}
            height={70}
          />
        ) : null}
        <Box>
          <Typography variant="lead1">{currentPaymentMethodTitle}</Typography>
          {accountNumber || ccLastNumbers ? (
            <Typography variant="body1" color="black.dark">
              Terminación {isAutomaticDebit ? accountNumber : ccLastNumbers}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Card>
  )
}

export default CurrentPaymentMethodSelector
