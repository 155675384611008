import { useMediaQuery } from '@mui/material'

import Button from 'components/UI/Button/Button'

import Icon from '../Icon'

const EditButton = ({
  title = 'Editar',
  showTitleOnMobile = false,
  customBreakText = null,
  ...props
}) => {
  const mediaQueryXs = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const xsDown = customBreakText !== null ? customBreakText : mediaQueryXs

  return (
    <Button variant="text" endIcon={<Icon name="pencil-line" />} {...props}>
      {(!xsDown || showTitleOnMobile) && title}
    </Button>
  )
}

export default EditButton
