import { useMemo } from 'react'

import useWorker from 'components/Worker/useWorker'

import { accountTypes, paymentMethods } from 'utils/company'
import { formatDisplayDate } from 'utils/dateTime'
import { formatIdNumber } from 'utils/format'
import { contractCategories, documentTypes } from 'utils/worker'

import UpdateWorkerGeneralDataModal from '../Modals/UpdateGeneralDataModal/UpdateGeneralDataModal'
import useUpdateGeneralWorkerDataModal from '../Modals/UpdateGeneralDataModal/useUpdateGeneralDataModal'
import SectionContainer from '../SectionContainer'

const PersonalDataContent = () => {
  const { worker, isFromAffiliationView } = useWorker({
    useCache: true,
  })
  const isRegistrationFinished = Boolean(worker?.contract_detail?.contract_id)

  const {
    updateGeneralDataModalState,
    openGeneralDataModal,
    closeGeneralDataModal,
  } = useUpdateGeneralWorkerDataModal()

  const sectionData = useMemo(() => {
    const data = [
      {
        title: 'Datos principales',
        onClick: () => openGeneralDataModal('Datos principales', 'basic_data'),
        buttonCyLabel: 'edit_basic_data_button',
        containerCyLabel: 'basic_data_container',
        hiddenEdit: isFromAffiliationView,
        allowsWorkerEdition: true,
        showDivider: !isFromAffiliationView,
        itemsList: isFromAffiliationView
          ? [
              {
                label: 'Nombre',
                value: worker.fullName,
                icon: 'user',
              },
              {
                label: 'Fecha de nacimiento',
                value: formatDisplayDate(worker.birthday),
                icon: 'calendar-days',
              },
              {
                label: 'Tipo de documento',
                value: documentTypes[worker.document_type],
                icon: 'id-card',
              },
              {
                label: 'Número de documento',
                value: formatIdNumber(worker.id_number),
                icon: 'id-card',
              },
              {
                label: 'Correo electrónico',
                value: worker.email,
                icon: 'mail',
              },
              {
                label: 'Departamento',
                value: worker.department_address?.name,
                icon: 'map-pin',
              },
              {
                label: 'Ciudad',
                value: worker.city_address?.name,
                icon: 'map-pin',
              },
              {
                label: 'Dirección',
                value: worker.address,
                icon: 'house',
              },
              {
                label: 'Teléfono',
                value: worker.phone || null,
                icon: 'smartphone',
              },
              {
                label: 'Tipo de contrato',
                value: contractCategories[worker.contract_category],
                icon: 'file-pen-line',
              },
            ]
          : [
              {
                label: 'Nombre completo',
                value: worker.fullName,
                icon: 'user',
              },
              {
                label: documentTypes[worker.document_type],
                value: formatIdNumber(worker.id_number),
                icon: 'id-card',
              },
              {
                label: 'Correo electrónico',
                value: worker.email,
                icon: 'mail',
              },
              {
                label: 'Fecha de nacimiento',
                value: formatDisplayDate(worker.birthday),
                icon: 'calendar-days',
              },
              {
                label: 'Dirección',
                value: worker.address,
                icon: 'map-pin',
              },
              { label: 'Teléfono', value: worker.phone, icon: 'smartphone' },
            ],
      },
    ]

    if (!isFromAffiliationView)
      data.push({
        title: 'Datos de pago',
        onClick: () => openGeneralDataModal('Datos de pago', 'payment_data'),
        containerCyLabel: 'payment_container',
        hiddenEdit: isFromAffiliationView,
        showDivider: false,
        itemsList: [
          {
            label: 'Medio de pago',
            value: paymentMethods[worker?.payment_method]
              ? paymentMethods[worker?.payment_method]
              : worker?.payment_method,
            icon: 'dollar-sign',
          },
          {
            label: 'Banco',
            value: worker.bank?.name,
            hidden: worker.payment_method !== 'wire_transfer',
            icon: 'landmark',
          },
          {
            label: 'Tipo de cuenta',
            value: accountTypes[worker.account_type],
            hidden: worker.payment_method !== 'wire_transfer',
            icon: 'piggy-bank',
          },
          {
            label: 'Número de cuenta',
            value: worker.account_number,
            hidden: worker.payment_method !== 'wire_transfer',
            icon: 'hash',
          },
        ],
      })

    return data
  }, [isFromAffiliationView, openGeneralDataModal, worker])

  return (
    <>
      {sectionData.map((item) => {
        const itemKey = `${item?.title
          .toString()
          .toLowerCase()
          .replace(/ /g, '_')}`
        return (
          <SectionContainer
            item={item}
            key={itemKey}
            containerCyLabel={item.containerCyLabel}
            isRegistrationFinished={isRegistrationFinished}
          />
        )
      })}
      {updateGeneralDataModalState.open ? (
        <UpdateWorkerGeneralDataModal
          onCancel={closeGeneralDataModal}
          state={updateGeneralDataModalState}
        />
      ) : null}
    </>
  )
}

export default PersonalDataContent
