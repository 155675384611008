import { useQueryClient } from 'react-query'

import { Box } from '@mui/material'

import { getCredentialsColumns } from 'components/Company/Form/Fields/Credentials/helpers'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import useCompanyCredentialsService from 'utils/hooks/company/credentials'
import useNotifications from 'utils/hooks/useNotifications'

import { updateCredentials } from 'services/company/companyService'

import { claimEntitiesValidationSchema } from './helpers'

export default function DisabilityClaimsEntities() {
  const queryClient = useQueryClient()
  const { showSuccessMessage } = useNotifications()

  const companyId = getCompanyId()
  const callback = (action) => {
    queryClient.invalidateQueries(['getCredentials', companyId])
    showSuccessMessage(`La credencial ha sido ${action}.`)
  }

  const handleAddCredentials = (credentialToCreate) => {
    const valuesToSend = credentialToCreate
    delete valuesToSend.institution_category

    return {
      data: {
        company_credentials: [valuesToSend],
      },
      mutationFunction: updateCredentials,
      mutateOptions: {
        onSuccess: () => callback('creada'),
      },
    }
  }

  const handleUpdateCredential = (oldCredentials, newCredential) => {
    const dirtyValues = getDirtyValues(
      oldCredentials,
      newCredential,
      claimEntitiesValidationSchema.fields
    )

    if (!isObjectEmpty(dirtyValues)) {
      return {
        data: {
          company_credentials: [
            {
              id: newCredential.id,
              institution_id: dirtyValues.provider_id,
              ...dirtyValues,
            },
          ],
        },
        mutationFunction: updateCredentials,
        mutateOptions: {
          onSuccess: () => callback('actualizada'),
        },
      }
    }

    return {}
  }

  const { companyCredentialsQuery } = useCompanyCredentialsService({
    queryParams: {
      queryKey: ['getCredentials', companyId],
      searchParams: { categories: ['risk_provider', 'health_provider'] },
    },
  })

  return (
    <Box
      sx={(theme) => ({
        marginTop: theme.spacing(3),
      })}
    >
      <Table
        data={companyCredentialsQuery?.data || []}
        columns={getCredentialsColumns()}
        options={{
          pagination: false,
          search: false,
          alignActionsCell: 'center',
          version: {
            toolbar: 'v2',
          },
        }}
        editable={{
          claimEntitiesValidationSchema,
          onAddRow: handleAddCredentials,
          onUpdateRow: handleUpdateCredential,
          hideDelete: true,
          enableAddRow: true,
          addRowPosition: 'top',
          addRowActionProps: {
            tooltip: 'Añadir entidad',
          },
          tableMinWidth: 720,
        }}
      />
    </Box>
  )
}
