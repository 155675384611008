import { Fragment, useState } from 'react'

import { Box, Button, Collapse, Divider, Typography } from '@mui/material'

const ActionsCards = ({ action, expandDataCy }) => {
  const [showMore, setShowMore] = useState(false)

  return (
    <Box className={action.className}>
      <Box
        sx={(theme) => ({
          borderRadius: theme.spacing(2),
          width: '100%',
          minHeight: '7rem',
          display: 'flex',
          boxShadow: theme.shadows[5],
          backgroundColor: theme.palette.white.main,
        })}
      >
        <Box
          sx={(theme) => ({
            width: '5.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            backgroundColor: `${action.color}`,
            borderRadius: theme.spacing(2, 0, 0, 2),
            paddingTop: theme.spacing(3),
          })}
        >
          <Box
            component="img"
            loading="lazy"
            src={action.icon}
            alt={action.alt}
            width={64}
            height={64}
          />
        </Box>
        <Box
          sx={(theme) => ({
            width: '100%',
            padding: theme.spacing(2, 0),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          })}
        >
          <Typography
            variant="lead1"
            sx={(theme) => ({
              margin: theme.spacing(0, 2),
            })}
          >
            {action.label}
          </Typography>
          <Collapse collapsedSize="0rem" in={showMore}>
            <Box
              sx={(theme) => ({
                display: 'flex',
                justifyContent: 'start',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: theme.spacing(1.5),
                padding: theme.spacing(2),
              })}
            >
              <Divider
                sx={{
                  width: '100%',
                  opacity: 0,
                  transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  ...(showMore && {
                    opacity: 1,
                    transition:
                      'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  }),
                }}
              />

              {action?.options.map((option, actionIndex) => {
                if (option.hidden) return null

                if (option.Component)
                  return (
                    <Fragment key={actionIndex.toString()}>
                      {option.Component}
                    </Fragment>
                  )

                return (
                  <Box key={actionIndex.toString()}>
                    <Button
                      variant="text"
                      sx={{
                        padding: 0,
                        height: 'auto',
                      }}
                      onClick={option.onClick}
                      startIcon={option.icon}
                    >
                      {option.label}
                    </Button>
                  </Box>
                )
              })}
            </Box>
          </Collapse>

          <div>
            <Button
              data-cy={expandDataCy}
              variant="text"
              size="large"
              onClick={() => setShowMore(!showMore)}
              sx={(theme) => ({
                textDecoration: 'underline',
                color: theme.palette.accent4.main,
                padding: 0,
                height: 'auto',
                margin: theme.spacing(0, 2),
                '&:focus, :hover': {
                  color: theme.palette.accent4.main,
                },
              })}
            >
              {!showMore ? 'Ver más' : 'Ver menos'}
            </Button>
          </div>
        </Box>
      </Box>
    </Box>
  )
}

export default ActionsCards
