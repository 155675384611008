import { useContext, useMemo } from 'react'

import { alpha, useTheme } from '@mui/material'

import Icon from 'components/UI/Icon'
import Table from 'components/UI/Table/Table'

import { getCompanyId } from 'utils/company'

import { periodPayrollSummary } from 'services/payroll/electronicPayrollPeriodService'

import Resume from './ActionGroup/Resume'
import { ElectronicPeriodContext } from './ElectronicPayroll'
import { getSummaryColumns } from './helpers'

const SummaryTable = ({ electronicPayrollId }) => {
  const companyId = getCompanyId()
  const { setShowSummary } = useContext(ElectronicPeriodContext) || {}
  const theme = useTheme()

  const actions = [
    {
      id: 'goBack',
      tooltip: 'Regresar',
      isFreeAction: true,
      buttonProps: {
        variant: 'outlined',
        startIcon: <Icon name="move-left" />,
      },
      onClick: () => setShowSummary(false),
    },
    {
      id: 'reportPayroll',
      isFreeAction: true,
      Component: <Resume electronicPayrollPeriodId={electronicPayrollId} />,
    },
  ]

  const expandedRows = useMemo(
    () => ({
      0: true,
    }),
    []
  )

  const fetchSummary = (pageIndex, search) => {
    return {
      queryKey: [
        'getElectronicPayrollSummary',
        pageIndex,
        search,
        companyId,
        electronicPayrollId,
      ],
      queryFunction: () =>
        periodPayrollSummary({
          page: pageIndex + 1,
          search,
          companyId,
          electronicPayrollId,
        }),
    }
  }

  const columns = useMemo(
    () => getSummaryColumns(electronicPayrollId),
    [electronicPayrollId]
  )

  return (
    <Table
      columns={columns}
      data={fetchSummary}
      actions={actions}
      options={{
        expandableRows: true,
        sorting: false,
        version: {
          toolbar: 'v2',
        },
        rowStyle: (_, row) => {
          const { white, complementary1 } = theme.palette

          if (row.depth) {
            const parentIndex = parseInt(row.id, 10)
            return {
              backgroundColor:
                parentIndex % 2 ? alpha(complementary1.light, 0.2) : white.main,
            }
          }
          return {
            backgroundColor:
              row.index % 2 ? alpha(complementary1.light, 0.2) : white.main,
          }
        },
        expandedInitialState: expandedRows,
      }}
    />
  )
}

export default SummaryTable
