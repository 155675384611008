import { Box, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import { subscriptionPaymentCategories } from 'utils/company'
import { capitalize } from 'utils/general'

import { PaymentStatus } from '../../helpers'
import { paymentMethodLogo } from './helpers'

const PaymentMethod = ({
  isAutomaticDebit,
  isCreditCard,
  subscriptionData,
  mainPaymentMethod,
  isPse,
}) => {
  const currentPaymentMethodTitle = isCreditCard
    ? `Tarjeta ${capitalize(mainPaymentMethod.credit_card_brand)}`
    : `Cuenta ${capitalize(mainPaymentMethod.automatic_debit_bank)}`

  const ccType = mainPaymentMethod.credit_card_brand?.toLowerCase() || ''
  const ccLastNumbers = mainPaymentMethod.credit_card_last_numbers || ''

  const accountNumber =
    mainPaymentMethod.automatic_debit_account_last_numbers || ''

  const { category: paymentCategory } = mainPaymentMethod

  const showPaymentMethodTitle =
    !isPse && subscriptionData?.payment_category !== 'distributor_payment'

  return (
    <Box sx={(theme) => ({ marginBottom: theme.spacing(4) })}>
      {isAutomaticDebit ? (
        <Box
          sx={(theme) => ({
            marginBottom: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <Typography variant="lead1">Estado</Typography>
          <PaymentStatus
            statusInfo={subscriptionData?.automatic_debit_account_info}
          />
        </Box>
      ) : null}
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0.5, 1),
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '0.5rem',
          '& img': {
            marginRight: theme.spacing(1.5),
          },
        })}
      >
        <>
          {isCreditCard ? (
            <>
              {paymentMethodLogo[ccType] ? (
                <Box
                  component="img"
                  src={paymentMethodLogo[ccType]}
                  alt="Credit card logo"
                  width={70}
                  height={70}
                />
              ) : (
                <Icon
                  name="credit-card"
                  sx={(theme) => ({
                    marginRight: theme.spacing(1),
                    fontSize: '2rem',
                  })}
                />
              )}
            </>
          ) : null}
        </>
        {isAutomaticDebit ? (
          <Box
            component="img"
            src={paymentMethodLogo.automaticDebit}
            alt="Automatic Debit"
            width={70}
            height={70}
          />
        ) : null}
        {isPse ? (
          <Box
            component="img"
            src={paymentMethodLogo.pse}
            alt="PSE Logo"
            width={70}
            height={70}
          />
        ) : null}
        <Box>
          <Typography variant="lead1">
            {subscriptionPaymentCategories[paymentCategory]
              ? subscriptionPaymentCategories[paymentCategory]
              : null}
          </Typography>
          {showPaymentMethodTitle ? (
            <Typography>{currentPaymentMethodTitle}</Typography>
          ) : null}
          {accountNumber || ccLastNumbers ? (
            <Typography variant="body1" color="black.dark">
              Terminación {isAutomaticDebit ? accountNumber : ccLastNumbers}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentMethod
