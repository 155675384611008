import { useInbox } from '@trycourier/react-hooks'
import { useEffect, useState } from 'react'

import { Box, Button, Paper, Typography, tabClasses } from '@mui/material'

import Icon from 'components/UI/Icon'
import LoadingBox from 'components/UI/Loading/LoadingBox'
import RoundedTabs from 'components/UI/MaterialUI/RoundedTabs'

import happyMan from 'assets/images/views/common/aleluya_person_no_shadow.svg'

import { Message } from './Messages'

const NotificationsPage = () => {
  const { markAllAsRead, messages = [], fetchMessages, isLoading } = useInbox()
  const [activeTab, setActiveTab] = useState(0)

  const filteredMessages =
    activeTab === 1 ? messages?.filter((message) => !message.read) : messages

  useEffect(() => {
    fetchMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeTab = (_, newTab) => {
    setActiveTab(newTab)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        gridColumn: '1 / -1',
      }}
    >
      <Paper
        sx={{
          width: '100%',
          maxWidth: '58rem',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '1rem',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(3, 3, 2, 3),
            borderBottom: `1px solid ${theme.palette.white.dark}`,
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              gap: theme.spacing(2),
              justifyContent: 'flex-start',
            },
          })}
        >
          <RoundedTabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="fullWidth"
            scrollButtons={false}
            tabsConfig={[
              {
                label: 'Todas',
              },
              {
                label: 'No leídas',
              },
            ]}
            sx={{
              width: '12rem',
              minHeight: 'auto',
              [`& .${tabClasses.root}`]: {
                padding: 0,
                height: '1.8rem',
                minHeight: 'auto',
                minWidth: 'auto',
              },
            }}
          />
          <Button
            variant="text"
            size="small"
            endIcon={<Icon name="circle-check-big" />}
            onClick={() => markAllAsRead(false)}
            sx={(theme) => ({
              [theme.breakpoints.down('tablet')]: {
                textAlign: 'left',
              },
            })}
          >
            Marcar todo como leído
          </Button>
        </Box>
        {!isLoading ? (
          <>
            {filteredMessages.length > 0 ? (
              filteredMessages?.map((message) => {
                return (
                  <Message key={message.created} message={message} withBorder />
                )
              })
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    height: '36rem',
                    width: '36rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: theme.spacing(2),
                  })}
                >
                  <Box
                    component="img"
                    sx={{
                      width: '40%',
                      height: '40%',
                      objectFit: 'contain',
                    }}
                    src={happyMan}
                    alt="Estás al día con tus notificaciones"
                  />
                  <Typography variant="body2">
                    ¡Estás al día con tus notificaciones!
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <LoadingBox />
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default NotificationsPage
