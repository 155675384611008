import {
  Box,
  CircularProgress,
  TableCell,
  TableRow,
  Typography,
  alpha,
} from '@mui/material'

import Icon from '../Icon'

const EmptyState = ({ colSpan, configuration = {}, loading }) => {
  const {
    title,
    description,
    logo,
    icon,
    callToAction,
    hideDescription,
    logoSize,
  } = configuration

  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        sx={{
          textAlign: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '13.375rem',
            padding: theme.spacing(3),
          })}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                maxWidth: '37.5rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {logo ? (
                <Box
                  component="img"
                  src={logo}
                  width={logoSize || 128}
                  height={logoSize || 128}
                  alt="Empty table logo"
                  sx={(theme) => ({
                    ...(!title && {
                      marginBottom: theme.spacing(1),
                    }),
                  })}
                />
              ) : (
                <Box
                  sx={(theme) => ({
                    width: '8rem',
                    height: '8rem',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '4.5rem',
                    backgroundColor: alpha(theme.palette.primary.light, 0.2),
                    ...(!title && {
                      marginBottom: theme.spacing(1),
                    }),
                  })}
                >
                  {icon || (
                    <Icon
                      name="cloud-alert"
                      sx={(theme) => ({
                        fontSize: '5rem',
                        color: theme.palette.primary.dark,
                      })}
                    />
                  )}
                </Box>
              )}

              {title ? (
                <Typography
                  variant="h5"
                  sx={(theme) => ({
                    color: theme.palette.black.dark,
                    margin: theme.spacing(2, 0),
                  })}
                >
                  {title}
                </Typography>
              ) : null}
              {!hideDescription ? (
                <Typography
                  variant="body1"
                  sx={(theme) => ({
                    color: theme.palette.black.dark,
                    ...(callToAction !== undefined && {
                      marginBottom: theme.spacing(2),
                    }),
                  })}
                >
                  {description || 'No se encontraron registros'}
                </Typography>
              ) : null}
              {callToAction || null}
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default EmptyState
