import { getCompanyId } from 'utils/company'

import http, { downloadFileWebSocket, getApiURL } from '../httpService'
import formatPeriod from './helpers'

export const getCurrent = async () => {
  const url = getApiURL({ pathname: '/current_period' })

  const { data } = await http.get(url)

  const formattedPeriod = formatPeriod(data)

  return formattedPeriod
}

export const getCurrentPeriod = ({ searchParams = {} } = {}) => {
  const url = getApiURL({
    pathname: '/current_period',
    searchParams,
  })

  return http.getV2(url)
}

export const getPeriodPayrolls = ({ searchParams = {} } = {}) => {
  const url = getApiURL({
    pathname: '/payrolls',
    searchParams,
  })

  return http.getV2(url)
}

export const getById = async (periodId) => {
  const url = getApiURL({ pathname: `/periods/${periodId}` })

  const { data } = await http.get(url)

  const formattedPeriod = formatPeriod(data)

  return formattedPeriod
}

export const getPeriodById = ({ periodId, searchParams = {} } = {}) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}`,
    searchParams,
  })

  return http.getV2(url)
}

export const getAllPeriods = (page, month, year) => {
  const url = getApiURL({
    pathname: '/periods',
    searchParams: { page, month, year },
  })

  return http.getV2(url)
}

export const getPeriodOptions = async () => {
  const url = getApiURL({ pathname: '/periods_dates' })

  const { data } = await http.get(url)

  return data
}

export const getPeriodDateOptions = () => {
  const url = getApiURL({ pathname: '/periods_dates' })

  return http.getV2(url)
}

export const getPeriodContext = (periodId) => {
  const url = getApiURL({ pathname: `/periods/${periodId}/options` })

  return http.getV2(url)
}

export const createPreviousPeriod = async (rangeType) => {
  const url = getApiURL({ pathname: '/periods' })

  const data = {
    period: {
      range_type: rangeType,
    },
  }

  const response = await http.post(url, data)
  return response
}

export const updateCurrent = async (period) => {
  const url = getApiURL({ pathname: '/current_period' })

  const data = {
    period,
  }

  const response = await http.patch(url, data)
  return response
}

export const updateCurrentPeriod = (period) => {
  const url = getApiURL({ pathname: '/current_period' })

  const data = {
    period,
  }

  return http.patchV2(url, data)
}

export const updateOrganizerAction = async (periodId, perform) => {
  const url = getApiURL({
    pathname: `/organizers/${getCompanyId()}/period_actions/${periodId}`,
    withoutVersion: true,
  })

  const data = { period_action: { perform } }

  const response = await http.patch(url, data)
  return response
}

export const getConsolidatePayrollSummaryFile = async ({
  initial_day: initialDay,
  end_day: endDay,
  async = true,
}) => {
  const url = getApiURL({
    pathname: '/consolidated_payroll_summary_file',
    searchParams: { initial_day: initialDay, end_day: endDay, async },
  })

  const res = await http.get(url)

  return async
    ? downloadFileWebSocket(`companies/${getCompanyId()}/payroll_summary`)
    : res
}

export const downloadConsolidatePayrollSummaryFile = ({
  initial_day: initialDay,
  end_day: endDay,
  async = true,
}) => {
  const url = getApiURL({
    pathname: '/consolidated_payroll_summary_file',
    searchParams: { initial_day: initialDay, end_day: endDay, async },
  })

  return http.getV2(url)
}

export const downloadNoveltiesSummaryFile = ({
  initial_day: initialDay,
  end_day: endDay,
  async = true,
}) => {
  const url = getApiURL({
    pathname: '/novelties_summary_files',
    searchParams: { initial_day: initialDay, end_day: endDay, async },
  })

  return http.getV2(url)
}

export const downloadHolidaysConsolidationFile = ({
  initial_day: initialDay,
  end_day: endDay,
  async = true,
}) => {
  const url = getApiURL({
    pathname: '/holidays_summary_files',
    searchParams: { initial_day: initialDay, end_day: endDay, async },
  })

  return http.getV2(url)
}

export const getPayrollSummary = ({ periodId, searchParams = {} } = {}) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/period_payrolls_summary`,
    searchParams,
  })

  return http.getV2(url)
}

export const getSeveranceFile = (periodId, socialBenefitPeriodId) => {
  const url = getApiURL({
    pathname: `/periods/${periodId}/severance_payment_file`,
    searchParams: {
      social_benefit_period_id: socialBenefitPeriodId,
    },
  })

  return http.getV2(url)
}

export const getLocationsByPeriod = ({ periodId }) => {
  const url = getApiURL({ pathname: `/periods/${periodId}/locations_counters` })

  return http.getV2(url)
}

export default {
  getCurrent,
  getCurrentPeriod,
  getPeriodOptions,
  updateCurrent,
  updateOrganizerAction,
  getById,
  getPeriodById,
  getAllPeriods,
  getConsolidatePayrollSummaryFile,
  createPreviousPeriod,
  getPayrollSummary,
  getSeveranceFile,
  getLocationsByPeriod,
  getPeriodContext,
  updateCurrentPeriod,
  getPeriodPayrolls,
}
