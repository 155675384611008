import { Formik, useFormikContext } from 'formik'
import { useRef, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Button, Typography } from '@mui/material'

import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import useLoadingModal from 'components/UI/Loading/useLoadingModal'
import Alert from 'components/UI/MaterialUI/Alert/Alert'

import useBeneficiariesService from 'utils/hooks/affiliations/beneficiaries'

import Actions from './Actions'
import EditBeneficiary from './EditBeneficiary'
import {
  attachDocuments,
  getBeneficiaryAffiliationEntitiesAlert,
  getBeneficiaryEntitiesToAffiliate,
  getBeneficiaryEntitiesToAffiliateArray,
  getInitialValues,
  remainKinshipOptions,
  validationSchema,
} from './helpers'

const BeneficiariesStep = ({
  worker,
  selectedEntitiesToAffiliate,
  isTrainee,
  preserveDocuments,
}) => {
  const queryClient = useQueryClient()
  const { showLoadingModal, hideLoadingModal } = useLoadingModal()
  const formikRef = useRef(null)
  const { values: mainFormValues } = useFormikContext()
  const [showAddBeneficiary, setShowAddBeneficiary] = useState(false)
  const affiliationQueryKey = ['getAffiliationById', worker?.id]
  const beneficiaries = mainFormValues?.beneficiaries || []

  const { beneficiariesMutation } = useBeneficiariesService()

  const onSubmit = (values, form) => {
    const data = new FormData()

    data.append('name', values.name)
    data.append('kinship', values.kinship)

    data.append(
      'beneficiary_entities_to_affiliate',
      JSON.stringify(
        getBeneficiaryEntitiesToAffiliateArray(values.entities_to_affiliate)
      )
    )

    if (['child', 'stepchild'].includes(values.kinship))
      data.append('age', values.age)

    Object.entries(values).forEach(([key, value]) => {
      if (value instanceof File) {
        data.append(key, value)
      }
    })

    showLoadingModal()

    beneficiariesMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId: worker?.id,
        beneficiaryData: data,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(affiliationQueryKey)
          preserveDocuments()
          setShowAddBeneficiary(false)
          hideLoadingModal()
          form.resetForm()
        },
        onError: () => {
          hideLoadingModal()
        },
      }
    )
  }

  const handleCloseAddBeneficiaryFields = () => {
    setShowAddBeneficiary(false)
    formikRef.current?.resetForm()
  }

  const { showBeneficiaryAffiliationEntitiesAlert, alertText } =
    getBeneficiaryAffiliationEntitiesAlert({
      worker,
      selectedEntitiesToAffiliate,
      isTrainee,
    })

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, submitForm }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6" color="primary.dark">
              Beneficiarios
            </Typography>
            <Typography variant="body1" color="black.dark">
              Los beneficiarios son personas que podrán disfrutar de los
              beneficios de seguridad social. Diligencia los campos y adjunta la
              documentación requerida.
            </Typography>
            {showBeneficiaryAffiliationEntitiesAlert ? (
              <Alert
                header="Ten en cuenta que:"
                severity="info"
                sx={(theme) => ({
                  marginTop: theme.spacing(2),
                })}
              >
                {alertText}
              </Alert>
            ) : null}
            {beneficiaries.map((beneficiary) => (
              <EditBeneficiary
                key={beneficiary.id}
                worker={worker}
                beneficiaries={beneficiaries}
                beneficiary={beneficiary}
                affiliationQueryKey={affiliationQueryKey}
                selectedEntitiesToAffiliate={selectedEntitiesToAffiliate}
                preserveDocuments={preserveDocuments}
              />
            ))}
            {showAddBeneficiary ? (
              <>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    columnGap: theme.spacing(3),
                    marginTop: theme.spacing(2.5),
                    gridTemplateColumns: '1fr 5rem',
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: 'grid',
                      gap: theme.spacing(3),
                      gridTemplateColumns: '1fr',
                      [theme.breakpoints.up('tablet')]: {
                        gridTemplateColumns: 'repeat(3, 1fr)',
                      },
                    })}
                  >
                    <FormField
                      name="name"
                      label="Nombre completo"
                      optional={false}
                      placeholder="Nombre"
                    />
                    <FormField
                      name="kinship"
                      label="Tipo de beneficiario"
                      optional={false}
                      variant="select"
                      placeholder="Selecciona"
                      options={remainKinshipOptions(
                        beneficiaries,
                        values.kinship
                      )}
                    />
                    <FormField
                      name="entities_to_affiliate"
                      label="Tipo de afiliación"
                      optional={false}
                      variant="select"
                      placeholder="Selecciona"
                      options={getBeneficiaryEntitiesToAffiliate(
                        selectedEntitiesToAffiliate
                      )}
                    />
                    {['child', 'stepchild'].includes(values.kinship) ? (
                      <FormField
                        name="age"
                        label="Edad"
                        optional={false}
                        variant="number"
                        isAllowed={(values) => {
                          const { floatValue } = values
                          return floatValue === undefined || floatValue <= 25
                        }}
                        placeholder="Edad en años"
                      />
                    ) : null}
                  </Box>
                  <Actions
                    leftAction={{
                      tooltip: 'Confirmar',
                      icon: <Icon name="check" />,
                      onClick: submitForm,
                    }}
                    rightAction={{
                      tooltip: 'Cancelar',
                      icon: <Icon name="x" />,
                      onClick: handleCloseAddBeneficiaryFields,
                    }}
                  />
                  <Box
                    sx={(theme) => ({
                      gridColumn: '1 / -1',
                      display: 'grid',
                      flexDirection: 'column',
                      marginTop: theme.spacing(3),
                      columnGap: theme.spacing(2),
                      rowGap: theme.spacing(1.5),
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      [theme.breakpoints.down('tablet')]: {
                        gap: theme.spacing(2.5),
                        gridTemplateColumns: '1fr',
                      },
                    })}
                  >
                    {attachDocuments(values)}
                  </Box>
                </Box>
              </>
            ) : null}
            <Button
              endIcon={<Icon name="plus" />}
              variant="outlined"
              onClick={() => {
                setShowAddBeneficiary(true)
              }}
              sx={(theme) => ({
                width: 'fit-content',
                marginTop: theme.spacing(3),
              })}
            >
              Agregar beneficiario
            </Button>
          </Box>
        )
      }}
    </Formik>
  )
}

export default BeneficiariesStep
