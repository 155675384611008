import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

import PremiumFeatureIcon from 'components/App/Premium/Atoms/PremiumFeatureIcon'
import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import BankFileModal from 'components/Period/Payroll/Modals/BankFileModal'
import PayslipModal from 'components/Period/Payroll/Modals/PayslipModal'
import useBankFileModal from 'components/Period/Payroll/Modals/useBankFileModal'
import usePayslipModal from 'components/Period/Payroll/Modals/usePayslipModal'
import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'
import useWorker from 'components/Worker/useWorker'

import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'
import useTerminationService from 'utils/hooks/worker/terminationService'

import * as routes from 'config/routes'

const TerminationMenu = ({ worker, confirm, isEditable }) => {
  const [termination, setTermination] = useState({})
  const { refreshWorker } = useWorker({ useCache: true })
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const navigate = useNavigate()

  const { showPremiumFeatures, openPremiumFeatureModal } = usePremiumFeature()

  const {
    payslipModalState,
    openPayslipModal,
    closePayslipModal,
    handleDownloadPayslip,
  } = usePayslipModal()

  const { bankFileModalState, openBankFileModal, closeBankFileModal } =
    useBankFileModal()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workerHistoryMenu',
  })

  const { terminationQuery, terminationMutation } = useTerminationService({
    serviceParams: {
      queryKey: ['getTermination', worker?.contract_id],
      contractId: worker?.contract_id,
    },
    queryOptions: {
      onSuccess: ({ data }) => setTermination(data),
      onError: (error) => handleError(error),
    },
  })

  const refreshWorkerData = () => {
    refreshWorker()
  }

  const handleDeleteTermination = (contractId) => {
    confirm({
      title: 'Eliminar liquidación laboral',
      description:
        '¿Estás seguro de eliminar la liquidación laboral de esta persona? Este cambio no puede deshacerse. Esta persona se incluirá de nuevo en todas las nóminas hasta su fecha final de contrato.',
      type: 'warning',
      onOk: async () => {
        await terminationMutation.mutateAsync(
          {
            mutationMethod: 'DELETE',
            contractId,
          },
          {
            onSuccess: () => {
              showSuccessMessage('Liquidación laboral eliminada')
              refreshWorkerData()
            },
          },
          {
            onError: (error) => handleError(error),
          }
        )
      },
      okText: 'Eliminar liquidación',
    })
  }

  const handleShowPayslip = () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    openPayslipModal({ payroll: { id: worker.payroll_id } })
    popupState.close()
  }

  const handleBankPaymentFile = () => {
    if (!showPremiumFeatures) {
      openPremiumFeatureModal()
      return
    }
    openBankFileModal({ payroll: { id: termination.payroll_id } })
    popupState.close()
  }

  if (terminationQuery.isSuccess) {
    return (
      <>
        <Button
          endIcon={<Icon name="chevron-down" />}
          variant="outlined"
          size="large"
          sx={{ width: { mobile: 1, tablet: 'auto' } }}
          {...bindTrigger(popupState)}
        >
          Liquidación
        </Button>

        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            onClick={() =>
              navigate(
                `${routes.PERIOD_PAYROLL_VIEW_DETAILS(
                  terminationQuery?.data?.payroll_id
                )}?selected_tab=termination`
              )
            }
          >
            Ver cálculos
          </MenuItem>
          {terminationQuery?.data?.bank_file ? (
            <MenuItem onClick={handleBankPaymentFile}>
              <ListItemText>Archivo del banco</ListItemText>
              <ListItemIcon>
                <PremiumFeatureIcon fontSize="1rem" />
              </ListItemIcon>
            </MenuItem>
          ) : null}
          {isEditable ? (
            <span>
              <MenuItem
                component={Link}
                to={routes.WORKER_CONTRACT_TERMINATION_EDIT(
                  worker.id,
                  worker.contract_id
                )}
              >
                Editar liquidación
              </MenuItem>
              <MenuItem
                onClick={() => handleDeleteTermination(worker.contract_id)}
              >
                Eliminar liquidación
              </MenuItem>
            </span>
          ) : null}
          {worker.termination.id ? (
            <MenuItem onClick={handleShowPayslip}>
              <ListItemText>Liquidación (PDF)</ListItemText>
              <ListItemIcon>
                <PremiumFeatureIcon fontSize="1rem" />
              </ListItemIcon>
            </MenuItem>
          ) : null}
        </Menu>
        <BankFileModal
          state={bankFileModalState}
          handleClose={closeBankFileModal}
        />
        {payslipModalState.open ? (
          <PayslipModal
            state={payslipModalState}
            handleClose={closePayslipModal}
            handleDownloadPayslip={handleDownloadPayslip}
          />
        ) : null}
      </>
    )
  }

  return null
}

export default TerminationMenu
