import { Form, Formik } from 'formik'
import { useQueryClient } from 'react-query'

import { Box, Typography } from '@mui/material'

import Button from 'components/UI/Button/Button'
import LoadingBox from 'components/UI/Loading/LoadingBox'

import { getCompanyId } from 'utils/company'
import { formatDisplayDateString } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'
import useCompanyHolisticPayrollService from 'utils/hooks/HolisticPayroll/CompayService'
import useAffiliationsService from 'utils/hooks/affiliations/affiliations'
import useCompanyCredentialsService from 'utils/hooks/company/credentials'
import useErrorHandler from 'utils/hooks/useErrorHandler'
import useNotifications from 'utils/hooks/useNotifications'

import {
  getInitialValues,
  getInvalidCredentialEntities,
  getValidationSchema,
  renderAffiliationCredentialsFields,
} from './helpers'

const UpdateCredentialsModalContent = ({
  workerId,
  handleClose,
  setLoadingModal,
  processType = 'affiliation',
  claimDetailData = {},
}) => {
  const { handleError } = useErrorHandler()
  const { showSuccessMessage } = useNotifications()
  const queryClient = useQueryClient()
  const affiliationQueryKey = ['getAffiliationById', workerId]
  const companyId = getCompanyId()

  const { claimsDetailId, claimNoveltyId } = claimDetailData

  const {
    affiliationsQuery: {
      data: affiliationsData,
      isLoading: affiliationsQueryIsLoading,
    },
    affiliationsMutation,
  } = useAffiliationsService({
    serviceParams: {
      queryKey: affiliationQueryKey,
      workerId,
    },
    queryOptions: {
      enabled: processType === 'affiliation',
    },
  })

  const {
    rejected_comment: rejectionComment,
    created_at: affiliationCreationDate,
    rejected_at: affiliationRejectionDate,
    certificates_data: certificatesData,
  } = affiliationsData || {}

  const handleAffiliationsMutation = (correctedData) => {
    affiliationsMutation.mutate(
      {
        mutationMethod: 'PUT',
        workerId,
        affiliationData: correctedData,
      },
      {
        onSuccess: async () => {
          setLoadingModal(false)
          await queryClient.invalidateQueries(affiliationQueryKey)
          await queryClient.invalidateQueries([
            'getCompanyOnboardings',
            companyId,
          ])
          handleClose()
        },
        onError: (error) => {
          setLoadingModal(false)
          handleError(error)
        },
      }
    )
  }

  const refreshClaimsTable = () => {
    queryClient.invalidateQueries('disabilityClaimsDetail')
    queryClient.invalidateQueries(['getDisabilityClaimsData', companyId])
  }

  const {
    companyHolisticPayrollQuery: {
      data: disabilityClaimsQueryData,
      isLoading: disabilityClaimsQueryIsLoading,
    },
    companyHolisticPayrollMutation: disabilityClaimsMutation,
  } = useCompanyHolisticPayrollService({
    serviceParams: {
      queryKey: ['getClaimStatusDetail', companyId],
      claimsDetailId,
      claimNoveltyId,
    },
    queryOptions: {
      enabled: processType === 'claim',
    },
  })

  const {
    created_at: claimCreationDate,
    review_at: claimReviewDate,
    missing_credential: isMissingCredential,
  } = disabilityClaimsQueryData || {}

  const handleDisabilityClaimsMutation = (correctedUpdatedData) => {
    disabilityClaimsMutation.mutate(
      {
        mutationMethod: 'PUT',
        claimsDetailId,
        claimNoveltyId,
        correctedClaimData: correctedUpdatedData,
      },
      {
        onSuccess: async () => {
          setLoadingModal(false)
          handleClose()
          refreshClaimsTable()
          showSuccessMessage('La credencial fue actualizada correctamente')
        },
        onError: (error) => {
          setLoadingModal(false)
          handleError(error)
        },
      }
    )
  }

  const { companyCredentialsMutation } = useCompanyCredentialsService({
    queryOptions: {
      enabled: false,
    },
  })

  const handleSubmit = (values) => {
    setLoadingModal(true)
    const correctedUpdatedData = new FormData()

    if (processType === 'affiliation') {
      correctedUpdatedData.append('status', 'waiting_response')
    }

    if (processType === 'claim') {
      correctedUpdatedData.append('status', 'in_progress')
    }

    companyCredentialsMutation.mutate(
      {
        mutationMethod: 'PUT',
        credentialsData: { company_credentials: Object.values(values) },
      },
      {
        onSuccess: () => {
          if (processType === 'affiliation') {
            handleAffiliationsMutation(correctedUpdatedData)
          }

          if (processType === 'claim') {
            handleDisabilityClaimsMutation(correctedUpdatedData)
          }
        },
        onError: (error) => {
          setLoadingModal(false)
          handleError(error)
        },
      }
    )
  }

  const claimsEntitiesArray = [disabilityClaimsQueryData]
  const invalidCredentials = getInvalidCredentialEntities(
    certificatesData || claimsEntitiesArray,
    processType
  )
  const moreThanOne = invalidCredentials?.length > 1

  const mutationIsLoading =
    companyCredentialsMutation.isLoading || affiliationsMutation.isLoading

  return (processType === 'affiliation' &&
    (affiliationsQueryIsLoading || invalidCredentials?.length === 0)) ||
    (processType === 'claim' &&
      (disabilityClaimsQueryIsLoading || isObjectEmpty(claimDetailData))) ? (
    <LoadingBox />
  ) : (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      })}
    >
      <Typography variant="h4" color="accent4.dark">
        Fecha de creación:{' '}
        {formatDisplayDateString(affiliationCreationDate || claimCreationDate)}
      </Typography>
      <Typography color="black.dark">
        Necesitamos las credenciales de acceso a{' '}
        {moreThanOne
          ? `tus cuentas con las siguientes entidades para gestionar estas afiliaciones`
          : `tu cuenta con la siguiente entidad para gestionar esta ${processType === 'affiliation' ? 'afiliación' : 'reclamación'}`}
        . ¡Relax! Esta información está 100% segura con nosotros.
      </Typography>
      {rejectionComment ? (
        <Typography color="black.dark">
          <b>Comentario adicional:</b> {rejectionComment}
        </Typography>
      ) : null}
      <Typography color="black.dark">
        <b>Fecha de revisión:</b>{' '}
        {formatDisplayDateString(affiliationRejectionDate || claimReviewDate)}
      </Typography>
      <Formik
        initialValues={getInitialValues(
          invalidCredentials,
          processType,
          isMissingCredential
        )}
        validationSchema={getValidationSchema(
          invalidCredentials,
          processType,
          isMissingCredential
        )}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            })}
          >
            {renderAffiliationCredentialsFields(
              certificatesData || claimsEntitiesArray,
              processType
            )}
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              gap: theme.spacing(2),
              marginTop: theme.spacing(3),
              [theme.breakpoints.down('tablet')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Button type="submit" loading={mutationIsLoading}>
              Enviar información
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancelar
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  )
}

export default UpdateCredentialsModalContent
