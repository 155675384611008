import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'

const CheckboxGroupField = ({
  value,
  row = false,
  options = [],
  setValue,
  justifiSpaceBetween = false,
  checkboxSx,
}) => {
  return (
    <FormGroup
      row={row}
      sx={(theme) => ({
        ...(justifiSpaceBetween && {
          [theme.breakpoints.up('laptop')]: {
            justifyContent: 'space-between',
          },
        }),
      })}
    >
      {options.map((option) => (
        <Box sx={checkboxSx} key={option.label}>
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={
                  value?.find?.(
                    (currentValue) => currentValue === option.value
                  ) !== undefined
                }
                onChange={({ target: { name, checked } }) => {
                  const valueSet = new Set(value)

                  if (checked) {
                    valueSet.add(name)
                  } else {
                    valueSet.delete(name)
                  }

                  setValue([...valueSet])
                }}
                name={option.value}
                disabled={option.disabled}
              />
            }
            label={option.label}
          />
        </Box>
      ))}
    </FormGroup>
  )
}

export default CheckboxGroupField
