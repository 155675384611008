import { SnackbarProvider } from 'notistack'
import { useRef } from 'react'

import Icon from 'components/UI/Icon'

import {
  CustomSnackbarError,
  CustomSnackbarInfo,
  CustomSnackbarSuccess,
  CustomSnackbarWarning,
} from './helpers'

const NotistackProvider = ({ children }) => {
  const notistackRef = useRef()

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      Components={{
        success: CustomSnackbarSuccess,
        error: CustomSnackbarError,
        warning: CustomSnackbarWarning,
        info: CustomSnackbarInfo,
      }}
      iconVariant={{
        error: <Icon name="circle-x" sx={{ fontSize: '1.5rem' }} />,
        success: <Icon name="circle-check" sx={{ fontSize: '1.5rem' }} />,
        info: <Icon name="info" sx={{ fontSize: '1.5rem' }} />,
        warning: <Icon name="circle-alert" sx={{ fontSize: '1.5rem' }} />,
      }}
      SnackbarProps={{
        'data-cy': 'notification-snack',
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

export default NotistackProvider
