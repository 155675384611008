import { Box, Button, Typography } from '@mui/material'

import WellnessHalfDonutChart from 'components/HolisticPayroll/Commons/WellnessHalfDonutChart'
import Icon from 'components/UI/Icon'

import { formatDisplayDateString } from 'utils/dateTime'
import { isObjectEmpty } from 'utils/general'

export const useCardsContent = ({
  coachingSessions,
  financialWellnessIndicator,
  changeTab,
}) => [
  {
    id: 'coming_soon',
    title: 'Próximamente',
    mainContent: (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <WellnessHalfDonutChart value={0} enableEmptyState />
        </Box>

        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Aún no tienes datos
        </Typography>
      </>
    ),
    button: (
      <Button
        disabled
        sx={(theme) => ({
          margin: theme.spacing(3, 0, 0, 0),
          [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(6.5, 1.5, 0),
          },
        })}
        endIcon={<Icon name="move-right" />}
      >
        Próximamente
      </Button>
    ),
  },
  {
    id: 'indicator_score',
    title: 'Indicador de Bienestar',
    mainContent: (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <WellnessHalfDonutChart
            value={financialWellnessIndicator?.value || 0}
          />
        </Box>

        {isObjectEmpty(financialWellnessIndicator) ? (
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            Aún no tienes datos
          </Typography>
        ) : (
          <>
            <Typography
              variant="h5"
              sx={(theme) => ({
                textAlign: 'center',
                margin: theme.spacing(3.6, 0, 1),
              })}
            >
              Puntaje promedio
            </Typography>
            <Typography variant="body1" color="accent4.main" textAlign="center">
              Actualizado el{' '}
              {formatDisplayDateString(financialWellnessIndicator?.updated_at)}
            </Typography>
          </>
        )}
      </>
    ),
    button: (
      <Button
        sx={(theme) => ({
          margin: theme.spacing(3, 0, 0, 0),
          [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(6.5, 1.5, 0),
          },
        })}
        endIcon={<Icon name="move-right" />}
        onClick={() => changeTab(1)}
      >
        Realizar test de bienestar
      </Button>
    ),
  },
  {
    id: 'coaching_sessions',
    title: 'Sesiones realizadas',
    mainContent: (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.5625rem',
              color: 'accent4.main',
              textAlign: 'center',
            }}
          >
            Número de sesiones realizadas:
          </Typography>
          <Typography
            sx={{
              fontSize: '6.125rem',
              fontWeight: '700',
              textAlign: 'center',
              fontFamily: 'Montserrat',
            }}
          >
            {isObjectEmpty(coachingSessions)
              ? '0'
              : coachingSessions?.value <= 9
                ? `0${coachingSessions?.value}`
                : coachingSessions?.value}
          </Typography>
        </Box>
      </>
    ),
    button: (
      <Button
        sx={(theme) => ({
          margin: theme.spacing(3, 0, 0, 0),
          [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(6.5, 1.5, 0),
          },
        })}
        endIcon={<Icon name="move-right" />}
        onClick={() => changeTab(2)}
      >
        Agendar cita
      </Button>
    ),
  },
]
