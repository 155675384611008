import { Button, Link, useMediaQuery } from '@mui/material'

import Icon from 'components/UI/Icon'

import { HELP_CENTER } from 'config/organization'

import IconButton from './IconButton'

const HelpButton = () => {
  const isMobileBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.down('laptop')
  )

  return isMobileBreakpoint ? (
    <IconButton
      component={Link}
      target="_blank"
      href={HELP_CENTER}
      icon={<Icon name="circle-help" />}
    />
  ) : (
    <Button
      component={Link}
      variant="outlined"
      target="_blank"
      href={HELP_CENTER}
      endIcon={<Icon name="circle-help" />}
    >
      Centro de ayuda
    </Button>
  )
}

export default HelpButton
