import { Box, Typography } from '@mui/material'

const formatDisplayValue = (value, id) => {
  const isDeductionValue = [
    'deductions',
    'loans',
    'payroll_retention',
  ].includes(id)

  return isDeductionValue ? `(${value?.trim()})` : value
}

const ImageCard = ({ id, name, icon, value, alt }) => {
  const backgroundColor = (theme) =>
    value ? theme.palette.primary.light : theme.palette.complementary1.light

  return (
    <Box
      sx={(theme) => ({
        height: '6rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: backgroundColor(theme),
        borderRadius: theme.spacing(1.5),
      })}
    >
      <Typography
        sx={(theme) => ({
          paddingTop: theme.spacing(0.5),
        })}
        variant="h6"
      >
        {name}
      </Typography>

      {value ? (
        <Typography
          sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
          variant="h4"
        >
          {typeof value === 'string' ? formatDisplayValue(value, id) : value}
        </Typography>
      ) : (
        <Box
          component="img"
          loading="lazy"
          src={icon}
          alt={alt}
          width={64}
          height={64}
        />
      )}
    </Box>
  )
}

export default ImageCard
