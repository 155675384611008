import { useMutation, useQuery } from 'react-query'

import { getQueryKey } from 'utils/reactQuery'

import {
  createDisabilityClaimsData,
  createDisabilityClaimsDetail,
  getClaimDetail,
  getCompanyHolisticPayroll,
  getDisabilityClaims,
  updateDisabilityClaimsData,
} from 'services/HolisticPayroll/CompanyService'

import useErrorHandler from '../useErrorHandler'

const getQueryFunction = (serviceParams) => {
  const {
    queryKey,
    claimsDetailId,
    queryMethod,
    claimNoveltyId,
    withDocument,
  } = serviceParams
  const currentQueryKey = getQueryKey(queryKey)

  if (currentQueryKey === 'getHolisticPayrollData')
    return getCompanyHolisticPayroll()

  if (currentQueryKey === 'getDisabilityClaimsData')
    return getDisabilityClaims(claimsDetailId)

  if (
    queryMethod === 'POST' &&
    currentQueryKey === 'createDisabilityClaimsData'
  )
    return createDisabilityClaimsData()

  if (currentQueryKey === 'getClaimStatusDetail')
    return getClaimDetail(claimsDetailId, claimNoveltyId, withDocument)

  return null
}

const getMutationFunction = (mutationData) => {
  const { mutationMethod, claimsDetailId, claimNoveltyId, correctedClaimData } =
    mutationData

  if (mutationMethod === 'POST')
    return createDisabilityClaimsDetail(claimsDetailId)

  if (mutationMethod === 'PUT')
    return updateDisabilityClaimsData({
      claimsDetailId,
      claimNoveltyId,
      correctedClaimData,
    })

  return null
}

const useCompanyHolisticPayrollService = ({
  serviceParams = {
    queryKey: 'getHolisticPayrollData',
  },
  queryOptions = {},
  mutationOptions = {},
} = {}) => {
  const { handleError } = useErrorHandler()

  const { data: { data } = {}, ...restCompanyHolisticPayroll } = useQuery(
    serviceParams.queryKey,
    () => getQueryFunction(serviceParams),
    {
      onError: (error) => handleError(error),
      ...queryOptions,
    }
  )

  const companyHolisticPayrollMutation = useMutation(
    (mutationData) => getMutationFunction(mutationData),
    {
      onError: (error) => handleError(error),
      ...mutationOptions,
    }
  )

  return {
    companyHolisticPayrollQuery: {
      data,
      ...restCompanyHolisticPayroll,
    },
    companyHolisticPayrollMutation,
  }
}

export default useCompanyHolisticPayrollService
