import { Box, Button, Paper, Typography } from '@mui/material'

import Icon from 'components/UI/Icon'

import Table from './Table'

export const TotalCard = ({ workersTotal, action }) => {
  return (
    <Paper
      sx={(theme) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 3),
        justifyContent: 'space-between',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(3.5),
        })}
      >
        <Icon name="users" fontSize="large" />
        <div>
          <Typography variant="lead2" color="black.dark">
            Total de personas
          </Typography>
          <Typography variant="h4">{workersTotal}</Typography>
        </div>
      </Box>
      {action || null}
    </Paper>
  )
}

const PeriodSocialSecurityPaymentModalContentSummary = ({
  workers,
  totals,
  isOnlyWorkers,
  providerName,
  showWorkers,
  setShowWorkers,
  provider,
}) => {
  const workersTotal = workers.filter(
    (worker) => worker.name !== 'Totales'
  )?.length

  return showWorkers ? (
    <Table
      workers={workers}
      totals={totals}
      isOnlyWorkers={isOnlyWorkers}
      handleClickReturn={() => setShowWorkers(false)}
      provider={provider}
      workersTotal={workersTotal}
    />
  ) : (
    <>
      <Typography variant="body1" color="black.dark">
        Para continuar el proceso de pago de seguridad social y enviar los datos
        de tu planilla a <b>{providerName}</b> debes dar clic en continuar,
        relax que no estarás solo, te iremos guiando en cada paso y siempre
        podrás regresar y editar cualquier valor.
      </Typography>
      <Box mt={4} mb={1}>
        <TotalCard
          workersTotal={workersTotal}
          action={
            <Button
              size="small"
              variant="text"
              onClick={() => setShowWorkers(true)}
            >
              Ver personas
            </Button>
          }
        />
      </Box>
    </>
  )
}

export default PeriodSocialSecurityPaymentModalContentSummary
