import * as yup from 'yup'

import { cellphoneInput } from 'utils/regex'

export const validationSchema = {
  0: yup.object({
    phone: yup
      .string()
      .nullable()
      .trim()
      .matches(
        cellphoneInput,
        'Debes ingresar un número celular válido para Colombia.'
      )
      .required(),
  }),
  1: yup.object({
    whatsapp_code: yup
      .string()
      .nullable()
      .min(4, 'El código debe tener 4 dígitos')
      .max(4)
      .required(),
  }),
}

export const initialValues = {
  phone: '',
  whatsapp_code: '',
}
