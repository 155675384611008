import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { Box, Button } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import Icon from 'components/UI/Icon'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import useDownloadURI from 'utils/hooks/useDownloadURI'
import useNotifications from 'utils/hooks/useNotifications'

import {
  deleteFreeTimeRequest,
  getFreeTimeRequest,
} from 'services/worker/freeTimeService'

import RequestFreeTimeModal from '../Modals/RequestFreeTimeModal'
import FreeTimeEmptyState from '../common/EmptyState'
import { columnsData, getActions, getEmptyStateTitle } from './helpers'

const FreeTimeIndex = () => {
  const { worker: { id: workerId } = {} } = useWorker({ useCache: true })
  const [freeTimeActiveStatus, setfreeTimeActiveStatus] = useState('')

  const fetchRequests = (pageIndex, search) => {
    return {
      queryKey: ['allRequests', pageIndex, search, freeTimeActiveStatus],
      workerId,
      queryFunction: () =>
        getFreeTimeRequest({
          workerId,
          page: pageIndex + 1,
          search,
          filter: freeTimeActiveStatus,
        }),
    }
  }

  const modals = useModals()
  const downloadURI = useDownloadURI()
  const queryClient = useQueryClient()

  const { showSuccessMessage } = useNotifications()

  const deleteRequest = (rowData) => {
    return {
      data: rowData,
      mutationFunction: deleteFreeTimeRequest,
      mutateOptions: {
        onSuccess: () => {
          queryClient.invalidateQueries('allRequests')
          showSuccessMessage('Solicitud eliminada correctamente')
        },
      },
    }
  }

  const handleCloseModal = () => modals.closeAll()

  const handleNewRequest = () => {
    modals.openModal({
      id: 'newRequest',
      content: (
        <RequestFreeTimeModal onClose={handleCloseModal} workerId={workerId} />
      ),
      modalProps: {
        header: 'Solicitar tiempo fuera',
        onCloseModal: handleCloseModal,
        disableClickOutsideModal: true,
        hideFooter: true,
        paperSx: {
          maxWidth: '40rem',
          width: '100%',
        },
      },
    })
  }

  const handleDownloadEvidence = (documentLink) => {
    downloadURI(documentLink)
  }

  const actions = getActions(
    workerId,
    handleNewRequest,
    modals,
    handleDownloadEvidence,
    freeTimeActiveStatus,
    setfreeTimeActiveStatus
  )

  return (
    <>
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Table
          data={fetchRequests}
          columns={columnsData}
          actions={actions}
          options={{
            version: {
              toolbar: 'v2',
            },
            emptyState: {
              title: getEmptyStateTitle(freeTimeActiveStatus),
              callToAction: (
                <Button onClick={handleNewRequest}>
                  Solicitar días libres
                </Button>
              ),
              icon: (
                <Icon
                  name="calendar-days"
                  sx={(theme) => ({
                    fontSize: '5rem',
                    color: theme.palette.primary.dark,
                  })}
                />
              ),
              hideDescription: true,
            },
            emptyStateComponent: (
              <FreeTimeEmptyState handleNewRequest={handleNewRequest} />
            ),
            alignActionsCell: 'center',
            customActionsWidth: '150px',
            customQueryFetch: 'novelty_requests',
          }}
          editable={{
            hideEdit: true,
            isDeletable: (row) => row.status === 'pending',
            onDeleteRow: deleteRequest,
            tableMinWidth: '1040px',
          }}
        />
      </Box>
    </>
  )
}

export default FreeTimeIndex
