import { Box, Typography } from '@mui/material'

import womanMotorcycle from 'assets/images/views/common/woman-motorcycle.png'

import { getEmptyStateText } from '../helpers'

export default function DisabilityClaimsTableEmptyState({
  textOption = 'organizer',
  activeTab = '',
}) {
  const emptyStateText = getEmptyStateText(textOption, activeTab)

  return (
    <Box
      sx={({ spacing }) => ({
        display: 'flex',
        padding: spacing(3),
        alignItems: 'center',
        justifyContent: 'center',
        gap: spacing(4),
      })}
    >
      <Box
        component="img"
        alt=""
        src={womanMotorcycle}
        sx={({ breakpoints }) => ({
          height: '21rem',
          width: '20.688rem',
          [breakpoints.down('tablet')]: {
            height: '15rem',
            width: '15rem',
          },
        })}
      />
      <Typography variant="h1" width="50%">
        {emptyStateText}
      </Typography>
    </Box>
  )
}
