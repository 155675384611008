import firebase from 'firebase/app'
import 'firebase/database'

import { getToken, getUserId, logIn } from 'utils/auth'
import { getCompanyId } from 'utils/company'

import sessionService from './auth/sessionService'
import FetchError from './util/FetchError'

export function getApiURL({
  companyId = getCompanyId(),
  protocol,
  withoutCompany = false,
  withoutVersion = false,
  pathname,
  searchParams = {},
} = {}) {
  let fullPathname = ''

  // if its without version its also without companyId
  if (!withoutVersion) {
    fullPathname += `/${process.env.REACT_APP_VERSION}`
    if (!withoutCompany) fullPathname += `/${companyId}`
  }

  fullPathname += pathname

  const url = new URL(process.env.REACT_APP_API_URL)

  if (protocol) url.protocol = protocol
  url.pathname = fullPathname

  Object.entries(searchParams).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (val != null && val !== '') {
          url.searchParams.append(`${key}[]`, val)
        }
      })
    } else if (value != null && value !== '') {
      url.searchParams.append(key, value)
    }
  })

  return url
}

export const handleFetchResponse = async (response) => {
  if (response.ok) {
    return response.json()
  }
  const { status, statusText } = response

  const fetchError = new FetchError(statusText || 'API response error', status)

  if (response.json) {
    const errorJson = await response.json()

    if (errorJson.errors) {
      fetchError.errors = errorJson.errors
    } else {
      fetchError.errors = errorJson.error
    }
  }
  throw fetchError
}

/**
 * Fetch resouce, if http 401 error returned, try refreshing jwt, and fetch again
 */
export const handleFetch = async (resource, init) => {
  const resourceStr = resource.toString()
  // try first fetch
  const response = await fetch(resourceStr, init)

  if (response.ok) {
    return response.json()
  }

  const { status, statusText } = response

  if (status === 401 && !resourceStr.includes('/session')) {
    // try refresh session
    const { data } = await sessionService.refresh()

    logIn(data)
    // and try again the request
    // with updated Authorization header bearer token
    const updatedInit = {
      ...init,
      headers: {
        ...init.headers,
        Authorization: `Bearer ${getToken()}`,
      },
    }
    const updatedResponse = await fetch(resourceStr, updatedInit)

    return handleFetchResponse(updatedResponse)
  }
  const fetchError = new FetchError(statusText || 'API response error', status)

  if (response.json) {
    const errorJson = await response.json()

    if (errorJson.errors) {
      fetchError.errors = errorJson.errors
    } else {
      fetchError.errors = errorJson.error
    }
  }
  throw fetchError
}

// handleVersion for use with React Query
export const handleFetchV2 = async (resource, options) => {
  const resourceStr = resource.toString()

  const promise = await fetch(resourceStr, options)

  if (promise.ok) return promise.json()

  const { status, statusText } = promise

  if (status === 401 && !resourceStr.includes('/session')) {
    // try refresh session
    const { data } = await sessionService.refresh()

    logIn(data)
    // and try again the request
    // with updated Authorization header bearer token
    const updatedOptions = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${getToken()}`,
      },
    }
    const updatedPromise = await fetch(resourceStr, updatedOptions)

    return handleFetchResponse(updatedPromise)
  }

  const fetchError = new FetchError(statusText || 'API response error', status)

  if (promise.json) {
    const errorJson = await promise.json()

    if (errorJson.errors) {
      fetchError.errors = errorJson.errors
    } else {
      fetchError.errors = errorJson.error
    }
  }

  throw fetchError
}

export function downloadFileWebSocket(collection) {
  return new Promise((resolve, reject) => {
    const fileRef = firebase
      .database()
      .ref()
      .child(`users/${getUserId()}/${collection}`)

    fileRef.on(
      'value',
      (snapshot) => {
        const val = snapshot.val()
        if (val)
          if (val.errors) reject(val)
          else resolve(val)
      },
      (error) => {
        reject(error)
      }
    )
  })
}

export const get = async (url) => {
  return handleFetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

// GET method version for use with React Query
export const getV2 = (url) => {
  // Create an AbortController to get the signal for cancel the request
  const controller = new AbortController()
  const { signal } = controller

  // React Query needs a promise in the queryFn option
  const promise = handleFetchV2(url, {
    method: 'GET',
    signal, // Add the signal to the request
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })

  // Set a cancel method for React Query to cancel the request
  promise.cancel = () => controller.abort()

  return promise
}

export const post = async (url, data) => {
  return handleFetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(data),
  })
}

// POST method version for use with React Query
export const postV2 = (url, data, initialConfig = {}) => {
  let body = data
  const { headers: customHeaders, ...restInitialConfig } = initialConfig
  const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${getToken()}`,
  })

  if (customHeaders) {
    Object.entries(customHeaders).forEach(([header, value]) => {
      headers.set(header, value)
    })
  }

  if (!(data instanceof FormData)) {
    body = JSON.stringify(data)
    headers.set('Content-Type', 'application/json')
  }

  return handleFetchV2(url, {
    method: 'POST',
    headers,
    body,
    ...restInitialConfig,
  })
}

export const postFile = (url, data) => {
  return handleFetchV2(url, {
    method: 'POST',
    headers: { Authorization: `Bearer ${getToken()}` },
    body: data,
  })
}

export const patch = async (url, data) => {
  return handleFetch(url, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(data),
  })
}

export const patchV2 = (url, data, initialConfig = {}) => {
  let body = data
  const { headers: customHeaders, ...restInitialConfig } = initialConfig
  const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${getToken()}`,
  })

  if (customHeaders) {
    Object.entries(customHeaders).forEach(([header, value]) => {
      headers.set(header, value)
    })
  }

  if (!(data instanceof FormData)) {
    body = JSON.stringify(data)
    headers.set('Content-Type', 'application/json')
  }

  return handleFetchV2(url, {
    method: 'PATCH',
    headers,
    body,
    ...restInitialConfig,
  })
}

export const put = async (url, data) => {
  let body = data
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${getToken()}`,
  }

  if (!(data instanceof FormData)) {
    body = JSON.stringify(data)
    headers['Content-Type'] = 'application/json'
  }

  return handleFetch(url, {
    method: 'PUT',
    headers,
    body,
  })
}

// PUT method version for use with React Query
export const putV2 = (url, data) => {
  let body = data
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${getToken()}`,
  }

  if (!(data instanceof FormData)) {
    body = JSON.stringify(data)
    headers['Content-Type'] = 'application/json'
  }

  return handleFetchV2(url, {
    method: 'PUT',
    headers,
    body,
  })
}

const deleteMethod = async (url) => {
  return handleFetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

// DELETE method version for use with React Query
const deleteV2 = (url) => {
  return handleFetchV2(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

export { deleteMethod as delete }

export default {
  get,
  post,
  patch,
  put,
  delete: deleteMethod,
  getV2,
  postV2,
  postFile,
  putV2,
  patchV2,
  deleteV2,
}
